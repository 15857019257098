import React, { useState } from 'react'

import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import { ModalComponent } from '../modals/modalComponent'

interface ISelectLanguageDropdownModalProps {
  languageText: ITranslationObject
  label?: string
  name?: string
  languages: IDropdownList[]
  languageValue: string
  closeModal: () => void
  handleSaveLanguage: (selectedValue: string) => void
}

export const SelectLanguageDropdownModal = (props: ISelectLanguageDropdownModalProps) => {
  const [selectedLanguageValue, setSelectedLanguageValue] = useState<string>(props.languageValue)

  const handleLanguageSelect = (selectedItem: IDropdownSelectedItem): void => {
    setSelectedLanguageValue(selectedItem.value)
  }

  const handleSaveClick = (): void => {
    if (props.languageValue !== selectedLanguageValue) {
      props.handleSaveLanguage(selectedLanguageValue)
    }
    props.closeModal()
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, 'Select language')}
        submitButtonText={getLanguageValue(props.languageText, 'Save')}
        cancelButtonText={getLanguageValue(props.languageText, 'Discard')}
        handleSubmitClick={handleSaveClick}
        handleCancelClick={props.closeModal}
      >
        <DropdownSelect
          name={props.name || 'language'}
          label={props.label ? props.label : getLanguageValue(props.languageText, 'Language')}
          list={props.languages}
          value={selectedLanguageValue}
          handleDropdownSelect={handleLanguageSelect}
        />
      </ModalComponent>
    </>
  )
}
