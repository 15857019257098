import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'

import { deleteUser, getAdminUserList, getUserList, impersonatedAuthenticate } from './actions'
import { RootState } from '@app/store/configureStore'
import { BooleanCell } from '../../components/reactTable/booleanCell'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { getUserDetails, sendEmailForResetPassword } from '../auth/signUp/actions'
import { UserActionsCell } from './userActionsCell'
import { addToast, setSpinner } from '../actions'
import { LoginTypeEnum, RoleEnum } from '../commonEnums'
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '@app/consts'
import { IPageConfiguredInfo, UserId } from '../reducer'
import { getInitialPageConfiguredInfoState } from '@app/components/reactTable/reactTable'
import { AnyAction } from 'redux'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { IUsers } from '../commonInterfaces'
import { MultiRoleBehavior, useAuthFlow } from '@app/auth'

export interface IFetchUsersBody {
  filter: string
  sorting: string
  maxResultCount: number
  skipCount: number
  role: number
}

export interface IUserRole {
  /**
   * The role of the users we're listing. Not the role of the current user, as the name suggests.
   */
  roleId: number
}

export const useUserList = (props: IUserRole) => {
  const { roleId } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMounted = useRef<boolean>(false)
  const pathname = window.location.pathname

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)
  const pageConfiguredInfo: IPageConfiguredInfo =
    useSelector((state: RootState) => state.mainReducer.pageConfiguredInfo).find(
      (info) => info.pathname === pathname
    ) ?? getInitialPageConfiguredInfoState()
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [isPathChanged, setIsPathChanged] = useState(false) // When switched b/w Admins/Facilitators/Participants
  const [data, setdata] = useState<IUsers[]>([])
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [editedRowId, setEditedRowId] = useState<UserId>(0 as UserId)
  const [deleteRowId, setDeleteRowId] = useState<UserId>(0 as UserId)
  const [selectedActionRowId, setSelectedActionRowId] = useState<UserId>(0 as UserId)
  const [selectedActionRowEmail, setSelectedActionRowEmail] = useState<string>('')
  const [selectedActionRowName, setSelectedActionRowName] = useState<string>('')
  const [resetPaswordModal, setResetPaswordModal] = useState<boolean>(false)
  const [loginUserModal, setLoginUserModal] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(DEFAULT_TABLE_ROWS_PER_PAGE) // items_per_page
  const [pageCount, setPageCount] = useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0) // page_number_selected
  const [userUpdated, setUserUpdated] = useState<boolean>(false) // This flag is used to re-reder the users data on requirement
  const [openUserModal, setOpenUserModal] = useState<boolean>(false)
  const authFlow = useAuthFlow(dispatch, navigate)

  useEffect(() => {
    isMounted.current = false
    setIsPathChanged(true)
  }, [pathname])

  const fetchUsers = (
    skipCount: number,
    existingFilter?: string,
    existingSorting?: string,
    existingPageLimit?: number
  ): void => {
    dispatch(setSpinner(true))

    const body: IFetchUsersBody = {
      filter: existingFilter !== undefined ? existingFilter : filter,
      sorting: existingSorting !== undefined ? existingSorting : sorting,
      maxResultCount: existingPageLimit !== undefined ? existingPageLimit : pageLimit,
      skipCount: skipCount,
      role: roleId,
    }

    if (roleId === RoleEnum.Admin) {
      getAdminUserList(body, dispatch)
        .then((response) => {
          if (response) {
            const pageSize = Math.ceil(response.totalCount / pageLimit)
            setdata([...response.items])
            setTotalCount(response.totalCount)
            setPageCount(pageSize)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
          if (!isMounted.current) isMounted.current = true
          setIsPathChanged(false)
        })
    } else {
      getUserList(body, dispatch)
        .then((response) => {
          if (response) {
            const pageSize = Math.ceil(response.totalCount / pageLimit)
            setdata([...response.items])
            setTotalCount(response.totalCount)
            setPageCount(pageSize)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
          if (!isMounted.current) isMounted.current = true
          setIsPathChanged(false)
        })
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected
      fetchUsers(skipCount)
    }
  }, [pageSelected, sorting, userUpdated])

  useEffect(() => {
    if (isMounted.current) {
      fetchUsers(0)
    }
  }, [filter, pageLimit, roleId])

  useEffect(() => {
    if (isPathChanged) {
      const { searchText, sorting, pageLimit, pageSelected } = pageConfiguredInfo
      setFilter(searchText)
      setSorting(sorting)
      setPageLimit(pageLimit)
      setPageSelected(pageSelected)
      fetchUsers(pageLimit * pageSelected, searchText, sorting, pageLimit)
    }
  }, [isPathChanged])

  const handleEditClick = (id: UserId): void => {
    setEditedRowId(id)
  }

  const handleDeleteClick = (id: UserId, fullName: string): void => {
    setDeleteRowId(id)
    setSelectedActionRowName(fullName)
  }

  const handleDeleteUser = (): void => {
    deleteUser(deleteRowId, dispatch).then((response) => {
      if (response?.success) {
        setDeleteRowId(0 as UserId)
        setSelectedActionRowName('')
        setUserUpdated(!userUpdated)
      }
    })
  }

  const handleRowActions = (id?: UserId, fullName?: string, email?: string): void => {
    if (id && fullName && email) {
      setSelectedActionRowId(id)
      setSelectedActionRowName(fullName)
      setSelectedActionRowEmail(email)
    } else {
      setSelectedActionRowId(0 as UserId)
      setSelectedActionRowName('')
      setSelectedActionRowEmail('')
      if (resetPaswordModal) setResetPaswordModal(false)
      if (loginUserModal) setLoginUserModal(false)
    }
  }

  const handleResetPasswordClick = (id: UserId, fullName: string, email: string): void => {
    handleRowActions(id, fullName, email)
    setResetPaswordModal(true)
  }

  const handleResetPassword = (): void => {
    sendEmailForResetPassword(selectedActionRowEmail, userLanguage.userLanguageCode, dispatch).then(
      (response) => {
        if (response?.status === 200) {
          handleRowActions()
          dispatch(addToast('Reset code sent') as AnyAction)
        }
      }
    )
  }

  const handleLoginUserClick = (id: UserId, fullName: string, email: string): void => {
    handleRowActions(id, fullName, email)
    setLoginUserModal(true)
  }

  async function handleImpersonatedAuthenticate(userId: UserId) {
    dispatch(setSpinner(true))
    await impersonatedAuthenticate(userId, dispatch, roleId)
      .then((response) => {
        // FIXME: i don't see us ever setting the language of the impersonated user. might be a bug.
        getUserDetails(dispatch, userLanguage.userLanguageCode).then((details) => {
          authFlow.redirectToStartPageByRole(
            response.accessToken.roleName,
            details,
            MultiRoleBehavior.Ignore
          )
        })
        dispatch(addToast('Logged in successfully') as AnyAction)
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const handleLoginUser = (): void => {
    handleRowActions()
    handleImpersonatedAuthenticate(selectedActionRowId)
  }

  const closeCustomModal = (): void => {
    handleRowActions()
  }

  const tableHeader: Array<ColumnDef<IUsers, keyof IUsers>> = [
    {
      header: '',
      accessorKey: 'actions',
      enableSorting: false,
      cell: ({ ...props }) => (
        <UserActionsCell
          id={props.row.original.id}
          roleId={roleId}
          isActive={props.row.original.isActive}
          languageText={languageText}
          email={props.row.original.emailAddress}
          name={`${props.row.original.name} ${props.row.original.surname}`}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          handleResetPasswordClick={handleResetPasswordClick}
          handleLoginUserClick={handleLoginUserClick}
        />
      ),
    },
    {
      header: getLanguageValue(languageText, 'ID'),
      accessorKey: 'id',
    },
    {
      header: getLanguageValue(languageText, 'Name'),
      accessorKey: 'name',
    },
    {
      header: getLanguageValue(languageText, 'Surname'),
      accessorKey: 'surname',
    },
    {
      header: getLanguageValue(languageText, 'Email'),
      accessorKey: 'emailAddress',
    },
    {
      header: getLanguageValue(languageText, 'Email Verified'),
      accessorKey: 'isEmailConfirmed',
      cell: ({ ...props }) => (
        <BooleanCell languageText={languageText} boolValue={props.row.original.isEmailConfirmed} />
      ),
    },
    {
      header: getLanguageValue(languageText, 'Active'),
      accessorKey: 'isActive',
      cell: ({ ...props }) => (
        <BooleanCell languageText={languageText} boolValue={props.row.original.isActive} />
      ),
    },
  ]

  if (roleId !== RoleEnum.Admin) {
    tableHeader.splice(
      7,
      0,
      {
        header: getLanguageValue(languageText, 'Default Client'),
        accessorKey: 'userClients',
        cell: ({ ...props }) => {
          if (props.row.original.userClients && props.row.original.userClients.length > 0) {
            const defaultClient = props.row.original.userClients.find(
              (item) => item.isDefaultClient === true
            )
            return <>{defaultClient && <div>{defaultClient.name}</div>}</>
          }
          return <></>
        },
      },
      {
        header: getLanguageValue(languageText, 'Number Of Clients'),
        accessorKey: '',
        cell: ({ ...props }) => (
          <p className='text-center'>{props.row.original.userClients?.length}</p>
        ),
      }
    )
  }

  if (roleId === RoleEnum.Participant) {
    tableHeader.splice(
      7,
      0,
      {
        header: getLanguageValue(languageText, 'Profiles'),
        accessorKey: 'numberOfProfiles',
      },
      {
        header: getLanguageValue(languageText, 'Delivered Profiles'),
        accessorKey: 'deliveredProfiles',
      }
    )
  }

  if (isLoggedInRoleAdmin(loggedInUserRole)) {
    tableHeader.push({
      header: getLanguageValue(languageText, 'Source'),
      accessorKey: 'sourceType',
    })

    tableHeader.push({
      header: getLanguageValue(languageText, 'Login'),
      accessorKey: 'lastLoginType',
      cell: ({ ...props }) => {
        if (props.row.original.lastLoginType) {
          return <>{<div>{LoginTypeEnum[props.row.original.lastLoginType]}</div>}</>
        }
        return <></>
      },
    })
  }

  const addUserClick = (): void => {
    setOpenUserModal(true)
  }

  const closeUserModal = (refreshUserList: boolean): void => {
    if (refreshUserList) {
      setUserUpdated(!userUpdated)
    }
    if (editedRowId) {
      setEditedRowId(0 as UserId)
    }
    setOpenUserModal(false)
  }

  const closeDeleteModal = (): void => {
    setDeleteRowId(0 as UserId)
  }

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    sorting,
    pageLimit,
    pageSelected,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    resetPaswordModal,
    loginUserModal,
    roleId,
    openUserModal,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    handleDeleteUser,
    handleResetPassword,
    handleLoginUser,
    closeCustomModal,
    addUserClick,
    closeUserModal,
    closeDeleteModal,
  }
}
