import React from 'react'

import { useLatestNews } from './hooks'
import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { IDocumentCategory, ILatestDocumentList } from './types'
import { FileExtentionType, FileType } from '../../../commonEnums'

export const LatestDocuments = (): JSX.Element => {
  const {
    languageText,
    documentCategories,
    documents,
    handleNavigateBack,
    handleDocDownloadClick,
  } = useLatestNews()

  return (
    <>
      <div className='p-2 pt-4 p-md-4'>
        <h3 className='fw-bold mb-3'>
          <span role='button' onClick={() => handleNavigateBack()}>
            {getLanguageValue(languageText, 'Resources')}
          </span>
          <span className='px-2'>/</span>
          {getLanguageValue(languageText, 'Documents')}
        </h3>

        <div className='bg-white mt-3 p-2 p-md-4 rounded shadow-sm'>
          {documentCategories && documentCategories.length > 0 ? (
            <>
              {documentCategories.map((category: IDocumentCategory) => (
                <div key={category.id} className=''>
                  <h3>{category.name}</h3>

                  {documents
                    .filter(
                      (document: ILatestDocumentList) => document.documentCategoryId === category.id
                    )
                    .map((document: ILatestDocumentList) => (
                      <div key={document.id} className='bg-secondary-subtle rounded my-3 p-3'>
                        <div className='row align-items-center'>
                          <div className='col fs-6'>
                            <div className='d-flex justify-content-between'>
                              <div>
                                {document.title}
                                <br />
                                {document.excerpt}
                              </div>
                              {(() => {
                                switch (true) {
                                  case document.originalFileName?.includes(FileExtentionType.PDF):
                                    return FileType.PDF
                                  case document.originalFileName?.includes(FileExtentionType.PPT):
                                    return FileType.PPT
                                  case document.originalFileName?.includes(FileExtentionType.DOC):
                                  case document.originalFileName?.includes(FileExtentionType.DOCX):
                                    return FileType.WORD
                                  default:
                                    return ''
                                }
                              })()}
                            </div>
                          </div>
                          <div className='col-auto'>
                            <a
                              href={document.originalFileName}
                              title={document.originalFileName}
                              target='_blank'
                              download
                              onClick={(e) => {
                                e.preventDefault()
                                handleDocDownloadClick(document.id, document.originalFileName)
                              }}
                              className='text-dark'
                              rel='noreferrer'
                            >
                              <i className='bi bi-download fs-3' />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}

                  {documents.filter(
                    (document: ILatestDocumentList) => document.documentCategoryId === category.id
                  ).length === 0 && (
                    <div className='rounded border border-secondary text-center py-4'>
                      {getLanguageValue(languageText, 'No documents available')}
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <h5 className='text-center'>
              {getLanguageValue(languageText, 'No Latest documents to display')}
            </h5>
          )}
        </div>
      </div>
    </>
  )
}
