import React from 'react'

import { Input } from '../../../components/formComponents/input'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import { SubscriptionType } from '../../clientList/addEditModal/clientEnums'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ISubscriptionCurrency } from '../addEditSubscription/hooks'

enum SubscriptionCurrencyInputs {
  currencyId = 'currencyId',
  price = 'price',
  priceAnnual = 'priceAnnual',
  priceMonthly = 'priceMonthly',
}

interface ISubscriptionCurrenciesProps {
  languageText: ITranslationObject
  subscriptionType: string
  currencies: IDropdownList[]
  currencyInfo: ISubscriptionCurrency[]
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
  handleDropdownChange: (selectedItem: IDropdownSelectedItem, index: number) => void
  handleDeleteCurrency: (index: number) => void
  handleAddCurrency: () => void
}

export const SubscriptionCurrencies = (props: ISubscriptionCurrenciesProps) => {
  const {
    languageText,
    subscriptionType,
    currencies,
    currencyInfo,
    handleInputChange,
    handleDropdownChange,
    handleDeleteCurrency,
    handleAddCurrency,
  } = props

  return (
    <div className='custom-card-gray '>
      {currencyInfo.map((currency, index) => {
        return (
          <form key={index}>
            {index > 0 && <div className='border grey-border border-top-0' />}
            <div className='row p-5'>
              <div className='col-md-3 col-12'>
                <DropdownSelect
                  name={SubscriptionCurrencyInputs.currencyId}
                  label={getLanguageValue(languageText, 'Currency')}
                  defaultLabel={getLanguageValue(languageText, 'Select Currency')}
                  list={currencies}
                  value={currency.currencyId}
                  focusInput={{
                    touched: true,
                    errorMessage: currency.isError
                      ? getLanguageValue(languageText, 'Please fill required fields')
                      : '',
                  }}
                  handleDropdownSelect={(e) => handleDropdownChange(e, index)}
                  required
                />
              </div>

              {Number(subscriptionType) === SubscriptionType.Plan ? (
                <>
                  <div className='col-md-3 col-12'>
                    <Input
                      name={SubscriptionCurrencyInputs.price}
                      label={getLanguageValue(languageText, 'Price')}
                      type='number'
                      value={currency.price}
                      handleInputChange={(e) => handleInputChange(e, index)}
                      required
                    />
                  </div>
                  <div className='col-md-3 col-12' />
                </>
              ) : (
                <>
                  <div className='col-md-3 col-12'>
                    <Input
                      name={SubscriptionCurrencyInputs.priceAnnual}
                      label={getLanguageValue(languageText, 'Price Annual')}
                      type='number'
                      value={currency.priceAnnual}
                      handleInputChange={(e) => handleInputChange(e, index)}
                      required
                    />
                  </div>
                  <div className='col-md-3 col-12'>
                    <Input
                      name={SubscriptionCurrencyInputs.priceMonthly}
                      label={getLanguageValue(languageText, 'Price Monthly')}
                      type='number'
                      value={currency.priceMonthly}
                      handleInputChange={(e) => handleInputChange(e, index)}
                      required
                    />
                  </div>
                </>
              )}

              <div className='col-md-3 col-12 mt-8'>
                {index === 0 ? (
                  <button
                    className='btn btn-success w-100'
                    type='button'
                    onClick={handleAddCurrency}
                  >
                    {getLanguageValue(languageText, 'Add')}
                  </button>
                ) : (
                  <button
                    className='btn btn-dark w-100'
                    type='button'
                    onClick={() => handleDeleteCurrency(index)}
                  >
                    {getLanguageValue(languageText, 'Delete')}
                  </button>
                )}
              </div>
            </div>
          </form>
        )
      })}
    </div>
  )
}
