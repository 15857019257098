import React, { useEffect } from 'react'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'

import { useAddEditModal } from './hooks'
import { Checkbox } from '../../../components/formComponents/checkbox'
import { DropdownSelect } from '@app/components/formComponents/dropdownSelect'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface IAddEditLanguageModalProps {
  rowData: any
  closeLanguageModal: (refreshLanguageList?: boolean) => void
}

export const AddEditLanguageModal: React.FC<IAddEditLanguageModalProps> = (props) => {
  const { rowData, closeLanguageModal } = props

  const {
    languageText,
    LanguageInputs,
    languageInfo,
    focusInput,
    isSubmitting,
    allLanguageCultures,
    allFlags,
    handleCallbackOnMount,
    handleCheckboxChange,
    handleDropdownSelect,
    handleValidationOnSubmit,
    handleSubmit,
  } = useAddEditModal()

  let isEditClicked: boolean = false
  if (Object.keys(rowData).length > 0) isEditClicked = true

  useEffect(() => {
    handleCallbackOnMount(rowData, isEditClicked)
  }, [])

  const onSubmitClick = async (): Promise<void> => {
    if (!handleValidationOnSubmit(rowData.id)) return
    const responseStatus = await handleSubmit(rowData.id)

    if (responseStatus === 200) {
      closeAddEditLanguageModal(true)
    }
  }

  const closeAddEditLanguageModal = (value: boolean): void => {
    closeLanguageModal(value)
  }

  const closeModal = () => closeAddEditLanguageModal(false)

  return (
    <>
      <ModalComponent
        headerText={
          isEditClicked
            ? getLanguageValue(languageText, 'Edit Language')
            : getLanguageValue(languageText, 'Add Language')
        }
        submitButtonText={getLanguageValue(languageText, 'Submit')}
        cancelButtonText={getLanguageValue(languageText, 'Discard')}
        handleSubmitClick={onSubmitClick}
        handleCancelClick={closeModal}
        submitButtonDisabled={isSubmitting}
        cancelButtonDisabled={isSubmitting}
      >
        <form>
          <div>
            <DropdownSelect
              dropdownHeight='mh-500px'
              name={LanguageInputs.language}
              label={getLanguageValue(languageText, 'Language')}
              defaultLabel={getLanguageValue(languageText, 'Select Language')}
              list={allLanguageCultures}
              value={languageInfo.language}
              focusInput={focusInput.language}
              handleDropdownSelect={handleDropdownSelect}
              searchOption
              required
            />
            <DropdownSelect
              dropdownHeight='mh-400px'
              name={LanguageInputs.flag}
              label={getLanguageValue(languageText, 'Flag')}
              defaultLabel={getLanguageValue(languageText, 'Select Country')}
              list={allFlags}
              value={languageInfo.flag}
              focusInput={focusInput.flag}
              handleDropdownSelect={handleDropdownSelect}
              searchOption
              required
            />
            <Checkbox
              className='mb-5'
              labelClass='me-5'
              name={LanguageInputs.isEnabled}
              label={getLanguageValue(languageText, 'Is Enabled')}
              value={languageInfo.isEnabled}
              handleCheckboxChange={handleCheckboxChange}
            />
            <Checkbox
              className='mb-5'
              labelClass='me-5'
              name={LanguageInputs.showFacilitator}
              label={getLanguageValue(languageText, 'Show Facilitator')}
              value={languageInfo.showFacilitator}
              handleCheckboxChange={handleCheckboxChange}
            />
            <Checkbox
              className='mb-5'
              labelClass='me-5'
              name={LanguageInputs.showParticipant}
              label={getLanguageValue(languageText, 'Show Participant')}
              value={languageInfo.showParticipant}
              handleCheckboxChange={handleCheckboxChange}
            />
            <Checkbox
              className='mb-5'
              labelClass='me-5'
              name={LanguageInputs.cultureNormUse}
              label={getLanguageValue(languageText, 'Culture Norm Use')}
              value={languageInfo.cultureNormUse}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
        </form>
      </ModalComponent>
    </>
  )
}
