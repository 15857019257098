import React from 'react'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'

interface IDefaultClientCellProps {
  languageText: ITranslationObject
  name: string
  isDefault: boolean
}

export const DefaultClientCell = (props: IDefaultClientCellProps) => {
  const { languageText, name, isDefault } = props

  return (
    <>
      {name}
      {isDefault && (
        <span className='badge badge-light fw-bold fs-7 mx-3'>
          {getLanguageValue(languageText, 'Default')}
        </span>
      )}
    </>
  )
}
