import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../api'
import { setNotification } from '../actions'
import {
  CLEAR_PARTICIPANT_PROFILE_DETAILS,
  GET_EMAIL_CODE,
  PARTICIPANT_PROFILE_EXISTS,
  SET_PARTICIPANT_PROFILE_DETAILS,
} from './constants'
import { IProfileDemographics } from './profileParticipantPages/demographicPage.tsx/hooks'
import { IParticipantRoleData } from './profileParticipantPages/rolePage/hooks'
import { IUpdateParticipantFormLanguageBody } from './selectLanguagePage/hooks'
import { IUserRespondentsBody } from './profileParticipantPages/inviteRespondents/inviteRespondents'
import { ISaveParticipantFieldValueBody } from '../profileList/addProfile/roleSettings/roleSettingsWithEditOption'
import {
  ICreateManualRespondentsBody,
  ICreateRespondentsParams,
  IRespondentEmailPreviewBody,
} from './profileParticipantPages/inviteRespondents/sendRespondentsInvitation'
import { IRespondentsInvitationBody } from './profileParticipantPages/inviteRespondents/resentRespondentInvite'
import {
  IFillParticipantProfileSelfFormBody,
  IFillProfileRespondentOtherFormBody,
} from '../../components/selfForm/selfForm'
import { IProfileDetails } from './reducer'
import { IProfileFormInfoResponse } from '@app/commonUtils/participantProfileHelper'
import {
  IClearParticipantProfileDetailsAction,
  IGetEmailCodeAction,
  ISetParticipantProfileDetailsAction,
  ISetParticipantProfileExists,
} from './interface'
import { AccessTokenScope, AuthenticateResult, setLoggedInUserRole } from '../auth/signUp/actions'
import { ApiResponse } from '@app/types'
import { IPresentationData } from './profileParticipantPages/profilePage/hooks'
import { ProfileId, RespondentId } from '../reducer'
import { ActivityStatus, ProfileStatus } from '../profileList/profileEnums'
import { IRespondentList } from './profileParticipantPages/inviteRespondents/selectFromList'

export const getEmailCode = (payload: string): IGetEmailCodeAction => ({
  type: GET_EMAIL_CODE,
  payload,
})

export const setParticipantProfileExists = (payload: boolean): ISetParticipantProfileExists => ({
  type: PARTICIPANT_PROFILE_EXISTS,
  payload,
})

export const setParticipantProfileDetails = (
  payload: IProfileDetails
): ISetParticipantProfileDetailsAction => ({
  type: SET_PARTICIPANT_PROFILE_DETAILS,
  payload,
})

export const clearParticipantProfileDetails = (): IClearParticipantProfileDetailsAction => ({
  type: CLEAR_PARTICIPANT_PROFILE_DETAILS,
  payload: undefined,
})

export const sendParticipantVerificationCodeEmail = async (
  uniqueCode: string,
  languageCode: string,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const endpointWithParams = `${apiEndPoints.sendParticipantVerificationCodeEmail}?uniqueCode=${uniqueCode}&languageCode=${languageCode}`
    const response = await api.post(endpointWithParams)
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

type AuthenticateVerificationLinkResult = {
  accessToken: AccessTokenScope
  uniqueCode: string
  verificationCode: string
  expireInSeconds: number
}

export const verifyParticipantVerificationLink = async (
  verificationCode: string,
  dispatch: Dispatch
): Promise<AuthenticateVerificationLinkResult> => {
  try {
    const endpointWithParams = `${apiEndPoints.verifyParticipantVerificationLink}?c=${verificationCode}`
    const response =
      await api.post<ApiResponse<AuthenticateVerificationLinkResult>>(endpointWithParams)

    if (!response.data.success) {
      return await Promise.reject(response.data)
    }

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const verifyParticipantVerificationCode = async (
  uniqueCode: string,
  verificationCode: string,
  dispatch: Dispatch
): Promise<ApiResponse<AuthenticateResult>> => {
  try {
    const endpointWithParams = `${apiEndPoints.verifyParticipantVerificationCode}?uniqueCode=${uniqueCode}&verificationCode=${verificationCode}`
    const response = await api.post<ApiResponse<AuthenticateResult>>(endpointWithParams)

    if (!response.data.success) {
      return await Promise.reject(response.data)
    }

    if (response.data.result.accessToken) {
      // Setting logged in role
      const roleName = response.data.result.accessToken.roleName
      dispatch(setLoggedInUserRole(roleName))

      // FIXME: hack for localhost frontend on non-localhost backend
      window.localStorage.setItem('logged_in', '1')
    }

    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const resetParticipantPassword = async (
  newPassword: string,
  dispatch: Dispatch
): Promise<boolean> => {
  const body = {
    newPassword: newPassword,
  }
  try {
    const response = await api.post(apiEndPoints.resetParticipantPassword, JSON.stringify(body), {})
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getParticipantFormInfo = async (
  uniqueCode: string,
  dispatch: Dispatch
): Promise<IProfileFormInfoResponse> => {
  try {
    const response = await api.get(apiEndPoints.getParticipantFormInfo, {
      params: { uniqueCode },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

type CheckShittyLinkResponse = {
  isProfile: boolean
  isRespondent: boolean
  languageId: number
  languageName: string
  isPasswordSet: boolean
  profileId: ProfileId
  profileRespondentId: RespondentId
  emailAddress: string
  participantName: string
  isFormFilledData: boolean
  isDemographicsFilled: boolean
  isFormFilled: boolean
  activityStatus: ActivityStatus
  profileStatus: ProfileStatus
}

export const checkValidParticipantOrRespondentLink = async (
  uniqueCode: string,
  dispatch: Dispatch
): Promise<CheckShittyLinkResponse> => {
  try {
    const endpointWithParams = `${apiEndPoints.checkValidParticipantOrRespondentLink}?uniqueCode=${uniqueCode}`
    const response = await api.post(endpointWithParams)
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const sendParticipantPasswordEmail = async (
  uniqueCode: string,
  languageCode: string,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const endpointWithParams = `${apiEndPoints.sendParticipantPasswordEmail}?uniqueCode=${uniqueCode}&languageCode=${languageCode}`
    const response = await api.post(endpointWithParams)
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getProfileDemographicById = async (
  id: ProfileId,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getProfileDemographicById, {
      params: { id },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getProfileParticipantRole = async (
  profileId: ProfileId,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getProfileParticipantRole, {
      params: { profileId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const saveParticipantFieldValue = async (
  body: ISaveParticipantFieldValueBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(apiEndPoints.saveParticipantFieldValue, JSON.stringify(body), {})
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
  return false
}

export const updateProfileDemographics = async (
  body: IProfileDemographics,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.put(apiEndPoints.updateProfileDemographics, JSON.stringify(body), {})
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateParticipantFormLanguage = async (
  body: IUpdateParticipantFormLanguageBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateParticipantFormLanguage,
      JSON.stringify(body),
      {
        params: body,
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const fillProfileRespondentOtherForm = async (
  body: IFillProfileRespondentOtherFormBody,
  token: string,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.post(
      apiEndPoints.fillProfileRespondentOtherForm,
      JSON.stringify(body),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: body,
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getUserForRespondents = async (
  body: IUserRespondentsBody,
  dispatch: Dispatch
): Promise<IRespondentList[]> => {
  try {
    const response = await api.get(apiEndPoints.getUserForRespondents, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getRespondentEmailPreview = async (
  body: IRespondentEmailPreviewBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getRespondentEmailPreview, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createManualProfileParticipantRespondents = async (
  body: ICreateManualRespondentsBody[],
  params: ICreateRespondentsParams,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.createManualProfileParticipantRespondents,
      JSON.stringify(body),
      {
        params: params,
      }
    )
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const sendRespondentsInvitation = async (
  body: IRespondentsInvitationBody[],
  languageCode: string,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.sendRespondentsInvitation, JSON.stringify(body), {
      params: { languageCode },
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteRespondent = async (id: number, dispatch: Dispatch): Promise<any> => {
  const paramQuery = {
    id: id,
  }
  try {
    const response = await api.delete(apiEndPoints.deleteRespondent, {
      params: paramQuery,
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateParticipantLanguage = async (
  languageCode: string,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateParticipantLanguage,
      {},
      {
        params: { languageCode },
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const canParticipantChangeLanguageWithoutEffect = async (
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.canParticipantChangeLanguageWithoutEffect, {}, {})
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getParticipantPresentationByPresentationId = async (
  presentationId: number,
  dispatch: Dispatch
): Promise<IPresentationData> => {
  try {
    const response = await api.get(apiEndPoints.getParticipantPresentationByPresentationId, {
      params: { presentationId },
    })
    if (!response.data.success) {
      return await Promise.reject()
    }
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
