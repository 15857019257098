import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import * as React from 'react'
import { IEmailTemplate } from './types'
import { Input } from '@app/components/formComponents/input'
import { TextArea } from '@app/components/formComponents/textArea'
import { ModalComponent } from '@app/components/modals/modalComponent'

type EditModalProps = {
  close: React.MouseEventHandler<HTMLElement>
  kind: 'create' | 'edit'
  languageText: ITranslationObject
  isSubmitting: boolean
  onChange: React.ChangeEventHandler
  onSave: () => unknown
  value: IEmailTemplate
}

export const EditModal: React.FC<EditModalProps> = (props) => {
  const languageText = props.languageText
  const header =
    props.kind === 'edit'
      ? getLanguageValue(props.languageText, 'Edit email template')
      : getLanguageValue(props.languageText, 'Add email template')

  return (
    <>
      <ModalComponent
        width='xl'
        headerText={header}
        submitButtonDisabled={props.isSubmitting}
        submitButtonText={getLanguageValue(languageText, 'Save')}
        handleSubmitClick={props.onSave}
        cancelButtonText={getLanguageValue(languageText, 'Discard')}
        handleCancelClick={props.close}
      >
        <form>
          <Input
            name='name'
            label={getLanguageValue(languageText, 'Name')}
            placeholder={getLanguageValue(languageText, 'Name')}
            value={props.value.name}
            handleInputChange={props.onChange}
            required
          />
          <TextArea
            name='content'
            label={getLanguageValue(languageText, 'Content')}
            placeholder={getLanguageValue(languageText, 'Content')}
            value={props.value.content}
            onChange={props.onChange}
            rows={24}
            required
          />
        </form>
      </ModalComponent>
    </>
  )
}
