import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../../api'
import { setNotification } from '../../../actions'
import { IDocumentList } from '../types'

export const createDocs = async (body: IDocumentList, dispatch: Dispatch): Promise<any> => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  try {
    const response = await api.post(apiEndPoints.createDocument, body, {
      headers: headers,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateDocs = async (body: IDocumentList, dispatch: Dispatch): Promise<any> => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  try {
    const response = await api.put(apiEndPoints.updateDocument, body, {
      headers: headers,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
