import React from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface ILogModalProps {
  languageText: ITranslationObject
  isEmail: boolean
  inviteDetails: Array<string>
  handleCloseClick: () => void
}

export const LogModal: React.FC<ILogModalProps> = (props) => {
  const { languageText, isEmail, inviteDetails, handleCloseClick } = props

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, isEmail ? 'Email details' : 'SMS details')}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={handleCloseClick}
      >
        {inviteDetails.map((detail, index) => (
          <div key={index} className='mb-4'>
            {detail}
          </div>
        ))}
      </ModalComponent>
    </>
  )
}
