import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { SocialProviderTypes } from '../../commonEnums'
import { clearNotifications, setSpinner } from '../../actions'
import { getUserExternalLogins } from '../../usersProfile/profileDetails/socialLogins/actions'
import { externalLogin, setSocialLoginStatus, getUserDetails, loggedInUserId } from './actions'
import { RootState } from '@app/store/configureStore'
import { MultiRoleBehavior, useAuthFlow } from '@app/auth'

interface IExternalLoginProps {
  profile: any // what?
  provider: string
}

// why is this some totally separate login flow? shouldn't it be tightly
// integrated with the main login page? any reason for it not to be?
export const ExternalUserLogin: React.FC<IExternalLoginProps> = (props) => {
  const { profile, provider } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formattedProvider = provider.charAt(0).toUpperCase() + provider.slice(1)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)
  const authFlow = useAuthFlow(dispatch, navigate)

  let sub: any = ''
  let accessCode: string = ''
  if (provider === SocialProviderTypes.MICROSOFT) {
    sub = profile.id_token ? JSON.parse(atob(profile.id_token.split('.')[1])).oid : null
  } else {
    sub = profile.sub
    accessCode = profile.code
  }

  const payload = {
    authProvider: formattedProvider,
    providerKey: sub,
    providerAccessToken: profile.access_token,
    providerCode: accessCode,
    provider,
    returnUrl: '/App',
    singleSignIn: true,
  }

  useEffect(() => {
    dispatch(clearNotifications(''))

    externalLogin(dispatch, payload)
      .then((response) => {
        dispatch(setSpinner(true))
        getUserDetails(dispatch, userLanguage.userLanguageCode).then((details) => {
          const userId = details.userId
          dispatch(loggedInUserId(userId)) // come on now, what is this?

          getUserExternalLogins(dispatch).then((socialLogins: string[]) => {
            dispatch(setSocialLoginStatus(socialLogins))
          })
          authFlow.redirectToStartPageByRole(
            response.accessToken.roleName,
            details,
            MultiRoleBehavior.AskUser
          )
        })
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }, [profile.email])

  return <React.Fragment />
}
