import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../api'
import { IRespondentData } from './reducer'
import { SET_RESPONDENT_CODE, SET_RESPONDENT_DATA } from './constant'
import { setNotification } from '../actions'
import { IUpdateRespondentFormLanguageBody } from '../App/respondentRoutes/hooks'
import { AuthenticateResult } from '../auth/signUp/actions'
import { ApiResponse } from '@app/types'

export const setRespondentCode = (payload: string) => ({ type: SET_RESPONDENT_CODE, payload })

export const setRespondentData = (payload: IRespondentData) => ({
  type: SET_RESPONDENT_DATA,
  payload,
})

export const respondentAuthenticate = async (
  respondentEmail: string,
  respondentId: number,
  dispatch: Dispatch
): Promise<AuthenticateResult> => {
  try {
    const response = await api.post<ApiResponse<AuthenticateResult>>(
      apiEndPoints.respondentAuthenticate,
      {},
      {
        params: {
          respondentEmail,
          respondentId,
          tenantId: 1,
        },
      }
    )
    if (!response.data.success) {
      return await Promise.reject(response.data)
    }
    if (response.data.result.accessToken) {
      // FIXME: hack for localhost frontend on non-localhost backend
      window.localStorage.setItem('logged_in', '1')
    }
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateRespondentFormLanguage = async (
  body: IUpdateRespondentFormLanguageBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateRespondentFormLanguage,
      JSON.stringify(body),
      {
        params: body,
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
