import React from 'react'
import clsx from 'clsx'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { renderHTMLAcademy } from '../../../commonUtils/renderHtmlHelper'

interface ICorrectAnswerCardProps {
  languageText: ITranslationObject
  label: string
  isCorrect: boolean
}

export const CorrectAnswerCard = (props: ICorrectAnswerCardProps): JSX.Element => {
  const { languageText, label, isCorrect } = props

  return (
    <div
      className={clsx(
        'p-3 px-md-4 rounded mt-3',
        isCorrect ? 'bg-success-subtle' : 'bg-danger-subtle'
      )}
    >
      <div className='mb-4'>
        {isCorrect ? (
          <i className='bi bi-hand-thumbs-up text-success fs-2'></i>
        ) : (
          <i className='bi bi-exclamation-triangle text-danger fs-2'></i>
        )}
      </div>

      <div className='fw-bold fs-4 mb-3'>{getLanguageValue(languageText, 'Explanation')}:</div>
      {renderHTMLAcademy(label)}
    </div>
  )
}
