import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { triggerLanguageChange } from '../../actions'
import { IRespondentData } from '../reducer'
import { RootState } from '@app/store/configureStore'
import { setRespondentData, updateRespondentFormLanguage } from '../actions'
import { IUpdateRespondentFormLanguageBody } from '@app/containers/App/respondentRoutes/hooks'
import { ILanguageObject } from '@app/commonUtils/languageFunctionsHelper'
import { respondentSelfFormInstructionSteps } from '@app/components/instruction/instructionSteps'

export const useParticipantRespondentLanguageNavbar = () => {
  const dispatch = useDispatch()

  const respondentData: IRespondentData = useSelector(
    (state: RootState) => state.respondentReducer.respondentData
  )
  const currentUserLanguages = useSelector(
    (state: RootState) => state.mainReducer.currentUserLanguages
  )
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const instructionSteps = useMemo(
    () => respondentSelfFormInstructionSteps(languageText),
    [languageText]
  )

  const initialSelectedLanguageData: ILanguageObject = {
    id: 0,
    displayName: '',
    icon: '',
    name: '',
  }
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [languages, setLanguages] = useState<ILanguageObject[]>([])
  const [selectedLanguageData, setSelectedLanguageData] = useState<ILanguageObject>(
    initialSelectedLanguageData
  )

  useEffect(() => {
    if (currentUserLanguages.length > 0) {
      const languages = currentUserLanguages.map((item) => {
        const displayName = item.displayName.split(' ')[0]
        return {
          id: item.id,
          icon: item.icon,
          name: item.name,
          displayName: displayName,
        }
      })
      setLanguages(languages)
    }
  }, [currentUserLanguages])

  useEffect(() => {
    if (languages.length > 0) {
      const userLanguage = respondentData.languageName
      const selectedLang = languages.find((item) => item.name === userLanguage)?.displayName ?? ''
      setSelectedLanguage(selectedLang)
    }
  }, [languages, respondentData.languageName])

  const updateLanguage = (language: ILanguageObject): void => {
    const body: IUpdateRespondentFormLanguageBody = {
      languageCode: language.name,
      profileId: respondentData.profileId,
      profileRespondentId: respondentData.profileRespondentId,
    }

    updateRespondentFormLanguage(body, dispatch).then((response) => {
      if (response) {
        dispatch(
          setRespondentData({
            ...respondentData,
            languageName: language.name,
          })
        )
        triggerLanguageChange(language.name, false, dispatch, language.id)
        setSelectedLanguage(language.displayName)
      }
    })
  }

  const handleChangeDropdownLanguage = (language: ILanguageObject): void => {
    if (language.displayName === selectedLanguage) return

    if (respondentData.isFormFilledData) setSelectedLanguageData(language)
    else updateLanguage(language)
  }

  const cancelLanguageChange = (): void => setSelectedLanguageData(initialSelectedLanguageData)

  const handleChangeLanguage = (): void => {
    updateLanguage(selectedLanguageData)
    setSelectedLanguageData(initialSelectedLanguageData)
  }

  return {
    languages,
    instructionSteps,
    selectedLanguage,
    selectedLanguageData,
    handleChangeDropdownLanguage,
    cancelLanguageChange,
    handleChangeLanguage,
  }
}
