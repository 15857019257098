import React from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface IChangeProfileModalProps {
  languageText: ITranslationObject
  bodyText: string
  handleCancelClick: () => void
  handleSaveClick: () => void
}

export const ChangeProfileModal: React.FC<IChangeProfileModalProps> = (props) => {
  const { languageText, bodyText, handleCancelClick, handleSaveClick } = props

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Change Profile Type')}
        submitButtonText={getLanguageValue(languageText, 'Yes')}
        cancelButtonText={getLanguageValue(languageText, 'No')}
        handleSubmitClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
      >
        {bodyText}
      </ModalComponent>
    </>
  )
}
