import React from 'react'
import { useNavigate } from 'react-router-dom'

import { routePath } from '../../routePaths'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ICourse } from '../types'

interface ICourseBreadcumbProps {
  languageText: ITranslationObject
  course: ICourse
}

export const CourseBreadcumb = (props: ICourseBreadcumbProps): JSX.Element => {
  const { languageText, course } = props
  const navigate = useNavigate()

  return (
    <h4 className='mb-4 d-flex gap-2 flex-wrap fw-bold'>
      <span role='button' onClick={() => navigate(routePath.participantAcademy)}>
        {getLanguageValue(languageText, 'Academy')}
      </span>
      /
      <span
        role='button'
        onClick={() =>
          navigate(
            routePath.participantCategoryCourses.replace(
              ':parentId',
              course.parentCategoryId.toString()
            )
          )
        }
      >
        {course.parentCategoryName}
      </span>
      /
      <span
        role='button'
        onClick={() =>
          navigate(
            routePath.participantCourseLessons.replace(':categoryId', course.categoryId.toString())
          )
        }
      >
        {course.categoryName}
      </span>
      / {course.title}
    </h4>
  )
}
