import React from 'react'

import { ProfileMeasure } from '../../../../../profileEnums'

interface IDeviationValueProps {
  profileMeasure: number
  deviationMessage: string
  deviationSpreadText: string
}

export const DeviationValue = (props: IDeviationValueProps): JSX.Element => {
  const { profileMeasure, deviationMessage, deviationSpreadText } = props

  const isMedium = profileMeasure === ProfileMeasure.Medium
  const isHigh = profileMeasure === ProfileMeasure.High

  return (
    <div className='d-flex gap-2'>
      <div className='d-flex align-items-center'>
        <div
          className='d-flex justify-content-center align-items-center py-1 border mt-1'
          style={{ gap: isHigh ? '14px' : isMedium ? '7px' : '1px', width: '80px' }}
        >
          <div className='p-1 bg-success' />
          <div className='p-1 bg-success' />
          <div className='p-1 bg-success' />
        </div>
      </div>
      <div className='w-75'>
        {deviationMessage}.
        <br />
        {deviationSpreadText}
      </div>
    </div>
  )
}
