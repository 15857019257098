import React from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../../../commonUtils/languageFunctionsHelper'
import { GroupDownload } from './groupDownload'
import { IEditActivity } from '@app/containers/profileList/editProfile/interface'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { ActivityId, ProfileId } from '@app/containers/reducer'

interface IGroupDownloadModalProps {
  id: ActivityId
  languageText: ITranslationObject
  name: string
  profileIds: Array<ProfileId>
  handleCloseClick: () => void
  profileData: IEditActivity
}

export const GroupDownloadModal = (props: IGroupDownloadModalProps): JSX.Element => {
  const { languageText, profileIds, handleCloseClick, name } = props

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Group Profile')}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={handleCloseClick}
        width='xl'
      >
        <GroupDownload
          id={props.id}
          languageText={languageText}
          name={name}
          profileIds={profileIds}
          profileData={props.profileData}
        />
      </ModalComponent>
    </>
  )
}
