import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '@app/store/configureStore'
import { getUserCourseParts, getCourseQuizzes, updateCourseTimespent } from '../actions'
import { IAcademyCategories, ICourse, ICourseParts } from '../types'
import { routePath } from '../../routePaths'
import { setNotification, setSpinner } from '../../actions'

export const useCourse = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { courseId, partId } = useParams()

  const userId = useSelector((state: RootState) => state.loginReducer.userId)
  const courseDetails: ICourse = useSelector(
    (state: RootState) => state.academyReducer.courseDetails
  )
  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initialCategoryState: IAcademyCategories = {
    id: 0,
    parentId: 0,
    name: '',
    description: '',
    topic: '',
    coursesCount: 0,
    lessonsCount: 0,
    progress: 0,
  }
  const initialCourseState: ICourse = {
    id: 0,
    title: '',
    description: '',
    duration: 0,
    parts: 0,
    keyIdeas: 0,
    partsCompleted: 0,
    quizesCompleted: 0,
    courseParts: [],
    quizes: [],
    partsCount: 0,
    category: initialCategoryState,
    parentCategories: [],
    tags: '',
    parentCategoryId: 0,
    parentCategoryName: '',
    categoryId: 0,
    categoryName: '',
  }
  const [course, setCourse] = useState<ICourse>(initialCourseState)
  const [startTime, setStartTime] = useState<number | null>(null)
  const [partNotFound, setPartNotFound] = useState<boolean>(false)
  const [activeSection, setActiveSection] = useState<number>(0)
  const convertedCourseId: string = String(courseId)

  const updateCourseDetails = (course: ICourse): void => {
    const initialActiveSection = course.courseParts.findIndex(
      (part: ICourseParts) => part.id === Number(partId)
    )
    if (initialActiveSection !== -1) {
      setActiveSection(initialActiveSection)
    } else setPartNotFound(true)
  }

  const fetchCourse = async (): Promise<void> => {
    dispatch(setSpinner(true))
    getUserCourseParts(Number(courseId), dispatch)
      .then((response) => {
        if (response && response.courseParts !== null) {
          setCourse(response)
          updateCourseDetails(response)
        } else setPartNotFound(true)
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    if (userId) {
      if (courseDetails.courseParts && courseDetails.courseParts.length > 0) {
        setCourse(courseDetails)
        updateCourseDetails(courseDetails)
      } else fetchCourse()
    }
  }, [courseId, courseDetails, userId])

  useEffect(() => {
    setStartTime(Date.now())
    window.scrollTo(0, 0)
  }, [activeSection])

  const backClickHandler = (id: number): void => {
    if (activeSection === 0) {
      navigate(routePath.participantAcademy)
    } else {
      const navUrl = routePath.participantCourse
        .replace(':courseId', convertedCourseId)
        .replace(':partId', course.courseParts[activeSection - 1]?.id.toString())
      navigate(navUrl)
    }
    if (startTime !== null) {
      const endTime = Date.now()
      const timeSpent = Math.round(endTime - startTime) / 1000 // Convert to seconds
      updateTimespent(id, Math.round(timeSpent))
    }
    setStartTime(Math.round(Date.now() / 1000))
    setActiveSection(activeSection - 1)
  }

  const forwardClickHandler = (id: number): void => {
    if (Array.isArray(course.courseParts) && activeSection + 1 === course.courseParts.length) {
      dispatch(setSpinner(true))
      getCourseQuizzes(convertedCourseId, dispatch)
        .then((res) => {
          if (res.quizes && res.quizes.length > 0) {
            const test = res.quizes[0].id
            const navUrl = routePath.participantQuiz
              .replace(':courseId', convertedCourseId)
              .replace(':quizId', String(test))
            navigate(navUrl)
          } else {
            dispatch(setNotification('There are currently no quizzes available.'))
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    } else {
      const navUrl = routePath.participantCourse
        .replace(':courseId', convertedCourseId)
        .replace(':partId', course.courseParts[activeSection + 1]?.id.toString())
      navigate(navUrl)
      setActiveSection(activeSection + 1)
    }
    if (startTime !== null) {
      const endTime = Date.now()
      const timeSpent = Math.round(endTime - startTime) / 1000 // Convert to seconds
      updateTimespent(id, Math.round(timeSpent))
    }
    setStartTime(Math.round(Date.now() / 1000))
  }

  const updateTimespent = (sectionId: number, timespent: number): void => {
    if (timespent === 0) return
    dispatch(setSpinner(true))
    updateCourseTimespent(userId, sectionId, timespent, dispatch).finally(() =>
      dispatch(setSpinner(false))
    )
  }

  return {
    course,
    activeSection,
    languageText,
    partNotFound,
    backClickHandler,
    forwardClickHandler,
    navigate,
  }
}
