import React from 'react'
import clsx from 'clsx'

interface ISwitchboxProps {
  label?: string
  labelFirstText?: string
  id?: number
  name?: string
  value?: boolean
  inputClass?: string
  labelClass?: string
  disable?: boolean
  handleSwitchboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Switchbox = (props: ISwitchboxProps) => {
  const {
    label,
    labelFirstText,
    id,
    name,
    value,
    disable,
    inputClass,
    labelClass,
    handleSwitchboxChange,
  } = props

  return (
    <div className='mb-6'>
      {labelFirstText && (
        <div>
          <span className={clsx('fs-6 mb-2', labelClass && labelClass)}>{labelFirstText}</span>
        </div>
      )}

      <div className='form-switch'>
        <input
          id={id ? String(id) : ''}
          className={clsx('form-check-input cursor-pointer', inputClass && inputClass)}
          type='checkbox'
          name={name ?? 'name'}
          role='switch'
          checked={value}
          onChange={handleSwitchboxChange}
          disabled={disable ?? false}
        />
        {label && (
          <span className={clsx('ms-2 form-check-label fs-6', labelClass && labelClass)}>
            {label}
          </span>
        )}
      </div>
    </div>
  )
}
