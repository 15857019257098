import React from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { ILogo } from '../hooks'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface IAddExistingIconProps {
  languageText: ITranslationObject
  uniqueIcons: ILogo[]
  onCloseExistingIconModal: () => void
  onSelectIcon: (selectedIcon: string, selectedIconPath: string) => void
}

export const AddExistingIcon: React.FC<IAddExistingIconProps> = (props) => {
  const { languageText, uniqueIcons, onSelectIcon, onCloseExistingIconModal } = props

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Select an existing icon')}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={onCloseExistingIconModal}
      >
        {uniqueIcons.map((icons, idx) => (
          <div className='col-md-4 text-center col-6 p-2' key={idx}>
            <img
              onClick={() => onSelectIcon(icons.logo, icons.logoFullUrl)}
              src={icons.logoFullUrl}
              className='img-thumbnail h-100px w-100px cursor-pointer'
            />
          </div>
        ))}
      </ModalComponent>
    </>
  )
}
