import React, { useMemo } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { IFocusError } from '../../../components/formComponents/input'
import { RootState } from '@app/store/configureStore'
import { createPlacement, updatePlacement } from './actions'
import { ValidationHelper } from '../../validationHelper'
import { getBehaveOptions } from '../../allWordsList/interface'
import { addToast, setSpinner } from '../../actions'

export enum PlacementsInput {
  position = 'position',
  description = 'description',
  low = 'low',
  high = 'high',
  self = 'self',
  behave = 'behave',
  countryCode = 'countryCode',
  cultureId = 'cultureId',
}

export interface IAddEditPlacementProps {
  rowData: any
  cultures: IDropdownList[]
  countriesList: IDropdownList[]
  closePlacementModal: (refreshPlacementList?: boolean) => void
}

interface IFocusInput {
  position: IFocusError
  low: IFocusError
  high: IFocusError
  self: IFocusError
  behave: IFocusError
  countryCode: IFocusError
  cultureId: IFocusError
}

interface IPlacements {
  position: string
  description: string
  low: string
  high: string
  self: boolean
  behave: string
  countryCode: string
  cultureId: string
}

export interface IPayloadBody {
  id?: number
  position: number
  description?: string
  low: number
  high: number
  self: boolean
  behave: number
  countryCode: number
  cultureId: number
}

export const useAddEditPlacementModal = (props: IAddEditPlacementProps) => {
  const { rowData, cultures, countriesList, closePlacementModal } = props
  const dispatch = useDispatch()
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [placementInfo, setPlacementInfo] = useState<IPlacements>({
    position: '',
    low: '',
    high: '',
    behave: '',
    self: false,
    description: '',
    countryCode: '',
    cultureId: '',
  })

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    countryCode: {
      touched: false,
      errorMessage: '',
    },
    cultureId: {
      touched: false,
      errorMessage: '',
    },
    behave: {
      touched: false,
      errorMessage: '',
    },
    position: {
      touched: false,
      errorMessage: '',
    },
    low: {
      touched: false,
      errorMessage: '',
    },
    high: {
      touched: false,
      errorMessage: '',
    },
    self: {
      touched: false,
      errorMessage: '',
    },
  })

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const bhaveOptions = useMemo(() => getBehaveOptions(languageText), [languageText])

  let isEditClicked: boolean = false
  if (Object.keys(rowData).length > 0) isEditClicked = true

  useEffect(() => {
    if (isEditClicked) {
      updatePlacementInfo(countriesList, cultures)
    }
  }, [])

  const updatePlacementInfo = (countries: IDropdownList[], cultures: IDropdownList[]): void => {
    const updatedCountry = countries.find(
      (country) => country.value === String(rowData.countryCode)
    )
    const updatedCulture = cultures.find((culture) => culture.value === String(rowData.cultureId))
    const updatedBehave = bhaveOptions.find((item) => item.value === String(rowData.behave))

    setPlacementInfo({
      ...placementInfo,
      position: rowData.position,
      low: rowData.low,
      high: rowData.high,
      self: rowData.self,
      description: rowData.description,
      behave: updatedBehave ? updatedBehave.value : '',
      countryCode: updatedCountry ? updatedCountry.value : '',
      cultureId: updatedCulture ? updatedCulture.value : '',
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    if (name === PlacementsInput.position) {
      const parsedValue = parseFloat(value)

      if (
        value !== '' &&
        (!/^(0|[1-9]\d*)$/.test(value) ||
          isNaN(parsedValue) ||
          (parsedValue !== 0 && (parsedValue < 0 || parsedValue > 100 || /^0\d+/.test(value))))
      ) {
        return
      }
    }

    if (name === PlacementsInput.low || name === PlacementsInput.high) {
      if (value === '') {
        setPlacementInfo({
          ...placementInfo,
          [name]: '',
        })
        return
      }

      if (!ValidationHelper.isPlacementValueValid(value)) {
        return
      }
    }

    setPlacementInfo({
      ...placementInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    setPlacementInfo({
      ...placementInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    if (e.target.name === PlacementsInput.self) {
      setPlacementInfo({
        ...placementInfo,
        self: e.target.checked,
      })
    } else {
      setPlacementInfo({
        ...placementInfo,
        [name]: e.target.checked,
      })
    }
  }

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    switch (name) {
      case PlacementsInput.position:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Position is required')
        }
        break
      case PlacementsInput.low:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Low value is required')
        } else if (/^\d+\.$/.test(value) || /^\d+\.\d*[^\d]$/.test(value)) {
          errorMessage = getLanguageValue(languageText, 'Low value is invalid')
        } else if (parseFloat(value) > parseFloat(placementInfo.high)) {
          errorMessage = getLanguageValue(languageText, 'Low value should be lower than high value')
        } else if (parseFloat(value) < 0.0 || parseFloat(value) > 6.999) {
          errorMessage = getLanguageValue(
            languageText,
            'Low value should be between 0.0000 and 6.999'
          )
        }
        break
      case PlacementsInput.high:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'High value is required')
        } else if (/^\d+\.$/.test(value) || /^\d+\.\d*[^\d]$/.test(value)) {
          errorMessage = getLanguageValue(languageText, 'High value is invalid')
        } else if (parseFloat(value) < parseFloat(placementInfo.low)) {
          errorMessage = getLanguageValue(
            languageText,
            'High value should be greater than low value'
          )
        } else if (parseFloat(value) < 0.1 || parseFloat(value) > 7) {
          errorMessage = getLanguageValue(languageText, 'High value should be between 0.0001 and 7')
        }
        break
      case PlacementsInput.behave:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Behave value is required')
        }
        break
      case PlacementsInput.countryCode:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Country is required')
        }
        break
      case PlacementsInput.cultureId:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Culture is required')
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !placementInfo.position ||
      focusInput.position.errorMessage ||
      !placementInfo.low ||
      focusInput.low.errorMessage ||
      !placementInfo.high ||
      focusInput.high.errorMessage ||
      !placementInfo.behave ||
      focusInput.behave.errorMessage ||
      !placementInfo.countryCode ||
      focusInput.countryCode.errorMessage ||
      !placementInfo.cultureId ||
      focusInput.cultureId.errorMessage
    ) {
      for (const item in PlacementsInput) {
        handleFormErrors(item, placementInfo[item])
      }
      return false
    }
    return true
  }

  const handleSubmit = (e: boolean): void => {
    setIsSubmitting(true)

    const body: IPayloadBody = {
      low: Number(placementInfo.low.replace(',', '.')),
      high: Number(placementInfo.high.replace(',', '.')),
      position: Number(placementInfo.position),
      self: placementInfo.self,
      description: placementInfo.description,
      behave: Number(placementInfo.behave),
      countryCode: Number(placementInfo.countryCode),
      cultureId: Number(placementInfo.cultureId),
    }

    if (isEditClicked) {
      body.id = rowData.id ?? 0
    }

    dispatch(setSpinner(true))
    if (isEditClicked) {
      updatePlacement(body, dispatch)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(addToast('Changes saved successfully') as AnyAction)
            closeAddEditPlacementModal(true)
          }
        })
        .finally(() => {
          setIsSubmitting(false)
          dispatch(setSpinner(false))
        })
    } else {
      createPlacement(body, dispatch)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(addToast('Placement created successfully') as AnyAction)
            closeAddEditPlacementModal(true)
          }
        })
        .finally(() => {
          setIsSubmitting(false)
          dispatch(setSpinner(false))
        })
    }
  }

  const onSubmitClick = (): void => {
    if (!handleValidationOnSubmit()) return
    else if (isEditClicked) {
      handleSubmit(false)
    } else {
      handleSubmit(true)
    }
  }

  const closeAddEditPlacementModal = (value: boolean) => {
    closePlacementModal(value)
  }

  const closeModal = () => closeAddEditPlacementModal(false)

  return {
    isEditClicked,
    languageText,
    placementInfo,
    focusInput,
    isSubmitting,
    cultures,
    countriesList,
    bhaveOptions,
    onSubmitClick,
    handleCheckboxChange,
    handleDropdownSelect,
    closeModal,
    handleBlurEvent,
    handleInputChange,
  }
}
