enum DimensionCases {
  Inquery = 'Inquery',
  Task = 'Task',
  Advocacy = 'Advocacy',
  People = 'People',
  OwnNeeds = 'OwnNeeds',
  OthersNeeds = 'OthersNeeds',
}

const getDimensionsBarHeight = (dimensionCase: DimensionCases, dimensionValue: number): string => {
  if (!dimensionValue) return ''

  let barHeight: number = 0
  const minimumPercentage: number = 10

  if (dimensionCase == DimensionCases.Inquery || dimensionCase == DimensionCases.Task) {
    //Reverse the dimension value with base of 12. i.e. 1 means 11 and 5 means 7.
    barHeight = ((12 - dimensionValue) / 12) * 100
  } else if (dimensionCase == DimensionCases.Advocacy || dimensionCase == DimensionCases.People) {
    barHeight = (dimensionValue / 12) * 100
  } else if (dimensionCase == DimensionCases.OwnNeeds) {
    //Reverse the dimension value with base of 100
    barHeight = 100 - dimensionValue
  } else if (dimensionCase == DimensionCases.OthersNeeds) {
    barHeight = dimensionValue
  }

  //Round of to 2 decimals
  barHeight = Math.round(barHeight * 100) / 100
  //If the minimum height is less then set it to 10%
  return `${barHeight > minimumPercentage ? barHeight : minimumPercentage}%`
}

interface IStdDeviation {
  leftOrBottomDeviation: number
  rightOrTopDeviation: number
}

// Below 2 fn's are used in graphs. The shadow should not exceed the graph.
// If its exceeding the graph, just remove it
const getDirOrAffDeviation = (oDirOrAffPos: number, outsideStdDev: number): IStdDeviation => {
  let leftOrBottomDeviation = outsideStdDev
  let rightOrTopDeviation = outsideStdDev

  if (outsideStdDev) {
    switch (oDirOrAffPos) {
      case 1:
        leftOrBottomDeviation = 0
        break
      case 2:
        leftOrBottomDeviation = 1
        break
      case 3:
        switch (outsideStdDev) {
          case 3:
            leftOrBottomDeviation = 2
        }
        break
      case 10:
        switch (outsideStdDev) {
          case 3:
            rightOrTopDeviation = 2
        }
        break
      case 11:
        rightOrTopDeviation = 1
        break
      case 12:
        rightOrTopDeviation = 0
        break
    }
  }

  return { leftOrBottomDeviation, rightOrTopDeviation }
}

const getAdapDeviation = (oFlex: number, outsideStdDevF: number): IStdDeviation => {
  let leftDeviation = outsideStdDevF
  let rightDeviation = outsideStdDevF

  if (outsideStdDevF === 1) {
    if (oFlex >= 0 && oFlex < 12) {
      leftDeviation = 0
    }
    if (oFlex > 88 && oFlex <= 100) {
      rightDeviation = 0
    }
  }
  if (outsideStdDevF === 2) {
    if (oFlex >= 0 && oFlex < 12) {
      leftDeviation = 0
    }
    if (oFlex > 11 && oFlex < 20) {
      leftDeviation = 1
    }
    if (oFlex > 88 && oFlex <= 100) {
      rightDeviation = 0
    }
    if (oFlex > 80 && oFlex < 89) {
      rightDeviation = 1
    }
  }
  if (outsideStdDevF === 3) {
    if (oFlex >= 0 && oFlex < 12) {
      leftDeviation = 0
    }
    if (oFlex > 11 && oFlex < 20) {
      leftDeviation = 1
    }
    if (oFlex > 19 && oFlex < 28) {
      leftDeviation = 2
    }
    if (oFlex > 88 && oFlex <= 100) {
      rightDeviation = 0
    }
    if (oFlex > 80 && oFlex < 89) {
      rightDeviation = 1
    }
    if (oFlex > 72 && oFlex < 81) {
      rightDeviation = 2
    }
  }
  return { leftOrBottomDeviation: leftDeviation, rightOrTopDeviation: rightDeviation }
}

export { DimensionCases, getDimensionsBarHeight, getDirOrAffDeviation, getAdapDeviation }
