import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../api'
import { setNotification } from '../../actions'
import { NewsType } from '../newsList/types'

export const getLatestNews = async (
  maxResultCount: number,
  skipCount: number,
  newsType: NewsType,
  dispatch: Dispatch
): Promise<any> => {
  const body = {
    maxResultCount,
    skipCount,
    newsType,
  }
  try {
    const response = await api.get(apiEndPoints.getLatestNews, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getNewsById = async (id: number, dispatch: Dispatch): Promise<any> => {
  const body = {
    id,
  }
  try {
    const response = await api.get(apiEndPoints.getNewsById, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
