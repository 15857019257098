import React from 'react'
import { DecimalSeparatorCountries } from '../../containers/placementsList/hooks'

interface IReplaceDecimalCellProps {
  numberValue: number
  userLanguage: string
}

export const ReplaceDecimalCell = (props: IReplaceDecimalCellProps) => {
  const { numberValue, userLanguage } = props

  const value: string = String(numberValue)
  const decimalSeparatorCountries = Object.values(DecimalSeparatorCountries)

  if (
    userLanguage &&
    decimalSeparatorCountries.includes(userLanguage as DecimalSeparatorCountries)
  ) {
    if (typeof value === 'string' && value.includes('.')) {
      return <span>{value.replace('.', ',')}</span>
    }
  }

  return <span>{value}</span>
}
