import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { EditProfileRespondentModal } from './editProfileRespondentModal'
import { IInviteRespondents } from '../../../addProfile/activityRespondentsStep'
import { IEditActivity } from '../../interface'
import { ActivityId } from '@app/containers/reducer'

interface IEditProfileRespondentStepProps {
  id: ActivityId
  languageText: ITranslationObject
  profileData: IEditActivity
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
}

export const EditProfileRespondentStep = (props: IEditProfileRespondentStepProps) => {
  const { id, languageText, profileData, isActivityCancelled, isActivityInvoiced } = props

  const [participantInviteExternal, setParticipantInviteExternal] = useState<boolean>(false)
  const [participantInviteColleagues, setParticipantInviteColleagues] = useState<boolean>(false)
  const [participantInviteOtherParticipant, setParticipantInviteOtherParticipant] =
    useState<boolean>(false)
  const [editRespondentModal, setEditRespondentModal] = useState<boolean>(false)

  useEffect(() => {
    setParticipantInviteExternal(profileData.participantInviteExternal)
    setParticipantInviteOtherParticipant(profileData.participantInviteOtherParticipant)
    setParticipantInviteColleagues(profileData.participantInviteColleagues)
  }, [profileData])

  const openEditModal = (): void => {
    if (!isActivityCancelled) {
      setEditRespondentModal(true)
    }
  }

  const closeRespondentModal = (): void => {
    setEditRespondentModal(false)
  }

  const handleSaveClick = (inviteRespondents: IInviteRespondents): void => {
    setParticipantInviteExternal(inviteRespondents.isInviteExternal)
    setParticipantInviteColleagues(inviteRespondents.isInviteColleagues)
    setParticipantInviteOtherParticipant(inviteRespondents.isInviteOtherParticipant)
  }

  const isGroup = profileData.activityType === true

  return (
    <>
      <div id='respondentSettings' className='p-3 mt-3 rounded bg-white'>
        <div>
          <div className='col-12 small fw-bold mb-2 d-none text-secondary opacity-75'>
            {getLanguageValue(languageText, 'Respondents')}
          </div>
          <div className='row'>
            <div className='col-md-4 col-12 border-end mb-md-0 mb-3'>
              <div className='text-secondary text-truncate'>
                {getLanguageValue(languageText, 'Allow external respondents')}
              </div>
              <div
                className={clsx(
                  'fs-5 fw-bold text-success',
                  !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer',
                  !participantInviteExternal && 'text-danger'
                )}
                onClick={() => !isActivityInvoiced && openEditModal()}
              >
                {getLanguageValue(languageText, participantInviteExternal ? 'Yes' : 'No')}
              </div>
            </div>
            <div
              className={clsx(
                'col-md-4 col-12',
                profileData.activityType === true && 'border-end mb-md-0 mb-3'
              )}
            >
              <div className='text-secondary text-truncate'>
                {getLanguageValue(languageText, 'Allow other colleagues as respondents')}
              </div>
              <div
                className={clsx(
                  'fs-5 fw-bold text-success',
                  !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer',
                  !participantInviteColleagues && 'text-danger'
                )}
                onClick={() => !isActivityInvoiced && openEditModal()}
              >
                {getLanguageValue(languageText, participantInviteColleagues ? 'Yes' : 'No')}
              </div>
            </div>

            <div className='col-md-4 col-12'>
              <div className='text-secondary text-truncate'>
                {getLanguageValue(languageText, 'Allow participants as respondents')}
              </div>
              <div
                className={clsx(
                  'fs-5 fw-bold text-success',
                  !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer',
                  !participantInviteOtherParticipant && 'text-danger'
                )}
                onClick={() => !isActivityInvoiced && openEditModal()}
              >
                {getLanguageValue(languageText, participantInviteOtherParticipant ? 'Yes' : 'No')}
              </div>
            </div>
          </div>
        </div>
      </div>

      {editRespondentModal && (
        <EditProfileRespondentModal
          id={id}
          languageText={languageText}
          isGroup={isGroup}
          participantInviteExternal={participantInviteExternal}
          participantInviteColleagues={participantInviteColleagues}
          participantInviteOtherParticipant={participantInviteOtherParticipant}
          closeModal={closeRespondentModal}
          handleSaveClick={handleSaveClick}
        />
      )}
    </>
  )
}
