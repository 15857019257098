import React, { useEffect, useState } from 'react'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
  DragEndEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'

import { ReorderItem } from './reorderItem'
import { ModalComponent } from '../modals/modalComponent'
import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'

export interface IReorderData {
  id: number
  title: string
  orderNumber: number
}

type IReorderModalProps = React.PropsWithChildren<{
  languageText: ITranslationObject
  headerText: string
  dataToReorder: IReorderData[]
  closeReorderModal: () => void
  handleSaveReorder: (updatedList: IReorderData[]) => void
}>

export const ReorderModal = (props: IReorderModalProps): JSX.Element => {
  const { languageText, headerText, dataToReorder, closeReorderModal, handleSaveReorder } = props

  const [data, setData] = useState<IReorderData[]>([])

  useEffect(() => {
    setData(dataToReorder)
  }, [dataToReorder])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const getTaskPos = (id: UniqueIdentifier): number => data.findIndex((item) => item.id === id)

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event

    if (!over || active.id === over.id) return

    setData((data) => {
      const originalPos = getTaskPos(active.id)
      const newPos = getTaskPos(over.id)

      return arrayMove(data, originalPos, newPos)
    })
  }

  const handleReorder = (): void => {
    const updatedList: IReorderData[] = [...data].map((item, index) => ({
      ...item,
      orderNumber: index + 1,
    }))
    handleSaveReorder(updatedList)
  }

  return (
    <ModalComponent
      headerText={headerText}
      cancelButtonText={getLanguageValue(languageText, 'Cancel')}
      submitButtonText={getLanguageValue(languageText, 'Save')}
      handleCancelClick={closeReorderModal}
      handleSubmitClick={handleReorder}
    >
      {props.children}
      <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
        <SortableContext items={data} strategy={verticalListSortingStrategy}>
          {data.map((item) => (
            <ReorderItem
              key={item.id}
              id={item.id}
              title={item.title}
              orderNumber={item.orderNumber}
            />
          ))}
        </SortableContext>
      </DndContext>
    </ModalComponent>
  )
}
