import React, { useState } from 'react'

import { ReactTable } from '../../components/reactTable/reactTable'
import { UploadEmployeeModal } from './uploadEmployeeModal/uploadEmployeeModal'
import { AddEmployeeModal } from './addEmployeeModal/addEmployeeModal'
import { useEmployeeList } from './hooks'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'

export const EmployeeList = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    setEmployeeUpdated,
  } = useEmployeeList()

  const [openUploadEmployeeFileModal, setOpenUploadEmployeeFileModal] = useState<boolean>(false)
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState<boolean>(false)

  const handleUploadEmployeeButtonClick = (): void => {
    setOpenUploadEmployeeFileModal(true)
  }

  const handleAddEmployeeButtonClick = (): void => {
    setOpenAddEmployeeModal(true)
  }

  const closeUploadEmployeeModal = (refreshUserList: boolean): void => {
    if (refreshUserList) {
      setEmployeeUpdated(true)
    }
    setOpenUploadEmployeeFileModal(false)
  }

  const closeAddEmployeeModal = (refreshUserList: boolean): void => {
    if (refreshUserList) {
      setEmployeeUpdated(true)
    }
    setOpenAddEmployeeModal(false)
  }

  return (
    <>
      <ReactTable
        wrapperClass='p-2 pt-4 p-md-4'
        headerText={getLanguageValue(languageText, 'Employees')}
        searchPlaceholder={getLanguageValue(languageText, 'Search Employee')}
        buttonText={getLanguageValue(languageText, 'Add Employee')}
        buttonSecondOptionalText={getLanguageValue(languageText, 'Upload Employee')}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={handleAddEmployeeButtonClick}
        handleSecondButtonClick={handleUploadEmployeeButtonClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {openUploadEmployeeFileModal ? (
        <UploadEmployeeModal
          languageText={languageText}
          closeUploadEmployeeModal={closeUploadEmployeeModal}
        />
      ) : (
        ''
      )}

      {openAddEmployeeModal ? (
        <AddEmployeeModal closeAddEmployeeModal={closeAddEmployeeModal} />
      ) : (
        ''
      )}
    </>
  )
}
