import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { routePath } from '../routePaths'
import { isLoggedInRoleParticipant } from '@app/commonUtils/roleHelper'

export interface IProfileHeaderProps {
  id: number
  name: string
  content: JSX.Element
  isActive: boolean
  href: string
}

export const useCurrentUserData = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const userDetails = useSelector((state: RootState) => state.loginReducer.userDetails)

  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [activePage, setActivePage] = useState<string>('')

  const profileElements = useMemo(
    () =>
      [
        {
          id: 1,
          name: getLanguageValue(languageText, 'Settings'),
          href: isLoggedInRoleParticipant(loggedInUserRole)
            ? routePath.participantUserProfile
            : routePath.userProfile,
        },
        {
          id: 2,
          name: getLanguageValue(languageText, 'Account'),
          href: isLoggedInRoleParticipant(loggedInUserRole)
            ? routePath.participantAccountPage
            : routePath.account,
        },
      ] as IProfileHeaderProps[],
    [languageText, loggedInUserRole]
  )

  useEffect(() => {
    const isParticipantProfile: boolean = location.pathname === routePath.participantUserProfile
    const isParticipantAccount: boolean = location.pathname === routePath.participantAccountPage

    if (isParticipantProfile || location.pathname === routePath.userProfile) {
      setActivePage(getLanguageValue(languageText, 'Settings'))
    } else if (isParticipantAccount || location.pathname === routePath.account) {
      setActivePage(getLanguageValue(languageText, 'Account'))
    }
  }, [location.pathname, languageText])

  const handleTabToggle = (href: string): void => {
    navigate(href)
  }

  return {
    activePage,
    profileElements,
    userDetails,
    handleTabToggle,
  }
}
