import React from 'react'

import { ProfileHeader } from '../profileHeader'
import { ReactTable } from '../../../components/reactTable/reactTable'
import { AddEditAccountModal } from '../../clientList/addEditModal/addEditAccountModal'
import { useAccountList } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { isLoggedInRoleAdmin, isLoggedInRoleFacilitator } from '@app/commonUtils/roleHelper'

export const Account = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openAccountModal,
    editedRowId,
    loggedInUserRole,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addAccountClick,
    refreshAccountList,
    closeAccountModal,
  } = useAccountList()

  let rowData = {}
  if (editedRowId) {
    rowData = data.find((item) => item.id === editedRowId) ?? {}
  }

  return (
    <>
      <div className='bg-white mt-4 p-3 p-md-4 rounded shadow-sm'>
        <ProfileHeader />
        <div className='row'>
          <ReactTable
            wrapperClass='mt-0'
            searchPlaceholder={getLanguageValue(languageText, 'Search Account')}
            buttonText={
              isLoggedInRoleFacilitator(loggedInUserRole)
                ? getLanguageValue(languageText, 'Add Account')
                : ''
            }
            tableHeader={tableHeader}
            data={data}
            totalCount={totalCount}
            pageCount={pageCount}
            filter={filter}
            pageLimit={pageLimit}
            pageSelected={pageSelected}
            minHeight=''
            handleButtonClick={addAccountClick}
            setFilter={setFilter}
            setSorting={setSorting}
            setPageLimit={setPageLimit}
            setPageSelected={setPageSelected}
          />
        </div>
      </div>
      {!isLoggedInRoleAdmin(loggedInUserRole) && (openAccountModal || editedRowId) ? (
        <AddEditAccountModal
          rowData={rowData}
          refreshClientList={refreshAccountList}
          closeClientModal={closeAccountModal}
        />
      ) : (
        ''
      )}
    </>
  )
}
