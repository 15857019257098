import React from 'react'
import JoditEditor, { IJoditEditorProps } from 'jodit-react'
import clsx from 'clsx'

import { IFocusError } from '../formComponents/input'

// ideally we would pull the jodit options directly
// from the package, but if we do that we end up with
// some infinite recursion that crashes the compiler.
type JoditOptions =
  typeof JoditEditor extends React.FC<infer P>
    ? P extends IJoditEditorProps
      ? P['config']
      : never
    : never

type EditorConfig = JoditOptions & {
  askBeforePasteHTML?: boolean
  askBeforePasteFromWord?: boolean
  showXPathInStatusbar?: boolean
  showCharsCounter?: boolean
  showWordsCounter?: boolean
  minHeight?: number
}

interface ITextEditorProps {
  label?: string
  name: string
  focusInput?: IFocusError
  required?: boolean
  placeholder?: string
  value: string
  handleTextEditor: (value: string, name: string) => void
  rows?: number
}

const config: EditorConfig = {
  readonly: false,
  defaultMode: 3,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  buttons: [
    'source',
    'fullsize',
    'preview',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'eraser',
    '|',
    'ul',
    'ol',
    'align',
    'indent',
    'outdent',
    '|',
    'hr',
    'table',
    'symbols',
    '|',
    'brush',
    'font',
    'fontsize',
    'paragraph',
    'lineHeight',
    'superscript',
    'subscript',
    '|',
    'classSpan',
    '|',
    'file',
    'image',
    'video',
    'link',
    'spellcheck',
    '|',
    'cut',
    'copy',
    'paste',
    'undo',
    'redo',
    '|',
  ],
  minHeight: 300,
  // disablePlugins: 'sanitize' option is used to disable the built-in sanitization plugin of Jodit.
  // This plugin is responsible for cleaning and sanitizing the HTML content
  // to remove potentially harmful or invalid HTML elements and attributes.
  disablePlugins: 'sanitize',
  toolbarButtonSize: 'middle',
}

export const TextEditor = (props: ITextEditorProps): JSX.Element => {
  return (
    <>
      <div className='mb-3'>
        {props.label && (
          <label className={clsx('mb-1', { required: props.required })}>{props.label}</label>
        )}
        <JoditEditor
          value={props.value}
          config={config}
          onChange={() => {}}
          // the blur event is used as an onChange-replacement because the jodit devs
          // couldn't figure out how to make this shit work correctly with react.
          //
          // https://github.com/jodit/jodit-react/issues/217
          onBlur={(newContent) => {
            // JODIT PACKAGE BUG - When we try to insert a link or special character from toolbar,
            // the onBlur event is triggered and not able to insert link or special character.
            // Also when no content, it returns newContent as '<p><br></p>' which gets saved in out state.
            // So, the below condition.
            if (newContent === props.value || newContent === '<p><br></p>') {
              return
            }
            props.handleTextEditor(newContent, props.name)
          }}
        />
        {props.focusInput?.errorMessage && (
          <div className='invalid-feedback d-inline-block'>{props.focusInput.errorMessage}</div>
        )}
      </div>
    </>
  )
}
