import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'

export const useParticipantRoute = () => {
  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const [toggle, setToggle] = useState<boolean>(true)
  const [width, setWidth] = useState<number>(window.innerWidth)

  return {
    toggle,
    width,
    loggedInUserRole,
    setWidth,
    setToggle,
  }
}
