import React from 'react'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { DropdownMenu, DropdownMenuItem } from '@app/components/formComponents/dropdownMenu'
import { ProfileId } from '@app/containers/reducer'

interface IParticipantProfileDropdownProps {
  languageText: ITranslationObject
  id?: string
  profileId?: ProfileId
  isGroup?: boolean
  isDeliver?: boolean
  isUnDeliver?: boolean
  handlePreviewClick?: (profileId: ProfileId, isSelf?: boolean) => void
  handleDeliverOrUnDeliverClick?: (profileId: ProfileId, isSelf?: boolean) => void
  handleDownloadClick?: (profileId: ProfileId) => void
  handleDownloadGroupClick?: () => void
}

export const ParticipantProfileDropdown: React.FC<IParticipantProfileDropdownProps> = (props) => {
  const {
    languageText,
    id,
    profileId,
    isGroup,
    isDeliver = false,
    isUnDeliver = false,
    handlePreviewClick,
    handleDeliverOrUnDeliverClick,
    handleDownloadClick,
    handleDownloadGroupClick,
  } = props

  const onDownloadClick = (): void => {
    if (handleDownloadClick) handleDownloadClick(profileId!)
  }

  const onPreviewClick = (): void => {
    if (handlePreviewClick) handlePreviewClick(profileId!)
  }

  const onDeliverOrUnDeliverClick = (): void => {
    if (handleDeliverOrUnDeliverClick) handleDeliverOrUnDeliverClick(profileId!)
  }

  const dropdownItems: Array<DropdownMenuItem> = [
    {
      name: getLanguageValue(languageText, 'Download'),
      onClick: onDownloadClick,
    },
  ]

  if (isGroup && handleDownloadGroupClick) {
    dropdownItems.push({
      name: getLanguageValue(languageText, 'Group Report'),
      onClick: handleDownloadGroupClick,
    })
  }

  dropdownItems.push({
    name: getLanguageValue(languageText, 'Preview'),
    onClick: onPreviewClick,
  })

  if (isDeliver || isUnDeliver) {
    dropdownItems.push({
      name: getLanguageValue(languageText, isDeliver ? 'Deliver' : 'Undeliver'),
      onClick: onDeliverOrUnDeliverClick,
    })
  }

  return <DropdownMenu id={id} items={dropdownItems} />
}
