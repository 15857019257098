import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { RootState } from '@app/store/configureStore'
import { getParticipantPresentationByPresentationId } from '../../actions'
import { routePath } from '../../../routePaths'
import { pIdQueryKey } from '@app/consts'
import { setSpinner } from '@app/containers/actions'
import { CoursePresentationStatus } from '@app/containers/profileList/profileEnums'
import { useParticipantProfileAuth } from '../../useParticipantProfileAuth'

export interface IPresentationData {
  description: string
  presentationDate: string
  status: CoursePresentationStatus
  dayOfWeek: string
  courseId: number
  activityName: string
  presentationTemplateId: number
  facilitatorName: string
  uniqueCode: string
  publicId: string
}

const PROFILE_POLL_TIMEOUT: number = 5000

export const useParticipantProfilePage = () => {
  const dispatch = useDispatch()
  useParticipantProfileAuth() // Required to get data from the URL unique code
  const pollRef = useRef(0)
  const navigate = useNavigate()

  const userId = useSelector((state: RootState) => state.loginReducer.userId)
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )

  const [presentationData, setPresentationData] = useState<IPresentationData>({
    description: '',
    presentationDate: '',
    status: CoursePresentationStatus.Unknown,
    dayOfWeek: '',
    courseId: 0,
    activityName: '',
    presentationTemplateId: 0,
    facilitatorName: '',
    uniqueCode: '',
    publicId: '', // PresentationPublicId
  })

  useEffect(() => {
    if (participantProfileDetails.presentationId && userId) {
      dispatch(setSpinner(true))
      getParticipantPresentationByPresentationId(participantProfileDetails.presentationId, dispatch)
        .then((response) => {
          if (response) {
            setPresentationData(response)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
        })
    }
  }, [participantProfileDetails.presentationId, userId])

  // API polling to check if the presentation is ongoing or delivered
  const fetchPresentationStatus = (): void => {
    getParticipantPresentationByPresentationId(
      participantProfileDetails.presentationId,
      dispatch
    ).then((response) => {
      if (response) {
        // Below condition is to enable join the meeting btn which would be in disabled state initially
        if (
          response.status === CoursePresentationStatus.Ongoing &&
          presentationData.status !== CoursePresentationStatus.Ongoing
        ) {
          setPresentationData(response)
        }
        // Redirect to profile report page if the presentation is completed
        if (response.status === CoursePresentationStatus.Completed) {
          navigate(
            routePath.participantProfileReport.replace(
              ':profileId',
              String(participantProfileDetails.profileId)
            )
          )
        } else {
          pollRef.current = window.setTimeout(() => fetchPresentationStatus(), PROFILE_POLL_TIMEOUT)
        }
      }
    })
  }

  useEffect(() => {
    if (userId && participantProfileDetails.presentationId && participantProfileDetails.profileId) {
      fetchPresentationStatus()

      return () => {
        window.clearTimeout(pollRef.current)
      }
    }
    return undefined
  }, [userId, participantProfileDetails.presentationId, participantProfileDetails.profileId])

  const handleJoinMeeting = (): void => {
    const redirectURL = `${process.env.PUBLIC_URL}${routePath.participantDelivery}`
    window.open(`${redirectURL}?${pIdQueryKey}=${presentationData.publicId}`, '_blank')
  }

  return {
    languageText,
    presentationData,
    handleJoinMeeting,
  }
}
