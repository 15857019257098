import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../api'
import { setNotification } from '../../actions'
import { ICreateOrUpdateUserBody } from './hooks'

export const createOrUpdateUser = async (
  body: ICreateOrUpdateUserBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createOrUpdateUser, JSON.stringify(body), {})
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const verifyUser = async (email: string, dispatch: Dispatch): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.verifyUserExists,
      {},
      {
        params: { email },
      }
    )
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
