import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { SelectLanguageDropdownModal } from '@app/components/languagePageComponent/selectLanguageDropdownModal'
import { LanguageCode } from '@app/containers/commonEnums'
import { ILanguage } from '@app/containers/commonInterfaces'
import { ILanguageText } from '@app/containers/reducer'
import * as React from 'react'

type HasInstructionLanguage = {
  instructionLanguageId: number | null
}

type Props<T> = {
  languageText: ILanguageText
  languages: Array<ILanguage>
  onChange: (next: T) => unknown
  onClose: () => unknown
  value: T
}

export function InstructionLanguageSelector<T extends HasInstructionLanguage>(
  props: Props<T>
): JSX.Element {
  const dropdownItems: Array<IDropdownList> = props.languages.map((it, index) => {
    return {
      id: it.id,
      displayName: it.displayName,
      value: it.name,
    }
  })

  const language = props.languages.find((it) => it.id === props.value.instructionLanguageId)

  return (
    <SelectLanguageDropdownModal
      languageText={props.languageText}
      languages={dropdownItems}
      languageValue={language?.name || LanguageCode.English}
      closeModal={props.onClose}
      handleSaveLanguage={(languageCode) => {
        const lang = props.languages.find((x) => x.name === languageCode)
        const next: T = {
          ...props.value,
          instructionLanguageId: lang?.id,
        }
        props.onChange(next)
      }}
    />
  )
}
