import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { RootState } from '@app/store/configureStore'
import { IAddEditProfileDescriptionModalProps } from './addEditProfileDescriptionModal'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { Directiveness, Affiliation } from '../../../commonUtils/behaveHelper'
import { IFocusError } from '../../../components/formComponents/input'
import { getFacilitatorCultures } from '../../languageList/actions'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { addToast, setSpinner } from '../../actions'
import {
  createProfileDescription,
  updateProfileDescription,
  getProfileDescriptionById,
} from '../actions'
import { ICulture } from '@app/containers/commonInterfaces'

enum ProfileDescriptionInputs {
  title = 'title',
  directiveness = 'directiveness',
  affiliation = 'affiliation',
  description = 'description',
  language = 'language',
}

export interface ICreateOrUpdateProfileDescription {
  id: number
  directiveness: string
  affiliation: string
  title: string
  description: string
  idiLanguageId: number
}

interface IProfileDescriptionInfo {
  directiveness: string
  affiliation: string
  title: string
  description: string
  language: string
}

interface IProfileDescription {
  directiveness: string
  affiliation: string
  title: string
  description: string
  language: string
  idiLanguageId: number
  id: number
}

export interface IFocusInput {
  directiveness: IFocusError
  affiliation: IFocusError
  title: IFocusError
  description: IFocusError
  language: IFocusError
}

export const useAddEditModal = (props: IAddEditProfileDescriptionModalProps) => {
  const { editId, refetchProfileDescriptionList, closeProfileDecriptionModal } = props

  const dispatch = useDispatch()

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initiaIProfileDescriptionInfoInfoState: IProfileDescriptionInfo = {
    directiveness: '',
    affiliation: '',
    title: '',
    description: '',
    language: '',
  }
  const [profileDescriptionInfo, setProfileDescriptionInfo] = useState<IProfileDescriptionInfo>(
    initiaIProfileDescriptionInfoInfoState
  )

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    directiveness: {
      touched: false,
      errorMessage: '',
    },
    affiliation: {
      touched: false,
      errorMessage: '',
    },
    title: {
      touched: false,
      errorMessage: '',
    },
    description: {
      touched: false,
      errorMessage: '',
    },
    language: {
      touched: false,
      errorMessage: '',
    },
  })
  const [languages, setLanguages] = useState<IDropdownList[]>([])

  // Check for edit click
  let isEditClicked: boolean = false
  if (editId > 0) isEditClicked = true

  useEffect(() => {
    if (!isEditClicked) {
      setProfileDescriptionInfo((prevProfileInfo) => ({
        ...prevProfileInfo,
        directiveness: Directiveness.A,
        affiliation: Affiliation.One.toString(),
      }))
    }
  }, [])

  useEffect(() => {
    getFacilitatorCultures(dispatch).then((response: ICulture[]) => {
      if (response?.length > 0) {
        const cultures: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.displayName,
          value: item.id.toString(),
        }))
        setLanguages(cultures)
      }
    })
    if (isEditClicked) {
      getProfileDescriptionById(editId, dispatch).then((response: IProfileDescription) => {
        if (response) {
          setProfileDescriptionInfo({
            directiveness: response.directiveness,
            affiliation: response.affiliation,
            title: response.title,
            description: response.description,
            language: response.idiLanguageId.toString(),
          })
        }
      })
    }
  }, [])

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [ProfileDescriptionInputs.directiveness]: 'Directiveness is required',
        [ProfileDescriptionInputs.affiliation]: 'Affiliation is required',
        [ProfileDescriptionInputs.title]: 'Title is required',
        [ProfileDescriptionInputs.description]: 'Description is required',
        [ProfileDescriptionInputs.language]: 'Culture is required',
      }
      errorMessage = errorMessages[name] && getLanguageValue(languageText, errorMessages[name])
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    setProfileDescriptionInfo({
      ...profileDescriptionInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleTextEditorChange = (content: string): void => {
    setProfileDescriptionInfo({
      ...profileDescriptionInfo,
      description: content,
    })
    handleFormErrors(ProfileDescriptionInputs.description, content)
  }

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    setProfileDescriptionInfo({
      ...profileDescriptionInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !profileDescriptionInfo.directiveness ||
      focusInput.description.errorMessage ||
      !profileDescriptionInfo.affiliation ||
      focusInput.description.errorMessage ||
      !profileDescriptionInfo.title ||
      focusInput.description.errorMessage ||
      !profileDescriptionInfo.description ||
      focusInput.description.errorMessage ||
      !profileDescriptionInfo.language ||
      focusInput.language.errorMessage
    ) {
      for (const item in ProfileDescriptionInputs) {
        handleFormErrors(item, profileDescriptionInfo[item])
      }
      return false
    }
    return true
  }

  const handleSaveClick = (): void => {
    if (!handleValidationOnSubmit()) return

    dispatch(setSpinner(true))
    const body: ICreateOrUpdateProfileDescription = {
      id: editId ?? 0,
      directiveness: profileDescriptionInfo.directiveness,
      affiliation: profileDescriptionInfo.affiliation,
      title: profileDescriptionInfo.title,
      description: profileDescriptionInfo.description,
      idiLanguageId: Number(profileDescriptionInfo.language),
    }

    if (editId > 0) {
      updateProfileDescription(body, dispatch)
        .then((response) => {
          if (response) {
            refetchProfileDescriptionList()
            closeProfileDecriptionModal()
            dispatch(addToast('Changes saved successfully') as AnyAction)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    } else {
      createProfileDescription(body, dispatch)
        .then((response) => {
          if (response) {
            refetchProfileDescriptionList()
            closeProfileDecriptionModal()
            dispatch(addToast('Saved successfully') as AnyAction)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  return {
    languageText,
    isEditClicked,
    ProfileDescriptionInputs,
    profileDescriptionInfo,
    focusInput,
    languages,
    handleInputChange,
    handleBlurEvent,
    handleTextEditorChange,
    handleDropdownSelect,
    handleSaveClick,
  }
}
