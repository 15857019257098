import React from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface IPreviewPopupProps {
  languageText: ITranslationObject
  previewHTML: string
  onCloseClick: () => void
}

export const PreviewPopup: React.FC<IPreviewPopupProps> = (props) => {
  const { languageText, previewHTML, onCloseClick } = props

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Invitation Preview')}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={onCloseClick}
        width='lg'
      >
        <div dangerouslySetInnerHTML={{ __html: previewHTML }} />
      </ModalComponent>
    </>
  )
}
