import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { HubConnectionBuilder } from '@microsoft/signalr'

import { RootState } from '@app/store/configureStore'
import {
  getIDIV2CurrentPresentationSlideId,
  idiV2SendOnlineStatusToFacilitator,
  idiV2GetParticipantPresentationSlides,
  getProfileParticipantPresentation,
} from './actions'
import { setNotification, setSpinner } from '../actions'
import { CoursePresentationStatus } from '../profileList/profileEnums'
import { getPresentationStatus } from '../facilitatorDelivery/actions'
import { IPresentationSlide } from '../facilitatorDelivery/hooks'
import { pIdQueryKey } from '@app/consts'
import { unsafeRenderPresentationSlide } from '@app/commonUtils/renderHtmlHelper'
import { ActivityId, ProfileId } from '../reducer'
import { joinPaths } from '../utils'

export interface IPresentationDataResponse {
  coursePresentationId: number
  templateId: number
  profileId: ProfileId
  participantName: string
  facilitatorName: string
  languageCode: string
  activityId: ActivityId
}

interface IPresentationData {
  presentationId: number
  templateId: number
  profileId: ProfileId
  participantName: string
  facilitatorName: string
  languageCode: string
}

export const useParticipantDelivery = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const presentationPublicId = queryParams.get(pIdQueryKey) || ''
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initialPresentationDataState: IPresentationData = {
    presentationId: 0,
    templateId: 0,
    profileId: 0 as ProfileId,
    participantName: '',
    facilitatorName: '',
    languageCode: '',
  }
  const initialSlideState: IPresentationSlide = {
    id: 0,
    description: '',
    html: '',
    slideNumber: 0,
    slideSteps: [],
    notes: '',
  }
  const [presentationData, setPresentationData] = useState<IPresentationData>(
    initialPresentationDataState
  )
  const [deliveryCancelled, setDeliveryCancelled] = useState<boolean>(false)
  const [deliveryFinished, setDeliveryFinished] = useState<boolean>(false)
  const [slideId, setSlideId] = useState<number>(0)
  const [ruleIndex, setRuleIndex] = useState<number | null>(null)
  const [coursePresentationDate, setCoursePresentationDate] = useState<string>('')
  const [presentationPending, setPresentationPending] = useState<boolean>(false)
  const [presentationSlides, setPresentationSlides] = useState<IPresentationSlide[]>([
    initialSlideState,
  ])
  const [participantScreen, setParticipantScreen] = useState<IPresentationSlide>(initialSlideState)
  const [cancelPresentationModal, setCancelPresentationModal] = useState(false)

  useEffect(() => {
    if (presentationPublicId && !presentationData.presentationId) {
      getProfileParticipantPresentation(presentationPublicId, dispatch).then((response) => {
        if (response) {
          setPresentationData({
            presentationId: response.coursePresentationId,
            templateId: response.templateId,
            profileId: response.profileId,
            participantName: response.participantName,
            facilitatorName: response.facilitatorName,
            languageCode: response.languageCode,
          })
        }
      })
    }
  }, [presentationPublicId])

  // signalr-connection
  useEffect(() => {
    if (presentationData.presentationId) {
      const baseUrl = process.env.REACT_APP_BASE_URL ?? ''
      const signalrLink = `signalr-delivery/?cpid=${presentationData.presentationId}&pid=${presentationData.profileId}`
      const connection = new HubConnectionBuilder()
        .withUrl(joinPaths(baseUrl, signalrLink))
        .withAutomaticReconnect()
        .build()

      connection.on('getParticipantSlide', (message: any, stepIndex: any) => {
        if (message === 'Canceled') {
          setDeliveryCancelled(true)
        }
        if (message === 'Finished') {
          setDeliveryFinished(true)
        }
        message && setSlideId(message)
        setRuleIndex(stepIndex)
      })

      connection.start().catch((e) => {
        dispatch(setNotification(e))
        return Promise.reject(e)
      })
    }
  }, [presentationData.presentationId])

  // Presentation Details for isAsp=false i.e., for react
  useEffect(() => {
    const getPresentationDetails = async (): Promise<any> => {
      dispatch(setSpinner(true))

      // Get presentation status
      await getPresentationStatus(presentationData.presentationId, dispatch).then((response) => {
        if (response) {
          setCoursePresentationDate(response.presentationDate)
          response.status === CoursePresentationStatus.Planned && setPresentationPending(true)
        }
      })

      // Send  participant online status
      await idiV2SendOnlineStatusToFacilitator(
        presentationData.presentationId,
        presentationData.profileId,
        true,
        2,
        dispatch
      )

      // Get presentation slides
      await idiV2GetParticipantPresentationSlides(
        presentationData.templateId,
        presentationData.profileId,
        presentationData.languageCode,
        dispatch
      ).then((response) => {
        if (response) {
          const updatedPresentationSlides: IPresentationSlide[] = response.items.slice()
          setPresentationSlides(updatedPresentationSlides)
        }
      })
    }

    if (presentationData.presentationId) {
      getPresentationDetails().finally(() => dispatch(setSpinner(false)))
    }
  }, [presentationData.presentationId])

  // Get current presentation slides for isAsp=false i.e., for react
  useEffect(() => {
    if (presentationSlides && presentationData.presentationId) {
      dispatch(setSpinner(true))
      getIDIV2CurrentPresentationSlideId(presentationData.presentationId, dispatch)
        .then((response) => {
          const currentScreen =
            presentationSlides.find((item) => item.id === response) ?? initialSlideState
          setParticipantScreen(currentScreen)
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }, [presentationSlides])

  // Setting slide
  useEffect(() => {
    if (slideId) {
      const newSlide =
        presentationSlides.find((item) => item.id === Number(slideId)) ?? initialSlideState
      setParticipantScreen(newSlide)
    }
  }, [slideId])

  function renderSlideHTML(): React.ReactElement {
    return unsafeRenderPresentationSlide(participantScreen, ruleIndex, {
      className: '',
    })
  }

  const handleClosePresentation = (isFinished = false): void => {
    //I am passing status as Ongoing, so that the presentation won't be cancelled.
    idiV2SendOnlineStatusToFacilitator(
      presentationData.presentationId,
      presentationData.profileId,
      false,
      CoursePresentationStatus.Ongoing,
      dispatch
    ).then((response) => {
      if (response) {
        window.close()
      }
    })
  }

  const handleCancelPresentation = (): void => setCancelPresentationModal(true)

  const handleCloseModal = (): void => setCancelPresentationModal(false)

  return {
    languageText,
    presentationData,
    presentationSlides,
    deliveryCancelled,
    deliveryFinished,
    presentationPending,
    coursePresentationDate,
    participantScreen,
    cancelPresentationModal,
    renderSlideHTML,
    handleClosePresentation,
    handleCancelPresentation,
    handleCloseModal,
  }
}
