import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ITranslationObject } from '../../../../../commonUtils/languageFunctionsHelper'
import { IProfileRespondentsList } from '../../interface'
import { getParticipantRespondentsByParticipantId } from '../../actions'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { setSpinner } from '../../../../actions'
import { RespondentStatus } from '@app/containers/participantPages/profileParticipantPages/inviteRespondents/respondentStatus'
import { ProfileId } from '@app/containers/reducer'

interface IParticipantsRespondentsStatusProps {
  languageText: ITranslationObject
  languages: IDropdownList[]
  profileId: ProfileId
}

export const ParticipantsRespondentsStatus = (props: IParticipantsRespondentsStatusProps) => {
  const { languageText, languages, profileId } = props

  const dispatch = useDispatch()

  const [participantDetails, setParticipantDetails] = useState<IProfileRespondentsList>({
    participantLink: '',
    respondents: [],
    noOfRespondents: 0,
    respondentsAnswered: 0,
    respondentsInvited: 0,
  })
  const [refetchRespondents, setRefetchRespondents] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setSpinner(true))
    getParticipantRespondentsByParticipantId(profileId, dispatch)
      .then((response) => {
        if (response) {
          setParticipantDetails(response)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }, [refetchRespondents])

  const handleRefetch = (): void => {
    setRefetchRespondents(!refetchRespondents)
  }

  return (
    <RespondentStatus
      languageText={languageText}
      languages={languages}
      profileId={profileId}
      participantDetails={participantDetails}
      handleRefetch={handleRefetch}
    />
  )
}
