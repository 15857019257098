import { CustomActionsCell } from '@app/components/reactTable/customActionsCell'
import { ReactTable } from '@app/components/reactTable/reactTable'
import { ColumnDef } from '@tanstack/react-table'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { AlertAction, IAlert } from './types'
import { deleteAlert, getAlerts, saveAlert } from './actions'
import { EditAlertModal } from './editAlertModal'
import { DeleteModal } from '@app/components/modals/deleteModal'
import { ITableCommonParams } from '../commonInterfaces'

type AlertListProps = unknown

export const AlertList: React.FC<AlertListProps> = () => {
  const dispatch = useDispatch()
  const lang = useSelector((state: RootState) => state.mainReducer.languageText)
  const [data, setData] = React.useState<Array<IAlert>>([])
  const [filter, setFilter] = React.useState<string>('')
  const [sorting, setSorting] = React.useState<string>('')
  const [totalCount, setTotalCount] = React.useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = React.useState<number>(10) // items_per_page
  const [pageCount, setPageCount] = React.useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = React.useState<number>(0) // page_number_selected
  const [editingAlert, setEditingAlert] = React.useState<IAlert | undefined>(undefined)
  const [deletingAlert, setDeletingAlert] = React.useState<IAlert | undefined>(undefined)
  const header: Array<ColumnDef<IAlert, keyof IAlert>> = [
    {
      header: '',
      accessorKey: 'actions',
      enableSorting: false,
      cell: ({ ...props }) => (
        <CustomActionsCell
          languageText={lang}
          id={props.row.original.id}
          editOption={true}
          deleteOption={true}
          handleEditClick={(id) => {
            const item = data.find((x) => x.id === id)
            if (item) {
              setEditingAlert(item)
            }
          }}
          handleDeleteClick={handleDeleteAlertClick}
        />
      ),
    },
    {
      header: getLanguageValue(lang, 'ID'),
      accessorKey: 'id',
    },
    {
      header: getLanguageValue(lang, 'Trigger'),
      accessorKey: 'trigger',
    },
    {
      header: getLanguageValue(lang, 'Is active'),
      accessorKey: 'isActive',
    },
  ]

  function loadAlertsAndUpdateState(): Promise<void> {
    const params: ITableCommonParams = {
      filter: filter,
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: pageSelected * pageLimit,
    }

    return getAlerts(dispatch, params).then((x) => {
      setData(x.items.slice())
      setTotalCount(x.totalCount)
      setPageCount(Math.ceil(x.totalCount / pageLimit))
    })
  }

  React.useEffect(() => {
    loadAlertsAndUpdateState()
  }, [pageSelected, sorting, filter])

  function onAddAlertClick(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault()
    event.stopPropagation()

    setEditingAlert({
      trigger: 'on_admin_login',
      title: '',
      body: '',
      isActive: true,
      actionTaken: AlertAction.None,
    })
  }

  function handleSaveAlertClick(event: React.MouseEvent<HTMLElement>): Promise<unknown> {
    event.preventDefault()
    event.stopPropagation()

    if (!editingAlert) {
      return Promise.reject()
    }

    return saveAlert(dispatch, editingAlert).then(() => {
      setEditingAlert(undefined)
      loadAlertsAndUpdateState()
    })
  }

  function handleDeleteAlertClick(id: number): void {
    const alert = data.find((x) => x.id === id)
    if (alert) {
      setDeletingAlert(alert)
    }
  }

  function handleActuallyDeleteAlertClick(): Promise<unknown> {
    if (!deletingAlert) {
      return Promise.reject()
    }

    return deleteAlert(dispatch, deletingAlert.id!).then(() => {
      loadAlertsAndUpdateState()
      setDeletingAlert(undefined)
    })
  }

  return (
    <React.Fragment>
      <ReactTable
        wrapperClass='p-2 pt-4 p-md-4'
        headerText={getLanguageValue(lang, 'Alerts')}
        buttonText={getLanguageValue(lang, 'Add alert')}
        handleButtonClick={onAddAlertClick}
        tableHeader={header}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {typeof editingAlert !== 'undefined' && (
        <EditAlertModal
          lang={lang}
          alert={editingAlert}
          handleClose={() => setEditingAlert(undefined)}
          handleSave={handleSaveAlertClick}
          onAlertChange={setEditingAlert}
        />
      )}

      {typeof deletingAlert !== 'undefined' && (
        <DeleteModal
          languageText={lang}
          headerText={deletingAlert.trigger}
          bodyText={deletingAlert.trigger}
          handleDeleteClick={handleActuallyDeleteAlertClick}
          closeDeleteModal={() => setDeletingAlert(undefined)}
        />
      )}
    </React.Fragment>
  )
}
