import React, { useEffect, useMemo, useState } from 'react'
import { Input } from '../../../../components/formComponents/input'
import {
  ToggleButtonGroup,
  IButtonGroupList,
} from '../../../../components/formComponents/toggleButtonGroup'
import { CustomModal as RespondentsModal } from '../../../../components/modals/customModal'
import { getNoOfRespondentsButtonList } from '../../../../commonUtils/roleSettingsFunctionsHelper'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'

interface IRoleSettingsProps {
  disabled?: boolean
  languageText: ITranslationObject
  selectedTypeOfRoleBtn: string
  typeOfRoleButtonList: IButtonGroupList[]
  roleInputSelected: boolean
  typeOfRoleInput: string
  selectedNoOfRespondentsBtn: string
  respondentsInputSelected: boolean
  noOfRespondentsInput: string
  showEditModeNoOfRespondent?: boolean
  showEditModeRoleType?: boolean
  respondentsConfirmModal: boolean
  onTypeOfRoleBtnClick: (name: string) => void
  onTypeOfRoleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleTypeOfRoleFocus: (e: React.FocusEvent<HTMLInputElement>) => void
  onNoOfRespondentsClick: (name: string) => void
  onNoOfRespondentsChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleNoOfRespondentsFocus: (e: React.FocusEvent<HTMLInputElement>) => void
  SubmitRespondentsConfirmModal: () => void
  cancelRespondentsConfirmModal: () => void
  setShowEditModeNoOfRespondent?: React.Dispatch<React.SetStateAction<boolean>>
  setShowEditModeRoleType?: React.Dispatch<React.SetStateAction<boolean>>
}

export const RoleSettings = (props: IRoleSettingsProps): JSX.Element => {
  const {
    disabled,
    languageText,
    selectedTypeOfRoleBtn,
    typeOfRoleButtonList,
    typeOfRoleInput,
    selectedNoOfRespondentsBtn,
    noOfRespondentsInput,
    respondentsConfirmModal,
    showEditModeNoOfRespondent,
    showEditModeRoleType,
    onTypeOfRoleBtnClick,
    onTypeOfRoleChange,
    handleTypeOfRoleFocus,
    onNoOfRespondentsClick,
    onNoOfRespondentsChange,
    handleNoOfRespondentsFocus,
    SubmitRespondentsConfirmModal,
    cancelRespondentsConfirmModal,
    setShowEditModeNoOfRespondent,
    setShowEditModeRoleType,
  } = props

  const noOfRespondentsButtonList = useMemo(() => getNoOfRespondentsButtonList(), [languageText])

  const [roleTitle, setRoleTitle] = useState<string>()

  useEffect(() => {
    if (selectedTypeOfRoleBtn !== '' && typeOfRoleInput == '') {
      const filteredArray = typeOfRoleButtonList.filter((value) =>
        selectedTypeOfRoleBtn.includes(value.name)
      )
      setRoleTitle(filteredArray[0].title)
    } else {
      setRoleTitle('')
    }
  }, [roleTitle, selectedTypeOfRoleBtn, languageText])

  const onEditNoOfRespondentsClick = () => {
    if (setShowEditModeNoOfRespondent) setShowEditModeNoOfRespondent(true)
  }

  const onEditRoleTypeClick = () => {
    if (setShowEditModeRoleType) setShowEditModeRoleType(true)
  }

  return (
    <>
      {/* TYPE OF ROLE BUTTONS */}
      <div className='form-label'>{getLanguageValue(languageText, 'Type of role')}</div>
      {showEditModeRoleType ? (
        <div className='d-flex gap-1 flex-wrap mb-2'>
          <div>
            <ToggleButtonGroup
              buttonList={typeOfRoleButtonList}
              selectedBtn={selectedTypeOfRoleBtn}
              disabled={disabled}
              handleButtonClick={onTypeOfRoleBtnClick}
            />
          </div>
          <Input
            placeholder={getLanguageValue(languageText, 'Other')}
            value={typeOfRoleInput}
            disable={disabled}
            handleInputChange={onTypeOfRoleChange}
            handleBlurEvent={handleTypeOfRoleFocus}
          />
        </div>
      ) : (
        <div className='d-flex align-items-center mb-4'>
          <div className='btn me-3 border'>{roleTitle || typeOfRoleInput}</div>
          <button className='btn btn-light' onClick={onEditRoleTypeClick}>
            <i className='bi bi-pencil'></i>
          </button>
        </div>
      )}

      {/* NUMBER OF RESPONDENTS BUTTONS */}
      <div className='form-label'>{getLanguageValue(languageText, 'No. of respondents')}</div>
      {showEditModeNoOfRespondent ? (
        <div className='d-flex gap-1 flex-wrap mb-2'>
          <div>
            <ToggleButtonGroup
              buttonList={noOfRespondentsButtonList}
              selectedBtn={selectedNoOfRespondentsBtn}
              disabled={disabled}
              handleButtonClick={onNoOfRespondentsClick}
            />
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input
                type='number'
                placeholder=''
                value={noOfRespondentsInput}
                disable={disabled}
                handleInputChange={onNoOfRespondentsChange}
                handleBlurEvent={handleNoOfRespondentsFocus}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex align-items-center mb-4'>
          <div className='btn border me-3'>
            {selectedNoOfRespondentsBtn || noOfRespondentsInput}
          </div>
          <button className='btn btn-light' onClick={onEditNoOfRespondentsClick}>
            <i className='bi bi-pencil'></i>
          </button>
        </div>
      )}

      {/* NUMBER OF RESPONDENTS CONFIRM MODAL */}
      {respondentsConfirmModal && (
        <RespondentsModal
          bodyText={`${getLanguageValue(
            languageText,
            `We do not recommend more than 8 respondents, are you sure you want to continue`
          )} ?`}
          cancelButtonText={getLanguageValue(languageText, 'No')}
          submitButtonText={getLanguageValue(languageText, 'Yes')}
          handleSubmitClick={SubmitRespondentsConfirmModal}
          handleCancelClick={cancelRespondentsConfirmModal}
        />
      )}
    </>
  )
}
