import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { RootState } from '@app/store/configureStore'
import { routePath } from '../../routePaths'
import { setCurrentUserLanguages, setSpinner, triggerLanguageChange } from '../../actions'
import {
  checkValidParticipantOrRespondentLink,
  setParticipantProfileDetails,
  updateParticipantFormLanguage,
} from '../actions'
import { getParticipantLanguages } from '../../languageList/languageTexts/actions'
import { IProfileDetails } from '../reducer'
import { useParticipantProfileAuth, ucQueryKey } from '../useParticipantProfileAuth'
import { langKey } from '@app/consts'
import { ILanguageObject, updateURLLanguage } from '@app/commonUtils/languageFunctionsHelper'
import { LanguageCode, LanguageId } from '@app/containers/commonEnums'
import { isLoggedIn } from '@app/containers/rootState'

export interface IUpdateParticipantFormLanguageBody {
  uniqueCode: string
  languageCode: string
}

export const useSelectLanguagePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { uniqueCode } = useParticipantProfileAuth()

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)
  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )

  const [languages, setLanguages] = useState<ILanguageObject[]>([])

  const fetchLanguages = async () => {
    dispatch(setSpinner(true))
    const languagesResponse = await getParticipantLanguages(dispatch).finally(() =>
      dispatch(setSpinner(false))
    )

    let languages: ILanguageObject[] = []
    if (languagesResponse && languagesResponse?.length > 0) {
      dispatch(setCurrentUserLanguages(languagesResponse))
      languages = languagesResponse.map((item) => {
        const displayname = item.displayName.split(' ')[0]
        return {
          key: item.id,
          id: item.id,
          icon: item.icon,
          value: String(item.id),
          name: item.name,
          displayName: displayname,
        }
      })
    }
    setLanguages(languages)
  }

  // Check whether participant already selected language / changed password
  const checkForNavigation = (): void => {
    dispatch(setSpinner(true))
    checkValidParticipantOrRespondentLink(uniqueCode, dispatch)
      .then((response) => {
        if (response) {
          const { languageName, languageId, isPasswordSet, emailAddress } = response

          const updatedParticipantProfileDetails: IProfileDetails = {
            ...participantProfileDetails,
            isPasswordSet,
            emailAddress,
            isParticipant: true,
          }
          dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))

          // if language was already set, bypass language page
          if (languageName) {
            navigate(
              `${routePath.participantLogIn}?${ucQueryKey}=${uniqueCode}&${langKey}=${languageName}`
            )
            triggerLanguageChange(languageName, false, dispatch, languageId)
          } else {
            fetchLanguages()
            triggerLanguageChange(LanguageCode.English, false, dispatch, LanguageId.English)
            updateURLLanguage(LanguageCode.English, navigate)
          }
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    checkForNavigation()
  }, [])

  const handleChangeLanguage = (value: string, id: number): void => {
    // For participant, language will be saved to db after password is changed if password is not set
    if (!participantProfileDetails.isPasswordSet) {
      navigate(`${routePath.participantLogIn}?${ucQueryKey}=${uniqueCode}&${langKey}=${value}`)
      if (value !== userLanguage.userLanguageCode) {
        triggerLanguageChange(value, false, dispatch, id)
      }
    } else {
      // This else block is for participant who has already logged in from
      // normal sign-in page / impersonated from admin / switched roles
      dispatch(setSpinner(true))

      const body: IUpdateParticipantFormLanguageBody = {
        uniqueCode: uniqueCode,
        languageCode: value,
      }
      updateParticipantFormLanguage(body, dispatch)
        .then((response) => {
          if (response) {
            if (isLoggedIn()) {
              // FROM LOGIN / SWITCH ROLES PAGE
              navigate(`${routePath.participantWelcomePage}?${ucQueryKey}=${uniqueCode}`)
            } else {
              navigate(
                `${routePath.participantLogIn}?${ucQueryKey}=${uniqueCode}&${langKey}=${value}`
              )
            }
            if (value !== userLanguage.userLanguageCode) {
              triggerLanguageChange(value, false, dispatch, id)
            }
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  return {
    languageText,
    languages,
    handleChangeLanguage,
  }
}
