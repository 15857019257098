// This file includes helper functions for date
import moment from 'moment'

// get Date in YYYY-MM-DD format
export const getFormattedDateOnly = (date: Date | string): string => {
  const formattedDate = moment(date).format('YYYY-MM-DD')
  return formattedDate
}

// Convert from '2023-07-24T14:44:53.684Z' to '2023 July 24'('YYYY MMMM DD')
export const getDateWithMonthFormat = (date: string): string => {
  const formattedDate = moment(date).format('YYYY MMMM DD')
  return formattedDate
}

// get Date in 'YYYY-MM-DD HH:mm:ss' format
export const getFullDateTimeFormat = (date: moment.MomentInput): string => {
  const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
  return formattedDate
}

// Convert from '2023-08-21 15:00:00' to '21 August 2023 - 15:00'('DD MMMM YYYY - HH:mm')
export const getDateWithMonthTimeFormat = (date: string): string => {
  const formattedDate = moment(date).format('DD MMMM YYYY - HH:mm')
  return formattedDate
}

// Convert date into 'YYYY-MM-DDTHH:mm:ssZ' i.e., ISO format
export const getISOFormattedDate = (date: Date | string): string => {
  const formattedDate = moment(date).toISOString()
  return formattedDate
}

// Convert from '2023-09-24T14:44:53.684Z' to 'Sep 24'('MMM DD')
export const getDateAndShortMonthFormat = (date: string): string => {
  const formattedDate = moment(date).format('MMM DD')
  return formattedDate
}
