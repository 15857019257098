import React from 'react'
import { E164Number } from 'libphonenumber-js/types'

import { SelectExistingInvoiceAddress } from './selectExistingInvoiceAddress'
import { Input } from '../../formComponents/input'
import { TextArea } from '../../formComponents/textArea'
import { PhoneNumberInput } from '../../../containers/employeeList/addEmployeeModal/phoneNumberInput'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import {
  IInvoiceExistingAddress,
  IInvoiceFocusInput,
  IInvoiceFormData,
  InvoiceInputs,
} from '../invoiceInterface'

interface IBillingAddressProps {
  languageText: ITranslationObject
  isActivity: boolean
  countries: IDropdownList[]
  existingAddresses: IInvoiceExistingAddress[]
  selectAddressModal: boolean
  invoiceAddressFormData: IInvoiceFormData
  focusInput: IInvoiceFocusInput
  openSelectAddressModal: () => void
  closeSelectAddressModal: () => void
  onSelectAddressClick: (data: IInvoiceExistingAddress) => void
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlePhoneInputChange: (value: E164Number) => void
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleCountrySelect: (selectedItem: IDropdownSelectedItem) => void
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void
  handleTextAreaBlurEvent: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}

export const InvoiceAddress = (props: IBillingAddressProps) => {
  const {
    languageText,
    isActivity,
    countries,
    existingAddresses,
    selectAddressModal,
    invoiceAddressFormData,
    focusInput,
    openSelectAddressModal,
    closeSelectAddressModal,
    onSelectAddressClick,
    handleInputChange,
    handlePhoneInputChange,
    handleTextAreaChange,
    handleCountrySelect,
    handleBlurEvent,
    handleTextAreaBlurEvent,
  } = props

  return (
    <div className='mt-10'>
      {isActivity && (
        <div
          className='fs-5 fw-bold my-4 text-decoration-underline d-inline-block'
          role='button'
          onClick={openSelectAddressModal}
        >
          {getLanguageValue(languageText, 'Choose address')}
        </div>
      )}
      <form className='row'>
        <div className='col-lg-3 col-12'>
          <Input
            name={InvoiceInputs.company}
            label={getLanguageValue(languageText, 'Company name')}
            placeholder={getLanguageValue(languageText, 'Company name')}
            value={invoiceAddressFormData.company}
            focusInput={focusInput.company}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>
        <div className='col-lg-3 col-12'>
          <Input
            name={InvoiceInputs.businessIdentification}
            label={getLanguageValue(languageText, 'Business identification')}
            placeholder={getLanguageValue(languageText, 'Business identification')}
            value={invoiceAddressFormData.businessIdentification}
            focusInput={focusInput.businessIdentification}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>
        <div className='col-lg-6 col-12'>
          <Input
            name={InvoiceInputs.invoiceReference}
            label={getLanguageValue(languageText, 'Invoice reference')}
            placeholder={getLanguageValue(languageText, 'Invoice reference')}
            value={invoiceAddressFormData.invoiceReference}
            focusInput={focusInput.invoiceReference}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>
        <div className='col-lg-9 col-12'>
          <Input
            name={InvoiceInputs.email}
            label={getLanguageValue(languageText, 'Email')}
            placeholder={getLanguageValue(languageText, 'Email')}
            value={invoiceAddressFormData.email}
            focusInput={focusInput.email}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>
        <div className='col-lg-3 col-12'>
          <PhoneNumberInput
            languageText={languageText}
            name={InvoiceInputs.telephone}
            label={getLanguageValue(languageText, 'Phone')}
            placeholder='+123 45 67 89'
            value={invoiceAddressFormData.telephone as E164Number}
            phoneNumberErrorMsg={focusInput.telephone.errorMessage}
            onChange={handlePhoneInputChange}
            onBlur={handleBlurEvent}
          />
        </div>
        <div className='col-12'>
          <TextArea
            name={InvoiceInputs.address}
            label={getLanguageValue(languageText, 'Postal Address')}
            placeholder={getLanguageValue(languageText, 'Address')}
            value={invoiceAddressFormData.address}
            focusInput={focusInput.address}
            onChange={handleTextAreaChange}
            handleBlurEvent={handleTextAreaBlurEvent}
            checkMarkNotRequired={true}
            required
          />
        </div>
        <div className='col-lg-3 col-12'>
          <Input
            name={InvoiceInputs.city}
            label={getLanguageValue(languageText, 'City')}
            placeholder={getLanguageValue(languageText, 'City')}
            value={invoiceAddressFormData.city}
            focusInput={focusInput.city}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>
        <div className='col-lg-3 col-12'>
          <Input
            name={InvoiceInputs.zip}
            label={getLanguageValue(languageText, 'Zip Code')}
            placeholder={getLanguageValue(languageText, 'Zip Code')}
            type='number'
            value={invoiceAddressFormData.zip}
            focusInput={focusInput.zip}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            required
          />
        </div>
        <div className='col-lg-3 col-12'>
          <DropdownSelect
            name={InvoiceInputs.countryId}
            label={getLanguageValue(languageText, 'Country')}
            defaultLabel={getLanguageValue(languageText, 'Select Country')}
            list={countries}
            value={invoiceAddressFormData.countryId}
            focusInput={focusInput.countryId}
            handleDropdownSelect={handleCountrySelect}
            searchOption
            required
          />
        </div>
        <div className='col-lg-3 col-12'>
          <Input
            name={InvoiceInputs.euvatid}
            label={getLanguageValue(languageText, 'EU VAT ID (optional)')}
            placeholder={getLanguageValue(languageText, 'EU VAT ID')}
            value={invoiceAddressFormData.euvatid}
            handleInputChange={handleInputChange}
          />
        </div>
      </form>

      {/* open select Address Modal */}
      {selectAddressModal && (
        <SelectExistingInvoiceAddress
          languageText={languageText}
          existingAddresses={existingAddresses}
          onSelectAddressClick={onSelectAddressClick}
          closeSelectAddressModal={closeSelectAddressModal}
        />
      )}
    </div>
  )
}
