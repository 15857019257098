import React, { useMemo, useState } from 'react'

import { Input } from '../../../../components/formComponents/input'
import { TextEditor } from '../../../../components/textEditor/textEditor'
import { DeleteModal } from '../../../../components/modals/deleteModal'
import { CourseParts, ICourseParts } from '../hooks'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import {
  DropdownSelect,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import { CoursePartDimension, getCoursePartGradeList } from '../../../../commonUtils/coursesHelper'

interface ICoursePartsProps {
  courseParts: ICourseParts[]
  languageText: ITranslationObject
  isSubmitting: boolean
  onCoursePartInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void
  handleCoursePartDropdownSelect: (selectedItem: IDropdownSelectedItem, index: number) => void
  handleCoursePartSubmit: (event: React.FormEvent<HTMLFormElement>, index: number) => void
  handleDeletePart: (id: number, index: number) => void
  onCoursePartTextEditorChange: (e?: string, name?: string, index?: number) => void
}

export const CoursePart = (props: ICoursePartsProps): JSX.Element | null => {
  const {
    courseParts,
    languageText,
    isSubmitting,
    onCoursePartInputChange,
    handleCoursePartDropdownSelect,
    handleCoursePartSubmit,
    handleDeletePart,
    onCoursePartTextEditorChange,
  } = props

  const [deleteModel, setDeleteModel] = useState<boolean>(false)
  const [partDeletedId, setPartDeletedId] = useState<number>(0)
  const [partDeletedIndex, setPartDeletedIndex] = useState<number>(0)

  const confirmDelete = (id: number, index: number) => {
    handleDeletePart(id, index)
    setDeleteModel(false)
  }

  const handleBtnDeleteClick = (id: number, index: number) => {
    if (id) {
      setPartDeletedId(id)
      setPartDeletedIndex(index)
      setDeleteModel(true)
    } else {
      confirmDelete(id, index)
    }
  }

  const closeDeleteModal = () => setDeleteModel(false)

  const dimensionList = useMemo(() => CoursePartDimension(languageText), [languageText])
  const gradeList = useMemo(() => getCoursePartGradeList(languageText), [languageText])

  return (
    <>
      {courseParts.map((coursePart, index) => {
        const isSaved = coursePart.isSaved || false

        return (
          <div className='my-4' key={index}>
            <div className='grey-bg-darker mx-n7 h-1px' />
            <div className='fs-2 fw-bold mt-8 mb-2'>
              {getLanguageValue(languageText, 'Part')} {index + 1}
            </div>
            <form onSubmit={(e) => handleCoursePartSubmit(e, index)}>
              <Input
                name={CourseParts.title}
                label={getLanguageValue(languageText, 'Title')}
                placeholder={getLanguageValue(languageText, 'Title')}
                value={coursePart.title}
                handleInputChange={(e) => onCoursePartInputChange(e, index)}
                required
              />
              <div className='row'>
                <div className='col-md-4 col-12'>
                  <DropdownSelect
                    name={CourseParts.dimension}
                    label={getLanguageValue(languageText, 'Dimension')}
                    defaultLabel={getLanguageValue(languageText, 'Select dimension')}
                    list={dimensionList}
                    value={coursePart.dimension}
                    handleDropdownSelect={(selectedItem) =>
                      handleCoursePartDropdownSelect(selectedItem, index)
                    }
                  />
                </div>
                <div className='col-md-4 col-12'>
                  <DropdownSelect
                    name={CourseParts.grade}
                    label={getLanguageValue(languageText, 'Grade')}
                    defaultLabel={getLanguageValue(languageText, 'Select grade')}
                    list={gradeList}
                    value={coursePart.grade}
                    handleDropdownSelect={(selectedItem) =>
                      handleCoursePartDropdownSelect(selectedItem, index)
                    }
                  />
                </div>
                <div className='col-md-4 col-12'>
                  <Input
                    name={CourseParts.duration}
                    label={getLanguageValue(languageText, 'Duration in sec')}
                    placeholder={getLanguageValue(languageText, 'Duration')}
                    value={coursePart.duration}
                    tooltipText='Estimated time to read this part calculated based on no of words'
                    disable
                  />
                </div>
              </div>
              <TextEditor
                name={CourseParts.content}
                label={getLanguageValue(languageText, 'Content')}
                placeholder={getLanguageValue(languageText, 'Content')}
                value={coursePart.content}
                handleTextEditor={(content) =>
                  onCoursePartTextEditorChange(content, CourseParts.content, index)
                }
                required
              />

              <div className='d-flex gap-4'>
                <button
                  type='submit'
                  className='btn btn-success w-125px'
                  disabled={isSaved || isSubmitting}
                >
                  <span>{getLanguageValue(languageText, isSaved ? 'Part saved' : 'Save')}</span>
                </button>

                {index !== 0 || isSaved ? (
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => handleBtnDeleteClick(coursePart.id, index)}
                  >
                    <span>{getLanguageValue(languageText, 'Delete part')}</span>
                  </button>
                ) : null}
              </div>
            </form>

            {coursePart.isError && (
              <div className='invalid-feedback d-inline-block'>
                {getLanguageValue(languageText, 'Please fill required fields')}
              </div>
            )}

            {deleteModel && (
              <DeleteModal
                languageText={languageText}
                headerText={getLanguageValue(languageText, 'Part')}
                bodyText={getLanguageValue(languageText, 'this part')}
                closeDeleteModal={closeDeleteModal}
                handleDeleteClick={() => confirmDelete(partDeletedId, partDeletedIndex)}
              />
            )}
          </div>
        )
      })}
    </>
  )
}
