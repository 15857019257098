import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { ITranslationObject, getLanguageValue } from './languageFunctionsHelper'

export enum Dimension {
  Directiveness = 'Directiveness',
  Affiliation = 'Affiliation',
  Adaptability = 'Adaptability',
}

export const CoursePartDimension = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: 1,
    value: '1',
    displayName: getLanguageValue(languageText, Dimension.Directiveness),
  },
  {
    id: 2,
    value: '2',
    displayName: getLanguageValue(languageText, Dimension.Affiliation),
  },
  {
    id: 3,
    value: '3',
    displayName: getLanguageValue(languageText, Dimension.Adaptability),
  },
]

enum CoursePartGrade {
  Low = 1,
  MediumLow = 2,
  MediumHigh = 3,
  High = 4,
}

export enum Grade {
  Low = 'Low',
  MediumLow = 'Medium Low',
  MediumHigh = 'Medium High',
  High = 'High',
}

export const getCoursePartGradeList = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: CoursePartGrade.Low,
    value: CoursePartGrade.Low.toString(),
    displayName: getLanguageValue(languageText, Grade.Low),
  },
  {
    id: CoursePartGrade.MediumLow,
    value: CoursePartGrade.MediumLow.toString(),
    displayName: getLanguageValue(languageText, Grade.MediumLow),
  },
  {
    id: CoursePartGrade.MediumHigh,
    value: CoursePartGrade.MediumHigh.toString(),
    displayName: getLanguageValue(languageText, Grade.MediumHigh),
  },
  {
    id: CoursePartGrade.High,
    value: CoursePartGrade.High.toString(),
    displayName: getLanguageValue(languageText, Grade.High),
  },
]

export const ConvertCourseDurationToMinutes = (seconds: number): number => {
  let mins = 0

  if (seconds) {
    if (seconds <= 60) mins = 1
    else mins = Math.round(seconds / 60)
  }

  return mins
}
