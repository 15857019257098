import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import {
  EmailStatus,
  IProfileRespondent,
  IProfileRespondentsList,
  RespondentProfileStatus,
  getRespondentProfileStatus,
} from '../../../profileList/editProfile/interface'
import { deleteRespondent } from '../../actions'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { RespondentsStatusProgressBar } from '../../../profileList/editProfile/modals/participantAccountDetailsModal/respondentsStatusProgressBar'
import { ResentRespondentInvite } from './resentRespondentInvite'
import { DeleteModal } from '../../../../components/modals/deleteModal'
import { ReactTooltip } from '../../../../components/tooltip/reactTooltip'
import { addToast, setSpinner } from '../../../actions'
import { ProfileId, RespondentId } from '@app/containers/reducer'

interface IRespondentStatusProps {
  languageText: ITranslationObject
  languages: IDropdownList[]
  profileId: ProfileId
  participantDetails: IProfileRespondentsList

  /**
   * The argument to this method are totally 'goddag yxskaft'.
   */
  handleRefetch: (selectList: boolean, invitedRespondents: boolean) => void
}

export const RespondentStatus = (props: IRespondentStatusProps): JSX.Element => {
  const { languageText, languages, profileId, participantDetails, handleRefetch } = props

  const dispatch = useDispatch()

  const initialRespondentsState: IProfileRespondent = {
    id: 0 as RespondentId,
    name: '',
    email: '',
    telephone: '',
    emailStatus: 0,
    emailOwnMessage: '',
    smsOwnMessage: '',
    smsStatus: 0,
    status: 0,
    idiLanguageId: 0,
    emailSentDate: '',
    instructionLanguageId: 0,
  }
  const [deleteRespondentId, setDeleteRespondentId] = useState<number>(0)
  const [resendInviteRespondentInfo, setResendInviteRespondentInfo] =
    useState<IProfileRespondent>(initialRespondentsState)

  const onRemindClick = (respondent: IProfileRespondent): void => {
    setResendInviteRespondentInfo(respondent)
  }

  const closeResendModal = (): void => {
    setResendInviteRespondentInfo(initialRespondentsState)
  }

  const handleResendInvite = (): void => {
    handleRefetch(false, true)
  }

  const onDeleteClick = (id: number): void => {
    setDeleteRespondentId(id)
  }

  const closeDeleteModal = (): void => {
    setDeleteRespondentId(0)
  }

  const handleDeleteRespondent = (): void => {
    dispatch(setSpinner(true))
    deleteRespondent(deleteRespondentId, dispatch)
      .then((response) => {
        if (response?.success) {
          dispatch(addToast('Respondent deleted successfully') as AnyAction)
          handleRefetch(true, true)
        }
      })
      .finally(() => {
        closeDeleteModal()
        dispatch(setSpinner(false))
      })
  }

  const isSomeFormFilled =
    (participantDetails.respondents &&
      participantDetails.respondents.some(
        (respondent) => respondent.status === RespondentProfileStatus.Completed
      )) ??
    false

  return (
    <>
      <div>
        {participantDetails.noOfRespondents > 0 ? (
          <>
            <div className='my-3'>
              {getLanguageValue(languageText, 'Keep track of your respondents here')}.{' '}
              {getLanguageValue(languageText, 'Invite and get response from')}{' '}
              {participantDetails.noOfRespondents ?? 0}{' '}
              {getLanguageValue(languageText, 'respondents')}.
            </div>

            <div className='my-4'>
              <RespondentsStatusProgressBar
                languageText={languageText}
                noOfRespondents={participantDetails.noOfRespondents}
                respondentsInvited={participantDetails.respondentsInvited}
                respondentsAnswered={participantDetails.respondentsAnswered}
              />
            </div>

            {participantDetails.respondents?.length > 0 &&
              participantDetails.respondents.map((respondent) => {
                if (respondent.status === RespondentProfileStatus.Completed) return
                return (
                  <div className='container' key={respondent.id}>
                    <div className='row rounded bg-body-secondary mt-3'>
                      <div className='d-flex align-items-center p-3 fs-5 col-12 col-md-5 fw-bold'>
                        <i className='bi bi-clock me-2'></i>
                        <ReactTooltip id={respondent.id.toString()} tooltipText={respondent.name}>
                          {respondent.name}
                        </ReactTooltip>
                      </div>
                      <div className='col-12 col-md-7 px-3 pt-md-3 pb-3 text-md-end'>
                        {respondent.emailStatus === EmailStatus.InvitationSentFailed ||
                        respondent.emailStatus === EmailStatus.ReminderSentFailed ? (
                          <>
                            <span className='text-danger me-3'>
                              <i className='bi bi-exclamation-triangle text-danger me-2'></i>
                              {getLanguageValue(languageText, 'Invitation error')}
                            </span>
                          </>
                        ) : (
                          <>
                            <div className='d-md-inline-block mb-3 mb-md-0 me-3'>
                              <i className='bi bi-send me-2'></i>
                              <span className='me-2'>
                                {getLanguageValue(
                                  languageText,
                                  getRespondentProfileStatus(respondent.status)
                                )}
                              </span>
                              {respondent.emailSentDate
                                ? respondent.emailSentDate.substring(0, 10)
                                : ''}
                            </div>
                            <button
                              className='btn border border-secondary-subtle me-3'
                              onClick={() => onRemindClick(respondent)}
                            >
                              {getLanguageValue(languageText, 'Remind')}
                            </button>
                          </>
                        )}
                        <button
                          className='btn border border-secondary-subtle'
                          onClick={() => onDeleteClick(respondent.id)}
                        >
                          <i className='bi bi-trash3'></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}

            {isSomeFormFilled && (
              <div className='mt-2'>
                {participantDetails.respondents.map((respondent, index) => {
                  if (respondent.status !== RespondentProfileStatus.Completed) return
                  return (
                    <div key={index} className='container'>
                      <div className='row bg-success-subtle align-items-center rounded mt-3'>
                        <div className='d-flex align-items-center fs-5 p-3 col-12 col-md-6 fw-bold'>
                          <i className='bi bi-check-circle me-2 text-success'></i>
                          <ReactTooltip id={respondent.id.toString()} tooltipText={respondent.name}>
                            {respondent.name}
                          </ReactTooltip>
                        </div>
                        <div className='col-12 col-md-6 px-3 pt-md-3 pb-3 align-items-centeralign-items-center text-success text-md-end'>
                          {getLanguageValue(languageText, 'Done! Response received')}.
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </>
        ) : (
          <div className='fs-6'>
            {getLanguageValue(languageText, 'Respondents are not yet invited')}.
          </div>
        )}
      </div>

      {resendInviteRespondentInfo.id > 0 && (
        <ResentRespondentInvite
          languageText={languageText}
          profileId={profileId}
          respondent={resendInviteRespondentInfo}
          languages={languages}
          closeModal={closeResendModal}
          handleSendClick={handleResendInvite}
        />
      )}

      {deleteRespondentId > 0 && (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, 'Respondent')}
          bodyText={getLanguageValue(languageText, 'Respondent')}
          handleDeleteClick={handleDeleteRespondent}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </>
  )
}
