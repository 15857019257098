import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { AccountSelectBtns } from '../profileEnums'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import {
  ToggleButtonGroup,
  IButtonGroupList,
} from '../../../components/formComponents/toggleButtonGroup'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { createClientByFacilitator, getAllCountries } from '../../clientList/addEditModal/action'
import { RootState } from '@app/store/configureStore'
import { Input, IFocusError } from '../../../components/formComponents/input'
import { ICreateUpdateClient } from '../../clientList/addEditModal/hooks'
import { AddEditAccountModal } from '../../clientList/addEditModal/addEditAccountModal'
import { IClients, ICountry } from '@app/containers/commonInterfaces'
import { addToast } from '@app/containers/actions'

enum ConsultantClientInputs {
  company = 'company',
  countryId = 'countryId',
}

interface IClientInfo {
  company: string
  countryId: string
}

interface IFocusInput {
  company: IFocusError
  countryId: IFocusError
}

interface IActivityConsultantClientSelectProps {
  languageText: ITranslationObject
  selectedBtn: string
  clientData: ReadonlyArray<IClients>
  clientId: number
  showEditModeConsultantClient: boolean
  stepsCompleted: number[]
  setShowEditModeConsultantClient: React.Dispatch<React.SetStateAction<boolean>>
  handleConsultantClientBtn: (name: string) => void
  handleConsultantClientSelect: (clientId: number) => void
  handleSaveClick: () => void
  refreshClientList: () => void

  openSelectClientEditMode: () => void
  closeClientSelectModal: () => void
  handleClientSelect: (clientId: number, clientName: string, isFacilitatorRemoved: boolean) => void
}

export const ActivityConsultantClientSelectionStep = (
  props: IActivityConsultantClientSelectProps
) => {
  const {
    languageText,
    selectedBtn,
    clientData,
    clientId,
    showEditModeConsultantClient,
    handleConsultantClientBtn,
    handleConsultantClientSelect,
    setShowEditModeConsultantClient,
    handleSaveClick,
    refreshClientList,
  } = props

  const accountSelectBtns = useMemo(
    () =>
      [
        {
          id: 1,
          name: AccountSelectBtns.Select,
          title: getLanguageValue(languageText, 'Select'),
        },
        {
          id: 2,
          name: AccountSelectBtns.AddNew,
          title: getLanguageValue(languageText, 'Add new'),
        },
      ] as IButtonGroupList[],
    [languageText]
  )

  const dispatch = useDispatch()
  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  const initialClientInfoState: IClientInfo = { company: '', countryId: '' }
  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: '',
  }
  const [countries, setCountries] = useState<IDropdownList[]>([])
  const [showSaveButton, setShowSaveButton] = useState<boolean>(true)
  const [clientInfo, setClientInfo] = useState<IClientInfo>(initialClientInfoState)
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    company: initialFocusInputState,
    countryId: initialFocusInputState,
  })
  const [addAccountModal, setAddAccountModal] = useState<boolean>(false)

  useEffect(() => {
    if (selectedBtn === AccountSelectBtns.AddNew && countries.length === 0)
      getAllCountries(dispatch).then((response: ICountry[]) => {
        if (response?.length > 0) {
          const countries: IDropdownList[] = response.map((item) => ({
            id: item.id,
            displayName: item.name,
            value: String(item.id),
          }))
          setCountries(countries)
        }
      })
  }, [selectedBtn])

  // Adding new client
  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    handleConsultantClientSelect(Number(selectedItem.value))
  }

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''
    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [ConsultantClientInputs.company]: 'Company is required',
        [ConsultantClientInputs.countryId]: 'Country is required',
      }
      errorMessage = errorMessages[name] && getLanguageValue(languageText, errorMessages[name])
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target

    setClientInfo({
      ...clientInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    handleFormErrors(name, value)
  }

  const handleCountrySelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    setClientInfo({
      ...clientInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleClientCreation = (clientId: number): void => {
    handleConsultantClientSelect(clientId)
    refreshClientList()
    setShowSaveButton(false)
    setShowEditModeConsultantClient(true)
    handleSaveClick()
    setClientInfo(initialClientInfoState)
    handleConsultantClientBtn(AccountSelectBtns.Select)
    dispatch(addToast('Client created successfully') as AnyAction)
  }

  const createNewClient = (): void => {
    const body: ICreateUpdateClient = {
      name: clientInfo.company,
      countryId: Number(clientInfo.countryId),
      enableParticipantCreateProfile: false,
      sourceType: 'IDI',
      sourceAddress: '',
    }

    // TODO: this method call is a little spooky. from reading the source
    //   it looks like we are creating a new client, reloading the user list
    //   but _not_ updating the 'default client' in redux. i think we should
    //   just invoke 'SwitchUserRole' here and move over to the new client.
    //
    //   -johan, 2024-09-04
    createClientByFacilitator(userId, body, dispatch).then((response) => {
      if (response?.success) {
        handleClientCreation(response.result)
      }
    })
  }

  const handleValidationOnSubmit = (): boolean => {
    let isError = false
    if (!clientInfo.company) {
      handleFormErrors(ConsultantClientInputs.company, clientInfo.company)
      isError = true
    }
    if (!clientInfo.countryId) {
      handleFormErrors(ConsultantClientInputs.countryId, clientInfo.countryId)
      isError = true
    }
    if (isError) return false

    return true
  }

  const onSaveClick = (): void => {
    if (selectedBtn === AccountSelectBtns.AddNew) {
      if (!handleValidationOnSubmit()) return
      createNewClient()
    } else {
      setShowSaveButton(false)
      setShowEditModeConsultantClient(true)
      handleSaveClick()
    }
  }

  const handleEditClick = (): void => {
    setShowSaveButton(true)
    setShowEditModeConsultantClient(false)
  }

  // Add more info
  const handleAddMoreInfoClick = (): void => {
    setAddAccountModal(true)
  }

  const closeClientModal = (clientId?: number): void => {
    if (clientId) {
      handleClientCreation(clientId)
    }
    setAddAccountModal(false)
  }

  const clientDropdownItems: Array<IDropdownList> = clientData.map((c) => {
    return {
      id: c.id,
      displayName: c.name,
      value: String(c.id),
    }
  })

  return (
    <>
      <div className='mb-4 p-4 rounded shadow-sm bg-white'>
        <div className='fs-5 fw-bold mb-3'> {getLanguageValue(languageText, 'Account')}</div>

        {!showEditModeConsultantClient && (
          <>
            <ToggleButtonGroup
              buttonList={accountSelectBtns}
              selectedBtn={selectedBtn}
              className='min-w-150px'
              handleButtonClick={handleConsultantClientBtn}
            />

            {selectedBtn === AccountSelectBtns.Select && (
              <div className='col-12 col-md-6 col-lg-3 mt-3'>
                <DropdownSelect
                  name='Company/Organisation'
                  label={getLanguageValue(languageText, 'Company/Organisation')}
                  list={clientDropdownItems}
                  value={clientId.toString()}
                  handleDropdownSelect={handleDropdownSelect}
                  searchOption
                />
              </div>
            )}

            {selectedBtn === AccountSelectBtns.AddNew && (
              <>
                <div className='row mt-3'>
                  <div className='col-md-3 col-12'>
                    <Input
                      name={ConsultantClientInputs.company}
                      label={getLanguageValue(languageText, 'Company/Organisation')}
                      placeholder={getLanguageValue(languageText, 'Company/Organisation')}
                      value={clientInfo.company}
                      focusInput={focusInput.company}
                      handleBlurEvent={handleBlurEvent}
                      handleInputChange={handleInputChange}
                      checkMarkNotRequired
                      required
                    />
                  </div>
                  <div className='col-md-3 col-12'>
                    <DropdownSelect
                      name={ConsultantClientInputs.countryId}
                      label={getLanguageValue(languageText, 'Country')}
                      defaultLabel={getLanguageValue(languageText, 'Select Country')}
                      list={countries}
                      value={clientInfo.countryId}
                      focusInput={focusInput.countryId}
                      handleDropdownSelect={handleCountrySelect}
                      searchOption
                      required
                    />
                  </div>
                  <div className='col-md-3 col-12 mt-md-4'>
                    <div className='btn border bg-white' onClick={handleAddMoreInfoClick}>
                      {getLanguageValue(languageText, 'Add more info')}
                    </div>
                  </div>
                </div>
              </>
            )}

            {showSaveButton && (
              <div className='mt-4'>
                <span
                  className='fs-5 fw-bold text-decoration-underline'
                  role='button'
                  onClick={onSaveClick}
                >
                  {getLanguageValue(languageText, 'Save and continue')}
                </span>
              </div>
            )}
          </>
        )}

        {showEditModeConsultantClient && (
          <>
            <button className='btn bg-white border'>
              {clientData.find((item) => item.id === clientId)?.name}
            </button>
            <button className='btn btn-light border px-3 ms-2' onClick={handleEditClick}>
              <i className='bi bi-pencil' />
            </button>
          </>
        )}
      </div>

      {addAccountModal && (
        <AddEditAccountModal
          rowData={{}}
          isActivity={true}
          refreshClientList={refreshClientList}
          closeClientModal={closeClientModal}
        />
      )}
    </>
  )
}
