import React from 'react'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { Input } from '../../../components/formComponents/input'

interface IResetPasswordProps {
  languageText: ITranslationObject
  forgotPasswordEmail: string
  successMessage?: string
  onGoBack: () => void
  setForgotPasswordEmail: (email: string) => void
  forgotPassword: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const ResetPassword = (props: IResetPasswordProps): JSX.Element => {
  const {
    languageText,
    forgotPasswordEmail,
    successMessage,
    onGoBack,
    setForgotPasswordEmail,
    forgotPassword,
  } = props

  return (
    <React.Fragment>
      <div>
        {successMessage ? (
          <div>
            <div className='fs-5 fw-semibold text-center'>{successMessage}</div>
            <div className='d-flex justify-content-center mt-5'>
              <button
                type='button'
                id='back-btn'
                className='btn btn-outline-secondary'
                onClick={onGoBack}
              >
                <i className='m-icon-swapleft'></i>
                {getLanguageValue(languageText, 'Back')}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className='mb-3 fs-4 fw-bolder'>
              {getLanguageValue(languageText, 'Forgot password')}
            </div>
            <div className='d-flex flex-column'>
              <Input
                label={getLanguageValue(languageText, 'Send email to reset password')}
                type='text'
                handleInputChange={(e) => setForgotPasswordEmail(e.target.value)}
                value={forgotPasswordEmail}
                placeholder={getLanguageValue(languageText, 'Email')}
                name='email'
                required={true}
              />
            </div>
            <div className='d-flex justify-content-between gap-5 mt-3'>
              <button type='submit' className='btn btn-success w-100' onClick={forgotPassword}>
                {getLanguageValue(languageText, 'Send')}
              </button>
              <button
                type='button'
                id='back-btn'
                className='btn btn-outline-secondary w-100'
                onClick={onGoBack}
              >
                <i className='m-icon-swapleft'></i>
                {getLanguageValue(languageText, 'Back')}
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
