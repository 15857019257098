import * as React from 'react'

import { StepComponent } from './createProfilePage'
import { CompletedStepPanel, CurrentStepPanel, UpcomingStepPanel } from './panels'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { ParticipantsRespondentsStatus } from '../profileList/editProfile/modals/participantAccountDetailsModal/participantsRespondentsStatus'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { RespondentStatus } from './profileParticipantPages/inviteRespondents/respondentStatus'
import { IProfileRespondentsList } from '../profileList/editProfile/interface'
import { getParticipantRespondentsByParticipantId } from '../profileList/editProfile/actions'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { InviteRespondents } from './profileParticipantPages/inviteRespondents/inviteRespondents'
import { ProfileStatus } from '../profileList/profileEnums'

export const RespondentStatusStep: StepComponent = (props) => {
  const __t = props.__t
  const title = __t('Respondents')
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const userLanguages = useSelector((state: RootState) => state.mainReducer.currentUserLanguages)
  const fakeDropdownLanguages: Array<IDropdownList> = userLanguages.map((l, index) => {
    return {
      id: l.id,
      displayName: l.displayName,
      value: l.name,
    }
  })
  const [details, setDetails] = React.useState<IProfileRespondentsList>({
    participantLink: '',
    noOfRespondents: 0,
    respondentsInvited: 0,
    respondentsAnswered: 0,
    respondents: [],
  })
  const dispatch = useDispatch()
  const [showAddRespondentModal, setShowAddRespondentModal] = React.useState(false)

  function loadRespondentDetailsAndUpdateState() {
    getParticipantRespondentsByParticipantId(props.profile.profileId, dispatch).then((res) => {
      setDetails(res)
    })
  }

  React.useEffect(() => {
    if (props.status !== 'current') {
      return
    }
    loadRespondentDetailsAndUpdateState()
  }, [props.status])

  switch (props.status) {
    case 'upcoming':
      return <UpcomingStepPanel title={title} />
    case 'current':
      return (
        <CurrentStepPanel __t={__t} title={title}>
          <div className='p-4'>
            <RespondentStatus
              profileId={props.profile.profileId}
              languageText={languageText}
              languages={fakeDropdownLanguages}
              participantDetails={details}
              handleRefetch={loadRespondentDetailsAndUpdateState}
            />
            {details.respondents.length < details.noOfRespondents && (
              <div className='text-center mt-4'>
                <button
                  className='btn btn-light'
                  onClick={(event) => {
                    event.preventDefault()
                    setShowAddRespondentModal(true)
                  }}
                >
                  <i className='bi bi-plus-lg' /> {__t('Add respondent')}
                </button>
              </div>
            )}
          </div>
          {showAddRespondentModal && (
            <InviteRespondents
              {...props}
              variant={{
                kind: 'modal',
                onSubmit: () => {
                  setShowAddRespondentModal(false)
                  loadRespondentDetailsAndUpdateState()
                },
                onClose: () => {
                  setShowAddRespondentModal(false)
                },
              }}
            />
          )}
        </CurrentStepPanel>
      )
    case 'completed': {
      const shouldShowStep =
        props.profile.status === ProfileStatus.Unknown ||
        props.profile.status === ProfileStatus.New ||
        props.profile.status === ProfileStatus.Active

      if (!shouldShowStep) {
        return null
      }
      return <CompletedStepPanel title={title} />
    }
  }
}
