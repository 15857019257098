import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { useEffect, useState } from 'react'
import {
  setRespondentCode,
  setRespondentData,
  updateRespondentFormLanguage,
} from '../../respondentPage/actions'
import { setCurrentUserLanguages, setSpinner, triggerLanguageChange } from '../../actions'
import { checkValidParticipantOrRespondentLink } from '../../participantPages/actions'
import { useNavigate } from 'react-router-dom'
import { routePath } from '../../routePaths'
import { IRespondentData } from '../../respondentPage/reducer'
import { getParticipantLanguages } from '../../languageList/languageTexts/actions'
import { LanguageCode, LanguageId } from '@app/containers/commonEnums'
import { ILanguageObject } from '@app/commonUtils/languageFunctionsHelper'
import { ProfileId } from '@app/containers/reducer'
import { ProfileStatus } from '@app/containers/profileList/profileEnums'

export interface IUpdateRespondentFormLanguageBody {
  profileId: ProfileId
  profileRespondentId: number
  languageCode: string
}

export const useRespondentRoute = () => {
  const currentURL = window.location.href
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const respondentCode: string = useSelector(
    (state: RootState) => state.respondentReducer.respondentCode
  )
  const respondentData: IRespondentData = useSelector(
    (state: RootState) => state.respondentReducer.respondentData
  )
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [languages, setLanguages] = useState<ILanguageObject[]>([])

  useEffect(() => {
    if (
      respondentCode &&
      Object.keys(languageText).length === 0 &&
      ((location.pathname !== routePath.respondentLanguagePage.replace(':c', respondentCode) &&
        respondentData.languageName) ||
        location.pathname === routePath.respondentLanguagePage.replace(':c', respondentCode))
      // The above 2 conditions are required as in languageSelect page, languageName will
      // not be available. But in other pages, it will be available
    ) {
      triggerLanguageChange(respondentData.languageName, false, dispatch, respondentData.languageId)
    }
  }, [respondentData.languageName, respondentCode])

  const extractCodeFromURL = (url: string): string => {
    const parts = url.split('/')
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === 'r' && parts[i + 1]) {
        return parts[i + 1]
      }
    }
    return ''
  }

  const fetchLanguages = async () => {
    const languagesResponse = await getParticipantLanguages(dispatch)
    let languages: ILanguageObject[] = []
    if (languagesResponse && languagesResponse?.length > 0) {
      dispatch(setCurrentUserLanguages(languagesResponse))
      languages = languagesResponse.map((item) => {
        const displayname = item.displayName.split(' ')[0]
        return {
          key: item.id,
          id: item.id,
          icon: item.icon,
          value: String(item.id),
          name: item.name,
          displayName: displayname,
        }
      })
    }
    setLanguages(languages)
  }

  useEffect(() => {
    const code = extractCodeFromURL(currentURL)
    dispatch(setRespondentCode(code))
    fetchLanguages()
  }, [])

  const checkForNavigation = (): void => {
    dispatch(setSpinner(true))
    checkValidParticipantOrRespondentLink(respondentCode, dispatch)
      .then((response) => {
        if (response) {
          // why are we copying this stuff around? couldn't we just
          // use the response? why not?
          const respondentData: IRespondentData = {
            languageName: response.languageName || LanguageCode.English,
            languageId: response.languageId || LanguageId.English,
            emailAddress: response.emailAddress,
            isFormFilledData: response.isFormFilledData,
            isFormFilled: response.isFormFilled,
            profileId: response.profileId,
            profileRespondentId: response.profileRespondentId,
            participantName: response.participantName,
            activityStatus: response.activityStatus,
            profileStatus: response.profileStatus,
          }
          dispatch(setRespondentData(respondentData))

          // why do we need a language ID to switch between languages? huh?
          triggerLanguageChange(
            respondentData.languageName,
            false,
            dispatch,
            respondentData.languageId
          )

          const canFillForm =
            !respondentData.isFormFilled && respondentData.profileStatus === ProfileStatus.Active

          // If profile is already delivered, respondent cannot fill the form
          // If form is completely filled already, go to thank you page
          if (!canFillForm) {
            navigate(routePath.respondentThankYou.replace(':c', respondentCode))
          }
          // If form is partially filled, go to self form page
          else if (respondentData.isFormFilledData) {
            navigate(routePath.respondentSelfForm.replace(':c', respondentCode))
          }
          // If language was already set, bypass language page
          //
          // NOTE 1: but.. how does this hook know that we're on the language page
          //   when this is called? and also, why aren't we navigating there if
          //   there's no language set?
          //
          // NOTE 2: this checks the language from the backend and not the derived
          //   value in 'respondentData', because that will always have a value.
          else if (response.languageName) {
            navigate(routePath.respondentLandingPage.replace(':c', respondentCode))
          }
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    const handleRouteChange = () => {
      // If route has changed due to the browser back button, make the API call again
      checkForNavigation()
    }

    if (respondentCode) {
      checkForNavigation()

      // Add a popstate event listener to detect browser back button clicks
      window.addEventListener('popstate', handleRouteChange)
    }

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleRouteChange)
    }
  }, [respondentCode])

  const handleChangeLanguage = (value: string, id: number): void => {
    dispatch(setSpinner(true))

    const body: IUpdateRespondentFormLanguageBody = {
      languageCode: value,
      profileId: respondentData.profileId,
      profileRespondentId: respondentData.profileRespondentId,
    }

    updateRespondentFormLanguage(body, dispatch)
      .then((response) => {
        if (response) {
          dispatch(
            setRespondentData({
              ...respondentData,
              languageName: value,
            })
          )
          triggerLanguageChange(value, false, dispatch, id)
          navigate(routePath.respondentLandingPage.replace(':c', respondentCode))
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  return {
    languageText,
    languages,
    handleChangeLanguage,
  }
}
