import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { ITranslationObject, getLanguageValue } from './languageFunctionsHelper'

enum CoursesPermissionAccess {
  Allow = 1,
  Deny = 2,
}

enum PermissionType {
  User = 1,
  Account = 2,
}

enum DateLimit {
  Yes = 1,
  No = 2,
}

const getAccsssList = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: CoursesPermissionAccess.Allow,
    displayName: getLanguageValue(languageText, 'Allow'),
    value: CoursesPermissionAccess.Allow.toString(),
  },
  {
    id: CoursesPermissionAccess.Deny,
    displayName: getLanguageValue(languageText, 'Deny'),
    value: CoursesPermissionAccess.Deny.toString(),
  },
]

const getPermissionTypeList = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: PermissionType.User,
    displayName: getLanguageValue(languageText, 'User'),
    value: PermissionType.User.toString(),
  },
  {
    id: PermissionType.Account,
    displayName: getLanguageValue(languageText, 'Account'),
    value: PermissionType.Account.toString(),
  },
]

const getDateLimitList = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: DateLimit.No,
    displayName: getLanguageValue(languageText, 'No'),
    value: DateLimit.No.toString(),
  },
  {
    id: DateLimit.Yes,
    displayName: getLanguageValue(languageText, 'Yes'),
    value: DateLimit.Yes.toString(),
  },
]

export { DateLimit, PermissionType, getAccsssList, getPermissionTypeList, getDateLimitList }
