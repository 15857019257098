import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getFullDateTimeFormat } from '../../../../../commonUtils/dateFunctionsHelper'
import { IFocusError } from '../../../../../components/formComponents/input'
import {
  createPresentation,
  deletePresentation,
  getAllPlannedAndOngoingPresentationsByCourseId,
  getCompletedParticipantsByProfileIdToSendPresentation,
  getParticipantNamesByProfileAndPresentationId,
  sendParticipantPresentationEmail,
  updatePresentation,
} from '../../actions'
import { ITabNavItem } from '../../../../../components/multiTabComponent/tabNavItem'
import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '../../../../../commonUtils/languageFunctionsHelper'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { IMultiDropdownList } from '../../../../../components/formComponents/dropdownMultiSelect'
import { IEditActivityCompletedProfile, IEditActivityPlannedPresentation } from '../../interface'
import { getAllPresentationTemplates } from '../../../../presentationsList/actions'
import { addToast, setSpinner } from '../../../../actions'
import { CoursePresentationStatus } from '@app/containers/profileList/profileEnums'
import { AnyAction } from 'redux'
import { ActivityId, ProfileId } from '@app/containers/reducer'

export interface ICreatePresentationBody {
  description?: string
  presentationDate?: string
  status?: number
  presentationTemplateId?: number
  courseId?: number
  facilitatorId?: number
  currentSlideId?: number | null
  profileIds?: number[]
  emailMessage?: string
}

// Create presentation keys
export enum PresentationInputKeys {
  presentationDate = 'presentationDate',
  emailInfo = 'emailInfo',
  presentationTemplate = 'presentationTemplate',
  existingPresentation = 'existingPresentation',
  send = 'send',
  dontSend = 'dontSend',
}

export enum TabIds {
  New = 'new',
  Existing = 'existing',
  Edit = 'edit',
}

interface IExistingPresentation {
  courseId: number
  currentSlideId: number
  emailMessage: string
  facilitatorId: number
  id: number
  presentationDate: string
  presentationTemplateId: number
  status: CoursePresentationStatus
  tenantId: number
  profileIds?: Array<ProfileId>
}

interface IPresentationTemplate {
  id: number
  name: string
}

interface IPresentationError {
  description: IFocusError
  presentationDate: IFocusError
  presentationTemplate: IFocusError
  existingPresentation: IFocusError
}

interface IPresentationInfo {
  presentationId: number
  presentationDate: Date | null
  emailInfo: string
  presentationTemplate: IPresentationTemplate
  existingPresentation: IPresentationTemplate
  currentSlideId: number | null
  presentationTemplateId: number
  profileIds: Array<ProfileId>
  status: CoursePresentationStatus
}

interface IEditPresentationError {
  presentationDate: IFocusError
  profileIds: IFocusError
}

export enum EditPresentationInputs {
  profileIds = 'profileIds',
  presentationDate = 'presentationDate',
  emailInfo = 'emailInfo',
  status = 'status',
}

export interface IUpdatePresentationBody {
  id: number
  presentationDate: string
  status: CoursePresentationStatus
  presentationTemplateId: number
  courseId: number
  facilitatorId: number
  currentSlideId: number | null
  profileIds?: Array<ProfileId>
  emailMessage: string
}

export const usePresentationModal = (
  isEdit: boolean,
  activityId: ActivityId,
  presentationData: IEditActivityPlannedPresentation | undefined,
  refetchPlannedPresentations: () => void,
  handleCloseClick: () => void,
  refetchParticipants: () => void,
  selectedProfiles?: IEditActivityCompletedProfile[]
) => {
  const dispatch = useDispatch()

  const userId = useSelector((state: RootState) => state.loginReducer.userId)
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [openSendPresentationModal, setOpenSendPresentationModal] = useState<boolean>(false)
  const [profileIds, setProfileIds] = useState<Array<ProfileId>>([])
  const [activeTab, setActiveTab] = useState<string>('')
  const [selectedPresentationDate, setSelectedPresentationDate] = useState<Date>(new Date())
  const [presentationTemplate, setPresentationTemplate] = useState<IDropdownList[]>([])
  const [existingPresentations, setExistingPresentations] = useState<IExistingPresentation[]>([])
  const [existingPresentationsList, setExistingPresentationsList] = useState<IDropdownList[]>([])
  const initialPresentationInfoState: IPresentationInfo = {
    presentationId: 0,
    presentationDate: null,
    emailInfo: '',
    presentationTemplate: {
      id: 0,
      name: '',
    },
    existingPresentation: {
      id: 0,
      name: '',
    },
    currentSlideId: null,
    presentationTemplateId: 0,
    profileIds: [],
    status: CoursePresentationStatus.Unknown,
  }

  const initialPresentationErrorState: IPresentationError = {
    description: {
      touched: false,
      errorMessage: '',
    },
    presentationDate: {
      touched: false,
      errorMessage: '',
    },
    presentationTemplate: {
      touched: false,
      errorMessage: '',
    },
    existingPresentation: {
      touched: false,
      errorMessage: '',
    },
  }

  const [presentationInfo, setPresentationInfo] = useState<IPresentationInfo>(
    initialPresentationInfoState
  )
  const [presentationError, setPresentationError] = useState<IPresentationError>(
    initialPresentationErrorState
  )

  // Edit Persentation
  const [editSelectedParticipantsList, setEditSelectedParticipantsList] = useState<
    IMultiDropdownList[]
  >([])

  const [editPresentationInfo, setEditPresentationInfo] = useState<IPresentationInfo>({
    presentationId: 0,
    presentationDate: new Date(),
    status: CoursePresentationStatus.Unknown,
    presentationTemplateId: 0,
    currentSlideId: 0,
    emailInfo: '',
    presentationTemplate: {
      id: 0,
      name: '',
    },
    existingPresentation: {
      id: 0,
      name: '',
    },
    profileIds: [],
  })
  const [editPresentationError, setEditPresentationError] = useState<IEditPresentationError>({
    presentationDate: {
      touched: false,
      errorMessage: '',
    },
    profileIds: {
      touched: false,
      errorMessage: '',
    },
  })
  const [showDeletePresentationModal, setShowDeletePresentationModal] = useState<boolean>(false)

  // Cleanup function to reset the presentationInfo when switching tabs
  useEffect(() => {
    setPresentationInfo(initialPresentationInfoState)
    setPresentationError(initialPresentationErrorState)
  }, [activeTab])

  const getTemplates = async (): Promise<void> => {
    await getAllPresentationTemplates('', '', 100, 0, dispatch).then((response) => {
      if (response?.items.length > 0) {
        const templates: IDropdownList[] = response?.items.map(
          (item: { id: number; description: string }) => ({
            id: item.id,
            displayName: item.description,
            value: item.description,
          })
        )
        setPresentationTemplate(templates)
      }
    })
  }

  const getExistingPresentations = async (): Promise<void> => {
    await getAllPlannedAndOngoingPresentationsByCourseId(activityId || 0, dispatch).then(
      (response) => {
        if (response?.items.length > 0) {
          const existingPresentationsList: IDropdownList[] = response.items.map(
            (item: { id: any; description: any }) => ({
              id: item.id,
              displayName: item.description,
              value: String(item.id),
            })
          )
          setExistingPresentations(response.items)
          setExistingPresentationsList(existingPresentationsList)
        }
      }
    )
  }

  useEffect(() => {
    if (!isEdit) {
      getTemplates()
      getExistingPresentations()
    }

    setActiveTab(TabIds.New)

    const selectedParticipantIds =
      (selectedProfiles && selectedProfiles.map((participant) => participant.id)) ?? []
    setProfileIds(selectedParticipantIds)

    const date = new Date()
    date.setHours(0, 0, 0, 0)
    setSelectedPresentationDate(date)
  }, [])

  const getProfileParticipantsForPresentation = async (): Promise<Array<ProfileId>> => {
    const allParticipantsList: IMultiDropdownList[] = []
    let selectedProfileIds: Array<ProfileId> = []

    // Getting previous selected participants
    await getParticipantNamesByProfileAndPresentationId(
      activityId,
      presentationData!.presentationId,
      dispatch
    ).then((response) => {
      if (response) {
        for (const [key, valuePair] of Object.entries(response)) {
          const id = parseInt(key)
          const label = String(valuePair)
          const value = String(key)
          const newObj: IMultiDropdownList = { id, label, value }
          allParticipantsList.push(newObj)
        }
        selectedProfileIds = allParticipantsList.map((item) => item.id as ProfileId)
        if (presentationData?.presentationStatus === CoursePresentationStatus.Completed) {
          setEditSelectedParticipantsList(allParticipantsList)
        }
      }
    })

    // Getting list of participants which doesn't have presentation
    if (presentationData?.presentationStatus !== CoursePresentationStatus.Completed) {
      await getCompletedParticipantsByProfileIdToSendPresentation(activityId, dispatch).then(
        (response) => {
          if (response) {
            for (const [key, valuePair] of Object.entries(response)) {
              if (selectedProfileIds.includes(Number(key) as ProfileId)) continue
              const id = parseInt(key)
              const label = String(valuePair)
              const value = String(key)
              const newObj: IMultiDropdownList = { id, label, value }
              allParticipantsList.push(newObj)
            }

            setEditSelectedParticipantsList(allParticipantsList)
          }
        }
      )
    }

    return selectedProfileIds
  }

  const updateProfilePresentation = async (): Promise<void> => {
    const selectedParticipantsIds = await getProfileParticipantsForPresentation()

    const parsedDate = new Date(presentationData!.presentationDateTime)
    if (presentationData)
      setEditPresentationInfo({
        ...editPresentationInfo,
        presentationId: presentationData.presentationId,
        presentationDate: parsedDate,
        status: presentationData.presentationStatus,
        profileIds: selectedParticipantsIds,
        currentSlideId: presentationData.currentSlideId,
        presentationTemplateId: presentationData.presentationTemplateId,
      })
  }

  useEffect(() => {
    if (isEdit) {
      updateProfilePresentation()

      const date = new Date()
      date.setHours(0, 0, 0, 0)
      setSelectedPresentationDate(date)
    }
  }, [isEdit])

  const navTabs = useMemo<ITabNavItem[]>(
    () => [
      {
        id: TabIds.New,
        title: getLanguageValue(languageText, 'New'),
      },
      {
        id: TabIds.Existing,
        title: getLanguageValue(languageText, 'Existing'),
      },
    ],
    [languageText]
  )

  const handleDateSelect = (name: string, date: Date): void => {
    setPresentationInfo({
      ...presentationInfo,
      [name]: date,
    })

    if (presentationError[name]?.touched) {
      handleFormErrors(name, String(date))
    }
  }

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { id, name, value } = selectedItem

    // lots of assumptions here in combination with weak typing. why are we
    // assuming that all dropdowns will operate on a property that has an
    // 'id' and a 'name'?
    //
    /** @see handleDropdownSelectForEditingPresentationWithoutAssumingThePropertyHasAnIdAndAName */
    setPresentationInfo((prevInfo) => ({
      ...prevInfo,
      [name]: {
        id: Number(id),
        name: value,
      },
    }))

    if (presentationError[name]?.touched) {
      const updatedValue: IPresentationTemplate = {
        id: Number(id),
        name: value,
      }
      handleFormErrors(name, updatedValue)
    }
  }

  function handleDropdownSelectForEditingPresentationWithoutAssumingThePropertyHasAnIdAndAName(
    item: IDropdownSelectedItem
  ): void {
    const looksLikeNumber = /^\d+$/.test(item.value)
    const value = looksLikeNumber ? parseInt(item.value, 10) : item.value

    setEditPresentationInfo((prev) => {
      return {
        ...prev,
        [item.name]: value,
      }
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setPresentationInfo({
      ...presentationInfo,
      [name]: value,
    })

    if (presentationError[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setPresentationInfo({
      ...presentationInfo,
      [name]: value,
    })

    if (presentationError[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleFormErrors = (name: string, value: string | IPresentationTemplate): void => {
    let errorMessage: string = ''

    switch (name) {
      case PresentationInputKeys.presentationDate:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Presentation date is required')
        }
        break
      case PresentationInputKeys.presentationTemplate:
        if (!value['name']) {
          errorMessage = getLanguageValue(languageText, 'Presentation template is required')
        }
        break
      case PresentationInputKeys.existingPresentation:
        if (!value['name']) {
          errorMessage = getLanguageValue(languageText, 'Choose a presentation')
        }
        break
      default:
        break
    }

    setPresentationError((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleValidationOnSubmit = (): boolean => {
    if (activeTab === TabIds.Existing) {
      if (!presentationInfo.existingPresentation.id) {
        for (const item in PresentationInputKeys) {
          handleFormErrors(item, presentationInfo[item])
        }
        return false
      }
      return true
    } else {
      if (!presentationInfo.presentationDate || !presentationInfo.presentationTemplate.id) {
        for (const item in PresentationInputKeys) {
          handleFormErrors(item, presentationInfo[item])
        }
        return false
      }
      return true
    }
  }

  const handleSaveClick = async (buttonClicked: PresentationInputKeys): Promise<void> => {
    if (!handleValidationOnSubmit()) return

    let existingPresentation: IExistingPresentation | undefined | null = null

    existingPresentation = existingPresentations.find(
      (presentation) => presentation.id === Number(presentationInfo.existingPresentation.name)
    )

    if (existingPresentation) {
      existingPresentation.profileIds = profileIds
    }

    const presentationDate = getFullDateTimeFormat(presentationInfo.presentationDate!)

    const body: ICreatePresentationBody = {
      ...(activeTab === TabIds.New && {
        presentationDate: presentationDate,
        status: 1,
        presentationTemplateId: presentationInfo.presentationTemplate.id,
        courseId: activityId || 0,
        facilitatorId: userId,
        currentSlideId: null,
        profileIds: profileIds,
        emailMessage: presentationInfo.emailInfo,
      }),
      ...(activeTab === TabIds.Existing &&
        existingPresentation && {
          presentationDate: existingPresentation.presentationDate,
          status: existingPresentation.status,
          presentationTemplateId: existingPresentation.presentationTemplateId,
          courseId: existingPresentation.courseId,
          facilitatorId: existingPresentation.facilitatorId,
          currentSlideId: existingPresentation.currentSlideId,
          profileIds: existingPresentation.profileIds,
          emailMessage: presentationInfo.emailInfo,
        }),
    }

    const isEmailSend = buttonClicked === PresentationInputKeys.send

    createPresentation(isEmailSend, body, dispatch)
      .then((response) => {
        if (response?.status === 200) {
          refetchPlannedPresentations()
          if (refetchParticipants) refetchParticipants()
          dispatch(addToast('Presentation created successfully') as AnyAction)
        }
      })
      .finally(() => {
        setOpenSendPresentationModal(false)
        handleCloseClick()
      })
  }

  const handleOpenInvitationModal = (): void => {
    if (!handleValidationOnSubmit()) return
    setOpenSendPresentationModal(true)
  }

  const handleCloseInvitationModal = (): void => {
    setOpenSendPresentationModal(false)
  }

  // edit presentation Model input handle Fn:
  const handleEditFormErrors = (name: string, value: string | IPresentationTemplate): void => {
    let errorMessage: string = ''

    switch (name) {
      case EditPresentationInputs.presentationDate:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Presentation date is required')
        }
        break
      case EditPresentationInputs.profileIds:
        if (editPresentationInfo.profileIds.length === 0) {
          errorMessage = getLanguageValue(languageText, 'Participant is required')
        }
        break
      default:
        break
    }

    setEditPresentationError((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleMultiDropdownSelect = (name: string, value: Array<string>): void => {
    setEditPresentationInfo({
      ...editPresentationInfo,
      [name]: value,
    })
    if (editPresentationError[name]?.touched) {
      handleEditFormErrors(name, String(value.length))
    }
  }

  const handleEditDateSelect = (name: string, date: Date): void => {
    setEditPresentationInfo({
      ...editPresentationInfo,
      [name]: date,
    })

    if (editPresentationError[name]?.touched) {
      handleEditFormErrors(name, String(date))
    }
  }

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setEditPresentationInfo({
      ...editPresentationInfo,
      [name]: value,
    })

    if (editPresentationError[name]?.touched) {
      handleEditFormErrors(name, value)
    }
  }

  const handleEditTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setEditPresentationInfo({
      ...editPresentationInfo,
      [name]: value,
    })

    if (editPresentationError[name]?.touched) {
      handleEditFormErrors(name, value)
    }
  }

  const handleEditValidationOnSubmit = (): boolean => {
    if (!editPresentationInfo.presentationDate || editPresentationInfo.profileIds.length === 0) {
      for (const item in EditPresentationInputs) {
        handleEditFormErrors(item, editPresentationInfo[item])
      }
      return false
    }
    return true
  }

  const handleEditSaveClick = async (selectedButton: PresentationInputKeys): Promise<void> => {
    if (!handleEditValidationOnSubmit()) return
    const presentationDate = getFullDateTimeFormat(editPresentationInfo.presentationDate!)
    const profileIds = editPresentationInfo.profileIds
    const body: IUpdatePresentationBody = {
      id: Number(editPresentationInfo.presentationId),
      presentationDate: presentationDate,
      presentationTemplateId: editPresentationInfo.presentationTemplateId,
      currentSlideId: editPresentationInfo.currentSlideId,
      status: editPresentationInfo.status,
      courseId: activityId,
      facilitatorId: userId,
      profileIds: profileIds,
      emailMessage: editPresentationInfo.emailInfo,
    }

    dispatch(setSpinner(true))
    updatePresentation(body, dispatch)
      .then((response) => {
        if (response?.status === 200) {
          refetchPlannedPresentations()
          refetchParticipants()
          if (selectedButton === PresentationInputKeys.send) {
            sendParticipantPresentationEmail(
              dispatch,
              profileIds,
              activityId,
              editPresentationInfo.presentationId
            )
          }
          dispatch(addToast('Presentation updated successfully') as AnyAction)
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
        handleCloseClick()
      })
  }

  const onDeletePresentationClick = (): void => {
    setShowDeletePresentationModal(true)
  }

  const handleDeletePresentation = async (): Promise<void> => {
    dispatch(setSpinner(true))
    deletePresentation(editPresentationInfo.presentationId, dispatch)
      .then((response) => {
        if (response) {
          refetchPlannedPresentations()
          refetchParticipants()
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
        handleCloseClick()
      })
  }

  const closeDeleteModal = (): void => {
    setShowDeletePresentationModal(false)
  }

  return {
    languageText,
    navTabs,
    activeTab,
    presentationTemplate,
    presentationError,
    presentationInfo,
    editPresentationInfo,
    editSelectedParticipantsList,
    selectedPresentationDate,
    openSendPresentationModal,
    existingPresentations,
    existingPresentationsList,
    editPresentationError,
    showDeletePresentationModal,
    handleOpenInvitationModal,
    handleCloseInvitationModal,
    setActiveTab,
    handleDateSelect,
    handleDropdownSelect,
    handleInputChange,
    handleTextAreaChange,
    handleSaveClick,
    handleMultiDropdownSelect,
    handleEditInputChange,
    handleEditDateSelect,
    handleEditTextAreaChange,
    handleEditSaveClick,
    handleDropdownSelectForEditingPresentationWithoutAssumingThePropertyHasAnIdAndAName,
    onDeletePresentationClick,
    handleDeletePresentation,
    closeDeleteModal,
  }
}
