import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { RootState } from '@app/store/configureStore'
import { updateRespondentFormFilledDataAnswer } from '../../containers/participantPages/profileParticipantPages/selfFormPage/actions'
import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { clearNotifications, setNotification, setSpinner } from '../../containers/actions'
import { routePath } from '../../containers/routePaths'
import {
  fillProfileRespondentOtherForm,
  setParticipantProfileDetails,
} from '../../containers/participantPages/actions'
import { IRespondentData } from '../../containers/respondentPage/reducer'
import { setRespondentData } from '../../containers/respondentPage/actions'
import { ucQueryKey } from '@app/containers/participantPages/useParticipantProfileAuth'
import { ProfileId } from '@app/containers/reducer'
import { useApiEndpoints } from '@app/api/end-points'
import { range } from '@app/containers/utils'

export interface IParticipantOrRespondentWordsBody {
  profileId: ProfileId
  profileRespondentId: number
  languageCode: string
}

export interface IUpdateFormFilledDataBody {
  wordPairId: number
  value: number
  formType: ProfileFormType
  profileId: ProfileId
  profileRespondentId: number
}

export interface IFillParticipantProfileSelfFormBody {
  profileId: ProfileId
  languageCode: string // huh?
}

export interface IFillProfileRespondentOtherFormBody {
  profileId: ProfileId
  profileRespondentId: number
  languageCode: string
}

export enum ProfileFormType {
  Self = 1,
  Other = 2,
}

export type IFormFilledDataKindOfButNotReally = {
  id: number
  leftWord: string
  rightWord: string
  value: number
}

interface ISelfFormListProps {
  languageText: ITranslationObject
  uniqueCode?: string
  userLanguage: string
  profileId: ProfileId
  profileRespondentId: number
  isFormFilledData: boolean
  isActivityRoleEnabled?: boolean
  respondentToken?: string
}

type FormQuestionsProps = {
  questions: ReadonlyArray<IFormFilledDataKindOfButNotReally>
  onAnswered: (wordPairId: number, value: number) => unknown
  isError: boolean
}

const FORM_RESPONSE_VALUES = [1, 2, 3, 4, 5, 6, 7] as const

export const FormQuestions: React.FC<FormQuestionsProps> = (props) => {
  return (
    <>
      {props.questions.map((question, listIndex) => {
        return (
          <div key={question.id}>
            <div
              className={clsx(
                'd-md-flex justify-content-center align-items-center fs-5 fw-bold py-3 py-md-4 px-1',
                props.isError && !question.value && 'bg-danger-subtle',
                listIndex < props.questions.length - 1 && 'border-bottom'
              )}
            >
              <div className='d-none col-md-3 d-md-flex justify-content-end'>
                {question.leftWord}
              </div>

              <div className='d-md-none d-flex justify-content-between mx-md-3 mb-2'>
                <div className=''>{question.leftWord}</div>
                <div className=''>{question.rightWord}</div>
              </div>

              <div className='gap-md-2 d-flex justify-content-between mx-md-3'>
                {FORM_RESPONSE_VALUES.map((value, index) => {
                  const selected = question.value === value
                  let id = String(index) + question.id
                  if (listIndex === 0 && props.questions.length > 1) {
                    if (index === 2) id = 'instruction2'
                    if (index === 0) id = 'instruction3'
                    if (index === 3) id = 'instruction4'
                  }

                  return (
                    <div
                      id={id}
                      key={id}
                      role='button'
                      className={clsx(
                        'rounded-circle p-lg-4 p-md-3 p-3 border',
                        selected
                          ? 'bg-success border-success'
                          : props.isError && !question.value
                            ? 'border-danger'
                            : 'border-secondary-subtle'
                      )}
                      onClick={() => !selected && props.onAnswered(question.id, index + 1)}
                    />
                  )
                })}
              </div>
              <div className='d-none d-md-block col-md-3'>{question.rightWord}</div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export const SelfForm = (props: ISelfFormListProps) => {
  const {
    languageText,
    uniqueCode,
    userLanguage,
    profileId,
    profileRespondentId,
    isFormFilledData,
    isActivityRoleEnabled,
    respondentToken,
  } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const api = useApiEndpoints(dispatch)

  const respondentCode: string = useSelector(
    (state: RootState) => state.respondentReducer.respondentCode
  )
  const respondentData: IRespondentData = useSelector(
    (state: RootState) => state.respondentReducer.respondentData
  )
  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )

  const initialFormState: IFormFilledDataKindOfButNotReally = {
    id: 0,
    leftWord: '',
    rightWord: '',
    value: 0,
  }
  const [selfFormList, setSelfFormList] = useState<IFormFilledDataKindOfButNotReally[]>([
    initialFormState,
  ])
  const [hiddenSelfFormList, setHiddenSelfFormList] = useState<IFormFilledDataKindOfButNotReally[]>(
    [initialFormState]
  )
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    if (!userLanguage) {
      return
    }

    if (!profileId) {
      return
    }

    if (!profileRespondentId) {
      return
    }

    dispatch(setSpinner(true))
    setIsError(false)

    const body: IParticipantOrRespondentWordsBody = {
      profileId: profileId,
      profileRespondentId: profileRespondentId,
      languageCode: userLanguage,
    }

    api
      .getParticipantOrRespondentWords(body)
      .then((response) => {
        const result: IFormFilledDataKindOfButNotReally[] = response

        // Display only 1st 20 questions
        // If any answer b/w 16th to 20th is selected, then display next 20 questions
        const showFullForm: boolean = result.slice(15, 20).some((item) => item.value > 0)

        if (showFullForm) {
          setSelfFormList(result)
        } else {
          setSelfFormList(result.slice(0, 20))
          setHiddenSelfFormList(result.slice(20, result.length))
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }, [profileRespondentId, userLanguage])

  const handleFormSelect = (id: number, value: number): void => {
    // Select the value once its selected, then call the api
    // If the api fails, the revert back the selected value
    const updatedList = [...selfFormList].map((list) => {
      if (list.id === id) {
        return { ...list, value: value } // Update the value for the matching ID
      }
      return list // Return the unchanged object for other IDs
    })
    setSelfFormList(updatedList)

    const body: IUpdateFormFilledDataBody = {
      wordPairId: id,
      value: value,
      profileId: profileId,
      profileRespondentId: profileRespondentId,
      formType: ProfileFormType.Other,
    }

    updateRespondentFormFilledDataAnswer(body, respondentToken!, dispatch).then((response) => {
      if (response?.success) {
        let showHiddenList: boolean = false

        const updatedList = [...selfFormList].map((list, index) => {
          if (list.id === id) {
            if (index > 14 && hiddenSelfFormList[0].id > 0) {
              showHiddenList = true
            }
            return { ...list, value: value } // Update the value for the matching ID
          }
          return list // Return the unchanged object for other IDs
        })
        if (showHiddenList) {
          updatedList.push(...hiddenSelfFormList)
          setHiddenSelfFormList([initialFormState])
        }
        setSelfFormList(updatedList)

        if (!isFormFilledData) {
          dispatch(
            setRespondentData({
              ...respondentData,
              isFormFilledData: true,
            })
          )
        }
      } else {
        // Revert back the selected value
        const updatedList = [...selfFormList].map((list) => {
          if (list.id === id) {
            return { ...list, value: 0 } // Update the value for the matching ID
          }
          return list // Return the unchanged object for other IDs
        })
        setSelfFormList(updatedList)
      }
    })
  }

  const handleValidation = (): boolean => {
    const isError = !selfFormList.every((obj) => obj.value)
    if (isError) {
      const notificationMessage =
        'Please ensure you have selected a value for each option in the list before advancing'
      dispatch(setNotification(notificationMessage))
    } else {
      dispatch(clearNotifications(''))
    }
    setIsError(isError)
    return isError
  }

  const onContinueClick = (): void => {
    if (handleValidation()) return
    dispatch(setSpinner(true))
    const body: IFillProfileRespondentOtherFormBody = {
      profileId,
      profileRespondentId,
      languageCode: userLanguage,
    }
    fillProfileRespondentOtherForm(body, respondentToken!, dispatch)
      .then((response) => {
        if (response) {
          navigate(routePath.respondentThankYou.replace(':c', respondentCode))
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  return (
    <div>
      <FormQuestions questions={selfFormList} onAnswered={handleFormSelect} isError={isError} />

      <div className={clsx('p-4 mt-3 text-center')}>
        {selfFormList.length > 20 && (
          <button className='btn btn-success btn-lg' onClick={onContinueClick}>
            {getLanguageValue(languageText, 'Submit')} <i className='bi bi-arrow-right ms-2'></i>
          </button>
        )}
      </div>
    </div>
  )
}
