import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../api'
import { setNotification } from '../../actions'
import { NewsType } from './types'

export const getNewsList = async (
  filter: string,
  sorting: string,
  newsType: NewsType,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch
): Promise<any> => {
  const body = {
    filter,
    sorting,
    newsType,
    maxResultCount,
    skipCount,
  }
  try {
    const response = await api.get(apiEndPoints.getNewsList, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteNews = async (newsId: number, dispatch: Dispatch): Promise<any> => {
  const paramQuery = {
    id: newsId,
  }
  try {
    await api.delete(apiEndPoints.deleteNewsPost, {
      params: paramQuery,
    })
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
