import React, { useMemo } from 'react'

import { Input } from '../../../components/formComponents/input'
import { TextEditor } from '../../../components/textEditor/textEditor'
import { DropdownSelect } from '@app/components/formComponents/dropdownSelect'
import { useAddEditModal } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '../../../components/modals/modalComponent'
import { getDirectivenessOptions, getAffiliationOptions } from '../../../commonUtils/behaveHelper'

export interface IAddEditProfileDescriptionModalProps {
  editId: number
  refetchProfileDescriptionList: () => void
  closeProfileDecriptionModal: () => void
}

export const AddEditProfileDescriptionModal = (props: IAddEditProfileDescriptionModalProps) => {
  const { closeProfileDecriptionModal } = props

  const {
    languageText,
    isEditClicked,
    ProfileDescriptionInputs,
    profileDescriptionInfo,
    focusInput,
    languages,
    handleInputChange,
    handleBlurEvent,
    handleTextEditorChange,
    handleDropdownSelect,
    handleSaveClick,
  } = useAddEditModal(props)

  const directivenessList = useMemo(() => getDirectivenessOptions(languageText), [languageText])
  const affiliationList = useMemo(() => getAffiliationOptions(languageText), [languageText])

  return (
    <>
      <ModalComponent
        width='lg'
        headerText={getLanguageValue(
          languageText,
          isEditClicked ? 'Edit Profile Description' : 'Add Profile Description'
        )}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        submitButtonText={getLanguageValue(languageText, 'Save')}
        handleCancelClick={closeProfileDecriptionModal}
        handleSubmitClick={handleSaveClick}
      >
        <DropdownSelect
          name={ProfileDescriptionInputs.directiveness}
          label={getLanguageValue(languageText, 'Directiveness')}
          list={directivenessList}
          value={profileDescriptionInfo.directiveness}
          focusInput={focusInput.directiveness}
          handleDropdownSelect={handleDropdownSelect}
          required
        />
        <DropdownSelect
          name={ProfileDescriptionInputs.affiliation}
          label={getLanguageValue(languageText, 'Affiliation')}
          list={affiliationList}
          value={profileDescriptionInfo.affiliation}
          focusInput={focusInput.affiliation}
          handleDropdownSelect={handleDropdownSelect}
          required
        />
        <Input
          name={ProfileDescriptionInputs.title}
          label={getLanguageValue(languageText, 'Title')}
          placeholder={getLanguageValue(languageText, 'Title')}
          value={profileDescriptionInfo.title}
          focusInput={focusInput.title}
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          required
        />
        <TextEditor
          name={ProfileDescriptionInputs.description}
          label={`${getLanguageValue(languageText, 'Description')}:`}
          placeholder={getLanguageValue(languageText, 'Description')}
          focusInput={focusInput.description}
          handleTextEditor={handleTextEditorChange}
          value={profileDescriptionInfo.description ?? ''}
          required
        />

        <DropdownSelect
          name={ProfileDescriptionInputs.language}
          label={getLanguageValue(languageText, 'CultureID')}
          defaultLabel={getLanguageValue(languageText, 'Select Culture')}
          list={languages}
          value={profileDescriptionInfo.language}
          focusInput={focusInput.language}
          handleDropdownSelect={handleDropdownSelect}
          searchOption
          required
        />
      </ModalComponent>
    </>
  )
}
