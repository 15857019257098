import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { AddEditMessage } from './addEditMessage'
import { deleteSavedMessage, getAllSavedMessages } from '../actions'
import { DeleteModal } from '../../../../components/modals/deleteModal'
import { MessageActionsCell } from './messageActionsCell'
import { ModalComponent } from '@app/components/modals/modalComponent'

export interface ISavedMessagesBody {
  maxResultCount: number
  emailMessageType: boolean
}

export interface ISavedMessage {
  id: number
  message: string
}

interface IInvitationMessageListModalProps {
  isEmail: boolean
  languageText: ITranslationObject
  handleCancelClick: () => void
  handleSaveClick: (selectedMessage: ISavedMessage) => void
}

export const InvitationMessageListModal: React.FC<IInvitationMessageListModalProps> = (props) => {
  const { isEmail, languageText, handleCancelClick, handleSaveClick } = props

  const dispatch = useDispatch()

  const initialMessageState: ISavedMessage = { id: 0, message: '' }
  const [messageList, setMessageList] = useState<ISavedMessage[]>([])
  const [selectedMessage, setSelectedMessage] = useState<ISavedMessage>(initialMessageState)
  const [addEditMessageModal, setAddEditMessageModal] = useState<boolean>(false)
  const [editMessage, setEditMessage] = useState<ISavedMessage>(initialMessageState)
  const [deleteMessage, setDeleteMessage] = useState<ISavedMessage>(initialMessageState)
  const [updateMessageList, setUpdateMessageList] = useState<boolean>(false)
  const [autoSelectMessage, setAutoSelectMessage] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const getSavedMessages = async (): Promise<void> => {
    const body: ISavedMessagesBody = {
      maxResultCount: 100,
      emailMessageType: isEmail,
    }
    await getAllSavedMessages(body, dispatch).then((response) => {
      if (response) {
        const messageList: ISavedMessage[] = response.items.map((list: ISavedMessage) => ({
          id: list.id,
          message: list.message,
        }))
        setMessageList(messageList)

        // Selecting newly added/edited message automatically
        if (autoSelectMessage) {
          const selectedMessage: ISavedMessage = messageList[messageList.length - 1]
          setSelectedMessage(selectedMessage)
          setAutoSelectMessage(false)
        }
      }
    })
  }

  useEffect(() => {
    // Getting Message List
    getSavedMessages()
  }, [updateMessageList])

  const handleSelect = (item: ISavedMessage) => {
    setSelectedMessage(item)
    handleSaveClick(item)
    if (error) setError('')
  }

  const handleAddMessage = (): void => setAddEditMessageModal(true)

  const handleCloseAddEditModal = (refreshList: boolean): void => {
    setAddEditMessageModal(false)
    if (editMessage.id > 0) {
      setEditMessage(initialMessageState)
      setAutoSelectMessage(true)
    }
    if (refreshList) {
      setUpdateMessageList(!updateMessageList)
    }
    if (error) setError('')
  }

  const handleEditMessage = (item: ISavedMessage): void => {
    setEditMessage(item)
    setAddEditMessageModal(true)
  }

  const handleDeleteMessage = (item: ISavedMessage): void => {
    setDeleteMessage(item)
  }

  const handleDeleteSavedMessage = async (): Promise<void> => {
    await deleteSavedMessage(deleteMessage.id, dispatch).then((response) => {
      if (response) {
        setDeleteMessage(initialMessageState)
        setUpdateMessageList(!updateMessageList)
      }
    })
  }

  const closeDeleteModal = (): void => setDeleteMessage(initialMessageState)

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, isEmail ? 'Email Messages' : 'SMS Messages')}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        handleCancelClick={handleCancelClick}
      >
        <div className='d-flex flex-end mb-8'>
          <button className='btn btn-success' onClick={handleAddMessage}>
            {getLanguageValue(languageText, 'Add Message')}
          </button>
        </div>

        {/* Message List */}
        {messageList.length > 0 ? (
          messageList.map((list: ISavedMessage) => (
            <div key={list.id} className='container-fluid my-5 overflow-scroll'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex justify-content-start align-items-center w-100'>
                  <MessageActionsCell
                    languageText={languageText}
                    selectedMessage={list}
                    handleEditClick={handleEditMessage}
                    handleDeleteClick={handleDeleteMessage}
                  />
                  <div className='ms-3'>{list.message}</div>
                </div>
                <button
                  className={clsx('btn btn-light', selectedMessage.id === list.id && 'btn-success')}
                  onClick={() => handleSelect(list)}
                >
                  {getLanguageValue(languageText, 'Select')}
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className='fs-5 text-center'>
            {getLanguageValue(languageText, 'No matching records found')}
          </div>
        )}
        {}

        {/* Error Message */}
        {error && <div className='invalid-feedback d-inline-block'>{error}</div>}
      </ModalComponent>

      {/* Add/Edit Message Modal */}
      {addEditMessageModal && (
        <AddEditMessage
          languageText={languageText}
          editMessage={editMessage}
          isEmail={isEmail}
          handleClose={handleCloseAddEditModal}
          handleSelect={handleSelect}
        />
      )}

      {/*Delete Message Modal */}
      {deleteMessage.id > 0 && (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, 'Message')}
          bodyText=''
          handleDeleteClick={handleDeleteSavedMessage}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </>
  )
}
