import React from 'react'
import { CustomModal } from '../../../../../components/modals/customModal'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'

interface IEditProfileConfirmationProps {
  languageText: ITranslationObject
  completedAndDeliveredProfileCount: number
  handleSaveClick: () => void
  closeShowConfirmationModal: () => void
}

export const EditProfileConfirmationModal = (props: IEditProfileConfirmationProps): JSX.Element => {
  const {
    languageText,
    completedAndDeliveredProfileCount,
    handleSaveClick,
    closeShowConfirmationModal,
  } = props

  const getConfirmationMessage = () => {
    const firstMessage = getLanguageValue(
      languageText,
      'If you change the norm then the profile will be recalculated'
    )
    let secondMessage = ''
    if (completedAndDeliveredProfileCount > 0) {
      secondMessage = getLanguageValue(
        languageText,
        'Note : The calculation is time taking process. So wait until complete'
      )
    }
    return (
      <div>
        <p>{firstMessage}</p>
        {secondMessage && <p>{secondMessage}</p>}
      </div>
    )
  }
  return (
    <>
      <CustomModal
        headerText={getLanguageValue(languageText, 'Confirm')}
        bodyText={getConfirmationMessage()}
        submitButtonText={getLanguageValue(languageText, 'Ok')}
        handleSubmitClick={handleSaveClick}
        handleCancelClick={closeShowConfirmationModal}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
      />
    </>
  )
}
