import React from 'react'
import moment from 'moment'

import logo from '../../../../../../../public/DeliveryImages/IDI_Logotyp.webp'
import { DownloadNavItem } from './downloadNavItem'

export const GroupDownloadHeader: React.FC = () => {
  return (
    <>
      <div className='d-flex justify-content-between gap-2'>
        <div className='d-flex justify-content-center align-items-center gap-20'>
          <img src={logo} className='idi-logo' />
        </div>

        <div className='d-flex justify-content-center align-items-center gap-4'>
          <DownloadNavItem
            icon={<i className='bi-calendar' />}
            info={moment().format('YYYY-MM-DD')}
          />
          <DownloadNavItem icon={<i className='bi-file-earmark' />} info='1/1' />
        </div>
      </div>

      <div className='border border-top w-100 my-4' />
    </>
  )
}
