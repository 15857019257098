import React, { useState } from 'react'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { UserId } from '@app/containers/reducer'
import { SelectLanguageDropdownModal } from '@app/components/languagePageComponent/selectLanguageDropdownModal'
import { LanguageCode } from '@app/containers/commonEnums'
import { InstructionLanguageSelector } from './instructionLanguageSelector'
import { ILanguage } from '@app/containers/commonInterfaces'

export interface IRespondentList {
  /** This is a user ID */
  id: UserId
  name: string
  emailAddress: string
  phoneNumber: string
  instructionLanguageId: number | null
}

interface ISelectFromListProps {
  languageText: ITranslationObject
  languages: Array<ILanguage>
  respondentsList: IRespondentList[]
  searchText: string
  selectedRespondents: IRespondentList[]
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectRespondent: (respondent: IRespondentList) => void
  handleRemoveRespondent: (respondent: IRespondentList) => void
}

export const SelectFromList = (props: ISelectFromListProps) => {
  const {
    languageText,
    respondentsList,
    searchText,
    selectedRespondents,
    handleSearch,
    handleSelectRespondent,
    handleRemoveRespondent,
  } = props

  const [visibleItems, setVisibleItems] = useState<number>(5)
  const [changeInstructionLanguageIndex, setChangeInstructionLanguageIndex] = useState<
    number | undefined
  >(undefined)

  const handleShowMore = () => {
    setVisibleItems(visibleItems + 5) // Increase the number of visible items
  }
  const handleShowless = () => {
    setVisibleItems(5) // Increase the number of visible items
  }

  const renderedItems = respondentsList.slice(0, visibleItems)

  return (
    <>
      <div className='select-from-list'>
        <div className='d-flex position-relative align-items-center mb-4'>
          <i className='bi bi-search position-absolute me-3 end-0'></i>
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid border-secondary-subtle'
            placeholder='Search...'
            value={searchText}
            onChange={handleSearch}
          />
        </div>

        {/* Respondent List */}
        <div className='respondents-list-wrapper'>
          {renderedItems.map((respondent) => {
            return (
              <div key={respondent.id} className='d-flex align-items-center py-2'>
                <button
                  className='btn btn-success'
                  onClick={() => handleSelectRespondent(respondent)}
                >
                  <i className='bi bi-plus-lg fs-5' />
                </button>
                <span className='fs-5 fw-bold ms-3'>{respondent.name}</span>
              </div>
            )
          })}
        </div>
        {respondentsList.length > 5 && (
          <div className='d-flex justify-content-center'>
            {visibleItems < respondentsList.length && (
              <button className='btn fw-semibold fs-5 bg-white' onClick={handleShowMore}>
                {getLanguageValue(languageText, 'Show More')}
                <i className='bi bi-chevron-down ms-2' />
              </button>
            )}
            {visibleItems >= respondentsList.length && (
              <button className='btn fw-semibold fs-5 bg-white' onClick={handleShowless}>
                {getLanguageValue(languageText, 'Show less')}
                <i className='bi bi-chevron-up ms-2' />
              </button>
            )}
          </div>
        )}
        <div className='border-bottom border-secondary-subtle mb-3' />

        {/* Selected Respondent  */}

        <div className='fs-5 fw-bold mb-3'>{getLanguageValue(languageText, 'Selected')}</div>
        {selectedRespondents.length > 0 && (
          <>
            <div className='mb-4'>
              {selectedRespondents.map((respondent, index: number) => (
                <div
                  key={respondent.id}
                  className='d-flex align-items-center bg-success rounded bg-opacity-10 mb-2'
                >
                  <span className='fs-5 fw-bold p-3 flex-grow-1'>{respondent.name}</span>
                  <div
                    className='p-2 me-3 btn'
                    title={getLanguageValue(languageText, 'Select the instruction language')}
                    onClick={(event) => {
                      event.preventDefault()
                      setChangeInstructionLanguageIndex(index)
                    }}
                  >
                    <i className='bi bi-globe fs-4' />
                  </div>
                  <div className='p-2 me-3 btn' onClick={() => handleRemoveRespondent(respondent)}>
                    <i className='bi bi-trash3 fs-4' />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {selectedRespondents.length < 1 && (
          <>
            <div className='d-inline-block bg-primary rounded bg-opacity-10 mb-4 p-3'>
              <i className='bi bi-info-circle me-2 fs-4' />{' '}
              {getLanguageValue(
                languageText,
                'No respondent selected. Select by clicking the + button above.'
              )}
            </div>
          </>
        )}
      </div>

      {typeof changeInstructionLanguageIndex === 'number' && (
        <InstructionLanguageSelector
          languageText={props.languageText}
          languages={props.languages}
          onChange={props.handleSelectRespondent}
          onClose={() => setChangeInstructionLanguageIndex(undefined)}
          value={selectedRespondents[changeInstructionLanguageIndex]}
        />
      )}
    </>
  )
}
