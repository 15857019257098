import React from 'react'

import { usePlacementList } from './hooks'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { ReactTable } from '../../components/reactTable/reactTable'
import { AddEditPlacementModal } from './addEditPlacementModal'

export const PlacementList = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openPlacementModal,
    editedRowId,
    cultures,
    countriesList,
    setFilterCulture,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addPlacementClick,
    closePlacementModal,
  } = usePlacementList()

  let rowData = {}
  if (editedRowId) {
    rowData = data.find((item) => item.id === editedRowId)
  }

  return (
    <>
      <ReactTable
        wrapperClass='p-2 pt-4 p-md-4'
        headerText={getLanguageValue(languageText, 'Placement list')}
        searchPlaceholder={getLanguageValue(languageText, 'Search Placement')}
        buttonText={getLanguageValue(languageText, 'Add Placement')}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        filterDropdownDefaultLabel={getLanguageValue(languageText, 'Select Culture')}
        filterDropdownLabel={getLanguageValue(languageText, 'Select Culture')}
        filterDropdownList={cultures}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilterDropdownTable={setFilterCulture}
        handleButtonClick={addPlacementClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {openPlacementModal || editedRowId ? (
        <AddEditPlacementModal
          rowData={rowData}
          cultures={cultures}
          countriesList={countriesList}
          closePlacementModal={closePlacementModal}
        />
      ) : (
        ''
      )}
    </>
  )
}
