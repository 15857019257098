import { ActivityStatus, ProfileStatus } from '../profileList/profileEnums'
import { ProfileId } from '../reducer'
import { SET_RESPONDENT_CODE, SET_RESPONDENT_DATA } from './constant'

export interface IRespondentData {
  languageName: string
  languageId: number
  emailAddress: string

  /** FIXME: Trashcan name alert. Form partly filled out */
  isFormFilledData: boolean

  /** FIXME: Trashcan name alert. Form fully filled out */
  isFormFilled: boolean
  profileId: ProfileId
  profileRespondentId: number
  participantName: string
  activityStatus: ActivityStatus
  profileStatus: ProfileStatus
}

export interface IInitialState {
  respondentCode: string
  respondentData: IRespondentData
}

const initialState: IInitialState = {
  respondentCode: '',
  respondentData: {
    languageName: '',
    languageId: 0,
    emailAddress: '',
    isFormFilledData: false,
    isFormFilled: false,
    profileId: 0 as ProfileId,
    profileRespondentId: 0,
    participantName: '',
    activityStatus: ActivityStatus.Created,
    profileStatus: ProfileStatus.New,
  },
}

export const respondentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_RESPONDENT_CODE:
      return {
        ...state,
        respondentCode: action.payload,
      }
    case SET_RESPONDENT_DATA:
      return {
        ...state,
        respondentData: action.payload,
      }
    default:
      return state
  }
}
