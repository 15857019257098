import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../api'
import { setNotification } from '../../actions'

export const updateWord = async (body: any, dispatch: Dispatch): Promise<any> => {
  try {
    const response = await api.put(apiEndPoints.updateWord, JSON.stringify(body))
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createWord = async (body: any, dispatch: Dispatch): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createWord, JSON.stringify(body))
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
