import { Dispatch } from 'redux'
import { api, apiEndPoints } from '../../api'
import { setNotification } from '../actions'
import { ApiResult } from '@app/types'
import { IClients } from '../commonInterfaces'

export const getClientList = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch
): Promise<ApiResult<IClients>> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
  }
  try {
    const response = await api.get(apiEndPoints.getClients, { params: body })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteClient = async (clientId: number, dispatch: Dispatch): Promise<boolean> => {
  const paramQuery = {
    id: clientId,
  }
  try {
    const response = await api.delete(apiEndPoints.deleteClient, {
      params: paramQuery,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
