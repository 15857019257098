import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addToast, clearNotifications, setNotification, setSpinner } from '../../actions'
import { RootState } from '@app/store/configureStore'
import {
  createEmployeeParticipantsWithRole,
  createEmployees,
  getEmployeesData,
  isEmployeeExists,
} from '../actions'
import { ValidationHelper } from '../../validationHelper'
import { getWindowDimensions, IWindowDimensions } from '../../../commonUtils/screenWidthHelper'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IRoleData } from '../../profileList/addProfile/roleSettings/roleSettingsModal'
import { E164Number } from 'libphonenumber-js/types'
import { ITabNavItem } from '../../../components/multiTabComponent/tabNavItem'
import { AnyAction } from 'redux'
import {
  IFetchParticipantsParams,
  IParticipantData,
  IParticipantRoleData,
} from '@app/containers/profileList/addProfile/activityParticipantsStep'
import {
  createParticipantsFromEditProfile,
  getProfileEmployeesByClientId,
} from '@app/containers/profileList/addProfile/actions'
import { ICreateProfileBody } from '@app/containers/profileList/addProfile/hooks'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { ApiResponse } from '@app/types'
import { ActivityId, ProfileId, UserId } from '@app/containers/reducer'

export interface ITableHeaders {
  name: string
  icon: string
  required: boolean
}

export enum EmployeeInputs {
  name = 'name',
}

export enum TabEnum {
  TAB_1 = 'Select',
  TAB_2 = 'Add New',
}
export interface ICreateEmployeesBody {
  name: string
  surname: string
  emailAddress: string
  phoneNumber: string
  languageId?: number
  clientId: number
  isDefaultClient: boolean
}

export interface ICreateEmployeeParticipantsWithRoleBody extends ICreateEmployeesBody {
  activityId: ActivityId
  roleId: number
  roleText: string
  noOfRespondents: number
}

export interface IEmployeeTableProps {
  name?: string
  surname?: string
  emailAddress?: string
  phoneNumber?: string
  languageId?: string
  firstNameError?: string
  lastNameError?: string
  phoneNumberError?: string
  emailError?: string
  isEmailExists?: boolean
  id: UserId
  isValidEmail?: boolean
  roleId: number
  roleText: string
  noOfRespondents: number
}

export interface IClientsList {
  id: number
  name: string
}

export interface IUploadEmployeeModalProps {
  languageText: ITranslationObject
  clientIdForEdit?: number
  uploadFromActivityPage?: boolean
  activityIdFromEditActivityPage?: ActivityId
  isIndividualActivity?: boolean
  existingEmails?: Array<string>
  isRolesRequired?: boolean
  refetchNewParticipants?: () => void
  setNewlyAddedEmployees?: (newlyAddedEmployees: any) => void
  closeUploadEmployeeModal: (refreshEmployeeList?: boolean) => void
}

export const useUploadEmployee = (props: IUploadEmployeeModalProps) => {
  const {
    languageText,
    uploadFromActivityPage,
    activityIdFromEditActivityPage,
    clientIdForEdit,
    isIndividualActivity,
    existingEmails,
    isRolesRequired,
    setNewlyAddedEmployees,
    closeUploadEmployeeModal,
  } = props

  const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.TAB_1)

  const navTabs = useMemo(() => {
    const tabs: ITabNavItem[] = [
      {
        id: TabEnum.TAB_1,
        title: getLanguageValue(languageText, 'Select'),
      },
      {
        id: TabEnum.TAB_2,
        title: getLanguageValue(languageText, 'Add new'),
      },
    ]

    return tabs
  }, [languageText])

  const dispatch = useDispatch()

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const defaultLanguageId = useSelector(
    (state: RootState) => state.loginReducer.userData.selectedLanguageId
  )
  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)
  const clientId = isLoggedInRoleAdmin(loggedInUserRole)
    ? clientIdForEdit!
    : defaultClient.defaultClientId
  const inputRef = useRef<HTMLInputElement>(null)

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(getWindowDimensions())
  const [employees, setEmployees] = useState<IEmployeeTableProps[]>([])
  const [file, setFile] = useState<File[]>([])
  const [selectedNewParticipantIndexForRole, setSelectedNewParticipantIndexForRole] = useState<
    number | null
  >(null)
  // States required in Select all tab from edit profile
  const [selectedParticipants, setSelectedParticipants] = useState<IParticipantRoleData[]>([])
  const [participantsList, setParticipantsList] = useState<IParticipantData[]>([])
  const [participantsFilter, setParticipantsFilter] = useState<string>('')
  const [selectedExistingParticipantIndexForRole, setSelectedExistingParticipantIndexForRole] =
    useState<number | null>(null)
  const [isRoleSettingsError, setIsRoleSettingsError] = useState<boolean>(false)

  const addRow = () => {
    const initialValues: IEmployeeTableProps = {
      id: 0 as UserId,
      name: '',
      surname: '',
      emailAddress: '',
      phoneNumber: '',
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      phoneNumberError: '',
      isEmailExists: false,
      roleId: 0,
      roleText: '',
      noOfRespondents: 0,
    }
    if (isRolesRequired) {
      // For edit profile feature
      setEmployees([
        ...employees,
        { ...initialValues, roleId: 0, roleText: '', noOfRespondents: 0 },
      ])
    } else {
      setEmployees([...employees, initialValues])
    }
  }

  const tableHeaders: ITableHeaders[] = [
    {
      name: getLanguageValue(languageText, 'Firstname'),
      icon: windowDimensions.width < 600 ? '' : 'bi bi-person-fill',
      required: windowDimensions.width < 600 ? false : true,
    },
    {
      name: getLanguageValue(languageText, 'Lastname'),
      icon: windowDimensions.width < 600 ? '' : 'bi bi-person-fill',
      required: windowDimensions.width < 600 ? false : true,
    },
    {
      name: getLanguageValue(languageText, 'Email'),
      icon: windowDimensions.width < 600 ? '' : 'bi bi-envelope-fill',
      required: windowDimensions.width < 600 ? false : true,
    },
    {
      name: getLanguageValue(languageText, 'Phonenumber'),
      icon: windowDimensions.width < 600 ? '' : 'bi bi-phone-fill',
      required: false,
    },
  ]

  useEffect(() => {
    if (uploadFromActivityPage) addRow()

    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (activityIdFromEditActivityPage) {
      const body: IFetchParticipantsParams = {
        activityId: activityIdFromEditActivityPage,
        clientId: Number(clientIdForEdit),
        searchText: participantsFilter,
      }

      getProfileEmployeesByClientId(body, dispatch).then((response) => {
        let updatedParticipantList: IParticipantData[]
        if (response) {
          updatedParticipantList = response.items.map((participant: IParticipantData) => ({
            id: participant.id,
            name: participant.name,
            surname: participant.surname,
          }))
          // Removing already selected participants from list
          if (selectedParticipants) {
            updatedParticipantList = updatedParticipantList.filter(
              (participant) => !selectedParticipants.map((item) => item.id).includes(participant.id)
            )
          }
          setParticipantsList(updatedParticipantList)
        }
      })
    }
  }, [])

  useEffect(() => {
    setIsRoleSettingsError(false)
  }, [activeTab])

  const closeModal = (): void => closeUploadModal(false)

  const closeUploadModal = (value: boolean): void => {
    closeUploadEmployeeModal(value)
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFile(Array.from(event.target.files!))
    setErrorMessage('')
  }

  const handleUploadFile = async (): Promise<void> => {
    if (!file[0]) {
      setErrorMessage(getLanguageValue(languageText, 'Something went wrong'))
      return
    }
    if (!file[0].name.endsWith('.csv')) {
      setErrorMessage(getLanguageValue(languageText, 'The selected file is not a .csv file'))
      return
    }

    const formData = new FormData()
    file.forEach((files) => formData.append('file', files))
    getEmployeesData(clientId, formData, dispatch).then((response) => {
      let id: number = 0
      const uploadedData = response.result.map((record: any) => ({
        ...record,
        id: id++,
      }))
      setEmployees(uploadedData)
      if (response.success) {
        if (response.result.length === 0) {
          setErrorMessage(getLanguageValue(languageText, 'No valid data present in the file.'))
          return
        } else {
          setErrorMessage('')
        }
      }
    })
  }

  const deleteRow = (index: number): void => {
    setEmployees(employees.filter((_, i) => i !== index))
  }

  const handleRoleSettings = (index: number): void => setSelectedNewParticipantIndexForRole(index)

  const closeRoleSettingsModal = (): void => {
    setSelectedNewParticipantIndexForRole(null)
    setSelectedExistingParticipantIndexForRole(null)
  }

  const onSaveRoleSettings = (roleData: IRoleData<UserId>): void => {
    const updatedEmployees = [...employees]
    const index = selectedNewParticipantIndexForRole ?? 0
    const updatedItem: IEmployeeTableProps = {
      ...updatedEmployees[index],
      roleId: roleData.roleId,
      roleText: roleData.roleText,
      noOfRespondents: roleData.noOfRespondents,
    }
    updatedEmployees[index] = updatedItem
    setEmployees(updatedEmployees)
    closeRoleSettingsModal()

    if (isRoleSettingsError) setIsRoleSettingsError(false)
  }

  const validateFirstName = (value: string | any[], index: string | number) => {
    let error = ''
    if (value.length === 0) {
      error = getLanguageValue(languageText, 'Firstname is required')
    }
    setEmployees((preEmployees) => {
      const updatedEmployees = [...preEmployees]
      updatedEmployees[index].firstNameError = error
      return updatedEmployees
    })
  }

  const validateLastName = (value: string | any[], index: string | number): void => {
    let error = ''
    if (value.length === 0) {
      error = getLanguageValue(languageText, 'Lastname is required')
    }
    setEmployees((preEmployees) => {
      const updatedEmployees = [...preEmployees]
      updatedEmployees[index].lastNameError = error
      return updatedEmployees
    })
  }

  const validateEmail = async (value: string, index: string | number): Promise<void> => {
    const email = value
    const updatedEmployees = [...employees]

    if (!value) {
      updatedEmployees[index].emailError = getLanguageValue(languageText, 'Email is required')
    } else if (!ValidationHelper.isEmailValid(value)) {
      updatedEmployees[index].emailError = getLanguageValue(languageText, 'Invalid email')
    } else if (!activityIdFromEditActivityPage) {
      try {
        const response = await isEmployeeExists(email, clientId, dispatch)
        if (response.result) {
          updatedEmployees[index].emailError = getLanguageValue(
            languageText,
            'Email already exists'
          )
        } else {
          updatedEmployees[index].emailError = ''
        }
      } catch (error) {
        updatedEmployees[index].emailError = getLanguageValue(languageText, 'Failed checking email')
        throw error
      }
    } else if (activityIdFromEditActivityPage && existingEmails?.includes(value)) {
      updatedEmployees[index].emailError = getLanguageValue(languageText, 'Email already exists')
    } else updatedEmployees[index].emailError = ''
    setEmployees(updatedEmployees)
  }

  const handleChange = (
    e: { target: { value: any } },
    index: string | number,
    field: string | number
  ): void => {
    const updatedEmployees = [...employees]
    updatedEmployees[index][field] = e.target.value
    setEmployees(updatedEmployees)
  }

  const handlePhoneInputChange = (
    value: any,
    index: string | number,
    field: string | number
  ): void => {
    const updatedEmployees = [...employees]
    updatedEmployees[index][field] = value
    setEmployees(updatedEmployees)
  }

  const validatePhoneNumber = (value: E164Number, index: string | number): void => {
    let error = ''
    const phoneNumber = parseInt(value)

    if (phoneNumber === 0) {
      error = ''
    } else if (value.length >= 1 && value.length <= 4) {
      error = getLanguageValue(languageText, 'Invalid phonenumber')
    } else {
      error = ''
    }
    setEmployees((preEmployees) => {
      const updatedEmployees = [...preEmployees]
      updatedEmployees[index].phoneNumberError = error
      return updatedEmployees
    })
  }

  const handleValidationOnSubmit = async (): Promise<boolean> => {
    const updatedEmployees = [...employees]
    let isValid = true
    for (let i = 0; i < updatedEmployees.length; i++) {
      const employee = updatedEmployees[i]

      if (!employee.name) {
        employee.firstNameError = getLanguageValue(languageText, 'Firstname is required')
        isValid = false
      }

      if (!employee.surname) {
        employee.lastNameError = getLanguageValue(languageText, 'Lastname is required')
        isValid = false
      }

      if (employee.phoneNumberError) {
        isValid = false
      }

      if (!employee.emailAddress) {
        employee.emailError = getLanguageValue(languageText, 'Email is required')
        isValid = false
      } else if (!ValidationHelper.isEmailValid(employee.emailAddress)) {
        employee.emailError = getLanguageValue(languageText, 'Invalid email')
        isValid = false
      } else if (!activityIdFromEditActivityPage) {
        try {
          const response = await isEmployeeExists(employee.emailAddress, clientId, dispatch)

          if (response.result) {
            employee.emailError = getLanguageValue(languageText, 'Email already exists')
            isValid = false
          }
        } catch (error) {
          employee.emailError = getLanguageValue(languageText, 'Failed checking email')
          isValid = false

          throw error
        }
      } else if (
        activityIdFromEditActivityPage &&
        existingEmails?.includes(employee.emailAddress)
      ) {
        employee.emailError = getLanguageValue(languageText, 'Email already exists')
        isValid = false
      }
    }

    setEmployees(updatedEmployees)
    return isValid
  }

  const createNewEmployees = (
    filteredData: ICreateEmployeesBody[] | ICreateEmployeeParticipantsWithRoleBody[]
  ): Promise<ApiResponse<unknown>> => {
    if (Number(activityIdFromEditActivityPage) > 0) {
      dispatch(setSpinner(true))
      return createEmployeeParticipantsWithRole(
        filteredData as ICreateEmployeeParticipantsWithRoleBody[],
        dispatch
      )
    } else {
      dispatch(setSpinner(true))
      return createEmployees(filteredData, dispatch)
    }
  }

  const handleIsRoleSettingsValid = (
    participants: IParticipantRoleData[] | IEmployeeTableProps[]
  ): boolean => {
    let isRoleSettingsValid = true
    participants.map((participant) => {
      if ((!participant.roleId && !participant.roleText) || !participant.noOfRespondents) {
        isRoleSettingsValid = false
        setIsRoleSettingsError(true)
        return
      }
    })
    return isRoleSettingsValid
  }

  const onSubmit = (): void => {
    dispatch(clearNotifications(''))
    const emailAddresses = employees.map((row) => row.emailAddress)
    const hasDuplicates = emailAddresses.some(
      (email, index) => emailAddresses.indexOf(email) !== index
    )
    if (hasDuplicates) {
      const notificationMessage = 'Duplicate email addresses found'
      dispatch(setNotification(notificationMessage))
      return
    }
    if (employees.length !== 0) {
      const filteredData: ICreateEmployeesBody[] | ICreateEmployeeParticipantsWithRoleBody[] =
        employees.map((row) => {
          let body: ICreateEmployeesBody | ICreateEmployeeParticipantsWithRoleBody = {
            name: row.name ?? '',
            surname: row.surname ?? '',
            emailAddress: row.emailAddress ?? '',
            phoneNumber: row.phoneNumber ?? '',
            clientId: clientId,
            languageId: uploadFromActivityPage ? 0 : Number(defaultLanguageId),
            isDefaultClient: true,
          }
          if (Number(activityIdFromEditActivityPage) > 0) {
            body = {
              ...body,
              activityId: activityIdFromEditActivityPage,
              roleId: row.roleId ?? 0,
              roleText: row.roleText ?? '',
              noOfRespondents: row.noOfRespondents ?? 0,
            }
          }
          return body
        })
      createNewEmployees(filteredData)
        .then((response) => {
          if (response && response.success === true) {
            const numberOfEmployeesCreated: number = filteredData.length
            setEmployees([])
            dispatch(
              addToast(
                numberOfEmployeesCreated > 1
                  ? 'Users created successfully'
                  : 'User created successfully'
              ) as AnyAction
            )
            closeUploadModal(true)
            setNewlyAddedEmployees && setNewlyAddedEmployees(response.result)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
        })
    }
  }

  const onSubmitClick = async (): Promise<void> => {
    const isValid = await handleValidationOnSubmit()
    if (!isValid) return
    if (isRolesRequired && !handleIsRoleSettingsValid(employees)) return
    onSubmit()
  }

  // Functions required in Select all tab from edit profile
  const handleParticipantSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setParticipantsFilter(e.target.value)
  }

  const handleSelectAllParticipants = (participants: IParticipantData[]): void => {
    participants.map((participant) => {
      const roleUpdatedParticipant: IParticipantRoleData = {
        ...participant,
        roleId: 0,
        roleText: '',
        noOfRespondents: 0,
      }
      setSelectedParticipants((prev) => [...prev, roleUpdatedParticipant])
    })

    const updatedParticipants = []
    setParticipantsList(updatedParticipants)
  }

  const handleSelectParticipant = (participant: IParticipantData): void => {
    const roleUpdatedParticipant: IParticipantRoleData = {
      ...participant,
      roleId: 0,
      roleText: '',
      noOfRespondents: 0,
    }

    setSelectedParticipants((prev) => [...prev, roleUpdatedParticipant])
    const updatedParticipants = participantsList.filter((item) => item.id !== participant.id)
    setParticipantsList(updatedParticipants)
  }

  const handleRemoveParticipant = (participant: IParticipantRoleData): void => {
    let updateSelectedParticipants: IParticipantRoleData[] = []

    // Removing participant from selected participants
    updateSelectedParticipants = selectedParticipants.filter((item) => item.id !== participant.id)
    setSelectedParticipants(updateSelectedParticipants)

    // Adding back the removed participant to participant list
    let updateParticipants: IParticipantData[] = []

    const isFiltered =
      participant.name.toLowerCase().includes(participantsFilter.toLowerCase()) ||
      participant.surname.toLowerCase().includes(participantsFilter.toLowerCase())
    if (isFiltered) {
      updateParticipants = [...participantsList]
      updateParticipants.push(participant) // Pushing the removed participant
      updateParticipants.sort((p1, p2) => p1.id - p2.id) // Arranging participant list based on id
      setParticipantsList(updateParticipants)
    }

    if (isRoleSettingsError) setIsRoleSettingsError(false)
  }

  const handleRemoveAllParticipants = (): void => {
    const removingSelectedAll = [...selectedParticipants]
    setSelectedParticipants([])
    setParticipantsList(removingSelectedAll)
  }

  const onRoleSettingsClick = (index: number): void => {
    setSelectedExistingParticipantIndexForRole(index)
  }

  const saveExistingParticipantRoleSettings = (roleData: IRoleData<ProfileId | UserId>): void => {
    if (isRoleSettingsError) setIsRoleSettingsError(false)

    const updatedSelectedParticipants = [...selectedParticipants]
    const index = selectedExistingParticipantIndexForRole ?? 0
    const updatedItem: IParticipantRoleData = {
      ...updatedSelectedParticipants[index],
      roleId: roleData.roleId,
      roleText: roleData.roleText,
      noOfRespondents: roleData.noOfRespondents,
    }
    updatedSelectedParticipants[index] = updatedItem
    setSelectedParticipants(updatedSelectedParticipants)
    closeRoleSettingsModal()
  }

  const handleSaveExistingParticipants = (): void => {
    if (isRolesRequired && !handleIsRoleSettingsValid(selectedParticipants)) return

    const body: ICreateProfileBody[] = []
    if (selectedParticipants.length > 0) {
      selectedParticipants.map((participant) => {
        body.push({
          activityId: activityIdFromEditActivityPage!,
          roleId: participant.roleId,
          roleText: participant.roleText,
          noOfRespondents: participant.noOfRespondents,
          userId: participant.id,
        })
      })
    }

    if (selectedParticipants.length > 0) {
      createParticipantsFromEditProfile(body, activityIdFromEditActivityPage!, dispatch).then(
        (response) => {
          if (response?.success) {
            props.refetchNewParticipants!()
            closeModal()
            dispatch(addToast('Updated participants') as AnyAction)
          }
        }
      )
    }
  }

  return {
    languageText,
    uploadFromActivityPage,
    isIndividualActivity,
    employees,
    errorMessage,
    inputRef,
    file,
    tableHeaders,
    selectedNewParticipantIndexForRole,
    navTabs,
    activeTab,
    selectedParticipants,
    participantsList,
    participantsFilter,
    selectedExistingParticipantIndexForRole,
    isRolesRequired,
    isRoleSettingsError,
    setActiveTab,
    validateEmail,
    validatePhoneNumber,
    handleRoleSettings,
    closeRoleSettingsModal,
    onSaveRoleSettings,
    deleteRow,
    addRow,
    onSubmitClick,
    validateFirstName,
    validateLastName,
    closeModal,
    handleFileUpload,
    handleUploadFile,
    handleChange,
    handlePhoneInputChange,
    handleParticipantSearch,
    handleSelectAllParticipants,
    handleSelectParticipant,
    handleRemoveAllParticipants,
    handleRemoveParticipant,
    onRoleSettingsClick,
    saveExistingParticipantRoleSettings,
    handleSaveExistingParticipants,
  }
}
