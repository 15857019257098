// Fn to compare between objects (just 1 level, no nested)
export const shallowEqual = <T extends Record<string, any>>(obj1: T, obj2: T): boolean => {
  // Check if both objects are the same reference
  if (obj1 === obj2) {
    return true
  }

  // Check if either object is null or undefined
  if (obj1 == null || obj2 == null) {
    return false
  }

  // Check if both are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false
  }

  // Check if both have the same number of keys
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  // Check if all keys and values are the same
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}
