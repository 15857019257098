import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'

import { DefaultClientCell } from './defaultClientCell'
import { setDefaultClient } from '../../auth/signUp/actions'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { RootState } from '@app/store/configureStore'
import { getAllClientsByUser } from './actions'
import { setSpinner } from '../../actions'
import { IClients } from '@app/containers/commonInterfaces'
import { isLoggedInRoleParticipant } from '@app/commonUtils/roleHelper'
import { UserId } from '@app/containers/reducer'

export interface IGetAllClientsByUserBody {
  userId: UserId
  roleName: string
  filter: string
  sorting: string
  maxResultCount: number
  skipCount: number
}

export interface IUpdateFacilitatorDefaultClientBody {
  userId: UserId
  clientId: number
  isDefaultClient: boolean
}

export const useAccountList = () => {
  const dispatch = useDispatch()
  const isMounted = useRef(false)

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const userId = useSelector((state: RootState) => state.loginReducer.userId)
  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)

  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [data, setData] = useState<IClients[]>([])
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [editedRowId, setEditedRowId] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10) // items_per_page
  const [pageCount, setPageCount] = useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0) // page_number_selected
  const [openAccountModal, setOpenAccountModal] = useState<boolean>(false) // Add/Edit Account popup
  const [accountUpdated, setAccountUpdated] = useState<boolean>(false) // This flag is used to re-reder the accounts data on requirement
  const [isSingleClient, setIsSingleClient] = useState<boolean>(false)

  const fetchClients = (skipCount: number): void => {
    dispatch(setSpinner(true))

    const body: IGetAllClientsByUserBody = {
      userId: userId,
      roleName: loggedInUserRole ?? '',
      filter,
      sorting,
      maxResultCount: pageLimit,
      skipCount,
    }

    getAllClientsByUser(body, dispatch)
      .then((response) => {
        if (response) {
          const isSingleClient = response.items.length === 1
          if (response.items.length == 0 && !isMounted.current) setOpenAccountModal(true)

          const pageSize = Math.ceil(response.totalCount / pageLimit)
          const updatedData: IClients[] = response.items.map((item) => {
            return {
              ...item,

              // FIXME: 'isTempDefaultClient' property doesn't exist on 'IClients'
              //   but typescript doesn't complain here, for some reason. is this
              //   a monkey patch around some other issue?
              //   -johan, 2024-09-01
              isTempDefaultClient:
                Number(defaultClient.defaultClientId) === item.id || isSingleClient ? true : false,
              isDefaultClient: item.isDefaultClient === null ? false : item.isDefaultClient,
            }
          })

          setData(updatedData)
          setTotalCount(response.totalCount)
          setPageCount(pageSize)
          if (accountUpdated) setAccountUpdated(false)
          if (isSingleClient) {
            setIsSingleClient(true)
            const clientName = updatedData[0].name
            dispatch(
              setDefaultClient({
                defaultClientId: updatedData[0].id,
                defaultClientName: clientName,
              })
            )
          } else setIsSingleClient(false)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected
      fetchClients(skipCount)
    }
  }, [pageSelected, sorting])

  useEffect(() => {
    if (userId && loggedInUserRole) {
      setPageSelected(0)
      fetchClients(0)
      if (!isMounted.current) isMounted.current = true
    }
  }, [filter, pageLimit, userId, loggedInUserRole])

  useEffect(() => {
    if (accountUpdated) {
      const skipCount = pageLimit * pageSelected
      fetchClients(skipCount)
    }
  }, [accountUpdated])

  useEffect(() => {
    if (data.length > 0) {
      const updatedData = data.map((item) => {
        return {
          ...item,
          isTempDefaultClient: defaultClient.defaultClientId === item.id ? true : false,
        }
      })

      setData(updatedData)
    }
  }, [defaultClient.defaultClientId, totalCount])

  const addAccountClick = (): void => {
    setOpenAccountModal(true)
  }

  const refreshAccountList = (): void => setAccountUpdated(true)

  const closeAccountModal = (): void => {
    if (editedRowId) {
      setEditedRowId(0)
    }
    setOpenAccountModal(false)
  }

  const handleEditClick = (id: number): void => setEditedRowId(id)

  const tableHeader = useMemo<ColumnDef<IClients, any>[]>(() => {
    const headerItems = [
      {
        header: '',
        accessorKey: 'actions',
        enableSorting: false,
        cell: ({ ...props }) => (
          <div
            className='btn btn-lg border-end rounded-0 my-n3 ms-n3'
            onClick={() => handleEditClick(props.row.original.id)}
          >
            <i className='bi bi-pencil-fill text-secondary' />
          </div>
        ),
      },
      {
        header: getLanguageValue(languageText, 'ID'),
        accessorKey: 'id',
        width: 'w-90px',
      },

      {
        header: getLanguageValue(languageText, 'Name'),
        accessorKey: 'name',
        cell: ({ ...props }) => (
          <DefaultClientCell
            languageText={languageText}
            name={props.row.original.name}
            isDefault={props.row.original.isDefaultClient}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, 'Country'),
        accessorKey: 'invoiceCountryName',
      },
      {
        header: getLanguageValue(languageText, 'Selected'),
        accessorKey: 'isTempDefaultClient',
        width: 'w-90px',
        cell: ({ ...props }) => (
          <i
            className={clsx(
              'bi bi-check-circle-fill fs-3',
              props.row.original.isTempDefaultClient ? 'text-success' : 'text-secondary'
            )}
          />
        ),
      },
    ]

    if (!isLoggedInRoleParticipant(loggedInUserRole)) {
      return headerItems
    }

    return headerItems.slice(1)
  }, [loggedInUserRole, languageText, data])

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openAccountModal,
    editedRowId,
    loggedInUserRole,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addAccountClick,
    refreshAccountList,
    closeAccountModal,
  }
}
