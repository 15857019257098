import { getLanguageValue, ITranslationObject } from '@app/commonUtils/languageFunctionsHelper'
import {
  canDrawProfileInGraph,
  getProfileGraphLabel,
  IMyProfiles,
} from '@app/containers/participantPages/myIDIProfilesPage/hooks'
import { ProfileStatus } from '@app/containers/profileList/profileEnums'
import { ProfileId } from '@app/containers/reducer'
import { classNames } from '@app/containers/utils'
import clsx from 'clsx'
import React from 'react'

const STEP_SIZE = 21
const ALLOWED_DIFFERENCE = 10

interface AdaptabilityGraphProps {
  profiles: ReadonlyArray<IMyProfiles>
  languageText: ITranslationObject
}

interface IAdaptabilityMarker {
  label: string
  count: number
  left: number
  top: number
}

const AdaptabilityMarker = (props: IAdaptabilityMarker): JSX.Element => {
  const clazzes = classNames({
    'adap-square bg-success': true,
  })

  return (
    <div className={clazzes} style={{ left: `${props.left - 2}%`, marginTop: `${props.top}px` }}>
      {/* We have more than one respondant with same value, let's add the gray indication. */}
      {props.count > 1 && <div className={clsx('div-count')}>{props.count}</div>}
      {props.label}
    </div>
  )
}

/**
 * This component is thought to superseed "GroupAdaptability" component that needs data on a custom format
 * supplied by the "GetProfilesOtherFormsAdaptability" call in "GetMyIDIProfiles". The idea is that
 * we will use the profiles set all together in the future but for now I will at least reuse the
 * profilesDeliveredReport array so that <ParticipantProfileGraph> and this component uses the same
 * data set. Joakim, 2024-10-18
 *
 * We still need to add support in this component to other, self and highlighted marker that
 * <GroupAdaptability> component supports, but there is not enough time for that now.
 * NOTE: The order of the profiles in the graph will be in the same order as supplied in the props
 * so the caller has to sort them before calling.
 *
 */
export const AdaptabilityGraph = (props: AdaptabilityGraphProps): JSX.Element => {
  if (!props.profiles.length) {
    return <></>
  }

  /**
   * TODO: This function is dangerously similar to what we do in <ParticipantProfileGraph>
   * and could probably be refactored out into a helper function instead.
   * @returns Build a dictionary with flexvalues and see if any of them have the same value
   */
  function buildPositionMap(): Map<number, ProfileId[]> {
    const positionMap: Map<number, ProfileId[]> = new Map()
    for (let i = 0; i < props.profiles.length; i++) {
      let profileIds: ProfileId[] = []
      const currentFlexValue = props.profiles[i].oFlex ?? 0
      if (positionMap.has(currentFlexValue)) {
        profileIds = positionMap.get(currentFlexValue) ?? []
      }
      profileIds.push(props.profiles[i].profileId)
      positionMap.set(currentFlexValue, profileIds)
    }
    return positionMap
  }

  function calculateMarkerPositions(): IAdaptabilityMarker[] {
    const markerPositions: IAdaptabilityMarker[] = []

    let baseFlexPosition = 0
    let stepCounter = 1
    let newMargin = 0
    let groupHeight = 0
    const seenAdaptabilityValues: number[] = []

    const graphPositions = buildPositionMap()

    props.profiles.forEach((profile, index) => {
      if (!canDrawProfileInGraph(profile)) {
        return
      }

      //Check if we've handled this flex value before
      if (seenAdaptabilityValues.includes(profile.oFlex)) {
        return
      }

      seenAdaptabilityValues.push(profile.oFlex)

      if (index === 0) {
        baseFlexPosition = profile.oFlex
      }

      if (index > 0) {
        const diff = Math.abs(profile.oFlex - baseFlexPosition)
        if (diff <= ALLOWED_DIFFERENCE) {
          stepCounter++
          newMargin = stepCounter * STEP_SIZE
          if (newMargin > groupHeight) {
            groupHeight = newMargin
          }
        } else {
          baseFlexPosition = profile.oFlex
          stepCounter = 0
          newMargin = 0
        }
      }

      const profileIds = graphPositions.get(profile.oFlex) ?? []

      const markerLabel = profileIds.map((profileId) => {
        const profile = props.profiles.find((p) => p.profileId === profileId)
        return getProfileGraphLabel(profile!, props.profiles)
      })

      markerPositions.push({
        label: markerLabel.join(','),
        count: profileIds.length,
        left: profile.oFlex,
        top: newMargin,
      })
    })
    return markerPositions
  }
  const adaptabilityMarkers = calculateMarkerPositions()

  return (
    <div className='w-100'>
      <div className='d-flex justify-content-center flex-wrap position-relative pt-4 mt-4 text-end w-100'>
        <div className='mt-3'>
          {adaptabilityMarkers.map((marker, index) => {
            return (
              <AdaptabilityMarker
                key={index}
                label={marker.label}
                count={marker.count}
                top={marker.top}
                left={marker.left}
              />
            )
          })}
        </div>

        <div
          className='w-10 border border-top-0 position-relative small'
          style={{ height: '150px' }}
        >
          <div className='float-start position-absolute top-0 start-0 translate-middle bg-white'>
            0
          </div>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            10
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            20
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            30
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            40
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            50
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            60
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            70
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            80
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            90
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            100
          </div>
        </div>
        <div className='w-100 d-flex justify-content-between pt-2'>
          <div>{getLanguageValue(props.languageText, 'Attend-to-self')}</div>
          <div className='text-center fw-bold'>
            {getLanguageValue(props.languageText, 'Adaptability')}{' '}
          </div>
          <div>{getLanguageValue(props.languageText, 'Attend-to-other')}</div>
        </div>
      </div>
    </div>
  )
}
