import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'

import { getAllEmployeesByClientId, getEmployeeListForAdmin } from './actions'
import { RootState } from '@app/store/configureStore'
import { routePath } from '../routePaths'
import { setSpinner } from '../actions'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { isLoggedInRoleAdmin, isLoggedInRoleFacilitator } from '@app/commonUtils/roleHelper'
import { UserId } from '../reducer'

export interface IEmployee {
  /** This is a user ID */
  id: UserId
  name: string
  surname: string
  emailAddress: string
  isActive: boolean
  phoneNumber: string
  actions: JSX.Element
}

export const useEmployeeList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMounted = useRef(false)

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)
  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [data, setdata] = useState<IEmployee[]>([])
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10) // items_per_page
  const [pageCount, setPageCount] = useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0) // page_number_selected
  const [employeeUpdated, setEmployeeUpdated] = useState<boolean>(false) // This flag is used to re-reder the users data on requirement

  const fetchEmployees = (skipCount: number): void => {
    dispatch(setSpinner(true))
    if (isLoggedInRoleAdmin(loggedInUserRole)) {
      getEmployeeListForAdmin(filter, sorting, pageLimit, skipCount, dispatch)
        .then((response) => {
          if (response) {
            const pageSize = Math.ceil(response.totalCount / pageLimit)
            setdata(response.items.slice())
            setTotalCount(response.totalCount)
            setPageCount(pageSize)
            if (employeeUpdated) setEmployeeUpdated(false)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    } else {
      getAllEmployeesByClientId(
        filter,
        sorting,
        pageLimit,
        skipCount,
        defaultClient.defaultClientId,
        dispatch
      )
        .then((response) => {
          if (response) {
            const pageSize = Math.ceil(response.totalCount / pageLimit)
            setdata(response.items.slice())
            setTotalCount(response.totalCount)
            setPageCount(pageSize)
            if (employeeUpdated) setEmployeeUpdated(false)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  useEffect(() => {
    if (isMounted.current || employeeUpdated) {
      const skipCount = pageLimit * pageSelected
      fetchEmployees(skipCount)
    }
  }, [pageSelected, sorting, employeeUpdated])

  useEffect(() => {
    if (isMounted.current) {
      fetchEmployees(0)
    }
    if (!isMounted.current) isMounted.current = true
  }, [filter, pageLimit])

  useEffect(() => {
    if (
      isLoggedInRoleAdmin(loggedInUserRole) ||
      (defaultClient.defaultClientId && isLoggedInRoleFacilitator(loggedInUserRole))
    ) {
      fetchEmployees(0)
    }
  }, [defaultClient, loggedInUserRole])

  const handleEditClick = (id: number): void => {
    navigate(routePath.editEmployee.replace(':id', id.toString()))
  }

  const tableHeader = useMemo<ColumnDef<IEmployee, any>[]>(
    () => [
      {
        header: '',
        accessorKey: 'actions',
        enableSorting: false,
        cell: ({ ...props }) => (
          <div
            className='btn btn-lg border-end rounded-0 my-n3 ms-n3'
            onClick={() => handleEditClick(props.row.original.id)}
          >
            <i className='bi bi-pencil-fill text-secondary' />
          </div>
        ),
      },
      {
        header: getLanguageValue(languageText, 'Firstname'),
        accessorKey: 'name',
      },
      {
        header: getLanguageValue(languageText, 'Lastname'),
        accessorKey: 'surname',
      },
      {
        header: getLanguageValue(languageText, 'Email'),
        accessorKey: 'emailAddress',
      },
      {
        header: getLanguageValue(languageText, 'Phonenumber'),
        accessorKey: 'phoneNumber',
      },
    ],
    [languageText]
  )

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    setEmployeeUpdated,
  }
}
