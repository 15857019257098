import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { getUsersToConnectAccount } from '../../../action'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { DynamicSearchInputField } from '@app/components/formComponents/dynamicSearchInputField'
import { UserId } from '@app/containers/reducer'

interface IDeleteModalProps {
  languageText: ITranslationObject
  clientId: number
  handleConnectUser: (userId: UserId) => void
  closeConnectUserModal: () => void
}

export const ConnectUserModal = (props: IDeleteModalProps) => {
  const { languageText, clientId, handleConnectUser, closeConnectUserModal } = props

  const dispatch = useDispatch()

  const initialSelectedUserState: IDropdownList = {
    id: 0,
    displayName: '',
    value: '',
  }
  const [usersList, setUsersList] = useState<IDropdownList[]>([])
  const [selectedUser, setSelectedUser] = useState<IDropdownList>(initialSelectedUserState)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const fetchList = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { value } = e.target
    getUsersToConnectAccount(clientId, value, dispatch).then((response) => {
      if (response) {
        const list: IDropdownList[] = response.map((user) => ({
          id: user.id,
          displayName: user.name,
          value: String(user.id),
        }))
        setUsersList(list)
      }
    })
  }

  const handleSelect = (selectedValue: IDropdownList): void => {
    setSelectedUser(selectedValue)
    setErrorMessage('')
  }

  const handleUnSelect = (): void => {
    setSelectedUser(initialSelectedUserState)
  }

  const handleConnectClick = (): void => {
    if (!selectedUser.id) {
      setErrorMessage(getLanguageValue(languageText, 'Select user to connect'))
      return
    }
    handleConnectUser(selectedUser.id as UserId)
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Connect User')}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        submitButtonText={getLanguageValue(languageText, 'Connect')}
        handleCancelClick={closeConnectUserModal}
        handleSubmitClick={handleConnectClick}
      >
        <DynamicSearchInputField
          languageText={languageText}
          placeholder={getLanguageValue(languageText, 'Search')}
          list={usersList}
          selectedValue={selectedUser}
          errorMessage={errorMessage}
          fetchList={fetchList}
          handleSelect={handleSelect}
          handleUnSelect={handleUnSelect}
        />
      </ModalComponent>
    </>
  )
}
