import * as React from 'react'
import { IConsent } from '@app/containers/auth/signUp/reducer'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { NEEDS_USER_CONSENT } from '@app/containers/auth/signUp/constants'
import { logout } from '@app/containers/auth/signUp/actions'
import { useApiEndpoints } from '@app/api/end-points'

type ConsentAnswerModalProps = {
  consent: IConsent
}

export const ConsentAnswerModal: React.FC<ConsentAnswerModalProps> = (props) => {
  const dispatch = useDispatch()
  const lang = useSelector((state: RootState) => state.mainReducer.languageText)
  const needsUserConsent = useSelector((state: RootState) => state.loginReducer.needsUserConsent)
  const api = useApiEndpoints(dispatch)

  async function handleAccept(event: React.MouseEvent): Promise<void> {
    event.preventDefault()

    await api.answerConsent(props.consent.id!, true)

    const next = needsUserConsent.filter((c) => c.id !== props.consent.id)

    dispatch({
      type: NEEDS_USER_CONSENT,
      payload: next,
    })
  }

  async function handleReject(event: React.MouseEvent): Promise<void> {
    event.preventDefault()

    await api.answerConsent(props.consent.id!, false)
    await logout(dispatch)
    window.location.pathname = '/'
  }

  return (
    <ModalComponent
      headerText={props.consent.title}
      submitButtonText={getLanguageValue(lang, 'Accept')}
      submitButtonDisabled={false}
      cancelButtonText={getLanguageValue(lang, 'Reject')}
      cancelButtonDisabled={false}
      handleSubmitClick={handleAccept}
      handleCancelClick={handleReject}
      width='lg'
    >
      <div dangerouslySetInnerHTML={{ __html: props.consent.body }} />
    </ModalComponent>
  )
}
