import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../api'
import { setNotification } from '../../actions'
import { ILatestDocument } from './latestDocuments/types'
import { IDocument } from './types'

export const getDocumentsList = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch
): Promise<IDocument> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
  }

  try {
    const response = await api.get(apiEndPoints.getDocumentsList, {
      params: body,
    })

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getLatestDocumentsList = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch
): Promise<ILatestDocument> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
  }

  try {
    const response = await api.get(apiEndPoints.getDocumentsList, {
      params: body,
    })

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteDocument = async (newsId: number, dispatch: Dispatch): Promise<any> => {
  try {
    await api.delete(apiEndPoints.deleteDocument, {
      params: {
        id: newsId,
      },
    })
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getAllDocumentCategory = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch
): Promise<any> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
  }
  try {
    const response = await api.get(apiEndPoints.getAllDocumentCategory, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
