import { Dispatch } from 'redux'
import { api, apiEndPoints } from '../../api'
import { setNotification, setSpinner } from '../actions'
import { IMarkAsFinish } from './courseSummary/hooks'
import {
  IAcademyCategories,
  IAcademyCoursesLanguages,
  ICategoryAndCourse,
  ICourse,
  ICourseAndLesson,
  IQuizDetails,
} from './types'
import {
  ACADEMY_COURSE_LANGUAGES,
  SELECTED_COURSE_LANGUAGE_ID,
  SET_COURSE_DETAILS,
} from './constant'
import { ApiResponse } from '@app/types'
import { UserId } from '../reducer'

export const setCourseDetails = (payload: ICourse) => ({
  type: SET_COURSE_DETAILS,
  payload,
})

export const setCourseLanguageId = (payload: number) => ({
  type: SELECTED_COURSE_LANGUAGE_ID,
  payload,
})

export const setAcademyCourseLanguages = (payload: IAcademyCoursesLanguages[]) => ({
  type: ACADEMY_COURSE_LANGUAGES,
  payload,
})

export const getAcademyCategories = async (
  /** @deprecated Remove 'clientId' and read it from the access token. */
  clientId: number,
  academyCourseLanguageId: number,
  dispatch: Dispatch
): Promise<IAcademyCategories[]> => {
  try {
    const response = await api.get(apiEndPoints.getAcademyCategories, {
      params: { clientId, academyCourseLanguageId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getCategoryCourses = async (
  parentCategoryId: number,
  /** @deprecated Remove 'clientId' and read it from the access token. */
  clientId: number,
  academyCourseLanguageId: number,
  dispatch: Dispatch
): Promise<ICategoryAndCourse> => {
  try {
    const response = await api.get(apiEndPoints.getCategoryCourses, {
      params: { parentCategoryId, clientId, academyCourseLanguageId },
    })

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getCategoryCourseLessons = async (
  categoryId: number,
  /** @deprecated Remove 'clientId' and read it from the access token. */
  clientId: number,
  academyCourseLanguageId: number,
  dispatch: Dispatch
): Promise<ICourseAndLesson> => {
  try {
    const response = await api.get(apiEndPoints.getCategoryCourseLessons, {
      params: { categoryId, clientId, academyCourseLanguageId },
    })

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getCourseDetails = async (
  userId: UserId,
  courseId: string,
  dispatch: Dispatch
): Promise<ICourse> => {
  const body = {
    userId,
    courseId,
  }
  try {
    const response = await api.get<ApiResponse<ICourse>>(apiEndPoints.getCourseDetails, {
      params: body,
    })

    if (!response.data.success) {
      throw new Error(response.data.error?.message || 'Very bad error')
    }

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getCoursesList = async (
  /** @deprecated Remove 'clientId' and read it from the access token. */
  clientId: number,
  academyCourseLanguageId: number,
  dispatch: Dispatch,
  categoryId = 0
): Promise<ICourse[]> => {
  try {
    const response = await api.get(apiEndPoints.getCoursesByUser, {
      params: { clientId, academyCourseLanguageId, categoryId },
    })

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getUserCourseParts = async (
  courseId: number,
  dispatch: Dispatch
): Promise<ICourse> => {
  try {
    const response = await api.get<ApiResponse<ICourse>>(apiEndPoints.getUserCourseParts, {
      params: { courseId },
    })

    if (!response.data.success) {
      throw new Error(response.data.error?.message || 'Very bad error')
    }

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateCourseTimespent = async (
  userId: UserId,
  partId: number,
  timeSpent: number,
  dispatch: Dispatch
) => {
  const queryParmas = {
    userId: userId,
    partId: partId,
    timeSpent: timeSpent,
  }
  try {
    await api.put(apiEndPoints.updateTimeSpent, {}, { params: queryParmas })
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getCourseQuizzes = async (courseId: string, dispatch: Dispatch): Promise<ICourse> => {
  const params = {
    courseId,
  }
  try {
    const response = await api.get<ApiResponse<ICourse>>(apiEndPoints.getCourseQuizes, {
      params,
    })
    if (!response.data.success) {
      throw new Error(response.data.error?.message || 'Vey bad error')
    }

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getUserQuizDetails = async (
  userId: UserId,
  quizId: string,
  dispatch: Dispatch
): Promise<IQuizDetails> => {
  const params = {
    userId,
    quizId,
  }
  try {
    const response = await api.get<ApiResponse<IQuizDetails>>(apiEndPoints.getUserQuizDetail, {
      params,
    })

    if (!response.data.success) {
      throw new Error(response.data.error?.message || 'Very bad error')
    }

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateUserQuizDetails = async (body, dispatch: Dispatch) => {
  try {
    await api.post(apiEndPoints.createOrUpdateUserQuizDetail, body)
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const restartCourse = async (
  userId: UserId,
  courseId: string,
  dispatch: Dispatch
): Promise<unknown> => {
  const params = {
    courseId,
    userId,
  }
  try {
    const response = await api.post(
      apiEndPoints.restartCourse,
      {},
      {
        params,
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const markAsFinish = async (body: IMarkAsFinish, dispatch: Dispatch): Promise<unknown> => {
  try {
    const response = await api.post(apiEndPoints.markAsFinish, body)
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const courseStarted = async (body: IMarkAsFinish, dispatch: Dispatch): Promise<unknown> => {
  dispatch(setSpinner(true))

  try {
    const response = await api.post(apiEndPoints.courseStarted, body)
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  } finally {
    dispatch(setSpinner(false))
  }
}

export const getAcademyCoursesLanguages = async (
  dispatch: Dispatch
): Promise<IAcademyCoursesLanguages[]> => {
  try {
    const response = await api.get(apiEndPoints.getAcademyCoursesLanguages)
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
