import React from 'react'
import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { DropdownMenu, DropdownMenuItem } from '../formComponents/dropdownMenu'

interface ICustomActionsCellProps {
  id?: number
  email?: string
  name?: string
  languageText: ITranslationObject
  changeTextValue?: string
  editOption?: boolean
  deleteOption?: boolean
  resetPasswordOption?: boolean
  changeTextOption?: boolean
  handleEditClick?: (id: number) => void
  handleDeleteClick?: (id: number, name: string) => void
  handleResetPasswordClick?: (name: string, email: string) => void
  handleChangeTextClick?: (changeTextValue: string) => void
}

export const CustomActionsCell: React.FC<ICustomActionsCellProps> = (props) => {
  const {
    id,
    email,
    name,
    languageText,
    changeTextValue,
    editOption,
    deleteOption,
    resetPasswordOption,
    changeTextOption,
    handleEditClick,
    handleDeleteClick,
    handleResetPasswordClick,
    handleChangeTextClick,
  } = props

  const onEditClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()
    event.stopPropagation()

    handleEditClick?.(id!)
  }

  const onDeleteClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()
    event.stopPropagation()

    handleDeleteClick?.(id!, name!)
  }

  const onResetPasswordClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()
    event.stopPropagation()

    handleResetPasswordClick?.(name!, email!)
  }

  const onChangeTextClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault()
    event.stopPropagation()

    handleChangeTextClick?.(changeTextValue!)
  }

  const dropdownItems: Array<DropdownMenuItem> = []

  if (editOption) {
    dropdownItems.push({
      name: getLanguageValue(languageText, 'Edit'),
      onClick: onEditClick,
    })
  }

  if (deleteOption) {
    dropdownItems.push({
      name: getLanguageValue(languageText, 'Delete'),
      onClick: onDeleteClick,
    })
  }

  if (resetPasswordOption) {
    dropdownItems.push({
      name: getLanguageValue(languageText, 'Reset Password'),
      onClick: onResetPasswordClick,
    })
  }

  if (changeTextOption) {
    dropdownItems.push({
      name: getLanguageValue(languageText, 'Change Text'),
      onClick: onChangeTextClick,
    })
  }

  return <DropdownMenu items={dropdownItems} />
}
