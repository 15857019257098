import React from 'react'

import { ProgressTimeline } from './dashboardComponents/progressTimeline'
import { AcademyStudies } from './dashboardComponents/academyStudies'
import { ParticipantProfile } from './dashboardComponents/participantProfile'
import { Status } from './dashboardComponents/status'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { useParticipantDashboard } from './hooks'

export const ParticipantDashboard = (): JSX.Element => {
  const { languageText, dashboardDetails } = useParticipantDashboard()

  return (
    <>
      <div className='mt-3 p-2 p-md-4'>
        <h3 className='mb-3 mt-2 fw-bold'>{getLanguageValue(languageText, 'My Progress')}</h3>

        <div className='row gx-5 gy-4 mt-2'>
          <div className='col-12 col-md-6'>
            <Status languageText={languageText} profileStatus={dashboardDetails.profileStatus} />
          </div>
          <div className='col-12 col-md-6'>
            <ProgressTimeline
              languageText={languageText}
              profileTimelines={dashboardDetails.profileTimelines}
            />
          </div>

          <div className='col-12 col-md-6'>
            <ParticipantProfile languageText={languageText} profiles={dashboardDetails.profiles} />
          </div>
          <div className='col-12 col-md-6'>
            <AcademyStudies
              languageText={languageText}
              profileCourseStudies={dashboardDetails.profileCourseStudies}
            />
          </div>
        </div>
      </div>
    </>
  )
}
