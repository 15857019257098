import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnDef } from '@tanstack/react-table'

import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { AccountUsersActionsCell } from './clientUserModals/accountUsersActionsCell'
import {
  connectFacilitatorToAccount,
  disconnectUserFromClient,
  getUsersByClientId,
} from '../../action'
import { setSpinner } from '@app/containers/actions'
import { ITableCommonParams } from '@app/containers/commonInterfaces'
import { IClientUsersListProps } from './clientUsersList'
import { UserId } from '@app/containers/reducer'

export interface IUsersForClient {
  id: number
  clientId: number
  name: string
  email: string
  isDefaultClient: boolean
  roleId: number
  roleName: string
}

interface IUserClient {
  id: number
  name: string
  roleName: string
  email: string
}

export interface IDisconnectUserFromClientBody {
  id: number
  clientId: number
  roleId: number
}

export const useClientUsersList = (props: IClientUsersListProps) => {
  const { clientId, refreshClientList } = props
  const dispatch = useDispatch()
  const isMounted = useRef<boolean>(false)

  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initialUsersForClientState: IUsersForClient = {
    id: 0,
    clientId: 0,
    name: '',
    email: '',
    isDefaultClient: false,
    roleId: 0,
    roleName: '',
  }
  const [data, setData] = useState<IUsersForClient[]>([])
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10) // items_per_page
  const [pageCount, setPageCount] = useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0) // page_number_selected
  const [userUpdated, setUserUpdated] = useState<boolean>(false) // This flag is used to re-reder the users data on requirement
  const [openUserModal, setOpenUserModal] = useState<boolean>(false)
  const [openUserConnectModal, setOpenUserConnectModal] = useState<boolean>(false)
  const [disconnectUserData, setDisconnectUserData] = useState<IUsersForClient>(
    initialUsersForClientState
  )
  const [editUserId, setEditUserId] = useState<UserId>(0 as UserId)

  const fetchUsersOfClient = (skipCount: number): void => {
    dispatch(setSpinner(true))

    const params: ITableCommonParams = {
      filter,
      sorting,
      maxResultCount: pageLimit,
      skipCount,
    }

    getUsersByClientId(clientId, params, dispatch)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit)
          setData(response.items)
          setTotalCount(response.totalCount)
          setPageCount(pageSize)
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
        if (!isMounted.current) isMounted.current = true
      })
  }

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected
      fetchUsersOfClient(skipCount)
    }
  }, [pageSelected, sorting, userUpdated])

  useEffect(() => {
    if (isMounted.current) {
      fetchUsersOfClient(0)
    }
  }, [filter, pageLimit])

  useEffect(() => {
    fetchUsersOfClient(0)
  }, [])

  const handleAddUser = (): void => setOpenUserModal(true)

  const closeUserModal = (refreshUserList: boolean): void => {
    if (refreshUserList) {
      setUserUpdated(!userUpdated)
      refreshClientList()
    }
    setOpenUserModal(false)
    setEditUserId(0 as UserId)
  }

  const handleConnectClick = (): void => setOpenUserConnectModal(true)

  const closeConnectUserModal = (): void => setOpenUserConnectModal(false)

  const handleConnectUser = (userId: UserId): void => {
    dispatch(setSpinner(true))
    connectFacilitatorToAccount(userId, clientId, dispatch)
      .then((response) => {
        if (response?.success) {
          closeConnectUserModal()
          setUserUpdated(!userUpdated)
          refreshClientList()
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const handleDisconnectClick = (id: number): void => {
    const disconnectUserData = data.find((user) => user.id === id) ?? initialUsersForClientState
    setDisconnectUserData(disconnectUserData)
  }

  const closeDisconnectUserModal = (): void => setDisconnectUserData(initialUsersForClientState)

  const handleDisconnectUser = (): void => {
    dispatch(setSpinner(true))
    const body: IDisconnectUserFromClientBody = {
      id: disconnectUserData.id,
      clientId: clientId,
      roleId: disconnectUserData.roleId,
    }

    disconnectUserFromClient(body, dispatch)
      .then((response) => {
        if (response) {
          closeDisconnectUserModal()
          setUserUpdated(!userUpdated)
          refreshClientList()
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const handleEditClick = (id: UserId): void => {
    setEditUserId(id)
  }

  const tableHeader: Array<ColumnDef<IUserClient, keyof IUserClient>> = [
    {
      header: '',
      accessorKey: 'actions',
      enableSorting: false,
      cell: ({ ...props }) => (
        <AccountUsersActionsCell
          languageText={languageText}
          id={props.row.original.id}
          handleEditClick={handleEditClick}
          handleDisconnectClick={handleDisconnectClick}
        />
      ),
    },
    {
      header: getLanguageValue(languageText, 'ID'),
      accessorKey: 'id',
    },
    {
      header: getLanguageValue(languageText, 'Users'),
      accessorKey: 'name',
    },
    {
      header: getLanguageValue(languageText, 'User type'),
      accessorKey: 'roleName',
    },
    {
      header: getLanguageValue(languageText, 'Email'),
      accessorKey: 'email',
    },
  ]

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openUserModal,
    editUserId,
    clientId,
    openUserConnectModal,
    disconnectUserData,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    handleAddUser,
    handleConnectClick,
    closeUserModal,
    handleConnectUser,
    closeConnectUserModal,
    handleDisconnectUser,
    closeDisconnectUserModal,
  }
}
