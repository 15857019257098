import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { RootState } from '@app/store/configureStore'
import { createOrUpdateLanguage, getAllLanguageCultures } from './action'
import { flagNames } from './flagNames'
import { IFocusError } from '../../../components/formComponents/input'
import { RenderFlagComponent } from '../renderFlagComponent'
import { getLanguages } from '../actions'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { addToast, setSpinner } from '../../actions'
import { ILanguage } from '@app/containers/commonInterfaces'

enum LanguageInputs {
  language = 'language',
  flag = 'flag',
  isEnabled = 'isEnabled',
  showFacilitator = 'showFacilitator',
  showParticipant = 'showParticipant',
  cultureNormUse = 'cultureNormUse',
}

interface IFocusInput {
  language: IFocusError
  flag: IFocusError
}

interface ILanguageInfo {
  language: string
  flag: string
  isEnabled: boolean
  showFacilitator: boolean
  showParticipant: boolean
  cultureNormUse: boolean
}

export interface ICreateOrUpdateLanguageBody {
  language: {
    id?: number
    name: string
    icon: string
    isEnabled: boolean
    showFacilitator: boolean
    showParticipant: boolean
    cultureNormUse: boolean
  }
}

export const useAddEditModal = () => {
  const dispatch = useDispatch()
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [languageInfo, setLanguageInfo] = useState<ILanguageInfo>({
    language: '',
    flag: '',
    isEnabled: true,
    showFacilitator: true,
    showParticipant: false,
    cultureNormUse: false,
  })
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    language: {
      touched: false,
      errorMessage: '',
    },
    flag: {
      touched: false,
      errorMessage: '',
    },
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [existingLanguages, setExistingLanguages] = useState<ILanguage[]>([])
  const [allLanguageCultures, setAllLanguageCultures] = useState<IDropdownList[]>([])
  const [allFlags, setAllFlags] = useState<IDropdownList[]>([])

  const handleCallbackOnMount = async (rowData: any, isEditClicked: boolean): Promise<void> => {
    const languagesResponse = await getAllLanguageCultures(dispatch)
    const existingLanguages = await getLanguages(dispatch)

    let languages: IDropdownList[] = []
    let flags: IDropdownList[] = []

    if (languagesResponse?.data.result.length > 0) {
      languages = languagesResponse.data.result.map((item, index) => ({
        id: index,
        displayName: item.displayText,
        value: item.value,
      }))
    }
    flags = flagNames.map((flagName: string, index: number) => {
      const value: string = flagName.substring(16)
      return {
        id: index,
        displayName: <RenderFlagComponent flagName={flagName} value={value} />,
        value: value,
      }
    })
    setAllLanguageCultures(languages)
    setAllFlags(flags)
    setExistingLanguages(existingLanguages.items.slice())

    if (isEditClicked) {
      updateLanguageInfo(rowData)
    }
  }

  const updateLanguageInfo = (rowData: any): void => {
    setLanguageInfo({
      language: rowData.name,
      flag: rowData.icon.substring(16),
      isEnabled: rowData.isDisabled,
      showFacilitator: rowData.showFacilitator,
      showParticipant: rowData.showParticipant,
      cultureNormUse: rowData.cultureNormUse,
    })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLanguageInfo({
      ...languageInfo,
      [e.target.name]: e.target.checked,
    })
  }

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    switch (name) {
      case LanguageInputs.language:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Language is required')
        } else if (value === 'languageExists') {
          errorMessage = getLanguageValue(languageText, 'Language already exists')
        }
        break
      case LanguageInputs.flag:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'This field is required')
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name
    const value = selectedItem.value

    setLanguageInfo({
      ...languageInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleValidationOnSubmit = (languageId: number): boolean => {
    if (!languageInfo.language || focusInput.language.errorMessage) {
      handleFormErrors(LanguageInputs.language, languageInfo.language)
      return false
    }
    if (!languageInfo.flag || focusInput.flag.errorMessage) {
      handleFormErrors(LanguageInputs.flag, languageInfo.flag)
      return false
    }
    // If language already exists
    const isLanguageExists = existingLanguages.some(
      (language) => language.name === languageInfo.language
    )
    if (isLanguageExists && !languageId) {
      handleFormErrors(LanguageInputs.language, 'languageExists')
      return false
    }
    return true
  }

  const handleSubmit = async (languageId: number): Promise<number> => {
    setIsSubmitting(true)
    dispatch(setSpinner(true))
    let responseStatus: number = 0

    const body: ICreateOrUpdateLanguageBody = {
      language: {
        name: languageInfo.language,
        icon: `famfamfam-flags ${languageInfo.flag}`,
        isEnabled: languageInfo.isEnabled,
        showFacilitator: languageInfo.showFacilitator,
        showParticipant: languageInfo.showParticipant,
        cultureNormUse: languageInfo.cultureNormUse,
      },
    }
    if (languageId) body.language['id'] = languageId

    await createOrUpdateLanguage(body, dispatch)
      .then((response) => {
        if (response) {
          responseStatus = response
          dispatch(addToast('Changes saved successfully') as AnyAction)
        }
      })
      .finally(() => {
        setIsSubmitting(false)
        dispatch(setSpinner(false))
      })
    return responseStatus
  }

  return {
    languageText,
    LanguageInputs,
    languageInfo,
    focusInput,
    isSubmitting,
    allLanguageCultures,
    allFlags,
    handleCallbackOnMount,
    handleCheckboxChange,
    handleDropdownSelect,
    handleValidationOnSubmit,
    handleSubmit,
  }
}
