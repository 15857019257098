interface ILimitTextAndEllipsesHelperProps {
  text: string
  limit: number
}

export const limitTextAndEllipses = ({ text, limit }: ILimitTextAndEllipsesHelperProps): string => {
  if (text.length <= limit) {
    return text
  } else {
    return `${text.slice(0, limit)}...`
  }
}
