import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ITableCommonParams, ModelType } from '@app/containers/commonInterfaces'
import { Dispatch } from 'redux'
import { API_BASE_URL } from '@app/constants'
import { routePath } from '@app/containers/routePaths'
import { resetState } from '@app/reducers/actions'
import { setSpinner } from '@app/containers/actions'
import { ApiResponse, ApiResult } from '@app/types'
import { IConsent } from '@app/containers/auth/signUp/reducer'
import { ProfileId, RespondentId } from '@app/containers/reducer'
import {
  CoursePresentationStatus,
  ProfileStatus,
  TypeOfRole,
} from '@app/containers/profileList/profileEnums'
import {
  EmailStatus,
  RespondentProfileStatus,
  SmsStatus,
} from '@app/containers/profileList/editProfile/interface'
import {
  IFillParticipantProfileSelfFormBody,
  IFormFilledDataKindOfButNotReally,
  IParticipantOrRespondentWordsBody,
  IUpdateFormFilledDataBody,
} from '@app/components/selfForm/selfForm'

export const endPoints = {
  getUserDetails: 'api/services/app/commonLookup/getUserDetails',
  getParticipantRespondentsByParticipantId:
    'api/services/app/profileRespondent/getParticipantRespondentsByParticipantId',

  // Tokenauth api's
  logIn: 'api/TokenAuth/Authenticate',
  impersonatedAuthenticate: 'api/TokenAuth/impersonatedAuthenticate',
  externalAuthenticate: 'api/TokenAuth/externalAuthenticate',
  verifyUserEmailConfirmCode: 'api/TokenAuth/verifyUserEmailConfirmCode',
  respondentAuthenticate: 'api/TokenAuth/respondentAuthenticate',
  switchUserRole: 'api/TokenAuth/SwitchUserRole',
  logout: 'api/TokenAuth/Logout',

  // Facilitator dashboard api's
  getFacilitatorDashboardDetails:
    'api/services/app/FacilitatorDashboard/getFacilitatorDashboardDetails',
  getFacilitatorParticipantsProfilesReport:
    'api/services/app/FacilitatorDashboard/getFacilitatorParticipantsProfilesReport',

  // Delivery api's
  updateParticipantsSlide: 'api/services/app/Delivery/updateParticipantsSlide',

  // User api's
  getUsers: 'api/services/app/User/getUsers',
  getUserById: 'api/services/app/User/getUserById',
  getAdminUsers: 'api/services/app/User/getAdminUsers',
  getUserByEmail: 'api/services/app/User/getUserByEmail',
  createOrUpdateUser: 'api/services/app/User/createOrUpdateUser',
  deleteUser: 'api/services/app/User/deleteUser',
  getUsersToConnectAccount: 'api/services/app/User/getUsersToConnectAccount',
  connectFacilitatorToAccount: 'api/services/app/User/connectFacilitatorToAccount',
  getClientFacilitators: 'api/services/app/User/getClientFacilitators',
  getUsersByClientId: (clientId: number) => `api/services/app/User/getUsersByClientId/${clientId}`,

  registerExternalLogin: 'api/TokenAuth/registerExternalLogin',
  getUserExternalLogins: 'api/services/app/UserLogin/getUserExternalLogins',
  deleteUserExternalLogin: 'api/services/app/UserLogin/deleteUserExternalLogin',

  // Presentation template api's
  createPresentationTemplate: 'api/services/app/PresentationTemplate/create',
  updatePresentationTemplate: 'api/services/app/PresentationTemplate/update',
  deletePresentationTemplate: 'api/services/app/PresentationTemplate/delete',
  getPresentationTemplateById: 'api/services/app/PresentationTemplate/getById',
  getAllPresentationTemplates: 'api/services/app/PresentationTemplate/getAllPresentationTemplates',
  getAllPresentationSlides: 'api/services/app/PresentationTemplateSlide/getByTemplateId', // Getting all slides at once
  getPresentationSlides: 'api/services/app/PresentationTemplateSlide/getAllByTemplateId', // With filtering, sorting, pagination
  createPresentationTemplateSlide: 'api/services/app/PresentationTemplateSlide/create',
  updatePresentationTemplateSlide: 'api/services/app/PresentationTemplateSlide/update',
  deletePresentationTemplateSlide: 'api/services/app/PresentationTemplateSlide/delete',
  getPresentationTemplateSlideById: 'api/services/app/PresentationTemplateSlide/getById',
  getIDIV2CurrentPresentationSlideId:
    'api/services/app/PresentationTemplateSlide/getIDIV2CurrentPresentationSlideId',
  idiV2GetParticipantPresentationSlides:
    'api/services/app/PresentationTemplateSlide/idiV2GetByTemplateId',
  exportPresentationTemplateSlides: (id: number) =>
    `api/services/app/PresentationTemplate/${id}/ExportSlides`,
  importPresentationTemplateSlides: (id: number) =>
    `api/services/app/PresentationTemplate/${id}/ImportSlides`,

  // CoursePresentation api's
  coursePresentationGetById: 'api/services/app/CoursePresentation/getById',
  participantsProfileDeliver: 'api/services/app/CoursePresentation/participantsProfileDeliver',
  getTemplateSlideById: 'api/services/app/CoursePresentation/getTemplateSlideById',
  createPresentation: 'api/services/app/CoursePresentation/createPresentation',
  updateCoursePresentationStatus:
    'api/services/app/CoursePresentation/updateCoursePresentationStatus',
  getAllPresentationParticipants:
    'api/services/app/CoursePresentation/getAllPresentationParticipants',
  getAllPlannedAndOngoingPresentationsByCourseId:
    'api/services/app/CoursePresentation/getAllPlannedAndOngoingPresentationsByCourseId',
  getCompletedParticipantsByProfileIdToSendPresentation:
    'api/services/app/CoursePresentation/getCompletedParticipantsByProfileIdToSendPresentation',
  getParticipantNamesByProfileAndPresentationId:
    'api/services/app/CoursePresentation/getParticipantNamesByProfileAndPresentationId',
  updatePresentation: 'api/services/app/CoursePresentation/updatePresentation',
  sendParticipantPresentationEmail:
    'api/services/app/CoursePresentation/sendParticipantPresentationEmail',
  getUpcomingPresentations: 'api/services/app/CoursePresentation/Upcoming',
  facilitatorPresentation: 'api/services/app/CoursePresentation/facilitatorPresentation',
  profileParticipantPresentation:
    'api/services/app/CoursePresentation/profileParticipantPresentation',
  deletePresentation: (id: number) => `api/services/app/CoursePresentation/${id}`,

  // Profile api's
  //
  // LOL no, these are not profile APIs. they are referring to the 'user profile'
  // as in 'user account' or 'user data'. totally unrelated to 'profiles' as this
  // application usually calls them.
  //
  // See the backend service 'IDI.Authorization.Users.Profile.ProfileAppService'
  //   -johan, 2024-11-07
  getCurrentUserProfile: 'api/services/app/Profile/GetCurrentUserProfileForEdit',
  editUserData: 'api/services/app/Profile/updateCurrentUserProfile',
  updateUserLanguage: 'api/services/app/Profile/updateUserLanguage',
  changePassword: 'api/services/app/Profile/changePassword',
  resetParticipantPassword: 'api/services/app/Profile/resetParticipantPassword',

  // actual profiles APIs
  getAllActivities: 'api/services/app/Activity/getAll',
  getAllActivitiesWithParticipants: 'api/services/app/Activity/getAllWithParticipants',
  deleteActivity: 'api/services/app/Activity/delete',
  createActivityCulture: 'api/services/app/Activity/createActivityCulture',
  updateActivityType: 'api/services/app/Activity/updateActivityType',
  updateActivityCulture: 'api/services/app/Activity/updateActivityCulture',
  updateActivityRole: 'api/services/app/Activity/updateActivityRole',
  updateActivityRespondents: 'api/services/app/Activity/updateActivityRespondents',
  getActivityById: 'api/services/app/Activity/getActivityById',
  saveActivityEditFieldValue: 'api/services/app/Activity/saveActivityEditFieldValue',
  cancelActivity: 'api/services/app/Activity/cancelActivity',
  editActivityUpdateRole: 'api/services/app/Activity/editActivityUpdateRole',
  getActivityByStatus: 'api/services/app/Activity/getActivityByStatus',
  getEditProfileByIdAndStatus: 'api/services/app/Activity/getEditProfileByIdAndStatus',
  getProfileReport: 'api/services/app/Activity/getProfileReport',
  getGroupReport: 'api/services/app/Activity/getGroupReport',
  getActiveActivitiesByUserId: 'api/services/app/Activity/getActiveActivitiesByUserId',
  recalculateProfiles: 'api/services/app/Activity/recalculateProfiles',
  updateActivityClient: 'api/services/app/Activity/updateActivityClient',
  updateCompletionDate: 'api/services/app/Activity/updateCompletionDate',

  // Invoice api's
  getInvoices: 'api/services/app/IDIInvoice/getAll',

  // Profile invoice api's
  getActivityInvoiceAddresses: 'api/services/app/Activity/getActivityInvoiceAddresses',
  getFortnoxCustomers: 'api/services/app/Activity/getFortnoxCustomers',
  getFortnoxInvoicesByCustomerNumber:
    'api/services/app/Activity/getFortnoxInvoicesByCustomerNumber',

  getInvoiceArticlesByProfileOrClientSubscriptionId:
    'api/services/app/IDIInvoice/getInvoiceArticlesByProfileOrClientSubscriptionId',
  getProfileInvoiceDetails: 'api/services/app/IDIInvoice/getProfileInvoiceDetails',
  createOrUpdateProfileInvoice: 'api/services/app/IDIInvoice/createOrUpdateProfileInvoice',
  updateFortnoxInvoiceStatus: 'api/services/app/IDIInvoice/updateFortnoxInvoiceStatus',
  sendProfileInvoiceToFortnox: 'api/services/app/IDIInvoice/sendProfileInvoiceToFortnox',

  // Subscription invoice api's
  getClientSubscriptionInvoiceDetails:
    'api/services/app/IDIInvoice/getClientSubscriptionInvoiceDetails',
  createOrUpdateSubscriptionInvoice:
    'api/services/app/IDIInvoice/createOrUpdateSubscriptionInvoice',
  sendSubscriptionInvoiceToFortnox: 'api/services/app/IDIInvoice/sendSubscriptionInvoiceToFortnox',

  // Language api's
  getLanguageTextByName: (langName) =>
    `api/services/app/Language/GetLanguageTextsByLanguageName?languageName=${langName}`,
  getLanguages: 'api/services/app/Language/getLanguages',
  getAllLanguages: 'api/services/app/Language/getAllLanguages',
  createOrUpdateLanguage: 'api/services/app/Language/createOrUpdateIDILanguage',
  getAllLanguageCultures: 'api/services/app/Language/getAllLanguageCultures',
  getFacilitatorCultures: 'api/services/app/Language/getFacilitatorCultures',
  createOrUpdateLanguageText: 'api/services/app/Language/createOrUpdateLanguageText',
  getFacilitatorLanguages: 'api/services/app/Language/getFacilitatorLanguages',
  getParticipantLanguages: 'api/services/app/Language/getParticipantLanguages',
  deleteLanguage: 'api/services/app/Language/deleteLanguage',
  deleteLanguageText: 'api/services/app/Language/deleteLanguageText',
  createDefaultText: 'api/languagetext/create-default',
  getAllLanguageTexts: 'api/services/app/Language/getAllLanguageTexts',
  // 'language text' means translation in plain english.
  exportLanguageTexts: 'api/services/app/Language/Export',
  importLanguageTexts: 'api/services/app/Language/Import',

  // IDI Client api's
  getClients: 'api/services/app/idiClient/getAll',
  createClient: 'api/services/app/idiClient/create',
  updateClient: 'api/services/app/idiClient/update',
  deleteClient: 'api/services/app/idiClient/delete',
  getAllClientsByUser: 'api/services/app/idiClient/getAllClientsByUser',
  updateFacilitatorDefaultClient: 'api/services/app/idiCLient/updateFacilitatorDefaultClient',
  deleteFacilitatorClient: 'api/services/app/idiClient/deleteFacilitatorClient',
  createClientByFacilitator: 'api/services/app/idiClient/createClientByFacilitator',
  disconnectUserFromClient: 'api/services/app/idiClient/disconncetUserFromClient',
  isFacilitatorInClientRole: 'api/services/app/idiClient/isFacilitatorInClientRole',

  // ClientSubscription api's
  getAllClientSubscriptions: 'api/services/app/clientSubscription/getAllByClientId',
  getClientSubscriptionById: 'api/services/app/clientSubscription/getById',
  createClientBilling: 'api/services/app/clientSubscription/create',
  updateClientBilling: 'api/services/app/clientSubscription/update',

  // Subscription & Billing api's
  getAnnualOrMonthlySubscriptions: 'api/services/app/subscription/getAnnualOrMonthlySubscriptions',

  // Messages api's
  getAllSavedMessages: 'api/services/app/savedMessage/getAll',
  createSavedMessage: 'api/services/app/savedMessage/create',
  updateSavedMessage: 'api/services/app/savedMessage/update',
  deleteSavedMessage: 'api/services/app/savedMessage/delete',

  // Account api's
  verifyUserExists: 'api/services/app/User/verifyUserExists',
  resetPassword: 'api/services/app/Account/resetPassword',
  verifyEmail: 'api/services/app/Account/ActivateEmail',
  sendResetPasswordCode: 'api/services/app/Account/SendPasswordResetCode',

  // Placements api's
  getAllPlacements: 'api/services/app/Placement/getAll',
  updatePlacements: 'api/services/app/Placement/update',
  createPlacements: 'api/services/app/Placement/create',

  // Words api's
  getWordPairsByCultureId: 'api/services/app/WordPairs/getWordPairsByCultureId',
  updateWord: 'api/services/app/WordPairs/update',
  createWord: 'api/services/app/WordPairs/create',

  // Courses api's
  getAllCourses: 'api/services/app/AcademyCourses/getAll',
  createCourse: 'api/services/app/AcademyCourses/create',
  getCoursebyId: 'api/services/app/AcademyCourses/getById',
  updateCourse: 'api/services/app/AcademyCourses/update',
  deleteCourse: 'api/services/app/AcademyCourses/delete',
  toggleActiveStatus: 'api/services/app/AcademyCourses/toggleEnabled',
  getOrderedCourses: 'api/services/app/AcademyCourses/getOrderedCourses',
  updateCourseOrder: 'api/services/app/AcademyCourses/updateCourseOrder',
  getCategoryCourseLessons: 'api/services/app/AcademyCourses/getCategoryCourseLessons',
  getAcademyCoursesLanguages: 'api/services/app/AcademyCourses/languages',
  getParticipantUserNames: '/api/services/app/user/getParticipantUserNames',

  // Course Permission api's
  createAcademyCoursePermission: 'api/services/app/academyCoursePermission/create',
  getClientNames: 'api/services/app/idiClient/getClientNames',
  updateAcademyCoursePermission: 'api/services/app/academyCoursePermission/update',
  deleteAcademyCoursePermission: 'api/services/app/academyCoursePermission/delete',
  getCoursePermissionByCourseId: 'api/services/app/academyCoursePermission/getByCourseId',

  createCourseParts: 'api/services/app/AcademyCourseParts/create',
  updateCourseParts: 'api/services/app/AcademyCourseParts/update',
  deleteCoursePart: 'api/services/app/AcademyCourseParts/delete',
  getCoursePartsByCourseId: 'api/services/app/AcademyCourseParts/getCoursePartsByCourseId',
  updateCoursePartsOrder: '/api/services/app/AcademyCourseParts/updateCoursePartsOrder',

  // Quiz api's
  createQuiz: 'api/services/app/AcademyQuiz/createQuizAndOptions',
  getQuizById: 'api/services/app/AcademyQuiz/getCourseQuizes',
  updateQuiz: 'api/services/app/AcademyQuiz/update',
  deleteQuiz: 'api/services/app/AcademyQuiz/delete',
  updateQuizOrder: 'api/services/app/AcademyQuiz/updateQuizOrder',

  // AcademyCategory
  getAcademyCategory: 'api/services/app/academyCategory/getAll',
  getAcademyCategories: 'api/services/app/academyCategory/getCategories',
  getCategoryCourses: 'api/services/app/academyCategory/getCategoryCourses',

  // AcademyCourseTag
  getAllTagNames: 'api/services/app/AcademyCourseTag/getAllTagNames',

  // Country & Culture api's
  getCountries: 'api/services/app/Country/getAll',
  countryLocate: 'api/services/app/Country/Locate',

  // Employees api's
  getAllEmployees: 'api/services/app/Employee/getAllEmployees',
  getAllEmployeesByClientId: 'api/services/app/Employee/getAllEmployeesByClientId',
  getEmployeeDetailsById: 'api/services/app/Employee/getEmployeeDetailsById',
  createEmployees: 'api/services/app/Employee/createEmployees',
  updateEmployee: 'api/services/app/Employee/updateEmployee',
  isEmployeeExists: 'api/services/app/Employee/getIsEmployeeExists',
  employeesImportedData: 'api/services/app/Employee/employeesImportedData',
  getProfileEmployeesByClientId: 'api/services/app/Employee/getProfileEmployeesByClientId',
  deleteEmployeeById: 'api/services/app/Employee/deleteEmployeeById',

  // Register new Participant
  registerParticipant: 'api/services/app/User/registerParticipantUser',

  // Participants/Respondents api's
  sendParticipantVerificationCodeEmail:
    'api/services/app/Profile/sendParticipantVerificationCodeEmail',
  verifyParticipantVerificationLink: 'api/TokenAuth/verifyParticipantVerificationLink',
  verifyParticipantVerificationCode: 'api/TokenAuth/verifyParticipantVerificationCode',
  getParticipantFormInfo: 'api/services/app/Profile/getParticipantFormInfo',
  checkValidParticipantOrRespondentLink:
    'api/services/app/Profile/CheckValidParticipantOrRespondentLink',
  sendParticipantPasswordEmail: 'api/services/app/Profile/sendParticipantPasswordEmail',
  getProfileDemographicById: 'api/services/app/Profile/getDemographicById',
  updateProfileDemographics: 'api/services/app/Profile/updateDemographics',
  getProfileParticipantRole: 'api/services/app/Profile/getProfileParticipantRole',
  updateParticipantFormLanguage: 'api/services/app/Profile/updateFormLanguage',
  updateParticipantLanguage: 'api/services/app/profile/updateParticipantLanguage',
  canParticipantChangeLanguageWithoutEffect:
    'api/services/app/profile/canParticpantChangeLanguageWithoutEffect',
  getUserForRespondents: 'api/services/app/Profile/getUserForRespondents',
  getRespondentEmailPreview: 'api/services/app/ProfileRespondent/getRespondentEmailPreview',
  updateParticipantProfileLock: 'api/services/app/Profile/updateParticipantProfileLock',
  createProfileParticipants: 'api/services/app/profile/createParticipants',
  createParticipantsFromEditProfile: 'api/services/app/profile/createParticipantsFromEditProfile',
  deleteParticipant: 'api/services/app/profile/delete',
  createEmployeeParticipantsWithRole: 'api/services/app/profile/createEmployeeParticipantsWithRole',
  updateProfileParticipantName: 'api/services/app/profile/updateProfileParticipantName',
  sendParticipantsInvitation: 'api/services/app/profile/sendParticipantsInvitation',
  previewParticipantInvitation: 'api/services/app/profile/previewParticipantInvitation',
  publishProfile: 'api/services/app/profile/publishProfile',
  unPublishProfile: 'api/services/app/profile/unPublishProfile',
  deliverProfilesManually: 'api/services/app/profile/deliverProfilesManually',
  unDeliverProfilesManually: 'api/services/app/profile/unDeliverProfilesManually',
  sendPublishOrManualProfileDeliverEmail:
    'api/services/app/profile/sendPublishOrManualProfileDeliverEmail',
  saveParticipantFieldValue: 'api/services/app/profile/saveParticipantFieldValue',
  getParticipantDashboardInfo: 'api/services/app/profile/getParticipantDashboardInfo',
  getParticipantDashboardInfoForFacilitator:
    'api/services/app/profile/getParticipantDashboardInfoForFacilitator',
  getMyIDIProfiles: 'api/services/app/profile/getMyIDIProfiles',
  idiV2SendOnlineStatusToFacilitator: 'api/services/app/profile/idiV2SendOnlineStatusToFacilitator',
  disconnectParticipantFromPresentation:
    'api/services/app/profile/disconnectParticipantFromPresentation',
  updateProfileParticipantPdfDownload:
    'api/services/app/profile/updateProfileParticipantPdfDownload',
  getParticipantPresentationByPresentationId:
    'api/services/app/profile/getParticipantPresentationByPresentationId',
  createProfileByParticipant: 'api/services/app/profile/createProfileByParticipant',
  updateRespondentFormLanguage: 'api/services/app/ProfileRespondent/updateFormLanguage',
  fillProfileRespondentOtherForm:
    'api/services/app/ProfileRespondent/fillProfileRespondentOtherForm',
  createManualProfileParticipantRespondents:
    'api/services/app/ProfileRespondent/createManualProfileParticipantRespondents',
  sendRespondentsInvitation: 'api/services/app/ProfileRespondent/sendRespondentsInvitation',
  deleteRespondent: 'api/services/app/ProfileRespondent/delete',
  transferProfile: 'api/services/app/profile/transfer',

  // Form filled api's
  updateRespondentFormFilledDataAnswer:
    'api/services/app/formFilledData/updateRespondentFormFilledDataAnswer',

  //Academy endpoints
  getCoursesByUser: 'api/services/app/academyCourses/getCoursesByUser',
  getUserCourseParts: 'api/services/app/academyUserCoursePartDetail/getCourseParts',
  createOrUpdateUserCoursePartDetail:
    'api/services/app/academyUserCoursePartDetail/createOrUpdateUserCoursePartDetail',
  createOrUpdateUserQuizDetail:
    'api/services/app/AcademyUserQuizDetail/CreateOrUpdateUserQuizDetail',
  getUserQuizDetail: 'api/services/app/AcademyUserQuizDetail/getUserQuizDetail',
  getCourseQuizes: 'api/services/app/academyQuiz/getCourseQuizes',
  getCourseDetails: 'api/services/app/academyQuiz/getCoursePartsAndQuizeCompletedProgress',
  restartCourse: 'api/services/app/academyCourses/restartCourse',
  updateTimeSpent: 'api/services/app/academyUserCoursePartDetail/updateTimeSpent',
  courseStarted: 'api/services/app/academyUserCourse/createOrUpdate',
  markAsFinish: 'api/services/app/academyUserCourse/markAsFinish',

  //Resources endpoints
  getNewsList: 'api/services/app/news/getAll',
  createNewsPost: 'api/services/app/news/create',
  updateNewsPost: 'api/services/app/news/update',
  deleteNewsPost: 'api/services/app/news/delete',

  //document endpoints
  getDocumentsList: '/api/services/app/document/getAll',
  createDocument: '/api/services/app/document/create',
  updateDocument: '/api/services/app/document/update',
  deleteDocument: '/api/services/app/document/delete',

  //document Category
  getAllDocumentCategory: '/api/services/app/documentcategory/getAll',
  //information endpoints
  getLatestNews: 'api/services/app/news/getAllPublishedNews',
  getNewsById: 'api/services/app/news/getById',
  //Subscription endpoints
  createSubscription: 'api/services/app/subscription/create',
  deleteSubscription: 'api/services/app/subscription/delete',
  updateSubscription: 'api/services/app/subscription/update',
  getAllSubscriptions: 'api/services/app/subscription/getAll',
  getSubscriptionById: 'api/services/app/subscription/getById',

  //Currency endpoints
  getAllCurrencies: 'api/services/app/currency/getAll',
  createCurrency: 'api/services/app/currency/create',
  deleteCurrency: 'api/services/app/currency/delete',
  updateCurrency: 'api/services/app/currency/update',
  getCurrencyById: 'api/services/app/currency/getById',

  //media endpoints
  getMediaImageFile: '/mediafile/get',
  getParticipantMedia: '/mediafile/getParticipantMedia',

  //profile description Category
  getProfileDescription: '/api/services/app/ProfileDescription/GetAll',
  createProfileDescription: '/api/services/app/ProfileDescription/Create',
  updateProfileDescription: '/api/services/app/ProfileDescription/Update',
  deleteProfileDescription: '/api/services/app/ProfileDescription/delete',
  getProfileDescriptionById: '/api/services/app/ProfileDescription/GetById',

  //audit log
  getAuditLogs: 'api/services/app/AuditLog/GetAuditLogs',

  // email templates
  getEmailTemplates: 'api/email-template',
  updateEmailTemplate: (id: number) => `api/email-template/${id}`,
  createEmailTemplate: 'api/email-template',
  deleteEmailTemplate: (id: number) => `api/email-template/${id}`,

  // logs
  getLogs: 'api/log',
  // rating
  submitRating: 'api/rating',

  // profile pdf
  getParticipantProfilePdfFile: 'api/services/app/profilepdf/individual',
  getParticipantProfileReportHtml: 'api/services/app/profilepdf/view',

  // alerts
  getAlerts: 'api/alert',
  createAlert: 'api/alert',
  updateAlert: (id: number) => `api/alert/${id}`,
  deleteAlert: (id: number) => `api/alert/${id}`,
  interactAlert: (id: number) => `api/alert/${id}/interact`,
  deleteUserInteractions: (id: number) => `api/alert/${id}/interact`,

  // model permissions
  getModelPermissions: (type: ModelType, modelId: number) =>
    `/api/model-permission?modelType=${type}&modelId=${modelId}`,
  updateModelPermissions: `/api/model-permission`,

  // Guide
  getOrPostGuide: 'api/guide', // get & post
}

// this is purposefully a file-scoped global, because we
// allow for multiple instances of the axios thing to exist.
let NUM_REQUESTS_LIVE = 0

type HttpRequestConfig = AxiosRequestConfig & {
  spinner?: boolean
}
type HttpClient = {
  get: <T>(url: string, config?: HttpRequestConfig) => Promise<AxiosResponse<T>>
  put: <T, D = unknown>(
    url: string,
    data: D,
    config?: HttpRequestConfig
  ) => Promise<AxiosResponse<T>>
  post: <T, D = unknown>(
    url: string,
    data: D,
    config?: HttpRequestConfig
  ) => Promise<AxiosResponse<T>>
  delete: <T>(url: string, config?: HttpRequestConfig) => Promise<AxiosResponse<T>>
}

function createHttpClient(dispatch: Dispatch): HttpClient {
  const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })

  function pushRequest(): void {
    NUM_REQUESTS_LIVE += 1
    if (NUM_REQUESTS_LIVE === 1) {
      dispatch(setSpinner(true))
    }
  }

  function popRequest(): void {
    NUM_REQUESTS_LIVE = Math.max(0, NUM_REQUESTS_LIVE - 1)
    if (NUM_REQUESTS_LIVE === 0) {
      dispatch(setSpinner(false))
    }
  }

  api.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401) {
      const { pathname } = window.location
      if (pathname !== routePath.signIn && pathname !== routePath.participantLogIn) {
        dispatch(resetState())
        localStorage.clear()
        sessionStorage.setItem('returnTo', window.location.pathname)
        sessionStorage.setItem('tokenExpired', 'true')
        window.location.href = routePath.signIn
      }
    }
    const maybeMessages = [
      error?.response?.data?.error?.message,
      error?.response?.data?.message,
      error.message,
    ]
    const message = maybeMessages.find((m) => !!m) || ''
    return Promise.reject(message)
  })

  function send(method: string, url: string, data: unknown, config: HttpRequestConfig | undefined) {
    const wantsSpinner = config?.spinner !== false
    if (wantsSpinner) {
      pushRequest()
    }
    const mergedConfig: HttpRequestConfig = {
      method: method,
      url: url,
      data: data,
      ...config,
    }
    return api.request(mergedConfig).finally(() => {
      if (wantsSpinner) {
        popRequest()
      }
    })
  }

  return {
    get: (url, config) => send('GET', url, undefined, config),
    put: (url, data, config) => send('PUT', url, data, config),
    post: (url, data, config) => send('POST', url, data, config),
    delete: (url, config) => send('DELETE', url, undefined, config),
  }
}

function unwrapApiResponse<T>(response: AxiosResponse<ApiResponse<T>>): Promise<T> {
  if (!response.data.success) {
    return Promise.reject(response.data)
  }
  return Promise.resolve(response.data.result)
}

export type Respondent = {
  id?: RespondentId
  profileId?: ProfileId
  name: string
  email: string
  telephone: string
  status: RespondentProfileStatus
  emailStatus: EmailStatus
  smsStatus: SmsStatus
  idiLanguageId: number
}

export type Profile = {
  // Add more props when necessary. See 'ProfileDto' in the backend.
  id?: ProfileId
  dUniqueCode: string
  status: ProfileStatus
  emailStatus: EmailStatus
  smsStatus: SmsStatus
  roleId: TypeOfRole
  noOfRespondents: number
  respondents: ReadonlyArray<Respondent>
  respondentsInvited: number
  respondentsAnswered: number
  coursePresentation?: Presentation
}

export type Presentation = {
  id?: number
  presentationDate: string
  status: CoursePresentationStatus
}

export function useApiEndpoints(dispatch: Dispatch) {
  const http = createHttpClient(dispatch)

  return {
    getConsents: (params: ITableCommonParams) => {
      return http
        .get<ApiResponse<ApiResult<IConsent>>>('api/consent', { params: params })
        .then(unwrapApiResponse)
    },
    answerConsent: (id: number, isAccepted: boolean) => {
      return http
        .post<ApiResponse<unknown>>(`api/consent/${id}/answer`, {
          isAccepted: isAccepted,
        })
        .then(unwrapApiResponse)
    },
    createConsent: (body: IConsent) => {
      return http.post<ApiResponse<unknown>>(`api/consent`, body).then(unwrapApiResponse)
    },
    updateConsent: (id: number, body: IConsent) => {
      return http.put<ApiResponse<unknown>>(`api/consent/${id}`, body).then(unwrapApiResponse)
    },
    deleteConsent: (id: number) => {
      return http.delete<ApiResponse<unknown>>(`api/consent/${id}`).then(unwrapApiResponse)
    },
    getProfile: (id: ProfileId) => {
      return http.get<Profile>(`api/profiles/${id}`).then((res) => res.data)
    },
    updateProfile: (id: ProfileId, value: Profile) => {
      return http.put<Profile>(`api/profiles/${id}`, value).then((res) => res.data)
    },
    getParticipantOrRespondentWords: (params: IParticipantOrRespondentWordsBody) => {
      return http
        .get<
          ApiResponse<Array<IFormFilledDataKindOfButNotReally>>
        >('api/services/app/formFilledData/getParticipantOrRespondentWords', { params })
        .then(unwrapApiResponse)
    },
    updateParticipantFormFilledDataAnswer: (body: IUpdateFormFilledDataBody) => {
      return http
        .put('api/services/app/formFilledData/updateParticipantFormFilledDataAnswer', body, {
          spinner: false,
        })
        .then(unwrapApiResponse)
    },

    // why does this guy need a language code? what?
    fillParticipantProfileSelfForm: (body: IFillParticipantProfileSelfFormBody) => {
      // the body should not go in the query params, dude..
      return http
        .post('api/services/app/Profile/fillParticipantProfileSelfForm', undefined, {
          params: body,
        })
        .then(unwrapApiResponse)
    },
  }
}

export type ApiEndpoints = ReturnType<typeof useApiEndpoints>
