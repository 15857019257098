import { Respondent } from '@app/api/end-points'
import { ProfileId } from '../reducer'
import {
  CLEAR_PARTICIPANT_PROFILE_DETAILS,
  GET_EMAIL_CODE,
  PARTICIPANT_PROFILE_EXISTS,
  SET_PARTICIPANT_PROFILE_DETAILS,
} from './constants'
import { ParticipantActionTypes } from './interface'
import { IMyProfiles } from './myIDIProfilesPage/hooks'
import { IProfileStatus } from './participantDashboard/interface'
import { ProfileStatus, TypeOfRole } from '../profileList/profileEnums'
import { IProfileFormInfoResponse } from '@app/commonUtils/participantProfileHelper'

/** @see IMyProfiles */
/** @see IProfileParticipantsStatus */
/** @see IProfileFormInfoResponse */
export interface IProfileDetails {
  languageName: string
  participantName: string
  isPasswordSet: boolean
  isDemographicsFilled: boolean
  isFormFilledData: boolean // some part of form is filled
  isFormFilled: boolean // full form is filled & submitted
  isParticipant: boolean
  profileId: ProfileId
  emailAddress: string
  noOfRespondents: number
  participantInviteColleagues: boolean
  participantInviteExternal: boolean
  participantInviteOtherParticipant: boolean

  // FIXME: this should be declared as the 'TypeOfRole' enum.
  //   doing a global search for '\s+roleId:' yields 30+ results
  //   which is kind of surprising, as this field only exists
  //   in one model. so much for reuse.
  roleId: number
  roleText: string
  isActivityRoleEnabled: boolean
  presentationId: number
  isLocked: boolean
  isSocialLogin: boolean
  status: ProfileStatus
  respondents: ReadonlyArray<Respondent>
  roleCanBeChangedByParticipant: boolean
  selfDeliveryTemplateId: number | null
  selfDeliverySlideIndex: number
}
const initialParticipantProfileDetailsState: IProfileDetails = {
  languageName: '',
  participantName: '',
  isPasswordSet: false,
  isDemographicsFilled: false,
  isFormFilledData: false,
  isFormFilled: false,
  isParticipant: false,
  profileId: 0 as ProfileId,
  emailAddress: '',
  noOfRespondents: 0,
  participantInviteColleagues: false,
  participantInviteExternal: false,
  participantInviteOtherParticipant: false,
  roleId: TypeOfRole.Colleagues,
  roleText: '',
  isActivityRoleEnabled: true,
  presentationId: 0,
  isLocked: false,
  isSocialLogin: false,
  status: ProfileStatus.Unknown,
  respondents: [],
  roleCanBeChangedByParticipant: false,
  selfDeliveryTemplateId: null,
  selfDeliverySlideIndex: 0,
}

export interface IInitialState {
  profileParticipantEmail: string
  getEmailCode: string
  participantProfileExists: boolean
  participantProfileDetails: IProfileDetails
}

const initialState: IInitialState = {
  profileParticipantEmail: '',
  getEmailCode: '',
  participantProfileExists: false,
  participantProfileDetails: initialParticipantProfileDetailsState,
}

export const participantReducer = (state = initialState, action: ParticipantActionTypes) => {
  switch (action.type) {
    case GET_EMAIL_CODE:
      return {
        ...state,
        getEmailCode: action.payload,
      }
    case PARTICIPANT_PROFILE_EXISTS:
      return {
        ...state,
        participantProfileExists: action.payload,
      }
    case SET_PARTICIPANT_PROFILE_DETAILS:
      return {
        ...state,
        participantProfileDetails: action.payload,
      }
    case CLEAR_PARTICIPANT_PROFILE_DETAILS:
      return {
        ...state,
        participantProfileDetails: initialParticipantProfileDetailsState,
      }
    default:
      return state
  }
}
