import { Dispatch } from 'redux'

import {
  getParticipantFormInfo,
  setParticipantProfileDetails,
} from '@app/containers/participantPages/actions'
import { IProfileDetails } from '@app/containers/participantPages/reducer'
import { ProfileId, UserId } from '@app/containers/reducer'
import { ActivityStatus, ProfileStatus } from '@app/containers/profileList/profileEnums'
import { Respondent } from '@app/api/end-points'

/** @see IProfileDetails Probable copy-pasta? */
export interface IProfileFormInfoResponse {
  languageName: string
  languageId: number
  userId: UserId
  profileId: ProfileId
  isPasswordSet: boolean
  isDemographicsFilled: boolean

  /** Naming jesus lord save us */
  isFormFilledData: boolean // some part of form is filled

  /** Naming jesus lord save us */
  isFormFilled: boolean // full form is filled & submitted
  isParticipant: boolean
  emailAddress: string
  noOfRespondents: number
  participantInviteColleagues: boolean
  participantInviteExternal: boolean
  participantInviteOtherParticipant: boolean
  roleId: number
  roleText: string
  isActivityRoleEnabled: boolean
  presentationId: number
  isLocked: boolean
  // Respondents
  profileRespondentId: number
  participantName: string
  status: ProfileStatus
  respondents: ReadonlyArray<Respondent>
  roleCanBeChangedByParticipant: boolean
  selfDeliveryTemplateId: number | null
  selfDeliverySlideIndex: number
}

export const getParticipantProfileInfo = async (
  uniqueCode: string, // is 'unique code' really our best way of identifying a profile? ok.
  dispatch: Dispatch,
  isSocialLogin: boolean // social login seems to be synonymous with 'external login'
): Promise<IProfileDetails> => {
  return await getParticipantFormInfo(uniqueCode, dispatch).then((response) => {
    const updatedParticipantProfileDetails: IProfileDetails = {
      languageName: response.languageName,
      participantName: response.participantName,
      isPasswordSet: response.isPasswordSet,
      isDemographicsFilled: response.isDemographicsFilled,
      isFormFilledData: response.isFormFilledData,
      isFormFilled: response.isFormFilled,
      isParticipant: true,
      profileId: response.profileId,
      emailAddress: response.emailAddress,
      noOfRespondents: response.noOfRespondents,
      participantInviteColleagues: response.participantInviteColleagues,
      participantInviteExternal: response.participantInviteExternal,
      participantInviteOtherParticipant: response.participantInviteOtherParticipant,

      // this must surely be a typo, no? there is no roleId 6. should this
      // go in the godlike 'noOfRespondents'?
      roleId: response.isActivityRoleEnabled === false ? 6 : response.roleId, // If Role settings is disabled, noOfRespondents = 6
      roleText: response.roleText,
      isActivityRoleEnabled: response.isActivityRoleEnabled === false ? false : true,
      presentationId: response.presentationId,
      isLocked: response.isLocked,
      isSocialLogin,
      status: response.status,
      respondents: response.respondents,
      roleCanBeChangedByParticipant: response.roleCanBeChangedByParticipant,
      selfDeliveryTemplateId: response.selfDeliveryTemplateId,
      selfDeliverySlideIndex: response.selfDeliverySlideIndex,
    }

    dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))
    return updatedParticipantProfileDetails
  })
}
