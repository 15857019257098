import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { getCourseDetails, markAsFinish, restartCourse } from '../actions'
import { RootState } from '@app/store/configureStore'
import { routePath } from '../../routePaths'
import { IAcademyCategories, ICourse } from '../types'
import { setSpinner } from '../../actions'
import { createWantRatingAction } from '@app/containers/rating/reducer'
import { ModelType } from '@app/containers/commonInterfaces'
import { pushToDataLayer } from '@app/commonUtils/google'
import { UserId } from '@app/containers/reducer'

export interface IMarkAsFinish {
  userId: UserId
  courseId: number
}

export const useCourseSummary = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { courseId } = useParams()
  const convertedCourseId: string = String(courseId)

  const userId = useSelector((state: RootState) => state.loginReducer.userId)
  const userDetails = useSelector((state: RootState) => state.loginReducer.userDetails)

  const initialCategoryState: IAcademyCategories = {
    id: 0,
    parentId: 0,
    name: '',
    description: '',
    topic: '',
    coursesCount: 0,
    lessonsCount: 0,
    progress: 0,
  }
  const [courseSummary, setCourseSummary] = useState<ICourse>({
    id: 0,
    logo: '',
    title: '',
    description: '',
    duration: 0,
    progress: 0,
    parts: 0,
    quizes: [],
    courseParts: [],
    keyMessage: '',
    actionableAdvice: '',
    suggestedFurtherReading: '',
    references: '',
    category: initialCategoryState,
    parentCategories: [],
    tags: '',
    parentCategoryId: 0,
    parentCategoryName: '',
    categoryId: 0,
    categoryName: '',
  })
  const [isResetCourse, setIsResetCourse] = useState<boolean>(false)
  const [pageNotFound, setPageNotFound] = useState<boolean>(false)
  const [timeSpentMessageModal, setTimeSpentMessageModal] = useState<boolean>(false)
  const [timeSpent, setTimeSpent] = useState<number>(0)

  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const fetchCourseDetails = async (): Promise<void> => {
    dispatch(setSpinner(true))

    getCourseDetails(userId, convertedCourseId, dispatch)
      .then((fetchedCourseDetails: ICourse) => {
        if (fetchedCourseDetails && fetchedCourseDetails.id !== 0)
          setCourseSummary(fetchedCourseDetails)
        else setPageNotFound(true)
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    if (userId) {
      fetchCourseDetails()
    }
  }, [userId])

  const closeTimeSpentMessageModal = (): void => {
    setTimeSpentMessageModal(false)
  }

  const handleCompleteCourse = (totalTimeSpent?: number): void => {
    closeTimeSpentMessageModal()

    // Get the total time spent by the participant
    const timeSpentByParticipant = totalTimeSpent ? totalTimeSpent : timeSpent

    const id = Number(convertedCourseId)
    const body: IMarkAsFinish = {
      userId: userId,
      courseId: id,
    }

    // Push data to Google Tag Manager's dataLayer
    pushToDataLayer({
      event: 'courseCompletion',
      courseCompletion: 'Course Completion',
      participantName: userDetails.name,
      courseTitle: courseSummary.title,
      timeSpentByParticipant: timeSpentByParticipant, // Time spent in seconds or minutes
    })

    // Mark course as finished
    markAsFinish(body, dispatch)
    navigate(routePath.participantAcademy)
    dispatch(createWantRatingAction(ModelType.Course, id, courseSummary.title))
  }

  const markAsFinishedHandler = (timespent: number): void => {
    if (timespent < courseSummary.duration) {
      setTimeSpentMessageModal(true)
      setTimeSpent(timespent)
    } else {
      handleCompleteCourse(timespent)
    }
  }

  const handleRestartCourse = async (): Promise<void> => {
    closeTimeSpentMessageModal()
    await restartCourse(userId, convertedCourseId, dispatch)
    navigate(routePath.participantAcademy)
  }

  const backClickHandler = (): void => {
    navigate(routePath.participantAcademy)
  }

  const openResetConfirmModal = (): void => {
    setIsResetCourse(true)
  }

  const closeConfirmModal = (): void => {
    setIsResetCourse(false)
  }

  return {
    courseSummary,
    languageText,
    isResetCourse,
    pageNotFound,
    timeSpentMessageModal,
    markAsFinishedHandler,
    handleRestartCourse,
    backClickHandler,
    openResetConfirmModal,
    closeConfirmModal,
    handleCompleteCourse,
    closeTimeSpentMessageModal,
  }
}
