import React from 'react'

import { useSelectLanguagePage } from './hooks'
import { LanguagePageComponent } from '../../../components/languagePageComponent/languagePageComponent'

export const SelectLanguagePage = (): JSX.Element => {
  const { languageText, languages, handleChangeLanguage } = useSelectLanguagePage()

  return (
    <>
      <LanguagePageComponent
        languageText={languageText}
        languages={languages}
        handleChangeLanguage={handleChangeLanguage}
      />
    </>
  )
}
