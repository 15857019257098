import React from 'react'
import clsx from 'clsx'

import {
  CorrectAnswerCard,
  CourseBreadcumb,
  CourseNotFound,
  NavigationButtons,
} from '../components'
import { useQuiz } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IQuizOptions } from '../types'

export const Quiz = (): JSX.Element => {
  const {
    courseQuiz,
    quizzes,
    activeQuiz,
    isAnswered,
    selectedOption,
    isCorrect,
    languageText,
    quizNotFound,
    handleOptionChange,
    forwardClickHandler,
    backClickHandler,
  } = useQuiz()

  if (quizNotFound) {
    return (
      <CourseNotFound
        title={getLanguageValue(languageText, 'Quiz not found')}
        text={getLanguageValue(languageText, 'The quiz you tried to access cannot be found')}
        languageText={languageText}
      />
    )
  }

  return (
    <>
      {courseQuiz && (
        <div className='mt-3 p-2 p-md-4 col-md-8 mx-auto'>
          <CourseBreadcumb languageText={languageText} course={courseQuiz} />
          <div className='shadow-sm p-4 rounded bg-white'>
            <div className='d-flex justify-content-between'>
              <h3 className='mb-4 fw-bold pt-1'>
                <i className='bi bi-question-circle me-2 fs-4 text-dark'></i>
                {getLanguageValue(languageText, 'Quiz')}
              </h3>
              <div className='d-flex flex-nowrap mb-4'>
                {quizzes.length > 0 ? (
                  quizzes.map((q, index) => (
                    <div
                      className={clsx(
                        ' rounded-circle p-2 py-1 px-md-3 py-md-2 me-1 ',
                        activeQuiz >= index ? 'bg-success text-white' : 'bg-white border text-dark'
                      )}
                      key={index}
                    >
                      {index + 1}
                    </div>
                  ))
                ) : (
                  <div className='fs-5'>{getLanguageValue(languageText, 'No quiz available')}</div>
                )}
              </div>
            </div>
            <p className='fw-bold fs-4 mb-4 pt-2'>{quizzes && quizzes[activeQuiz]?.question}</p>
            {/* Choises */}
            <div className='d-flex flex-column content-justify-center flex-row-fluid'>
              {quizzes.length > 0 &&
                quizzes[activeQuiz].quizOptions &&
                quizzes[activeQuiz].quizOptions.map(
                  ({ answerOption, id, isCorrect }: IQuizOptions) => (
                    <div key={id} className='d-flex align-items-center mb-1 fs-5'>
                      <div className='mb-3'>
                        <div className='d-flex align-items-center'>
                          <input
                            className='btn-check'
                            type='checkbox'
                            id={String(id)}
                            value={id}
                            disabled={isAnswered}
                            style={{
                              background:
                                isAnswered && isCorrect
                                  ? '#58BA7F'
                                  : selectedOption === String(id)
                                    ? '#BC3C3C'
                                    : '#fff',
                              opacity: 1,
                              cursor: 'pointer',
                            }}
                            checked={selectedOption === String(id)}
                            onChange={(event) => handleOptionChange(event, isCorrect)}
                          />
                          <label
                            htmlFor={String(id)}
                            className={
                              isAnswered
                                ? 'me-4 btn border p-1 px-2'
                                : 'me-4 btn border border-dark p-1 px-2'
                            }
                            style={{
                              background:
                                isAnswered && isCorrect
                                  ? '#58BA7F'
                                  : selectedOption === String(id)
                                    ? '#BC3C3C'
                                    : '#fff',
                              opacity: 1,
                              cursor: 'pointer',
                            }}
                          >
                            {isAnswered ? (
                              isCorrect ? (
                                <i className='bi bi-check-lg fs-4 text-white'></i>
                              ) : selectedOption === String(id) ? (
                                <i className='bi bi-x-lg fs-4 text-white'></i>
                              ) : (
                                <i className='bi bi-x-lg fs-4 text-white'></i>
                              )
                            ) : (
                              <i className='bi bi-check-lg fs-4 text-white'></i>
                            )}
                          </label>
                          <div>{answerOption}</div>
                        </div>
                      </div>
                    </div>
                  )
                )}

              {selectedOption && (
                <CorrectAnswerCard
                  languageText={languageText}
                  label={quizzes[activeQuiz].correctAnswerReason}
                  isCorrect={isCorrect}
                />
              )}
            </div>

            <NavigationButtons
              onForwardClick={forwardClickHandler}
              onBackClick={backClickHandler}
              isDisabled={!isAnswered}
              forwardLabel={
                activeQuiz === quizzes.length - 1 && getLanguageValue(languageText, 'Finish')
              }
            />
          </div>
        </div>
      )}
    </>
  )
}
