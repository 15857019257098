import clsx from 'clsx'
import React from 'react'
import DatePicker from 'react-datepicker'

import { IFocusError } from '../formComponents/input'
import 'react-datepicker/dist/react-datepicker.css'

interface IReactDatePicker {
  className?: string
  label?: string
  name: string
  placeholder?: string
  date: Date | null
  minDate?: Date
  maxDate?: Date
  required?: boolean
  focusInput?: IFocusError
  disabled?: boolean
  dateWithTime?: boolean
  handleDateSelect: (name: string, date: Date) => void
}

export const ReactDatePicker = (props: IReactDatePicker): JSX.Element => {
  const {
    className,
    label,
    name,
    placeholder,
    date,
    minDate,
    maxDate,
    required,
    focusInput,
    disabled,
    dateWithTime,
    handleDateSelect,
  } = props

  const onDateSelect = (date: Date): void => {
    handleDateSelect(name, date)
  }

  return (
    <div className={clsx('mb-3', className && className)}>
      {label && <label className={clsx('mb-1', { required: required })}>{label}</label>}
      <DatePicker
        className='form-control form-control-solid w-100'
        placeholderText={placeholder}
        selected={date}
        dateFormat={dateWithTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
        onChange={onDateSelect}
        shouldCloseOnSelect={dateWithTime ? false : true}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        disabledKeyboardNavigation
        showTimeInput={dateWithTime}
      />

      {focusInput?.errorMessage && (
        <div className='invalid-feedback d-inline-block'>{focusInput.errorMessage}</div>
      )}
    </div>
  )
}
