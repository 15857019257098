import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { getParticipantRespondentsByParticipantId } from '../../actions'
import { ProfileStatus } from '../../../profileEnums'
import { ParticipantsRespondentsStatus } from './participantsRespondentsStatus'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { setSpinner } from '../../../../actions'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { ProfileId } from '@app/containers/reducer'

interface IParticipantAccountDetailsModalProps {
  languageText: ITranslationObject
  languages: IDropdownList[]
  profileId: ProfileId
  status?: number
  handleCloseClick: () => void
}

export const ParticipantAccountDetailsModal = (props: IParticipantAccountDetailsModalProps) => {
  const { languageText, languages, profileId, status, handleCloseClick } = props

  const dispatch = useDispatch()
  const isNewStatus = status === ProfileStatus.New

  const [participantLink, setParticipantLink] = useState<string>('')

  useEffect(() => {
    if (isNewStatus) {
      dispatch(setSpinner(true))
      getParticipantRespondentsByParticipantId(profileId, dispatch)
        .then((response) => {
          if (response) {
            setParticipantLink(response.participantLink)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }, [])

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Participant account details')}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={handleCloseClick}
        width='lg'
      >
        {isNewStatus ? (
          <>
            <div className='fs-6'>
              {getLanguageValue(
                languageText,
                'Please use the following link to open the participant interface'
              )}
            </div>
            <div className='fs-6 mt-3'>{participantLink}</div>
          </>
        ) : (
          <>
            <ParticipantsRespondentsStatus
              languageText={languageText}
              languages={languages}
              profileId={profileId}
            />
          </>
        )}
      </ModalComponent>
    </>
  )
}
