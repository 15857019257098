import { Dispatch } from 'redux'
import { api, apiEndPoints } from '../../api'
import { setNotification } from '../actions'
import { IFetchActivitiesParams } from './hooks'
import { ApiResult } from '@app/types'
import { IActivity } from '../commonInterfaces'
import { ActivityId } from '../reducer'

export const getActivityList = async (
  params: IFetchActivitiesParams,
  dispatch: Dispatch
): Promise<ApiResult<IActivity>> => {
  try {
    const response = await api.get(apiEndPoints.getAllActivities, { params })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteActivity = async (id: ActivityId, dispatch: Dispatch): Promise<boolean> => {
  const paramQuery = {
    id: id,
  }
  try {
    const response = await api.delete(apiEndPoints.deleteActivity, {
      params: paramQuery,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
