import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../api'
import { clearNotifications, setNotification } from '../actions'
import { IDispatch } from '../auth/signUp/actions'
import {
  ICreateEmployeeParticipantsWithRoleBody,
  ICreateEmployeesBody,
} from './uploadEmployeeModal/hooks'
import { IEmployee } from './hooks'
import { ApiResponse, ApiResult } from '@app/types'
import { UserId } from '../reducer'

export const getAllEmployeesByClientId = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  clientId: number,
  dispatch: IDispatch
): Promise<ApiResult<IEmployee>> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
    clientId,
  }

  try {
    const response = await api.get<ApiResponse<ApiResult<IEmployee>>>(
      apiEndPoints.getAllEmployeesByClientId,
      {
        params: body,
      }
    )
    if (!response.data.success) {
      return Promise.reject(response.data)
    }
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getEmployeeListForAdmin = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  dispatch: IDispatch
): Promise<ApiResult<IEmployee>> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
  }

  try {
    const response = await api.get<ApiResponse<ApiResult<IEmployee>>>(
      apiEndPoints.getAllEmployees,
      {
        params: body,
      }
    )
    if (!response.data.success) {
      return Promise.reject(response.data)
    }
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getEmployeesData = async (clientId: number, file: object, dispatch: IDispatch) => {
  dispatch(clearNotifications(''))
  try {
    const response = await api.post(apiEndPoints.employeesImportedData, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        clientId,
      },
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getEmployeeDetailsById = async (id: number, clientId: number, dispatch: IDispatch) => {
  try {
    const response = await api.get(apiEndPoints.getEmployeeDetailsById, {
      params: { id, clientId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getParticipantDashboardInfoForFacilitator = async (
  userId: UserId,
  clientId: number,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getParticipantDashboardInfoForFacilitator, {
      params: { userId, clientId },
    })

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createEmployees = async (body: ICreateEmployeesBody[], dispatch: IDispatch) => {
  try {
    const response = await api.post(apiEndPoints.createEmployees, JSON.stringify(body), {})
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createEmployeeParticipantsWithRole = async (
  body: ICreateEmployeeParticipantsWithRoleBody[],
  dispatch: IDispatch
): Promise<ApiResponse<unknown>> => {
  try {
    const response = await api.post(
      apiEndPoints.createEmployeeParticipantsWithRole,
      JSON.stringify(body),
      {}
    )
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const isEmployeeExists = async (email: string, clientId: number, dispatch: Dispatch) => {
  try {
    const response = await api.get(apiEndPoints.isEmployeeExists, {
      params: {
        email,
        clientId,
      },
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteEmployeeById = async (
  userId: UserId,
  clientId: number,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.delete(apiEndPoints.deleteEmployeeById, {
      params: { userId, clientId },
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
