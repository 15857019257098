import React from 'react'

import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { DimensionCases, getDimensionsBarHeight } from '../../commonUtils/dimensionsHelper'

interface IDimensionsChartProps {
  languageText: ITranslationObject
  directivenessSelfValue: number
  directivenessOtherValue: number
  affiliationSelfValue: number
  affiliationOtherValue: number
  adaptabilitySelfValue: number
  adaptabilityOtherValue: number
}

export const DimensionsChart = (props: IDimensionsChartProps): JSX.Element => {
  const {
    languageText,
    directivenessSelfValue,
    directivenessOtherValue,
    affiliationSelfValue,
    affiliationOtherValue,
    adaptabilitySelfValue,
    adaptabilityOtherValue,
  } = props

  return (
    <div className='card-body mt-4'>
      <div className='row text-center'>
        {/* Directiveness */}
        <div className='col-12 col-md-4'>
          <div className='d-flex flex-column justify-content-end idi-profile-dimensions-height rounded border p-2 text-white'>
            <div className='d-flex align-items-end position-relative w-100 h-100 mb-2 gap-2'>
              <div
                className='bg-danger p-2 position-absolute bottom-0 start-0'
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.Inquery,
                    directivenessSelfValue
                  )}`,
                }}
              ></div>
              <div
                className='w-50 bg-success d-flex justify-content-center align-items-end pb-1'
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.Inquery,
                    directivenessOtherValue
                  )}`,
                }}
              >
                <span className='z-1'>{getLanguageValue(languageText, 'Inquery')}</span>
              </div>

              <div
                className='bg-danger p-2 position-absolute bottom-0 end-0'
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.Advocacy,
                    directivenessSelfValue
                  )}`,
                }}
              ></div>
              <div
                className='w-50 bg-success d-flex justify-content-center align-items-end pb-1'
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.Advocacy,
                    directivenessOtherValue
                  )}`,
                }}
              >
                <span className='z-1'>{getLanguageValue(languageText, 'Advocacy')}</span>
              </div>
            </div>
            <div className='w-100 text-dark fw-bold'>
              {getLanguageValue(languageText, 'Directiveness')}
            </div>
          </div>
        </div>

        {/* Affiliation */}
        <div className='col-12 col-md-4 align-items-end'>
          <div className='d-flex flex-column justify-content-end idi-profile-dimensions-height rounded border p-2 text-white'>
            <div className='d-flex align-items-end position-relative w-100 h-100 mb-2 gap-2'>
              <div
                className='bg-danger p-2 position-absolute bottom-0 start-0'
                style={{
                  height: `${getDimensionsBarHeight(DimensionCases.Task, affiliationSelfValue)}`,
                }}
              ></div>
              <div
                className='w-50 bg-success d-flex justify-content-center align-items-end pb-1'
                style={{
                  height: `${getDimensionsBarHeight(DimensionCases.Task, affiliationOtherValue)}`,
                }}
              >
                <span className='z-1'>{getLanguageValue(languageText, 'Task')}</span>
              </div>

              <div
                className='bg-danger p-2 position-absolute bottom-0 end-0'
                style={{
                  height: `${getDimensionsBarHeight(DimensionCases.People, affiliationSelfValue)}`,
                }}
              ></div>
              <div
                className='w-50 bg-success d-flex justify-content-center align-items-end pb-1'
                style={{
                  height: `${getDimensionsBarHeight(DimensionCases.People, affiliationOtherValue)}`,
                }}
              >
                <span className='z-1'>{getLanguageValue(languageText, 'People')}</span>
              </div>
            </div>
            <div className='w-100 text-dark fw-bold'>
              {getLanguageValue(languageText, 'Affiliation')}
            </div>
          </div>
        </div>

        {/* Adaptability */}
        <div className='col-12 col-md-4'>
          <div className='d-flex flex-column justify-content-end idi-profile-dimensions-height rounded border p-2 text-white'>
            <div className='d-flex align-items-end position-relative w-100 h-100 mb-2 gap-2'>
              <div
                className='bg-danger p-2 position-absolute bottom-0 start-0'
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.OwnNeeds,
                    adaptabilitySelfValue
                  )}`,
                }}
              ></div>
              <div
                className='w-50 bg-success d-flex justify-content-center align-items-end pb-1'
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.OwnNeeds,
                    adaptabilityOtherValue
                  )}`,
                }}
              >
                <span className='z-1'>{getLanguageValue(languageText, 'Own needs')}</span>
              </div>

              <div
                className='bg-danger p-2 position-absolute bottom-0 end-0'
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.OthersNeeds,
                    adaptabilitySelfValue
                  )}`,
                }}
              ></div>
              <div
                className='w-50 bg-success d-flex justify-content-center align-items-end pb-1'
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.OthersNeeds,
                    adaptabilityOtherValue
                  )}`,
                }}
              >
                <span className='z-1'>{getLanguageValue(languageText, 'Others needs')}</span>
              </div>
            </div>
            <div className='w-100 text-dark fw-bold'>
              {getLanguageValue(languageText, 'Adaptability')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
