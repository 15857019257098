import React from 'react'

interface IDownloadNavItemProps {
  icon: JSX.Element | string
  info: string
}

export const DownloadNavItem = (props: IDownloadNavItemProps) => {
  return (
    <div className='whitespace-nowrap'>
      <span className='py-2 me-2'>{props.icon}</span>
      <span className='fs-6 fw-bold'>{props.info}</span>
    </div>
  )
}
