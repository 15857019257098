import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../api'
import { setNotification } from '../../actions'
import { ICreateUpdateClient, ICreateUpdateSubscriptionBody } from './hooks'
import { IDisconnectUserFromClientBody } from './utils/clientUsers/hooks'
import { ICountry, ITableCommonParams } from '@app/containers/commonInterfaces'
import { UserId } from '@app/containers/reducer'

export const getUsersByClientId = async (
  clientId: number,
  params: ITableCommonParams,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getUsersByClientId(clientId), {
      params: params,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getAllCountries = async (dispatch: Dispatch): Promise<any> => {
  const body = {
    maxResultCount: 100,
    skipCount: 0,
  }

  try {
    const response = await api.get(apiEndPoints.getCountries, { params: body })
    if (response?.data?.result) {
      const countries: ICountry[] = response.data.result.items
      return countries.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    }
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createClient = async (body: ICreateUpdateClient, dispatch: Dispatch): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createClient, JSON.stringify(body), {})
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateClient = async (body: ICreateUpdateClient, dispatch: Dispatch): Promise<any> => {
  try {
    const response = await api.put(apiEndPoints.updateClient, JSON.stringify(body), {})
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createClientByFacilitator = async (
  userId: UserId,
  body: ICreateUpdateClient,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createClientByFacilitator, JSON.stringify(body), {
      params: { userId },
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getAllClientSubscriptions = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  clientId: number,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getAllClientSubscriptions, {
      params: { filter, sorting, maxResultCount, skipCount, clientId },
    })
    return response.data.result.items
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getClientSubscriptionById = async (
  subscriptionId: number,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getClientSubscriptionById, {
      params: { id: subscriptionId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

// getAnnualOrMonthlySubscriptions
export const getAnnualOrMonthlySubscriptions = async (
  isAnnual: boolean,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getAnnualOrMonthlySubscriptions, {
      params: { isAnnual },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

// Create client billing
export const createClientBilling = async (
  body: ICreateUpdateSubscriptionBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createClientBilling, JSON.stringify(body), {})
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

// Update client billing
export const updateClientBilling = async (
  body: ICreateUpdateSubscriptionBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.put(apiEndPoints.updateClientBilling, JSON.stringify(body), {})
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

// Get Users To Connect Account
export const getUsersToConnectAccount = async (
  clientId: number,
  searchText: string,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getUsersToConnectAccount, {
      params: { clientId, searchText },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

// Connect User to Client
export const connectFacilitatorToAccount = async (
  userId: UserId,
  clientId: number,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(
      apiEndPoints.connectFacilitatorToAccount,
      {},
      {
        params: { userId, clientId, isDefaultClient: false },
      }
    )
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

// Disconnect User from Client
export const disconnectUserFromClient = async (
  body: IDisconnectUserFromClientBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.post(apiEndPoints.disconnectUserFromClient, JSON.stringify(body), {})
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
