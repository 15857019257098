import * as React from 'react'
import { ColumnDef, Row } from '@tanstack/react-table'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { IFilterObject } from '../../containers/auditLogs/interface'

export interface IReactTableProps<T> {
  wrapperClass?: string
  headerText?: string
  searchPlaceholder?: string
  buttonText?: string
  buttonSecondOptionalText?: string
  customButtons?: JSX.Element
  data: Array<T>
  tableHeader: ColumnDef<T>[]
  totalCount?: number
  pageCount?: number
  filter?: string
  sorting?: string
  pageLimit?: number
  pageSelected?: number
  minHeight?: string
  paginationRequired?: boolean
  isTranslationList?: boolean
  filterDropdownList?: IDropdownList[]
  filterDropdownLabel?: string
  filterDropdownDefaultLabel?: string

  /**
   * Controls whether '.table-responsive' is added to the container. Causes
   * large tables to be horizontally scrollable. Defaults to 'true'.
   */
  responsive?: boolean
  customFilters?: JSX.Element
  showCustomFilters?: boolean
  storePageConfiguredInfo?: boolean // Store Page Search, pagination, sorting, numberOfRows info
  showClearFiltersButton?: boolean
  handleButtonClick?: React.MouseEventHandler<HTMLButtonElement>
  handleSecondButtonClick?: React.MouseEventHandler<HTMLButtonElement>
  handleRowClick?: (event: React.MouseEvent<HTMLTableRowElement>, row: Row<T>) => unknown
  setFilter?: (value: string) => void
  setFilterDropdownTable?: (value: string) => void
  setSorting?: (value: string) => void
  setPageLimit?: (value: number) => void
  setPageSelected?: (value: number) => void
  handleUpdatedFilterOptions?: (value: IFilterObject) => void
  handleClearCustomFilters?: () => void // This prop is used to clear custom filters coming from parent component
}

export interface IPageSelected {
  selected: number
}

export enum TableSort {
  desc = 'desc',
  asc = 'asc',
}
