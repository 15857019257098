import React from 'react'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { DeleteModal } from '@app/components/modals/deleteModal'
import { Input } from '@app/components/formComponents/input'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { api } from '@app/api'
import { endPoints } from '@app/api/end-points'
import { ApiResponse, ApiResult } from '@app/types'
import { ILanguage, ITableCommonParams } from '@app/containers/commonInterfaces'
import { exportTranslations, getLanguages, importTranslations } from '../actions'
import { addToast, setSpinner } from '@app/containers/actions'
import { AnyAction } from 'redux'
import {
  LanguageTextKey,
  deleteLanguageText,
  CreateOrUpdateLanguageTextBody,
  createOrUpdateLanguageText,
  LanguageTextKeyPair,
} from './actions'
import debounce from 'lodash/debounce'
import { Pagination } from '@app/components/reactTable/pagination'
import { useSearchParams } from 'react-router-dom'
import { CustomActionsCell } from '@app/components/reactTable/customActionsCell'
import { LanguageCode } from '@app/containers/commonEnums'
import { Checkbox } from '@app/components/formComponents/checkbox'
import { ImportExportModal } from '@app/components/importExportModal'
import {
  DropdownMultiSelect,
  IMultiDropdownList,
} from '@app/components/formComponents/dropdownMultiSelect'
import { AddEditLanguageTextModal } from './addEditModal'
import { classNames } from '@app/containers/utils'

type ILanguageTextProps = unknown

function getAllLanguageTexts(params: ITableCommonParams): Promise<ApiResult<LanguageTextKeyPair>> {
  return api
    .get<ApiResponse<ApiResult<LanguageTextKeyPair>>>(endPoints.getAllLanguageTexts, {
      params: params,
    })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data)
      }
      return res.data.result
    })
}

type Action =
  | { kind: 'delete'; key: string }
  | ({ kind: 'edit'; oldKey: string } & LanguageTextKeyPair)
  | ({ kind: 'add' } & LanguageTextKeyPair)
  | { kind: 'export_import' }

type SaveFn = (body: CreateOrUpdateLanguageTextBody) => Promise<void>

const ROLE_KEY_NAMES = [
  'allowAdminRole',
  'allowFacilitatorRole',
  'allowParticipantRole',
  'allowRespondentRole',
] as const

type RoleKey = (typeof ROLE_KEY_NAMES)[number]
type Filters = { filter: string } & { [K in RoleKey]: boolean }

const ROLE_KEY_TITLES: { [K in RoleKey]: [string, string] } = {
  allowAdminRole: ['A', 'Allow admins'],
  allowFacilitatorRole: ['F', 'Allow facilitators'],
  allowParticipantRole: ['P', 'Allow participants'],
  allowRespondentRole: ['R', 'Allow respondents'],
}
const BASE_LANGUAGE = LanguageCode.English

export const LanguageText = (props: ILanguageTextProps): JSX.Element => {
  const filterRef = React.useRef<HTMLInputElement>(null)
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = React.useState<Filters>(() => {
    return {
      filter: searchParams.get('filter') || '',
      allowAdminRole: false,
      allowFacilitatorRole: false,
      allowParticipantRole: false,
      allowRespondentRole: false,
    }
  })
  const [totalCount, setTotalCount] = React.useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = React.useState<number>(50) // items_per_page
  const [pageCount, setPageCount] = React.useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = React.useState<number>(0) // page_number_selected
  const [pairs, setPairs] = React.useState<Array<LanguageTextKeyPair>>([])
  const [action, setAction] = React.useState<Action | undefined>(undefined)
  const [languages, setLanguages] = React.useState<ReadonlyArray<ILanguage>>([])
  const [languageCodes, setLanguageCodes] = React.useState<Array<string>>(() => {
    const codes = searchParams.getAll('languageCode')
    if (!codes.includes(BASE_LANGUAGE)) {
      codes.push(BASE_LANGUAGE)
    }
    return codes
  })
  const [sorting, setSorting] = React.useState<[string, string]>(['Key', 'ASC'])

  const doImmediateSave: SaveFn = (body) => {
    dispatch(setSpinner(true))

    return createOrUpdateLanguageText(body, dispatch)
      .then((res) => {
        dispatch(addToast('Saved') as AnyAction)
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  const doDebouncedSave = React.useCallback<SaveFn>(debounce(doImmediateSave, 300), [])

  function reloadAndUpdateState(page: number): Promise<unknown> {
    const params: ITableCommonParams = {
      filter: filters.filter,
      sorting: sorting.join(' '),
      skipCount: pageLimit * page,
      maxResultCount: pageLimit,
    }

    for (const key of ROLE_KEY_NAMES) {
      // technically this is a three-way switch where you can unset, enable or disable.
      // we're only really interested in the enabled use case though.
      if (filters[key]) {
        params[key] = true
      }
    }

    dispatch(setSpinner(true))

    return getAllLanguageTexts(params)
      .then((res) => {
        setTotalCount(res.totalCount)
        setPageCount(Math.ceil(res.totalCount / pageLimit))
        setPageSelected(page)
        setPairs(res.items.slice())
        setAction(undefined)
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  React.useEffect(() => {
    reloadAndUpdateState(0)
  }, [filters, sorting])

  React.useEffect(() => {
    reloadAndUpdateState(pageSelected)
  }, [pageLimit, pageSelected])

  React.useEffect(() => {
    getLanguages(dispatch).then((res) => {
      const sorted = res.items.slice()
      const collator = new Intl.Collator(BASE_LANGUAGE)
      sorted.sort((a, b) => {
        return collator.compare(a.displayName, b.displayName)
      })
      setLanguages(sorted)
    })
  }, [])

  React.useEffect(() => {
    setSearchParams({
      filter: filters.filter,
      languageCode: languageCodes,
    })
  }, [filters, languageCodes])

  React.useEffect(() => {
    filterRef.current?.focus()
  }, [])

  function handleDeleteClick(idx: number): void {
    const item = pairs[idx]
    setAction({
      kind: 'delete',
      key: item.key,
    })
  }

  function handleDeleteClickActually(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault()

    if (action?.kind !== 'delete') {
      return
    }

    const body: LanguageTextKey = {
      key: action.key,
    }

    dispatch(setSpinner(true))

    deleteLanguageText(body, dispatch)
      .then((res) => {
        dispatch(addToast('Deleted') as AnyAction)
      })
      .finally(() => {
        dispatch(setSpinner(false))
        setAction(undefined)
        reloadAndUpdateState(pageSelected)
      })
  }

  function handleAddKeyClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault()

    setAction({
      kind: 'add',
      key: '',
      allowAdminRole: true,
      allowFacilitatorRole: true,
      allowParticipantRole: true,
      allowRespondentRole: true,
      notes: '',
      values: {},
    })
  }

  function handleRenameKeyClick(idx: number): void {
    const item = pairs[idx]
    setAction({
      kind: 'edit',
      oldKey: item.key,
      ...item,
    })
  }
  function handleAddOrEditKeyClickSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault()

    if (action?.kind !== 'add' && action?.kind !== 'edit') {
      return
    }

    const values = action.values
    const body: CreateOrUpdateLanguageTextBody = {
      key: action.key,
      oldKey: action.kind === 'edit' ? action.oldKey : undefined,
      allowAdminRole: action.allowAdminRole,
      allowFacilitatorRole: action.allowFacilitatorRole,
      allowParticipantRole: action.allowParticipantRole,
      allowRespondentRole: action.allowRespondentRole,
      notes: action.notes,
      languageNameValues: Object.entries(values).map(([code, value]) => {
        return {
          languageName: code,
          value: value,
        }
      }),
    }
    dispatch(setSpinner(true))

    createOrUpdateLanguageText(body, dispatch)
      .then((res) => {
        setAction(undefined)
        reloadAndUpdateState(0)
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  function handleImportAndExportClick(event: React.MouseEvent<HTMLButtonElement>): void {
    setAction({
      kind: 'export_import',
    })
  }

  const languagesDropdownList: Array<IMultiDropdownList> = languages.map((lang) => {
    return {
      id: lang.id,
      label: lang.displayName,
      value: lang.name,
    }
  })

  return (
    <>
      <div className='p-2 pt-4 p-md-4'>
        <h3 className='mb-3 fw-bold'>{getLanguageValue(languageText, 'Translations')}</h3>

        <div className='row mb-3'>
          <div className='col-3'>
            <DropdownMultiSelect
              name=''
              handleMultiDropdownSelect={(name, values) => {
                setLanguageCodes(values)
              }}
              list={languagesDropdownList}
              placeholder={getLanguageValue(languageText, 'Select Language')}
              value={languageCodes}
              narrow={true}
            />
          </div>
          <div className='col-2 d-flex align-items-center justify-content-between'>
            {ROLE_KEY_NAMES.map((name, idx) => {
              const [label, title] = ROLE_KEY_TITLES[name]

              return (
                <Checkbox
                  key={idx}
                  value={filters[name]}
                  label={label}
                  title={getLanguageValue(languageText, title)}
                  handleCheckboxChange={(event) => {
                    setFilters({
                      ...filters,
                      [name]: event.target.checked,
                    })
                  }}
                />
              )
            })}
          </div>
          <div className='col-7 d-flex'>
            <div className='flex-grow-1'>
              <Input
                inputRef={filterRef}
                handleInputChange={(event) => {
                  setFilters({
                    ...filters,
                    filter: event.target.value,
                  })
                }}
                placeholder={getLanguageValue(languageText, 'Search')}
                value={filters.filter}
                narrow={true}
              />
            </div>

            <div className='ms-3'>
              <button className='btn btn-success' onClick={handleImportAndExportClick}>
                {getLanguageValue(languageText, 'Import & export')}
              </button>
            </div>
            <div className='ms-3'>
              <button className='btn btn-success' onClick={handleAddKeyClick}>
                {getLanguageValue(languageText, 'Add key')}
              </button>
            </div>
          </div>
        </div>

        <table className='table align-middle'>
          <thead>
            <tr>
              <th className='fw-normal text-secondary bg-dark bg-opacity-10' />
              <th
                className='col-3 fw-normal text-secondary bg-dark bg-opacity-10'
                onClick={(event) => {
                  setSorting(['Key', sorting[1] === 'ASC' ? 'DESC' : 'ASC'])
                }}
                role='button'
              >
                {getLanguageValue(languageText, 'Key')}
                {sorting[0] === 'Key' && (
                  <i
                    className={classNames({
                      bi: true,
                      'bi-chevron-up': sorting[1] === 'ASC',
                      'bi-chevron-down': sorting[1] === 'DESC',
                      'ms-1': true,
                    })}
                  />
                )}
              </th>
              <th className='col-2 fw-normal text-secondary bg-dark bg-opacity-10'>
                A / F / P / R
              </th>
              <th
                className='col-2 fw-normal text-secondary bg-dark bg-opacity-10'
                onClick={(event) => {
                  setSorting(['Notes', sorting[1] === 'ASC' ? 'DESC' : 'ASC'])
                }}
                role='button'
              >
                {getLanguageValue(languageText, 'Notes')}
                {sorting[0] === 'Notes' && (
                  <i
                    className={classNames({
                      bi: true,
                      'bi-chevron-up': sorting[1] === 'ASC',
                      'bi-chevron-down': sorting[1] === 'DESC',
                      'ms-1': true,
                    })}
                  />
                )}
              </th>
              <th className='col-5 fw-normal text-secondary bg-dark bg-opacity-10'>
                {getLanguageValue(languageText, 'Value')}
              </th>
            </tr>
          </thead>
          <tbody>
            {pairs.map((pair, i) => {
              function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): void {
                const next = pairs.slice()

                next[i] = {
                  ...pair,
                  [event.target.name]: event.target.checked,
                }

                setPairs(next)

                const body: CreateOrUpdateLanguageTextBody = {
                  ...pair,
                  oldKey: undefined,
                  languageNameValues: [],
                  [event.target.name]: event.target.checked,
                }

                doImmediateSave(body)

                const hasRoleFiltersSelected = ROLE_KEY_NAMES.some((t) => !!filters[t])

                if (hasRoleFiltersSelected) {
                  reloadAndUpdateState(pageSelected)
                }
              }

              return (
                <tr key={i}>
                  <td>
                    <CustomActionsCell
                      id={i}
                      languageText={languageText}
                      editOption={true}
                      deleteOption={true}
                      handleEditClick={handleRenameKeyClick}
                      handleDeleteClick={handleDeleteClick}
                    />
                  </td>
                  <td>{pair.key}</td>
                  <td>
                    <div className='d-flex justify-content-between'>
                      {ROLE_KEY_NAMES.map((name, idx) => {
                        const [label, title] = ROLE_KEY_TITLES[name]

                        return (
                          <div className='' key={idx}>
                            <Checkbox
                              name={name}
                              value={pair[name]}
                              title={getLanguageValue(languageText, title)}
                              handleCheckboxChange={handleCheckboxChange}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </td>
                  <td>{pair.notes}</td>
                  <td>
                    {languages.map((lang) => {
                      const elemKey = `${lang.name}.${pair.key}`

                      if (!languageCodes.includes(lang.name)) {
                        return <React.Fragment key={elemKey} />
                      }

                      const languageCode = lang.name

                      return (
                        <div className='mb-2' key={elemKey}>
                          <Input
                            handleInputChange={(event) => {
                              const next = pairs.slice()
                              const pair = next[i]
                              const value = event.target.value

                              next[i] = {
                                ...pair,
                                values: {
                                  ...pair.values,
                                  [languageCode]: value,
                                },
                              }

                              setPairs(next)

                              const body: CreateOrUpdateLanguageTextBody = {
                                key: pair.key,
                                oldKey: undefined,
                                allowAdminRole: pair.allowAdminRole,
                                allowFacilitatorRole: pair.allowFacilitatorRole,
                                allowParticipantRole: pair.allowParticipantRole,
                                allowRespondentRole: pair.allowRespondentRole,
                                notes: pair.notes,
                                languageNameValues: [
                                  {
                                    languageName: languageCode,
                                    value: value,
                                  },
                                ],
                              }

                              doDebouncedSave(body)
                            }}
                            placeholder={getLanguageValue(languageText, 'Type here')}
                            value={pair.values?.[languageCode]}
                            narrow={true}
                            inputGroupLeft={<span style={{ minWidth: 50 }}>{lang.name}</span>}
                          />
                        </div>
                      )
                    })}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <Pagination
          languageText={languageText}
          handlePageChange={(next) => {
            setPageSelected(next.selected)
          }}
          handlePageLimit={(event) => {
            setPageLimit(Number(event.target.value))
          }}
          pageCount={pageCount}
          pageLimit={pageLimit}
          pageSelected={pageSelected}
          totalCount={totalCount}
        />
      </div>

      {action?.kind === 'delete' && (
        <DeleteModal
          languageText={languageText}
          headerText={action?.key}
          bodyText={action?.key || ''}
          handleDeleteClick={handleDeleteClickActually}
          closeDeleteModal={() => setAction(undefined)}
        >
          <div className='alert alert-danger mt-3'>
            All translations for this key will be deleted, regardless of the language.
          </div>
        </DeleteModal>
      )}

      {action?.kind === 'add' && (
        <AddEditLanguageTextModal
          kind='add'
          languages={languages}
          languageText={languageText}
          onChange={(next) => {
            setAction({
              kind: 'add',
              ...next,
            })
          }}
          onClose={() => setAction(undefined)}
          onSubmit={handleAddOrEditKeyClickSubmit}
          value={action}
        />
      )}

      {action?.kind === 'edit' && (
        <AddEditLanguageTextModal
          kind='edit'
          languages={languages}
          languageText={languageText}
          onChange={(next) => {
            setAction({
              kind: 'edit',
              oldKey: action.key,
              ...next,
            })
          }}
          onClose={() => setAction(undefined)}
          onSubmit={handleAddOrEditKeyClickSubmit}
          value={action}
        />
      )}

      {action?.kind === 'export_import' && (
        <ImportExportModal
          name='translations'
          handleClose={() => {
            setAction(undefined)
          }}
          handleExport={() => {
            return exportTranslations(dispatch)
          }}
          handleImport={(data) => {
            return importTranslations(data, dispatch).then((res) => {
              setAction(undefined)
              reloadAndUpdateState(0)
            })
          }}
          lang={languageText}
        />
      )}
    </>
  )
}
