import React, { ReactNode } from 'react'

import {
  IResolveParams,
  LoginSocialGoogle,
  LoginSocialMicrosoft,
  objectType,
} from 'reactjs-social-login'
import { SocialLoginsEnum } from '../../../commonEnums'

interface ISocialLoginsProps {
  provider: string
  children: ReactNode
  onResolve: ({ provider, data }: IResolveParams) => void
  onReject: (reject: string | objectType) => void
}

export const SocialLogins = (props: ISocialLoginsProps): JSX.Element | null => {
  const { provider, children, onResolve, onReject } = props

  switch (provider) {
    case SocialLoginsEnum.Google.toLowerCase():
      return (
        <LoginSocialGoogle
          client_id={process.env.REACT_APP_GG_APP_ID || ''}
          redirect_uri={process.env.REDIRECT_URI}
          scope='openid profile email'
          discoveryDocs='claims_supported'
          access_type='offline'
          onResolve={onResolve}
          onReject={onReject}
        >
          {children}
        </LoginSocialGoogle>
      )
    case SocialLoginsEnum.Microsoft.toLowerCase():
      return (
        <LoginSocialMicrosoft
          client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ''}
          redirect_uri={process.env.REDIRECT_URI || ''}
          isOnlyGetToken={true}
          scope='profile openid email user.read'
          onResolve={onResolve}
          onReject={onReject}
        >
          {children}
        </LoginSocialMicrosoft>
      )
    default:
      return null
  }
}
