import React from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IFacilitatorDashboardAccount } from '../interface'
import { routePath } from '../../routePaths'

interface IFacilitatorDashboardAccountSectionProps {
  languageText: ITranslationObject
  account: IFacilitatorDashboardAccount
}

export const FacilitatorDashboardAccountSection = (
  props: IFacilitatorDashboardAccountSectionProps
): JSX.Element => {
  const { languageText, account } = props
  const { users, profiles, courses } = account

  const navigate = useNavigate()

  let progressBarWidth: number = 0
  if (users) progressBarWidth = 30
  if (profiles) progressBarWidth += 30
  if (courses) progressBarWidth += 30

  const handleProfileClick = (): void => {
    if (!users || profiles) return
    navigate(routePath.addActivity)
  }

  return (
    <div className='col-12 col-md-4'>
      <div className='card h-100 shadow-sm bg-white rounded border-0'>
        <div className='card-header border-0 p-3 fs-5 text-body-tertiary'>
          {getLanguageValue(languageText, 'Account')}
        </div>
        <div className='card-body p-4'>
          <div className='progress'>
            <div
              className='progress-bar bg-success'
              style={{ width: `${progressBarWidth}%` }}
            ></div>
          </div>

          {/* Users */}
          <div
            className={clsx(
              'd-flex mt-3 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm',
              users ? 'bg-white' : ''
            )}
          >
            <div className='small ms-1'>{getLanguageValue(languageText, 'Users')}</div>
            <span className={clsx('border px-2 rounded', users ? 'bg-success text-white' : '')}>
              {users}
            </span>
          </div>

          {/* Profiles */}
          <div
            className={clsx(
              'd-flex mt-1 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm',
              !users ? 'bg-white' : !profiles ? 'bg-white' : '',
              users && !profiles && 'cursor-pointer'
            )}
            onClick={handleProfileClick}
          >
            <div className='small ms-1'>{getLanguageValue(languageText, 'Activities')}</div>
            {!users || profiles ? (
              <span
                className={clsx('border px-2 rounded', profiles ? 'bg-success text-white' : '')}
              >
                {profiles}
              </span>
            ) : (
              <i className='bi bi-arrow-right me-2 text-success' />
            )}
          </div>

          {/* Courses */}
          <div
            className={clsx(
              'd-flex mt-1 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm',
              !profiles ? 'bg-white' : !courses ? '' : ''
            )}
          >
            <div className='small ms-1'>{getLanguageValue(languageText, 'Courses studied')}</div>
            <span className={clsx('border px-2 rounded', courses ? 'bg-success text-white' : '')}>
              {courses}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
