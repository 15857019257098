import React from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IFacilitatorDashboardNews } from '../interface'
import { NewsType } from '../../resources/newsList/types'
import { routePath } from '../../routePaths'

interface IFacilitatorDashboardNewsSectionProps {
  languageText: ITranslationObject
  news: IFacilitatorDashboardNews[]
}

export const FacilitatorDashboardNewsSection = (
  props: IFacilitatorDashboardNewsSectionProps
): JSX.Element => {
  const { languageText, news } = props

  const navigate = useNavigate()

  const handleNavigateNewsBlog = (id: number, type: number): void => {
    if (!id) return
    if (type == NewsType.News) {
      navigate(routePath.latestNewsPage.replace(':id', id.toString()))
    } else if (type == NewsType.Blogs) {
      navigate(routePath.latestBlogPage.replace(':id', id.toString()))
    }
  }

  return (
    <div className='col-12 col-md-4 mb-7 mb-md-0'>
      <div className='card h-100 shadow-sm bg-white rounded border-0'>
        <div className='card-header border-0 p-3 fs-5 text-body-tertiary'>
          {getLanguageValue(languageText, 'News')}
        </div>
        <div className='card-body p-4'>
          {news.length > 0 ? (
            news.map((info, index) => {
              const firstInfo = index === 0
              const lastInfo = index === news.length - 1

              return (
                <div
                  key={info.id}
                  className={clsx(
                    !firstInfo && 'mt-4',
                    !lastInfo && 'border-bottom border-secondary pb-4'
                  )}
                >
                  <span className='badge text-bg-secondary text-white pt-2 mb-2 fs-6'>
                    {info.publishedDate.substring(0, 10)}
                  </span>
                  <span className='badge text-bg-secondary text-white pt-2 ms-2 fs-6'>
                    {NewsType[info.type]}
                  </span>
                  <div
                    className='fs-4 fw-bold cursor-pointer'
                    onClick={() => handleNavigateNewsBlog(info.id, info.type)}
                  >
                    {info.title}
                  </div>
                  <div className='fs-7' dangerouslySetInnerHTML={{ __html: info.excerpt }} />
                </div>
              )
            })
          ) : (
            <div className='color-999'>{getLanguageValue(languageText, 'No latest news')}</div>
          )}
        </div>
      </div>
    </div>
  )
}
