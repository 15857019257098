import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { getFormattedDateOnly } from '../../../../commonUtils/dateFunctionsHelper'
import { IProfileParticipantsCourseStudy } from '../interface'
import { IMarkAsFinish } from '../../../academy/courseSummary/hooks'
import { courseStarted, getUserCourseParts, setCourseDetails } from '../../../academy/actions'
import { routePath } from '../../../routePaths'
import { ICourse } from '../../../academy/types'
import { setNotification } from '../../../actions'
import { RootState } from '@app/store/configureStore'

interface IAcademyStudiesProps {
  languageText: ITranslationObject
  profileCourseStudies: IProfileParticipantsCourseStudy[]
  readOnly?: boolean
}

export const AcademyStudies = (props: IAcademyStudiesProps): JSX.Element => {
  const { languageText, profileCourseStudies, readOnly = false } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  const handleCourseNavigation = async (courseId: number): Promise<void> => {
    const body: IMarkAsFinish = {
      userId: userId,
      courseId: courseId,
    }

    const res: ICourse = await getUserCourseParts(courseId, dispatch)

    if (res && res.courseParts && res.courseParts.length > 0) {
      dispatch(setCourseDetails(res))
      const firstPartId = res.courseParts[0].id

      const courseUrl = routePath.participantCourse
        .replace(':courseId', courseId.toString())
        .replace(':partId', firstPartId.toString())

      const response = await courseStarted(body, dispatch)

      if (response) {
        navigate(courseUrl)
      }
    } else if (res && !res.courseParts?.length) {
      dispatch(setNotification('No course parts available'))
    }
  }

  return (
    <div className='card h-100 shadow-sm bg-white rounded border-0'>
      <div className='card-header border-0 p-3 fs-5 text-body-tertiary'>
        {getLanguageValue(languageText, 'Academy studies')}
      </div>
      <div className='card-body p-4'>
        <div className='overflow-scroll idi-participant-academy-mh'>
          {profileCourseStudies.length > 0 &&
            profileCourseStudies.map((course, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    'p-3 rounded mb-2 me-2 item-align-center',
                    course.done ? 'bg-light' : 'border'
                  )}
                >
                  <div className='progress w-25 float-end'>
                    <div
                      className='progress-bar bg-success-subtle'
                      style={{ width: `${course.progress.toFixed(0)}%` }}
                    />
                  </div>
                  <div
                    className='d-flex justify-content align-items-center'
                    onClick={
                      !readOnly && !course.done
                        ? () => handleCourseNavigation(course.id)
                        : () => handleCourseNavigation(course.id)
                    }
                    role='button'
                  >
                    <div className='small float-end small bg-light p-1 text-secondary rounded me-3'>
                      {getFormattedDateOnly(course.courseDate)}
                    </div>
                    <div
                      className={clsx(
                        '',
                        course.done ? 'text-secondary' : 'text-secondary fw-bold',
                        !readOnly && 'cursor-pointer'
                      )}
                    >
                      <div className=''>
                        {course.done ? (
                          <i className='bi bi-check-lg fs-5 me-2 text-success'></i>
                        ) : (
                          <i className='bi bi-play-fill fs-5 me-2 text-primary'></i>
                        )}{' '}
                        {course.title}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
