import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getLatestNews } from './actions'
import { RootState } from '@app/store/configureStore'
import { IBlogs, IDocuments, ILatestNews, IPublishedNews } from './types'
import { NewsType } from '../newsList/types'
import { routePath } from '../../routePaths'
import { getMediaImageFile, setSpinner } from '../../actions'
import { UserGeneratedMediaType } from '../../commonEnums'

export const useLatestNews = () => {
  const dispatch = useDispatch()
  const isMounted = useRef(false)
  const navigate = useNavigate()
  const pageLimit = 10

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [totalCount, setTotalCount] = useState<number>(0)
  const [skipCount, setSkipCount] = useState<number>(0)
  const [latestNews, setLatestNews] = useState<ILatestNews[] | IBlogs[] | IDocuments[]>([])
  const [newsType, setNewsType] = useState<number>(0)
  const [isLastData, setIsLastData] = useState<boolean>(false)

  //useeffects for main news list page
  useEffect(() => {
    if (location.pathname === routePath.latestNews) {
      setNewsType(NewsType.News)
    } else if (location.pathname === routePath.latestBlogs) {
      setNewsType(NewsType.Blogs)
    }
  }, [location.pathname])

  const getAllNews = async (skipCount: number): Promise<void> => {
    if (!newsType) return
    dispatch(setSpinner(true))
    getLatestNews(pageLimit, skipCount, newsType, dispatch)
      .then((fetchedNews: IPublishedNews) => {
        if (fetchedNews) {
          const updatedNewsItems = fetchedNews.items.map(async (newsItem) => {
            const fetchedImages = await getMediaImageFile(
              newsItem.id,
              UserGeneratedMediaType.News,
              dispatch
            )
            return { ...newsItem, image: fetchedImages }
          })

          Promise.all(updatedNewsItems).then((updatedNews) => {
            setLatestNews((prevNews) => [...prevNews, ...updatedNews])
          })

          setTotalCount(fetchedNews.totalCount)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    if (!newsType) return
    getAllNews(0)
  }, [newsType])

  useEffect(() => {
    if (totalCount <= pageLimit) {
      //if item is only 1 record then to not show the load more options
      setIsLastData(true)
    } else if (skipCount > 0 && skipCount >= totalCount - pageLimit) {
      //for more records after pressing load options
      setIsLastData(true)
      getAllNews(skipCount)
    } else if (skipCount === 0) {
      //on the page load call the api
      setIsLastData(false)
    } else {
      setIsLastData(false)
      getAllNews(skipCount)
    }
    if (!isMounted.current) isMounted.current = true
  }, [skipCount, totalCount])

  const handleLoadMore = () => {
    setSkipCount(skipCount + pageLimit)
  }

  const handleNavigateNewsBlog = (id: string) => {
    if (!id) return
    if (newsType == NewsType.News) {
      navigate(routePath.latestNewsPage.replace(':id', id))
    } else if (newsType == NewsType.Blogs) {
      navigate(routePath.latestBlogPage.replace(':id', id))
    }
  }

  const handleNavigateBack = () => {
    navigate(routePath.information)
  }

  return {
    languageText,
    isLastData,
    latestNews,
    newsType,
    handleLoadMore,
    handleNavigateNewsBlog,
    handleNavigateBack,
  }
}
