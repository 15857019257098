import React from 'react'

import { useResetPassword } from './hooks'
import { Input } from '../../components/formComponents/input'
import { ValidationBox } from '../../components/newPasswordInputBox/validationBox'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { CustomModal } from '../../components/modals/customModal'
import { AuthLayout } from '../auth/signUp/layout'

export const ResetPasswordPage = () => {
  const {
    UserInputs,
    userInfo,
    focusInput,
    isSubmitting,
    languageText,
    handleBlurEvent,
    handleInputChange,
    handleSubmit,
    showSuccessModal,
    handleRedirection,
  } = useResetPassword()

  const passwordRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    passwordRef.current?.focus()
  }, [])

  return (
    <>
      <AuthLayout page='login' language={languageText} hideLanguageSelection={true}>
        <h3>{getLanguageValue(languageText, 'Change password')}</h3>

        <form onSubmit={handleSubmit}>
          <div className='my-4'>
            <Input
              name={UserInputs.newPassword}
              label={getLanguageValue(languageText, 'New Password')}
              placeholder={getLanguageValue(languageText, 'New Password')}
              value={userInfo.newPassword}
              focusInput={focusInput.newPassword}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              required
              type='password'
              inputRef={passwordRef}
            />
            <ValidationBox value={userInfo.newPassword} languageText={languageText} />
            <Input
              name={UserInputs.confirmNewPassword}
              label={getLanguageValue(languageText, 'Confirm New Password')}
              placeholder={getLanguageValue(languageText, 'Confirm New Password')}
              value={userInfo.confirmNewPassword}
              focusInput={focusInput.confirmNewPassword}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              type='password'
              required
            />
          </div>
          <button className='btn btn-success' disabled={isSubmitting} type='submit'>
            <span className='indicator-label'>{getLanguageValue(languageText, 'Reset')}</span>
          </button>
        </form>
      </AuthLayout>

      {showSuccessModal && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Password reset successful')}
          bodyText={`${getLanguageValue(languageText, 'You will be redirected to login page')}`}
          handleCancelClick={handleRedirection}
          handleSubmitClick={handleRedirection}
          submitButtonText={getLanguageValue(languageText, 'Ok')}
        />
      )}
    </>
  )
}
