import React, { useState } from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { ProfileStatus } from '../../../profileEnums'
import { publishProfile, unPublishProfile, transferProfile } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { addToast } from '@app/containers/actions'
import { AnyAction } from 'redux'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { Input, IFocusError } from '@app/components/formComponents/input'
import { ProfileId, UserId } from '@app/containers/reducer'

interface IProfileParticipantAccountProps {
  languageText: ITranslationObject
  status: number
  profileId: ProfileId
  pdfProfileDownloaded?: boolean
  isDownloaded: boolean
  closeModal: () => void
  handleUnPublishProfile?: () => void
  handlePublishProfile?: (profileId: ProfileId) => void
  handleTransferProfile?: () => void
}

export const ProfileParticipantAccount = (props: IProfileParticipantAccountProps): JSX.Element => {
  const {
    languageText,
    status,
    isDownloaded,
    profileId,
    pdfProfileDownloaded,
    closeModal,
    handlePublishProfile,
    handleUnPublishProfile,
    handleTransferProfile,
  } = props

  const dispatch = useDispatch()
  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)

  const [transferUserId, setTransferUserId] = useState<UserId>(0 as UserId)
  const [showTransferElements, setShowTransferElements] = useState<boolean>(false)
  const [focusInput, setFocusInput] = useState<IFocusError>({
    touched: false,
    errorMessage: '',
  })

  const handlePublishClick = (): void => {
    if (pdfProfileDownloaded || isDownloaded) {
      publishProfile(profileId, dispatch).then((response) => {
        if (response) {
          dispatch(addToast('Profile published') as AnyAction)
          closeModal()
          handlePublishProfile && handlePublishProfile(profileId)
        }
      })
    }
  }

  const handleUnPublishClick = (): void => {
    unPublishProfile(profileId, dispatch).then((response) => {
      if (response) {
        dispatch(addToast('Profile un-published') as AnyAction)
        handleUnPublishProfile && handleUnPublishProfile()
        closeModal()
      }
    })
  }

  const handleTransferClick = (): void => {
    if (transferUserId > 0) {
      transferProfile(profileId, transferUserId, dispatch).then((response) => {
        if (response) {
          dispatch(addToast('Profile transferred') as AnyAction)
          handleTransferProfile && handleTransferProfile()
          closeModal()
        }
      })
    }
  }

  const onTransferUserChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    let errorMessage: string = ''
    setTransferUserId(Number(value) as UserId)

    if (e.target.value === '' || Number(e.target.value) <= 0) {
      errorMessage = getLanguageValue(languageText, 'UserId required')
    }

    setFocusInput((prev) => ({
      ...prev,
      touched: true,
      errorMessage: errorMessage,
    }))
  }

  return (
    <div id='editParticipantPublish'>
      <div className='fs-5 fw-bold mb-2'>{getLanguageValue(languageText, 'IDI-account')}</div>

      {(status === ProfileStatus.New || status === ProfileStatus.Active) &&
        getLanguageValue(languageText, 'Not ready')}

      {(status === ProfileStatus.Completed || status === ProfileStatus.DeliveryPlanned) && (
        <div className='d-flex align-items-center gap-2'>
          <span
            className='cursor-pointer'
            onClick={() => {
              if (pdfProfileDownloaded || isDownloaded) {
                handlePublishClick()
              }
            }}
          >
            <div className='border p-3 rounded'></div>
          </span>

          <div>
            <span>
              {getLanguageValue(languageText, 'Status')}:
              {getLanguageValue(languageText, 'Not published')}.
            </span>
            {''}
            {(isLoggedInRoleAdmin(loggedInUserRole) || pdfProfileDownloaded) && (
              <span
                className={`${
                  pdfProfileDownloaded || isDownloaded
                    ? 'text-decoration-underline cursor-pointer'
                    : ''
                }`}
                onClick={handlePublishClick}
              >
                ({getLanguageValue(languageText, 'Publish IDI-profile to participants IDI-Account')}
                )
              </span>
            )}
          </div>
        </div>
      )}

      {status === ProfileStatus.Delivery && (
        <div className='d-flex align-items-center gap-2'>
          <div className='btn btn-success' onClick={handleUnPublishClick}>
            <i className='bi bi-check-lg'></i>
          </div>
          <div className='w-225px'>
            <span>
              {getLanguageValue(languageText, 'Status')}:
              {getLanguageValue(languageText, 'Published')}.
            </span>
            {isLoggedInRoleAdmin(loggedInUserRole) && (
              <span
                className='text-decoration-underline cursor-pointer'
                onClick={handleUnPublishClick}
              >
                ({getLanguageValue(languageText, 'Unpublish IDI-profile')}).
              </span>
            )}
          </div>
        </div>
      )}

      <div>
        {isLoggedInRoleAdmin(loggedInUserRole) && (
          <div className='d-flex gap-2 pt-2'>
            <span
              className='text-decoration-underline cursor-pointer'
              onClick={() => {
                setShowTransferElements(!showTransferElements)
              }}
            >
              {getLanguageValue(languageText, 'Transfer profile to another user')}
            </span>
            {showTransferElements && (
              <div className='d-flex gap-2'>
                <Input
                  type='number'
                  label={getLanguageValue(languageText, 'Enter User Id')}
                  placeholder={getLanguageValue(languageText, 'User Id')}
                  focusInput={focusInput}
                  value={transferUserId}
                  handleInputChange={onTransferUserChange}
                />
                <div className='btn btn-danger mt-4 mb-3' onClick={handleTransferClick}>
                  {getLanguageValue(languageText, 'Transfer')}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
