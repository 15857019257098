import React from 'react'

import { MyProfiles } from './myProfilesComponents/myProfiles'
import { ParticipantProfileGraph } from '../participantDashboard/dashboardComponents/participantProfileGraph'
import { IMyProfiles, useMyIDIProfiles } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { CreateNewProfile } from '../participantDashboard/dashboardComponents/createNewProfileModal'
import { IProfileGroupReport } from '../../profileList/editProfile/interface'
import { emptyProfileGroupReport } from '../../utils'
import { ProfileStatus } from '@app/containers/profileList/profileEnums'
import { useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { AdaptabilityGraph } from '@app/components/graphs/AdaptabilityGraph'
import { ProfileId } from '@app/containers/reducer'

export const ParticipantMyIDIProfiles = (): JSX.Element => {
  const { languageText, myProfilesDetails } = useMyIDIProfiles()

  //Temporary fix for adjusting the response from the backend
  const profileGroupReport: IProfileGroupReport = emptyProfileGroupReport()
  profileGroupReport.otherGroupReport.groupReportAdaptabilities =
    myProfilesDetails.profilesOtherFormsAdaptabilites

  const allProfilesDelivered = myProfilesDetails.profiles.every(
    (profile) => profile.status === ProfileStatus.Delivery
  )

  const userDetails = useSelector((state: RootState) => state.loginReducer.userDetails)
  const [selectedProfileId, setSelectedProfileId] = React.useState<ProfileId | undefined>(undefined)

  return (
    <>
      <div className='mt-3 p-2 p-md-4'>
        <h3 className='mt-2 pb-4 fw-bold'>{getLanguageValue(languageText, 'My Profiles')}</h3>
        {allProfilesDelivered && userDetails.enableParticipantCreateProfile && (
          <CreateNewProfile languageText={languageText} readOnly={false} />
        )}
        <div className='row shadow-sm bg-white rounded mx-0'>
          <div className='col-md-6'>
            <div className='p-2 p-md-4 mt-3'>
              <MyProfiles
                languageText={languageText}
                profiles={myProfilesDetails.profiles}
                selectedProfileId={selectedProfileId}
                setSelectedProfileId={(id) => {
                  setSelectedProfileId(id)
                }}
              />
            </div>
          </div>
          <div className='col-md-6 border-start'>
            <div className='ps-4 pe-2 m-md-4 p-md-4'>
              <ParticipantProfileGraph
                languageText={languageText}
                profiles={myProfilesDetails.profiles}
                selectedProfileId={selectedProfileId}
                setSelectedProfileId={(id) => {
                  setSelectedProfileId(id)
                }}
              />
              <div className='pb-4 pe-3 p-md-0'>
                <AdaptabilityGraph
                  profiles={myProfilesDetails.profiles}
                  languageText={languageText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
