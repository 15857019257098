import React from 'react'

import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IChangePasswordProps, PasswordInfo, useChangePassword } from './hooks'
import { Input } from '../../../components/formComponents/input'
import { ValidationBox } from '../../../components/newPasswordInputBox/validationBox'

export const ChangePassword = (props: IChangePasswordProps): JSX.Element => {
  const {
    focusInput,
    userPassword,
    languageText,
    submitButtonDisabled,
    handleBlurEvent,
    handleInputChange,
    onSubmitClick,
  } = useChangePassword(props)

  return (
    <>
      <div className='rounded border my-3'>
        <div className='p-3 p-md-4'>
          <div className='mb-3'>
            <Input
              name={PasswordInfo.currentPassword}
              handleInputChange={handleInputChange}
              handleBlurEvent={handleBlurEvent}
              placeholder=''
              focusInput={focusInput.currentPassword}
              label={getLanguageValue(languageText, 'Current password')}
              value={userPassword.currentPassword}
              type='password'
            />
          </div>
          <div className='row'>
            <div className='col-xl-6 col-12 mb-3'>
              <Input
                name={PasswordInfo.password}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
                focusInput={focusInput.password}
                value={userPassword.password}
                label={getLanguageValue(languageText, 'New password')}
                tooltipText={getLanguageValue(
                  languageText,
                  'Password must be at least 8 characters include at least one non alphanumeric character at least one digit and at least one uppercase symbol'
                )}
                type='password'
              />
            </div>
            <div className='col-xl-6 col-12'>
              <Input
                name={PasswordInfo.confirmPassword}
                handleInputChange={handleInputChange}
                handleBlurEvent={handleBlurEvent}
                value={userPassword.confirmPassword}
                focusInput={focusInput.confirmPassword}
                label={getLanguageValue(languageText, 'Confirm new password')}
                type='password'
              />
            </div>
          </div>

          <ValidationBox value={userPassword.password} languageText={languageText} />
        </div>
        <div className='bg-dark bg-opacity-10 p-3 p-md-4'>
          <button
            type='submit'
            className='btn btn-success mx-8 my-6'
            data-kt-users-modal-action='submit'
            onClick={onSubmitClick}
            disabled={submitButtonDisabled ?? false}
          >
            <span className='indicator-label'>
              {getLanguageValue(languageText, 'Update password')}
            </span>
          </button>
        </div>
      </div>
    </>
  )
}
