import clsx from 'clsx'
import React from 'react'

export interface ITabNavItem {
  id: string
  title: string
}

interface ITabNavItemProps {
  navTabs: Array<ITabNavItem>
  className?: string
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
}

export const TabNavItem = (props: ITabNavItemProps) => {
  const { navTabs, className, activeTab, setActiveTab } = props

  return (
    <ul className='nav nav-tabs mb-3'>
      {navTabs.map((tab) => (
        <li
          key={tab.id}
          className={clsx('nav-item', className && className)}
          onClick={() => setActiveTab(tab.id)}
        >
          <button
            className={clsx('nav-link', {
              active: activeTab === tab.id,
            })}
            data-bs-toggle='tab'
          >
            {tab.title}
          </button>
        </li>
      ))}
    </ul>
  )
}
