import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IFocusError } from '../../../components/formComponents/input'
import { addToast, clearNotifications, setSpinner } from '../../actions'
import { ValidationHelper } from '../../validationHelper'
import { changePassword } from './actions'
import { AnyAction } from 'redux'

interface IUserPassword {
  password: string
  confirmPassword: string
  currentPassword: string
}

interface IFocusInput {
  password: IFocusError
  confirmPassword: IFocusError
  currentPassword: IFocusError
}

export enum PasswordInfo {
  password = 'password',
  confirmPassword = 'confirmPassword',
  currentPassword = 'currentPassword',
}

export interface IChangePasswordProps {
  languageText: ITranslationObject
  cancelButtonDisabled?: boolean
  submitButtonDisabled?: boolean
}

export const useChangePassword = (props: IChangePasswordProps) => {
  const { languageText, cancelButtonDisabled, submitButtonDisabled } = props

  const dispatch = useDispatch()

  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false)
  const [showBar, setShowBar] = useState<boolean>(false)
  const [userPassword, setUserPassword] = useState<IUserPassword>({
    password: '',
    confirmPassword: '',
    currentPassword: '',
  })
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    password: {
      touched: false,
      errorMessage: '',
    },
    confirmPassword: {
      touched: false,
      errorMessage: '',
    },
    currentPassword: {
      touched: false,
      errorMessage: '',
    },
  })

  const handleChangePasswordModal = (e: { preventDefault: () => void }): void => {
    e.preventDefault()
    setOpenChangePasswordModal(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setUserPassword({
      ...userPassword,
      [name]: value,
    })

    if (focusInput[name as keyof typeof focusInput]?.touched) {
      handleFormErrors(name, value)
    }
    if (name === 'password') {
      setShowBar(true)
    }
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    switch (name) {
      case PasswordInfo.currentPassword:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Password is required')
        }
        break
      case PasswordInfo.password:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Password is required')
        } else if (value) {
          const isValid = ValidationHelper.isPasswordValid(value)
          if (!isValid)
            errorMessage = getLanguageValue(languageText, 'Please follow the instruction')
        }
        break
      case PasswordInfo.confirmPassword:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Confirm password is required')
        } else if (value !== userPassword.password) {
          errorMessage = getLanguageValue(languageText, 'Confirm Password does not match')
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !userPassword.currentPassword ||
      focusInput.currentPassword.errorMessage ||
      !userPassword.password ||
      focusInput.password.errorMessage ||
      !userPassword.confirmPassword ||
      focusInput.confirmPassword.errorMessage ||
      (userPassword.password && focusInput.password.errorMessage) ||
      (userPassword.confirmPassword && focusInput.confirmPassword.errorMessage) ||
      (userPassword.password && userPassword.confirmPassword !== userPassword.password)
    ) {
      for (const item in PasswordInfo) {
        handleFormErrors(item, userPassword[item as keyof typeof userPassword])
      }
      return false
    }
    return true
  }

  const handleSubmit = (): void => {
    dispatch(setSpinner(true))
    dispatch(clearNotifications(''))
    const currentPassword = userPassword.currentPassword
    const newPassword = userPassword.password

    changePassword(dispatch, currentPassword, newPassword)
      .then((response) => {
        if (response?.success) {
          dispatch(addToast('Password saved successfully') as AnyAction)
        } else {
          return
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  const onSubmitClick = (): void => {
    if (!handleValidationOnSubmit()) return
    handleSubmit()
  }

  return {
    focusInput,
    userPassword,
    openChangePasswordModal,
    languageText,
    cancelButtonDisabled,
    submitButtonDisabled,
    showBar,
    handleChangePasswordModal,
    setOpenChangePasswordModal,
    handleBlurEvent,
    handleInputChange,
    onSubmitClick,
  }
}
