import React from 'react'

import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { DropdownSelect, IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { DateLimit, PermissionType } from '../../../../commonUtils/coursePermissionHelper'
import { ReactDatePicker } from '../../../../components/datePicker/ReactDatePicker'
import { DynamicSearchInputField } from '../../../../components/formComponents/dynamicSearchInputField'
import AddImage from '../../../../../public/DeliveryImages/AddIcon.svg'
import DeleteImage from '../../../../../public/DeliveryImages/deleteIcon.svg'
import { CoursePermission, useCoursePermissionModal } from './hooks'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface ICoursePermissionPropType {
  courseId: number
  courseTitle: string
  closeModal: () => void
}

export const CoursePermissionModal: React.FC<ICoursePermissionPropType> = (props) => {
  const { courseTitle, closeModal } = props
  const {
    languageText,
    accessList,
    PermissionTypeList,
    dateLimitList,
    coursePermissionInfo,
    suggestions,
    allCoursePermissionInfo,
    editingIndex,
    selectedUser,
    errorMessage,
    isError,
    handleUnSelectAll,
    handleUnSelect,
    handleAllDateSelect,
    handleDateSelect,
    updatePermission,
    deleteCoursePermission,
    handlePermissionAllIdClick,
    handleAllPermissionSelect,
    handlePermissionIdClick,
    handlePermissionUserInputChange,
    handlePermissionAccountInputChange,
    handlePermissionSelect,
    onSubmitPermission,
  } = useCoursePermissionModal(props)

  const truncatedText = courseTitle.length > 25 ? `${courseTitle.slice(0, 25)}...` : courseTitle

  return (
    <>
      <ModalComponent
        headerText={`${getLanguageValue(
          languageText,
          'Academy Course Permission Settings'
        )}: ${truncatedText}`}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={closeModal}
        width='xl'
      >
        <form>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-12 row'>
              <div className='col-lg-4 col-xl-3 col-12'>
                <DropdownSelect
                  name={CoursePermission.access}
                  label={getLanguageValue(languageText, 'Access')}
                  defaultLabel={getLanguageValue(languageText, 'Select')}
                  list={accessList}
                  value={coursePermissionInfo.access}
                  handleDropdownSelect={handlePermissionSelect}
                  required
                />
              </div>
              <div className='col-lg-4 col-xl-4 col-12'>
                <DropdownSelect
                  name={CoursePermission.permissionType}
                  label={getLanguageValue(languageText, 'Type')}
                  defaultLabel={getLanguageValue(languageText, 'Select')}
                  list={PermissionTypeList}
                  value={coursePermissionInfo.permissionType}
                  handleDropdownSelect={handlePermissionSelect}
                  required
                />
              </div>
              <div className='col-lg-4 col-xl-5 col-12'>
                <DynamicSearchInputField
                  languageText={languageText}
                  label={getLanguageValue(languageText, 'Name')}
                  placeholder={getLanguageValue(languageText, 'Search')}
                  list={suggestions}
                  selectedValue={selectedUser}
                  disable={!coursePermissionInfo.permissionType}
                  fetchList={
                    coursePermissionInfo.permissionType &&
                    coursePermissionInfo.permissionType === String(PermissionType.User)
                      ? handlePermissionUserInputChange
                      : handlePermissionAccountInputChange
                  }
                  handleSelect={handlePermissionIdClick}
                  handleUnSelect={handleUnSelect}
                  required
                />
              </div>
            </div>

            <div className='col-lg-5 col-12 row'>
              <div className='col-lg-4 col-12'>
                <DropdownSelect
                  name={CoursePermission.dateLimit}
                  label={getLanguageValue(languageText, 'Date limit')}
                  defaultLabel={getLanguageValue(languageText, 'Select')}
                  list={dateLimitList}
                  value={coursePermissionInfo.dateLimit}
                  handleDropdownSelect={handlePermissionSelect}
                  required
                />
              </div>

              {Number(coursePermissionInfo.dateLimit) === DateLimit.Yes && (
                <div className='d-flex gap-4 col-lg-8 col-12 pe-6 pe-lg-0'>
                  <ReactDatePicker
                    label='Start Date'
                    className='col-6'
                    placeholder={getLanguageValue(languageText, 'Select Date')}
                    name={CoursePermission.fromDate}
                    date={coursePermissionInfo.fromDate}
                    maxDate={new Date(String(coursePermissionInfo.toDate))}
                    handleDateSelect={handleDateSelect}
                  />

                  <ReactDatePicker
                    label='End Date'
                    className='col-6'
                    placeholder={getLanguageValue(languageText, 'Select Date')}
                    name={CoursePermission.toDate}
                    date={coursePermissionInfo.toDate}
                    minDate={new Date(String(coursePermissionInfo.fromDate))}
                    handleDateSelect={handleDateSelect}
                  />
                </div>
              )}
            </div>

            <div
              className='col-lg-1 col-12 d-flex justify-content-center ms-auto'
              onClick={onSubmitPermission}
            >
              <img src={AddImage} className='cursor-pointer' />
            </div>
          </div>
          <div className='text-danger'>{errorMessage}</div>
        </form>

        <div className='border-top border-bottom mb-6 mt-6' />
        {allCoursePermissionInfo?.length > 0 ? (
          <>
            {allCoursePermissionInfo.map((coursePermission, i) => {
              const selectedId: IDropdownList = {
                id: i,
                displayName:
                  String(coursePermission.permissionType) === String(PermissionType.User)
                    ? coursePermission.userName
                      ? String(coursePermission.userName)
                      : ''
                    : coursePermission.clientName
                      ? String(coursePermission.clientName)
                      : '',
                value:
                  String(coursePermission.permissionType) === String(PermissionType.User)
                    ? String(coursePermission.userId)
                    : String(coursePermission.clientId),
              }

              return (
                <div className='row mb-6' key={i}>
                  <div className='col-lg-6 col-12 row'>
                    <div className='col-lg-4 col-xl-3 col-12'>
                      <DropdownSelect
                        name={CoursePermission.access}
                        list={accessList}
                        value={String(coursePermission.access)}
                        handleDropdownSelect={(selectedItem) =>
                          handleAllPermissionSelect(selectedItem, i)
                        }
                      />
                    </div>
                    <div className='col-lg-4 col-xl-4 col-12'>
                      <DropdownSelect
                        name={CoursePermission.permissionType}
                        list={PermissionTypeList}
                        value={String(coursePermission.permissionType)}
                        handleDropdownSelect={(selectedItem) =>
                          handleAllPermissionSelect(selectedItem, i)
                        }
                      />
                    </div>
                    <div className='col-lg-4 col-xl-5 col-12'>
                      <DynamicSearchInputField
                        languageText={languageText}
                        placeholder={getLanguageValue(languageText, 'Search')}
                        list={suggestions}
                        selectedValue={selectedId}
                        fetchList={
                          String(coursePermission.permissionType) === String(PermissionType.User)
                            ? handlePermissionUserInputChange
                            : handlePermissionAccountInputChange
                        }
                        handleSelect={(selectedItem) => handlePermissionAllIdClick(selectedItem, i)}
                        disable={!editingIndex.includes(i)}
                        handleUnSelect={() => handleUnSelectAll(i)}
                        required
                      />
                    </div>
                  </div>

                  <div className='col-lg-5 col-12 row'>
                    <div className='col-lg-4 col-12'>
                      <DropdownSelect
                        name={CoursePermission.dateLimit}
                        list={dateLimitList}
                        value={
                          String(coursePermission.dateLimit) === 'true' ||
                          String(coursePermission.dateLimit) === String(DateLimit.Yes)
                            ? String(DateLimit.Yes)
                            : String(DateLimit.No)
                        }
                        handleDropdownSelect={(selectedItem) =>
                          handleAllPermissionSelect(selectedItem, i)
                        }
                      />
                    </div>
                    {(String(coursePermission.dateLimit) === 'true' ||
                      String(coursePermission.dateLimit) === String(DateLimit.Yes)) && (
                      <div className='d-flex gap-4 col-lg-8 col-12 pe-6 pe-lg-0'>
                        <ReactDatePicker
                          name={CoursePermission.fromDate}
                          className='col-6'
                          date={
                            coursePermission.fromDate
                              ? new Date(String(coursePermission.fromDate))
                              : null
                          }
                          maxDate={new Date(String(coursePermission.toDate))}
                          handleDateSelect={(name: string, date: Date) =>
                            handleAllDateSelect(name, date, i)
                          }
                        />
                        <ReactDatePicker
                          name={CoursePermission.toDate}
                          className='col-6'
                          date={
                            coursePermission.toDate
                              ? new Date(String(coursePermission.toDate))
                              : null
                          }
                          minDate={new Date(String(coursePermission.fromDate))}
                          handleDateSelect={(name: string, date: Date) =>
                            handleAllDateSelect(name, date, i)
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className='col-lg-1 col-12 d-flex h-50 justify-content-center ms-auto'>
                    {editingIndex.includes(i) ? (
                      <div onClick={() => updatePermission(i)}>
                        <img src={AddImage} className='cursor-pointer' />
                      </div>
                    ) : (
                      <img
                        src={DeleteImage}
                        className='cursor-pointer'
                        onClick={() => deleteCoursePermission(Number(coursePermission.id))}
                      />
                    )}
                  </div>
                  {isError[i] && editingIndex.includes(i) && (
                    <div className='text-danger '>{isError[i]}</div>
                  )}
                </div>
              )
            })}
          </>
        ) : (
          <span className='d-flex justify-content-center'>
            {getLanguageValue(languageText, 'No data avaliable')}
          </span>
        )}
      </ModalComponent>
    </>
  )
}
