import React from 'react'

import { useSelfForm } from './hooks'
import {
  FormQuestions,
  IFormFilledDataKindOfButNotReally,
  ProfileFormType,
  SelfForm,
} from '../../../../components/selfForm/selfForm'
import { Instruction } from '../../../../components/instruction/instruction'
import { GetTypeOfRoleName, ProfileStatus } from '../../../profileList/profileEnums'
import { GuidePages } from '@app/containers/commonEnums'
import { Step, StepComponent } from '../../createProfilePage'
import { CompletedStepPanel, CurrentStepPanel, UpcomingStepPanel } from '../../panels'

export const ParticipantSelfFormPage: StepComponent = (props) => {
  const { languageText, instructionSteps, uniqueCode, userLanguage, participantProfileDetails } =
    useSelfForm()

  // TODO: maybe move this state up to the root? hm...
  const [questions, setQuestions] = React.useState<
    ReadonlyArray<IFormFilledDataKindOfButNotReally>
  >([])

  React.useEffect(() => {
    if (props.status !== 'current') {
      return
    }

    props.api
      .getParticipantOrRespondentWords({
        profileId: props.profile.profileId,

        // TODO: respondent support!
        profileRespondentId: 0,
        languageCode: props.languageCode,
      })
      .then(setQuestions)
    //needs to depend on languageCode as well for the case when user changes
    //while filling in the self form - Joakim 241031
  }, [props.status, props.languageCode])

  function setQuestionValue(wordPairId: number, value: number) {
    const index = questions.findIndex((x) => x.id === wordPairId)
    const next = questions.slice()
    next[index].value = value
    setQuestions(next)
  }

  const __t = props.__t
  const title = __t('Self assessment')
  const isFormCompleted =
    questions.length > 0 && questions.every((q) => q.value !== null && q.value !== 0)

  switch (props.status) {
    case 'upcoming':
      return <UpcomingStepPanel title={title} />
    case 'current':
      return (
        <CurrentStepPanel
          __t={__t}
          title={
            <div className='d-flex'>
              <div className='flex-grow-1'>{title}</div>
              <div className='flex-grow-0'>
                <Instruction
                  targetElement='instruction4'
                  guidePage={GuidePages.Participant_SelfForm}
                  instructionSteps={instructionSteps}
                  /* WHAT IS THE '5'? */
                  stepsNotToSkip={[5]}
                />
              </div>
            </div>
          }
          nextDisabled={!isFormCompleted}
          onNextClick={(event) => {
            event.preventDefault()

            props.api
              .fillParticipantProfileSelfForm({
                profileId: props.profile.profileId,
                languageCode: props.languageCode,
              })
              .then(() => {
                props.setStep(Step.RespondentStatus)
              })
          }}
        >
          <div className='p-4'>
            <p>
              {__t(
                'Fill in the form according to how you think your respondents perceive you in your role'
              )}
            </p>
            <FormQuestions
              questions={questions}
              onAnswered={(wordPairId, value) => {
                setQuestionValue(wordPairId, value)

                props.api
                  .updateParticipantFormFilledDataAnswer({
                    profileId: props.profile.profileId,
                    profileRespondentId: 0,
                    formType: ProfileFormType.Self,
                    wordPairId: wordPairId,
                    value: value,
                  })
                  .catch((err) => {
                    setQuestionValue(wordPairId, 0)
                    return Promise.reject(err)
                  })
              }}
              isError={false}
            />
          </div>
        </CurrentStepPanel>
      )
    case 'completed': {
      const shouldShowStep =
        props.profile.status === ProfileStatus.Unknown ||
        props.profile.status === ProfileStatus.New ||
        props.profile.status === ProfileStatus.Active

      if (!shouldShowStep) {
        return null
      }

      return <CompletedStepPanel title={title} />
    }
  }
}
