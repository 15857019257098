import { Dispatch } from 'redux'
import { api, apiEndPoints } from '../../../api'
import { setNotification } from '../../actions'
import { ICreateOrUpdateLanguageBody } from './hooks'

export const createOrUpdateLanguage = async (
  body: ICreateOrUpdateLanguageBody,
  dispatch: Dispatch
): Promise<number> => {
  try {
    const response = await api.post(apiEndPoints.createOrUpdateLanguage, JSON.stringify(body), {})
    return response.status
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getAllLanguageCultures = async (dispatch: Dispatch): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getAllLanguageCultures)
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
