import * as React from 'react'

import { PhoneNumberInput } from '../../../employeeList/addEmployeeModal/phoneNumberInput'
import { Input, IFocusError } from '../../../../components/formComponents/input'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { E164Number } from 'libphonenumber-js/types'
import { InstructionLanguageSelector } from './instructionLanguageSelector'
import { ILanguage } from '@app/containers/commonInterfaces'

export enum ManualRespondentInputs {
  name = 'name',
  emailAddress = 'emailAddress',
  phoneNumber = 'phoneNumber',
  nameError = 'nameError',
  emailError = 'emailError',
  phoneNumberError = 'phoneNumberError',
}

export interface IManualRespondentInfo {
  /** FIXME: this is an ID of unknown type. */
  id: number
  name: string
  emailAddress: string
  phoneNumber: string
  nameError: IFocusError
  emailError: IFocusError
  phoneNumberError: IFocusError
  instructionLanguageId: number | null
}

interface IAddManuallyProps {
  languageText: ITranslationObject
  languages: Array<ILanguage>
  respondentInfo: IManualRespondentInfo[]
  handleChange: (next: IManualRespondentInfo, index: number) => unknown

  /* How important can the blur event really be? Are we confusing ourselves again and doing 'onChange' stuff during blur? */
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void
  onDeleteRowClick: (index: number) => void
  onAddRowClick: () => void
}

export const AddManually: React.FC<IAddManuallyProps> = (props) => {
  const [changeInstructionLanguageIndex, setChangeInstructionLanguageIndex] = React.useState<
    number | undefined
  >(undefined)

  return (
    <div>
      {props.respondentInfo.map((respondent, index) => (
        <div key={index} className='add-manually-row'>
          <div className='row justify-content-center'>
            <div className='col-md-4'>
              <Input
                id={`${ManualRespondentInputs.name}${respondent.id}`}
                name={ManualRespondentInputs.name}
                placeholder={getLanguageValue(props.languageText, 'Name')}
                value={respondent.name}
                focusInput={respondent.nameError}
                handleBlurEvent={props.handleBlurEvent}
                handleInputChange={(event) => {
                  props.handleChange(
                    {
                      ...respondent,
                      name: event.target.value,
                    },
                    index
                  )
                }}
                required
              />
            </div>
            <div className='col-md-3'>
              <Input
                id={`${ManualRespondentInputs.emailAddress}${respondent.id}`}
                name={ManualRespondentInputs.emailAddress}
                placeholder={getLanguageValue(props.languageText, 'Email')}
                value={respondent.emailAddress}
                focusInput={respondent.emailError}
                handleBlurEvent={props.handleBlurEvent}
                handleInputChange={(event) => {
                  props.handleChange(
                    {
                      ...respondent,
                      emailAddress: event.target.value,
                    },
                    index
                  )
                }}
                required
              />
            </div>
            <div className='col-md-5 d-flex align-items-start'>
              <div className='flex-grow-1'>
                <PhoneNumberInput
                  value={(respondent.phoneNumber as E164Number) || ''}
                  onChange={(value) => {
                    props.handleChange(
                      {
                        ...respondent,
                        phoneNumber: value,
                      },
                      index
                    )
                  }}
                  languageText={props.languageText}
                  placeholder={getLanguageValue(props.languageText, 'Mobile number')}
                />
              </div>
              <button
                className='btn btn-light border-secondary-subtle ms-2'
                onClick={() => {
                  setChangeInstructionLanguageIndex(index)
                }}
                title={getLanguageValue(props.languageText, 'Select the instruction language')}
              >
                <i className='bi bi-globe'></i>
              </button>
              <button
                className='btn btn-light border-secondary-subtle ms-2'
                onClick={() => props.onDeleteRowClick(index)}
              >
                <i className='bi bi-trash3'></i>
              </button>
            </div>
            <div className='my-4 mt-md-2 mb-md-3 d-md-none border-top'></div>
            <div className='my-2 d-md-block d-none'></div>
          </div>
        </div>
      ))}
      {/* Uhm... why is there a hidden button here? Wtf? */}
      <div className='d-flex justify-content-center border-bottom mb-4 pb-4 pb-md-3 d-none'>
        <div
          className='d-flex gap-2 justify-content-center align-items-center'
          onClick={props.onAddRowClick}
          role='button'
        >
          <i className='bi bi-plus-lg text-dark fs-3'></i>
          <p className='my-auto'>{getLanguageValue(props.languageText, 'Add Field')}</p>
        </div>
      </div>

      {typeof changeInstructionLanguageIndex === 'number' && (
        <InstructionLanguageSelector
          languageText={props.languageText}
          languages={props.languages}
          onChange={(next) => props.handleChange(next, changeInstructionLanguageIndex)}
          onClose={() => setChangeInstructionLanguageIndex(undefined)}
          value={props.respondentInfo[changeInstructionLanguageIndex]}
        />
      )}
    </div>
  )
}
