export const flagNames: string[] = [
  'famfamfam-flags ad',
  'famfamfam-flags ae',
  'famfamfam-flags af',
  'famfamfam-flags ag',
  'famfamfam-flags ai',
  'famfamfam-flags al',
  'famfamfam-flags am',
  'famfamfam-flags an',
  'famfamfam-flags ao',
  'famfamfam-flags ar',
  'famfamfam-flags as',
  'famfamfam-flags at',
  'famfamfam-flags au',
  'famfamfam-flags aw',
  'famfamfam-flags ax',
  'famfamfam-flags az',
  'famfamfam-flags ba',
  'famfamfam-flags bb',
  'famfamfam-flags bd',
  'famfamfam-flags be',
  'famfamfam-flags bf',
  'famfamfam-flags bg',
  'famfamfam-flags bh',
  'famfamfam-flags bi',
  'famfamfam-flags bj',
  // 'famfamfam-flags bl',
  'famfamfam-flags bm',
  'famfamfam-flags bn',
  'famfamfam-flags bo',
  'famfamfam-flags bq',
  'famfamfam-flags br',
  'famfamfam-flags bs',
  'famfamfam-flags bt',
  'famfamfam-flags bv',
  'famfamfam-flags bw',
  'famfamfam-flags by',
  'famfamfam-flags bz',
  'famfamfam-flags ca',
  'famfamfam-flags catalonia',
  'famfamfam-flags cc',
  'famfamfam-flags cd',
  'famfamfam-flags cf',
  'famfamfam-flags cg',
  'famfamfam-flags ch',
  'famfamfam-flags ci',
  'famfamfam-flags ck',
  'famfamfam-flags cl',
  'famfamfam-flags cm',
  'famfamfam-flags cn',
  'famfamfam-flags co',
  'famfamfam-flags cr',
  'famfamfam-flags cs',
  'famfamfam-flags cu',
  'famfamfam-flags cv',
  // 'famfamfam-flags cw',
  'famfamfam-flags cx',
  'famfamfam-flags cy',
  'famfamfam-flags cz',
  'famfamfam-flags de',
  'famfamfam-flags dj',
  'famfamfam-flags dk',
  'famfamfam-flags dm',
  'famfamfam-flags do',
  'famfamfam-flags dz',
  'famfamfam-flags ec',
  'famfamfam-flags ee',
  'famfamfam-flags eg',
  'famfamfam-flags eh',
  'famfamfam-flags england',
  'famfamfam-flags er',
  'famfamfam-flags es',
  'famfamfam-flags et',
  // 'famfamfam-flags eu',
  'famfamfam-flags fam',
  'famfamfam-flags fi',
  'famfamfam-flags fj',
  'famfamfam-flags fk',
  'famfamfam-flags fm',
  'famfamfam-flags fo',
  'famfamfam-flags fr',
  'famfamfam-flags ga',
  'famfamfam-flags gb',
  'famfamfam-flags gd',
  'famfamfam-flags ge',
  'famfamfam-flags gf',
  'famfamfam-flags gg',
  'famfamfam-flags gh',
  'famfamfam-flags gi',
  'famfamfam-flags gl',
  'famfamfam-flags gm',
  'famfamfam-flags gn',
  'famfamfam-flags gp',
  'famfamfam-flags gq',
  'famfamfam-flags gr',
  'famfamfam-flags gs',
  'famfamfam-flags gt',
  'famfamfam-flags gu',
  'famfamfam-flags gw',
  'famfamfam-flags gy',
  'famfamfam-flags hk',
  'famfamfam-flags hm',
  'famfamfam-flags hn',
  'famfamfam-flags hr',
  'famfamfam-flags ht',
  'famfamfam-flags hu',
  'famfamfam-flags id',
  'famfamfam-flags ie',
  'famfamfam-flags il',
  // 'famfamfam-flags im',
  'famfamfam-flags in',
  'famfamfam-flags io',
  'famfamfam-flags iq',
  'famfamfam-flags ir',
  'famfamfam-flags is',
  'famfamfam-flags it',
  'famfamfam-flags je',
  'famfamfam-flags jm',
  'famfamfam-flags jo',
  'famfamfam-flags jp',
  'famfamfam-flags ke',
  'famfamfam-flags kg',
  'famfamfam-flags kh',
  'famfamfam-flags ki',
  'famfamfam-flags km',
  'famfamfam-flags kn',
  'famfamfam-flags kp',
  'famfamfam-flags kr',
  'famfamfam-flags kw',
  // 'famfamfam-flags ky',
  'famfamfam-flags kz',
  'famfamfam-flags la',
  'famfamfam-flags lb',
  'famfamfam-flags lc',
  'famfamfam-flags li',
  'famfamfam-flags lk',
  'famfamfam-flags lr',
  'famfamfam-flags ls',
  'famfamfam-flags lt',
  'famfamfam-flags lu',
  'famfamfam-flags lv',
  'famfamfam-flags ly',
  'famfamfam-flags ma',
  'famfamfam-flags mc',
  'famfamfam-flags md',
  'famfamfam-flags me',
  'famfamfam-flags mf',
  'famfamfam-flags mg',
  'famfamfam-flags mh',
  'famfamfam-flags mk',
  'famfamfam-flags ml',
  'famfamfam-flags mm',
  'famfamfam-flags mn',
  'famfamfam-flags mo',
  'famfamfam-flags mp',
  'famfamfam-flags mq',
  'famfamfam-flags mr',
  'famfamfam-flags ms',
  'famfamfam-flags mt',
  'famfamfam-flags mu',
  'famfamfam-flags mv',
  'famfamfam-flags mw',
  'famfamfam-flags mx',
  'famfamfam-flags my',
  'famfamfam-flags mz',
  'famfamfam-flags na',
  'famfamfam-flags nc',
  'famfamfam-flags ne',
  'famfamfam-flags nf',
  'famfamfam-flags ng',
  'famfamfam-flags ni',
  'famfamfam-flags nl',
  'famfamfam-flags no',
  'famfamfam-flags np',
  'famfamfam-flags nr',
  'famfamfam-flags nu',
  'famfamfam-flags nz',
  'famfamfam-flags om',
  'famfamfam-flags pa',
  'famfamfam-flags pe',
  'famfamfam-flags pf',
  'famfamfam-flags pg',
  'famfamfam-flags ph',
  'famfamfam-flags pk',
  'famfamfam-flags pl',
  'famfamfam-flags pm',
  'famfamfam-flags pn',
  'famfamfam-flags pr',
  'famfamfam-flags ps',
  'famfamfam-flags pt',
  'famfamfam-flags pw',
  'famfamfam-flags py',
  'famfamfam-flags qa',
  'famfamfam-flags re',
  'famfamfam-flags ro',
  'famfamfam-flags rs',
  'famfamfam-flags ru',
  'famfamfam-flags rw',
  'famfamfam-flags sa',
  'famfamfam-flags sb',
  'famfamfam-flags sc',
  'famfamfam-flags scotland',
  'famfamfam-flags sd',
  'famfamfam-flags se',
  'famfamfam-flags sg',
  'famfamfam-flags sh',
  'famfamfam-flags si',
  'famfamfam-flags sj',
  'famfamfam-flags sk',
  'famfamfam-flags sl',
  'famfamfam-flags sm',
  'famfamfam-flags sn',
  'famfamfam-flags so',
  'famfamfam-flags sr',
  // 'famfamfam-flags ss',
  'famfamfam-flags st',
  'famfamfam-flags sv',
  // 'famfamfam-flags sx',
  'famfamfam-flags sy',
  'famfamfam-flags sz',
  'famfamfam-flags tc',
  'famfamfam-flags td',
  'famfamfam-flags tf',
  'famfamfam-flags tg',
  'famfamfam-flags th',
  'famfamfam-flags tj',
  'famfamfam-flags tk',
  'famfamfam-flags tl',
  'famfamfam-flags tm',
  'famfamfam-flags tn',
  'famfamfam-flags to',
  'famfamfam-flags tr',
  'famfamfam-flags tt',
  'famfamfam-flags tv',
  'famfamfam-flags tw',
  'famfamfam-flags tz',
  'famfamfam-flags ua',
  'famfamfam-flags ug',
  'famfamfam-flags um',
  'famfamfam-flags us',
  'famfamfam-flags uy',
  'famfamfam-flags uz',
  'famfamfam-flags va',
  'famfamfam-flags vc',
  'famfamfam-flags ve',
  'famfamfam-flags vg',
  'famfamfam-flags vi',
  'famfamfam-flags vn',
  'famfamfam-flags vu',
  'famfamfam-flags wales',
  'famfamfam-flags wf',
  'famfamfam-flags ws',
  'famfamfam-flags ye',
  'famfamfam-flags yt',
  'famfamfam-flags za',
  'famfamfam-flags zm',
  'famfamfam-flags zw',
]
