import React from 'react'

import { SendPresentationInvitationModal } from './sendPresentationInvitationModal'
import { TabNavItem } from '@app/components/multiTabComponent/tabNavItem'
import { TabContent } from '@app/components/multiTabComponent/tabContent'
import { TextArea } from '@app/components/formComponents/textArea'
import { ReactDatePicker } from '@app/components/datePicker/ReactDatePicker'
import { DropdownSelect, IDropdownList } from '@app/components/formComponents/dropdownSelect'
import {
  EditPresentationInputs,
  PresentationInputKeys,
  TabIds,
  usePresentationModal,
} from './hooks'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { DropdownMultiSelect } from '@app/components/formComponents/dropdownMultiSelect'
import { IEditActivityCompletedProfile, IEditActivityPlannedPresentation } from '../../interface'
import {
  CoursePresentationStatus,
  getCoursePresentationStatus,
} from '@app/containers/profileList/profileEnums'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { DeleteModal } from '@app/components/modals/deleteModal'
import { ActivityId } from '@app/containers/reducer'

interface IPresentationsProps {
  activityId: ActivityId
  isEdit: boolean
  selectedProfiles?: IEditActivityCompletedProfile[]
  presentationData?: IEditActivityPlannedPresentation
  handleCloseClick: () => void
  refetchPlannedPresentations: () => void
  refetchParticipants: () => void
  handlePlayPresentation?: (presentationStatus: number, presentationPublicId: string) => void
}

export const PresentationsModal: React.FC<IPresentationsProps> = (props) => {
  const {
    isEdit,
    presentationData,
    selectedProfiles,
    activityId,
    handleCloseClick,
    refetchPlannedPresentations,
    refetchParticipants,
    handlePlayPresentation,
  } = props

  const {
    languageText,
    navTabs,
    activeTab,
    presentationTemplate,
    presentationError,
    presentationInfo,
    selectedPresentationDate,
    openSendPresentationModal,
    existingPresentationsList,
    editPresentationInfo,
    editSelectedParticipantsList,
    editPresentationError,
    showDeletePresentationModal,
    handleOpenInvitationModal,
    handleCloseInvitationModal,
    setActiveTab,
    handleDateSelect,
    handleDropdownSelect,
    handleTextAreaChange,
    handleSaveClick,
    handleMultiDropdownSelect,
    handleEditDateSelect,
    handleEditTextAreaChange,
    handleEditSaveClick,
    handleDropdownSelectForEditingPresentationWithoutAssumingThePropertyHasAnIdAndAName,
    onDeletePresentationClick,
    handleDeletePresentation,
    closeDeleteModal,
  } = usePresentationModal(
    isEdit,
    activityId,
    presentationData,
    refetchPlannedPresentations,
    handleCloseClick,
    refetchParticipants,
    selectedProfiles
  )

  const statusOptionsList: Array<IDropdownList> = [
    CoursePresentationStatus.Planned,
    CoursePresentationStatus.Ongoing,
    CoursePresentationStatus.Completed,
    CoursePresentationStatus.Cancelled,
  ].map((status, idx) => {
    return {
      id: idx,
      displayName: getCoursePresentationStatus(status),
      value: String(status),
    }
  })

  const isPresentationCompleted =
    presentationData?.presentationStatus === CoursePresentationStatus.Completed

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(
          languageText,
          isEdit ? 'Edit Presentation' : 'Create Presentation'
        )}
        submitButtonText={
          isEdit && !isPresentationCompleted
            ? getLanguageValue(languageText, 'Save And Resend')
            : undefined
        }
        secondarySubmitButtonText={
          !isPresentationCompleted ? getLanguageValue(languageText, 'Save') : undefined
        }
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleSubmitClick={() => handleEditSaveClick(PresentationInputKeys.send)}
        handleSecondarySubmitClick={() => {
          isEdit ? handleEditSaveClick(PresentationInputKeys.dontSend) : handleOpenInvitationModal()
        }}
        handleCancelClick={handleCloseClick}
        width='lg'
      >
        {!isEdit && (
          <TabNavItem navTabs={navTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        )}

        {isEdit ? (
          <DropdownMultiSelect
            name={EditPresentationInputs.profileIds}
            label={getLanguageValue(languageText, 'Select Participants')}
            placeholder={getLanguageValue(languageText, 'Select Participants')}
            list={editSelectedParticipantsList}
            value={editPresentationInfo.profileIds.map(String)}
            focusInput={editPresentationError.profileIds}
            disabled={isPresentationCompleted}
            handleMultiDropdownSelect={handleMultiDropdownSelect}
            required
          />
        ) : (
          <>
            <div className='fs-6 mb-2'>{getLanguageValue(languageText, 'Participants')}:</div>

            <div className='mb-3 border rounded bg-gray-100 p-2'>
              {selectedProfiles &&
                selectedProfiles.map((profile) => (
                  <div className='fs-6 d-flex flex-column m-2' key={profile.id}>
                    <div className='d-flex align-items-center gap-2'>
                      <i className='bi bi-person-fill'></i>
                      {profile.name}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}

        <TabContent id={TabIds.New} activeTab={activeTab}>
          <div>
            {/* Select Presentation Template */}
            {!isEdit && (
              <DropdownSelect
                name={PresentationInputKeys.presentationTemplate}
                label={getLanguageValue(languageText, 'Presentation Template')}
                defaultLabel={getLanguageValue(languageText, 'Select Presentation Template')}
                list={presentationTemplate}
                value={presentationInfo.presentationTemplate.name}
                focusInput={presentationError.presentationTemplate}
                handleDropdownSelect={handleDropdownSelect}
                searchOption
                required
              />
            )}

            {/* Select Presentation Date Template */}
            <ReactDatePicker
              className='d-flex flex-column'
              label={getLanguageValue(languageText, 'Presentation Date')}
              dateWithTime
              name={
                isEdit
                  ? EditPresentationInputs.presentationDate
                  : PresentationInputKeys.presentationDate
              }
              placeholder={getLanguageValue(languageText, 'Select Presentation Date')}
              date={
                isEdit ? editPresentationInfo.presentationDate : presentationInfo.presentationDate
              }
              handleDateSelect={isEdit ? handleEditDateSelect : handleDateSelect}
              focusInput={
                isEdit ? editPresentationError.presentationDate : presentationError.presentationDate
              }
              minDate={selectedPresentationDate}
              disabled={isPresentationCompleted}
              required
            />

            {/* Select Email Info Input */}
            <TextArea
              label={getLanguageValue(languageText, 'Email info')}
              name={isEdit ? EditPresentationInputs.emailInfo : PresentationInputKeys.emailInfo}
              value={
                isEdit ? editPresentationInfo.emailInfo || '' : presentationInfo.emailInfo || ''
              }
              onChange={isEdit ? handleEditTextAreaChange : handleTextAreaChange}
              disable={isPresentationCompleted}
            />

            {isEdit ? (
              <>
                <DropdownSelect
                  label={getLanguageValue(languageText, 'Status')}
                  name={EditPresentationInputs.status}
                  handleDropdownSelect={
                    handleDropdownSelectForEditingPresentationWithoutAssumingThePropertyHasAnIdAndAName
                  }
                  list={statusOptionsList}
                  value={String(editPresentationInfo.status)}
                  disabled={isPresentationCompleted}
                />
                <div className='d-flex justify-content-between flex-wrap gap-3'>
                  {presentationData?.presentationStatus !== CoursePresentationStatus.Completed && (
                    <div className='fw-bold'>
                      <button
                        className='btn btn-success py-0 me-2'
                        onClick={() =>
                          presentationData &&
                          handlePlayPresentation &&
                          handlePlayPresentation(
                            presentationData.presentationStatus,
                            presentationData.presentationPublicId
                          )
                        }
                      >
                        <i className='bi bi-play-fill fs-5' />
                      </button>
                      {getLanguageValue(
                        languageText,
                        presentationData?.presentationStatus === CoursePresentationStatus.Planned
                          ? 'Start Presentation'
                          : 'Continue Presentation'
                      )}
                    </div>
                  )}
                  <button className='btn btn-danger' onClick={onDeletePresentationClick}>
                    {getLanguageValue(languageText, 'Delete Presentation')}
                  </button>
                </div>
              </>
            ) : undefined}
          </div>
        </TabContent>

        <TabContent id={TabIds.Existing} activeTab={activeTab}>
          <div>
            {/* Select Existing presentations Input */}
            <DropdownSelect
              name={PresentationInputKeys.existingPresentation}
              label={getLanguageValue(languageText, 'Existing presentations')}
              defaultLabel={getLanguageValue(languageText, 'Existing presentations')}
              list={existingPresentationsList}
              value={presentationInfo.existingPresentation.name}
              focusInput={presentationError.existingPresentation}
              handleDropdownSelect={handleDropdownSelect}
              searchOption
              required
            />
            {/* Select Email Info Input */}
            <TextArea
              label={getLanguageValue(languageText, 'Email info')}
              name={PresentationInputKeys.emailInfo}
              value={presentationInfo.emailInfo || ''}
              onChange={handleTextAreaChange}
            />
          </div>
        </TabContent>
        {openSendPresentationModal && (
          <SendPresentationInvitationModal
            languageText={languageText}
            handleCloseInvitationModal={handleCloseInvitationModal}
            handleSaveClick={handleSaveClick}
          />
        )}

        {showDeletePresentationModal && (
          <DeleteModal
            languageText={languageText}
            headerText={getLanguageValue(languageText, 'Presentation')}
            bodyText={getLanguageValue(languageText, 'this presentation')}
            handleDeleteClick={handleDeletePresentation}
            closeDeleteModal={closeDeleteModal}
          />
        )}
      </ModalComponent>
    </>
  )
}
