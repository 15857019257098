import React from 'react'
import clsx from 'clsx'

import { IGroupReportAdaptabilities, IProfileGroupReport } from '../../../interface'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../../commonUtils/languageFunctionsHelper'
import { ReportType, ReportTypes } from '../../../../../../types'
import { classNames } from '@app/containers/utils'
import { ProfileId } from '@app/containers/reducer'

interface IGroupAdaptabilityProps {
  languageText: ITranslationObject
  reportType: ReportType
  hoveredProfileId?: ProfileId
  profileGroupReport: IProfileGroupReport | undefined
  selectedProfileIds?: Array<ProfileId>
  enableMarkerDimming?: boolean // If we don't have a list of participants, we want to show the markers with full opacity
}

interface IGroupAdaptabilityMarkerProps {
  adaptabilityIndex: string
  adaptabilityType: 'self' | 'other'
  count: number
  hovered?: boolean
  index: number
  left: number
  profileIds: Array<ProfileId>
  selected?: boolean
  top: number
}

const STEP_SIZE = 21
const ALLOWED_DIFFERENCE = 5

export const GroupAdaptability = (props: IGroupAdaptabilityProps): JSX.Element => {
  if (!props.profileGroupReport) {
    return <></>
  }

  const selfGroupReportAdaptabilities =
    props.profileGroupReport.selfGroupReport?.groupReportAdaptabilities ?? []
  const otherGroupReportAdaptabilities =
    props.profileGroupReport.otherGroupReport?.groupReportAdaptabilities ?? []
  const selectedIds = props.selectedProfileIds ?? []
  const enableMarkerDimming = props.enableMarkerDimming ?? true

  let averageAdaptability = 0
  if (props.reportType === ReportTypes.SELF)
    averageAdaptability = props.profileGroupReport.selfGroupReport?.averageAdaptability
  if (props.reportType === ReportTypes.OTHER)
    averageAdaptability = props.profileGroupReport.otherGroupReport?.averageAdaptability

  selfGroupReportAdaptabilities.sort((a, b) => a.flex - b.flex)
  otherGroupReportAdaptabilities.sort((a, b) => a.flex - b.flex)

  function calculateMarkerPositions(
    adaptabilities: IGroupReportAdaptabilities[],
    adaptabilityType: 'self' | 'other'
  ): IGroupAdaptabilityMarkerProps[] {
    const markerPositions: IGroupAdaptabilityMarkerProps[] = []

    let baseFlexPosition = 0
    let stepCounter = 1
    let newMargin = 0
    let groupHeight = 0

    adaptabilities.map((adaptability, index: number) => {
      if (index === 0) {
        baseFlexPosition = adaptability.flex
      }

      if (index > 0) {
        const diff = Math.abs(adaptability.flex - baseFlexPosition)
        if (diff <= ALLOWED_DIFFERENCE) {
          stepCounter++
          newMargin = stepCounter * STEP_SIZE
          if (newMargin > groupHeight) {
            groupHeight = newMargin
          }
        } else {
          baseFlexPosition = adaptability.flex
          stepCounter = 0
          newMargin = 0
        }
      }
      markerPositions.push({
        adaptabilityIndex: adaptability.adaptabilityIndex,
        adaptabilityType: adaptabilityType,
        count: adaptability.count,
        index: index,
        left: adaptability.flex,
        profileIds: adaptability.profileIds,
        top: newMargin,
      })
    })
    return markerPositions
  }

  const AdaptabilityMarker = (props: IGroupAdaptabilityMarkerProps): JSX.Element => {
    const highlighted =
      props.selected ||
      props.hovered ||
      !enableMarkerDimming ||
      (selectedIds.length === 0 && enableMarkerDimming)

    const clazzes = classNames({
      'adap-circle bg-danger': props.adaptabilityType === 'self',
      'adap-square bg-success': props.adaptabilityType === 'other',
      'adap-circle-hovered': props.hovered && props.adaptabilityType === 'self',
      'adap-square-hovered': props.hovered && props.adaptabilityType === 'other',
      'marker-highlighted': highlighted,
      'marker-dimmed': !highlighted,
    })

    return (
      <div
        key={props.index}
        className={clazzes}
        style={{ left: `${props.left - 2}%`, marginTop: `${props.top}px` }}
      >
        {/* We have more than one respondant with same value, let's add the gray indication. */}
        {props.count > 1 && (
          <div className={clsx('div-count', props.hovered && 'div-count-hovered')}>
            {props.count}
          </div>
        )}
        {props.adaptabilityIndex}
      </div>
    )
  }

  // Joakim - Calculate the marker positions for self and other so that we just can loop through and render them.
  // I want to keep as little logic as possible in the render function.
  const selfMarkerPositions = calculateMarkerPositions(selfGroupReportAdaptabilities, 'self')
  const otherMarkerPositions = calculateMarkerPositions(otherGroupReportAdaptabilities, 'other')

  const isSelf = props.reportType === ReportTypes.SELF
  const isOther = props.reportType === ReportTypes.OTHER
  const isBoth = props.reportType === ReportTypes.BOTH

  return (
    <div className='w-100'>
      <div className='d-flex justify-content-center flex-wrap position-relative pt-4 mt-4 text-end w-100'>
        {
          //Insert values here
        }
        {isOther || isBoth ? (
          <div className='mt-3'>
            {otherMarkerPositions.map((adaptability, index) => {
              const hovered = props.hoveredProfileId
                ? adaptability.profileIds.includes(props.hoveredProfileId)
                : false

              //If the anyone in the participant group is in the selected list, we want to highlight the marker
              const selected: boolean = selectedIds.some((participantId) =>
                adaptability.profileIds.includes(participantId)
              )
              return (
                <AdaptabilityMarker
                  adaptabilityIndex={adaptability.adaptabilityIndex}
                  adaptabilityType='other'
                  count={adaptability.count}
                  hovered={hovered}
                  index={index}
                  key={index}
                  left={adaptability.left}
                  profileIds={adaptability.profileIds}
                  selected={selected}
                  top={adaptability.top}
                />
              )
            })}
          </div>
        ) : (
          <></>
        )}
        {isSelf || isBoth ? (
          <div className='mt-3'>
            {selfMarkerPositions.map((adaptability, index) => {
              const hovered = props.hoveredProfileId
                ? adaptability.profileIds.includes(props.hoveredProfileId)
                : false

              //If the anyone in the participant group is in the selected list, we want to highlight the marker
              const selected: boolean = selectedIds.some((profile) =>
                adaptability.profileIds.includes(profile)
              )

              return (
                <AdaptabilityMarker
                  adaptabilityIndex={adaptability.adaptabilityIndex}
                  adaptabilityType='self'
                  count={adaptability.count}
                  hovered={hovered}
                  index={index}
                  key={index}
                  left={adaptability.left}
                  profileIds={adaptability.profileIds}
                  selected={selected}
                  top={adaptability.top}
                />
              )
            })}
          </div>
        ) : (
          <></>
        )}

        <div
          className='w-10 border border-top-0 position-relative small'
          style={{ height: '150px' }}
        >
          <div className='float-start position-absolute top-0 start-0 translate-middle bg-white'>
            0
          </div>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            10
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            20
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            30
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            40
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            50
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            60
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            70
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            80
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            90
          </div>
        </div>
        <div className='w-10 border-bottom border-end position-relative small'>
          <div className='float-start position-absolute top-0 start-100 translate-middle bg-white'>
            100
          </div>
        </div>
        <div className='w-100 d-flex justify-content-between pt-2'>
          <div>{getLanguageValue(props.languageText, 'Attend-to-self')}</div>
          <div className='text-center fw-bold'>
            {getLanguageValue(props.languageText, 'Adaptability')}{' '}
            {averageAdaptability ? <>&Oslash; {averageAdaptability}%</> : ''}
          </div>
          <div>{getLanguageValue(props.languageText, 'Attend-to-other')}</div>
        </div>
      </div>
    </div>
  )
}
