import { LoginTypeEnum, RoleName } from './commonEnums'
import { ADD_TOAST, REMOVE_TOAST } from './constants'
import { ProfileStatus } from './profileList/profileEnums'
import { ActivityId, ProfileId, UserId } from './reducer'

// User details
export interface IUserDetails {
  userId: UserId
  name: string
  userLanguageId: number
  userLanguageCode: string
  userLanguageDisplayName: string
  userRole: RoleName | null
  userRoleId: number
  userRoles: IUserClientRoles[]
  defaultClientId: number | null
  defaultClientName: string
  activityId: ActivityId
  profileId: ProfileId
  isOverviewPage: boolean
  isConsultant: boolean
  isProfileExists: boolean
  enableParticipantCreateProfile: boolean
  uniqueCode: string //Only exists on a participant otherwise defaults to emtpy string
  isManager: boolean
}

export interface IUserClientRoles {
  assignedRoleName: RoleName
  clientId: number | null
  isDefaultClient: boolean
  userRoleId: number | null
}

export interface IUserProfile {
  name: string
  surname: string
  userName: string
  emailAddress: string
  phoneNumber: string
  isPhoneNumberConfirmed: boolean
  isTwoFactorEnabled: boolean
  isEmailConfirmed: boolean
  timezone: string
  selectedLanguageId: number | null
}

export interface IRoles {
  id: number
  name: string
  displayName: string
}

// Existing countries response from api
export interface ICountry {
  name: string
  code: number
  logical: number
  id: number
}

// Language response from api
export interface ILanguage {
  creationTime: string
  cultureNormUse: boolean
  displayName: string
  icon: string
  id: number
  isDeleted: boolean
  isDisabled: boolean
  name: string
  showFacilitator: boolean
  showParticipant: boolean
}

// Culture response from api
export interface ICulture {
  activityUse: boolean
  displayName: string
  formUse: boolean
  icon: string
  id: number
  isDisabled: boolean
  name: string
  showFacilitator: boolean
  showParticipant: boolean
}

export interface ILanguageTextByKey {
  languageName: string
  value: string
}

export interface IUpdateLanguageParams {
  userId: UserId
  languageCode: string
  isParticipant: boolean
}

// Clients Response
export interface IClients {
  active: boolean
  address: string
  businessIdentification: string
  city: string
  company: string
  countryId: number
  countryName: string
  deliveryCountryName: string
  differentInvoiceAddress: boolean
  email: string
  enableParticipantCreateProfile: boolean
  euvatid: string
  facilitator: string
  id: number
  invoiceAddress: string
  invoiceCity: string
  invoiceCompanyName: string
  invoiceCountryId: number
  invoiceCountryName: string
  invoiceEUVATID: string
  invoiceReference: string
  invoiceZip: string
  isDefaultClient: boolean
  name: string
  numberOfFacilitators: number
  numberOfParticipants: number
  numberOfProfileParticipants: number
  numberOfProfiles: number
  numberOfSubscriptions: number
  numberOfUsers: number
  orgNo: string
  sourceAddress: string
  sourceType: string
  telephone: string
  userRoleId: number
  validFrom: string
  validTo: string
  vat: string
  zip: string
}

// Profiles Response
export interface IActivity {
  client: string
  completedProfiles: number
  created: string
  date: string
  facilitator: string
  id: number
  name: string
  registeredProfiles: number
  status: ProfileStatus
  sourceType: string
  sourceAddress: string
}

// Users Response
export interface IUsers {
  id: number
  name: string
  surname: string
  emailAddress: string
  isEmailConfirmed: boolean
  isActive: boolean
  phoneNumber: string
  userClients: any
  sourceType: string
  sourceAddress: string
  lastLoginType: LoginTypeEnum | null
}

export interface ITableCommonParams {
  filter: string
  sorting: string
  maxResultCount: number
  skipCount: number
}

// Toaster
export interface Toast {
  id: string
  message: string
}
export interface AddToastAction {
  type: typeof ADD_TOAST
  payload: Toast
}
export interface RemoveToastAction {
  type: typeof REMOVE_TOAST
  payload: { id: string }
}
export type ToastActionTypes = AddToastAction | RemoveToastAction

export interface IGuide {
  guideName: string
  // guideStep: number
  isCompleted: boolean
}

export enum ModelType {
  Unknown = 0,
  Course,
  PresentationTemplate,
}

// Copied from the backend ModelSourceTypes.cs file
export enum ModelSourceTypes {
  Unknown = 'Unknown',
  IDI = 'IDI',
  Google = 'Google',
  LinkedIn = 'LinkedIn',
  Microsoft = 'Microsoft',
  Migrated = 'Migrated',
  Facilitator = 'Facilitator',
  Admin = 'Admin',
}
