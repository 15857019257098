import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { getParticipantProfileInfo } from '@app/commonUtils/participantProfileHelper'
import { routePath } from '../routePaths'
import { isLoggedIn } from '../rootState'

export const ucQueryKey = 'uc'

interface IUseParticipantProfileAuth {
  uniqueCode: string
}

/**
 * @deprecated This thing is a cancer spreading through our app. We shouldn't do auth
 *   checking inside a react hook, that is crazy. What's even more crazy is that this
 *   hook has a side effect of loading 'participantProfileDetails', which many components
 *   depend on. lol.
 */
export const useParticipantProfileAuth = (): IUseParticipantProfileAuth => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const queryParams = new URLSearchParams(location.search)
  const uniqueCode = queryParams.get(ucQueryKey) || ''

  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )

  useEffect(() => {
    const loggedIn = isLoggedIn()

    if (!uniqueCode && !loggedIn) return navigate(routePath.signIn)
    else if (!uniqueCode) return navigate(routePath.participantDashboard)
    else if (uniqueCode && loggedIn && !participantProfileDetails.profileId) {
      getParticipantProfileInfo(uniqueCode, dispatch, false)
    }
  }, [])

  return { uniqueCode }
}
