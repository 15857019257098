import React from 'react'

import famfamfam_flags from '../../../public/DeliveryImages/famfamfam_flags.png'
import logo from '../../../public/DeliveryImages/IDI_Logotyp.webp'
import { TermsFooter } from '../termsFooter/termsFooter'
import {
  ILanguageObject,
  ITranslationObject,
  getLanguageValue,
} from '../../commonUtils/languageFunctionsHelper'

interface ILanguagePagePropType {
  languageText: ITranslationObject
  languages: ILanguageObject[]
  handleChangeLanguage: (name: string, id: number) => void
}

export const LanguagePageComponent = (props: ILanguagePagePropType): JSX.Element => {
  const { languageText, languages, handleChangeLanguage } = props

  return (
    <div className='container my-md-5'>
      <div className='row p-3'>
        <div className='col-12 col-md-4 shadow p-4 p-md-5 bg-white rounded mx-auto'>
          <img src={logo} className='idi-logo mb-4' />
          <div className='d-flex flex-column'>
            <div className='d-flex flex-column justify-content-between'>
              <h4 className='mb-3 pt-2 fw-bolder'>
                {getLanguageValue(languageText, 'Select language')}
              </h4>
              <p className='mb-4'>
                {getLanguageValue(
                  languageText,
                  'You can change the language in your settings after you have logged in'
                )}
              </p>
              <div className='row gx-2'>
                {languages.map((languageItem) => (
                  <div
                    key={languageItem.id}
                    onClick={() => handleChangeLanguage(languageItem.name, languageItem.id)}
                    role='button'
                    className='col-6 p-2 border rounded'
                  >
                    <div className='d-flex flex-row align-items-center gap-2 fw-bold'>
                      <div
                        className={`${languageItem.icon} mx-1`}
                        style={{
                          backgroundImage: `url(${famfamfam_flags})`,
                        }}
                      ></div>
                      {languageItem.displayName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <TermsFooter languageText={languageText} footerContainerClassName='px-2 mt-3' />
      </div>
    </div>
  )
}
