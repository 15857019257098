export type Map<T> = { [key: string]: T }

export enum ReportTypes {
  SELF = 'SELF',
  OTHER = 'OTHER',
  BOTH = 'BOTH',
}

export type ReportType = ReportTypes.SELF | ReportTypes.OTHER | ReportTypes.BOTH

export interface ApiResult<T> {
  totalCount: number
  items: ReadonlyArray<T>
}

export interface ValidationErrorInfo {
  message: string
  members: string[]
}

//TODO: Joakim - Naming here is weird - find a better name.
export interface ErrorObject {
  code: number
  message: string
  details: string
  validationErrors: ValidationErrorInfo[]
}

export type ApiResponse<T> =
  | { success: true; result: T }
  | { success: false; error: ErrorObject | undefined }
