import React from 'react'

import { CourseTopProgress } from './courseNavigation'
import { renderHTMLAcademy } from '../../../commonUtils/renderHtmlHelper'
import { CourseBreadcumb, CourseNotFound, NavigationButtons } from '../components'
import { useCourse } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'

export const Course = (): JSX.Element => {
  const {
    activeSection,
    course,
    languageText,
    partNotFound,
    forwardClickHandler,
    backClickHandler,
  } = useCourse()

  if (partNotFound) {
    return (
      <CourseNotFound
        title={getLanguageValue(languageText, 'Part not found')}
        text={getLanguageValue(languageText, 'The part you tried to access cannot be found')}
        languageText={languageText}
      />
    )
  } else
    return (
      <>
        {course && course.courseParts.length > 0 && (
          <div className='p-2 pt-4 p-md-4 col-md-8 mx-auto'>
            <div className='d-flex flex-column justify-items-center'>
              <CourseBreadcumb languageText={languageText} course={course} />
            </div>
            <div className='shadow-sm p-4 rounded bg-white'>
              {course.courseParts.length > 0 && (
                <div className='mt-2 mb-4'>
                  <CourseTopProgress
                    courseParts={course.courseParts}
                    activeSection={activeSection + 1}
                  />
                </div>
              )}
              <div className='d-flex align-items-center fw-bolder text-dark me-5 mb-4'>
                <i className='bi bi-book-half fs-3 me-3 text-dark'></i>
                <>
                  {getLanguageValue(languageText, 'Part')} {activeSection + 1} /{course.partsCount}
                </>
              </div>
              <div className='fw-bold mb-3 fs-4'>{course.courseParts[activeSection].title}</div>
              {renderHTMLAcademy(course.courseParts[activeSection].content)}

              <NavigationButtons
                onForwardClick={() => forwardClickHandler(course.courseParts[activeSection].id)}
                onBackClick={() => backClickHandler(course.courseParts[activeSection].id)}
                isDisabled={false}
                forwardLabel={
                  activeSection + 1 === course.courseParts.length &&
                  getLanguageValue(languageText, 'Go To Quiz')
                }
              />
            </div>
          </div>
        )}
      </>
    )
}
