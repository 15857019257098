import React from 'react'

import { useLatestNews } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { NewsType } from '../newsList/types'

export const LatestNews = (): JSX.Element => {
  const {
    languageText,
    isLastData,
    latestNews,
    newsType,
    handleLoadMore,
    handleNavigateNewsBlog,
    handleNavigateBack,
  } = useLatestNews()

  return (
    <>
      <div className='p-2 pt-4 p-md-4'>
        <h3 className='fw-bold mb-3'>
          <span role='button' onClick={() => handleNavigateBack()}>
            {getLanguageValue(languageText, 'Resources')}
          </span>
          <span className='px-2'>/</span>
          {getLanguageValue(
            languageText,
            newsType == NewsType.News ? 'Latest News' : 'Latest Blogs'
          )}
        </h3>

        <div className='bg-white mt-3 p-2 p-md-4 rounded shadow-sm'>
          {latestNews && latestNews.length > 0 ? (
            <>
              {latestNews.map((news) => (
                <div key={news.id} className='bg-secondary-subtle rounded mb-3 p-3'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <div className='card-body p-0'>
                        <h2
                          className='card-title'
                          role='button'
                          onClick={() => handleNavigateNewsBlog(news.id)}
                        >
                          {news.title}
                        </h2>

                        <p className='card-text'>{news.publishedDate.toString().slice(0, -8)}</p>

                        <p className='card-text'>{news.excerpt}</p>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <img src={news.image} className='card-img' alt='...' />
                    </div>
                  </div>
                </div>
              ))}

              {!isLastData && (
                <>
                  <button
                    type='button'
                    className='btn btn-default border border-secondary me-3'
                    onClick={handleLoadMore}
                  >
                    {getLanguageValue(languageText, 'Load More..')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-default border border-warning'
                    onClick={handleNavigateBack}
                  >
                    {getLanguageValue(languageText, 'Go Back')}
                  </button>
                </>
              )}
            </>
          ) : (
            <h5 className='text-center'>
              {getLanguageValue(
                languageText,
                newsType == NewsType.News
                  ? 'No Latest news to display'
                  : 'No Latest blogs to display'
              )}
            </h5>
          )}
        </div>
      </div>
    </>
  )
}
