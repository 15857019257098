import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { setSpinner } from '@app/containers/actions'
import { RootState } from '@app/store/configureStore'
import { IParticipantProfileReportHtml } from '@app/containers/profileList/editProfile/interface'
import {
  downloadParticipantProfilePdfReport,
  getParticipantProfileReportHtml,
} from '@app/containers/profileList/editProfile/actions'
import { ProfileOutputPages } from '@app/containers/profileList/profileEnums'
import { getLanguageValue, TranslateFn } from '@app/commonUtils/languageFunctionsHelper'
import '@app/css/components/idiMatris.scss'
import { downloadFileFromBlob } from '@app/commonUtils/downloadHepler'
import { idiV2GetParticipantPresentationSlides } from '@app/containers/participantDelivery/actions'
import { IPresentationSlide } from '@app/containers/facilitatorDelivery/hooks'
import { SelfDeliveryScroller } from '@app/containers/presentationsList/previewSelfDelivery'
import { ProfileId } from '@app/containers/reducer'

export const ParticipantProfileReport = (): JSX.Element => {
  const dispatch = useDispatch()

  const profileId = (Number(useParams().profileId) || 0) as ProfileId
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)
  const [participantReportHtml, setParticipantReportHtml] = useState<IParticipantProfileReportHtml>(
    {
      participantName: '',
      title: '',
      html: '',
      enableSelfDelivery: false,
      selfDeliveryTemplateId: 0,
      selfDeliverySlideIndex: 0,
    }
  )

  useEffect(() => {
    if (!userLanguage.userLanguageCode) {
      return
    }

    dispatch(setSpinner(true))
    getParticipantProfileReportHtml(
      profileId,
      userLanguage.userLanguageCode,
      ProfileOutputPages.All,
      dispatch
    )
      .then((report) => {
        setParticipantReportHtml(report)
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }, [userLanguage.userLanguageCode])

  const handleDownloadProfileReport = async (): Promise<void> => {
    dispatch(setSpinner(true))

    try {
      const reportName = `IDIProfile_${participantReportHtml.participantName}_${profileId}.pdf`
      const blobData = await downloadParticipantProfilePdfReport(
        [profileId],
        userLanguage.userLanguageCode,
        ProfileOutputPages.All,
        dispatch
      )
      downloadFileFromBlob(blobData, reportName)
    } catch (e: any) {
      //Error is suppressed as the error is aleady handled in the downloadParticipantProfilePdfReport function
    }
    dispatch(setSpinner(false))
  }

  return (
    <>
      <div className='mt-3 p-2 p-md-4'>
        <div className='row'>
          <h4 className='col-12 col-sm-8 col-md-10 mb-4 fw-bold'>
            {getLanguageValue(languageText, 'Profile')} / {profileId} /{' '}
            {participantReportHtml.title}
          </h4>
          <div className='col-12 col-sm-4 col-md-2 ps-0 text-md-end'>
            <span
              className='cursor-pointer text-dark fw-bold'
              onClick={handleDownloadProfileReport}
              role='button'
            >
              <i className='bi bi-file-earmark-arrow-down fs-7 ms-md-4 me-md-2 me-1'></i>
              {getLanguageValue(languageText, 'Download')}
            </span>
          </div>
        </div>
        <div
          className='shadow-sm p-3 p-md-4 rounded bg-white'
          dangerouslySetInnerHTML={{ __html: participantReportHtml.html }}
        ></div>
      </div>
    </>
  )
}
