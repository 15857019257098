import * as React from 'react'
import { StepComponent } from './createProfilePage'
import { CurrentStepPanel, UpcomingStepPanel } from './panels'
import { routePath } from '../routePaths'

export const ProfileStep: StepComponent = (props) => {
  const __t = props.__t
  const title = __t('IDI-Profile')

  switch (props.status) {
    case 'upcoming':
      return <UpcomingStepPanel title={title} />
    case 'current': {
      return (
        <CurrentStepPanel __t={__t} title={title}>
          <div className='p-4'>
            <button
              className='btn btn-primary'
              onClick={(event) => {
                event.preventDefault()

                const url = routePath.participantProfileReport.replace(
                  ':profileId',
                  props.profile.profileId.toString()
                )

                window.open(url, '__blank')
              }}
            >
              {__t('Go to profile')}
            </button>
          </div>
        </CurrentStepPanel>
      )
    }
    case 'completed': {
      return null
    }
  }
}
