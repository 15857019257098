import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { AddEditAccountModal } from '../../../../clientList/addEditModal/addEditAccountModal'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { getClientNames, ITinyClient } from '../../../../coursesList/actions'
import { IUpdateActivityClientBody } from '../../../addProfile/hooks'
import { updateActivityClient } from '../../../addProfile/actions'
import { isFacilitatorInClientRole } from '../../actions'
import { CustomModal as WarningModal } from '../../../../../components/modals/customModal'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { addToast } from '@app/containers/actions'
import { AnyAction } from 'redux'
import { Input } from '@app/components/formComponents/input'
import { IClients } from '@app/containers/commonInterfaces'
import { ActivityId, UserId } from '@app/containers/reducer'

interface ISelectedClient {
  clientId: number
  clientName: string
}

interface IClientSelectProps {
  languageText: ITranslationObject
  clientId: number
  activityId?: ActivityId // From edit-profile
  facilitatorId?: UserId
  clientData?: ReadonlyArray<IClients> // From add-profile
  refetchClientList?: () => void
  closeClientSelectModal: () => void
  handleClientSelect: (clientId: number, clientName: string, isFacilitatorRemoved: boolean) => void
}

export const ClientSelectModal: React.FC<IClientSelectProps> = (props) => {
  const {
    languageText,
    clientId,
    activityId,
    facilitatorId,
    clientData,
    refetchClientList,
    handleClientSelect,
    closeClientSelectModal,
  } = props

  const dispatch = useDispatch()

  const initialSelectedClientState: ISelectedClient = {
    clientId: 0,
    clientName: '',
  }
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [addAccountModal, setAddAccountModal] = useState<boolean>(false)
  const [clientsList, setClientsList] = useState<ReadonlyArray<ITinyClient>>([])
  const [clientWarningModal, setClientWarningModal] = useState<boolean>(false)
  const [selectedClient, setSelectedClient] = useState<ISelectedClient>(initialSelectedClientState)

  const rowData = {}

  useEffect(() => {
    if (!activityId && clientData && clientData?.length > 0) {
      setClientsList(clientData)
    }
  }, [clientData])

  const fetchClientDetails = async (searchValue: string): Promise<void> => {
    const clientsList = await getClientNames(searchValue, dispatch)
    let clients: ITinyClient[] = []
    if (clientsList?.length > 0) {
      clients = clientsList.map((item) => ({
        id: item.id,
        name: item.name,
      }))
      setClientsList(clients)
    } else setClientsList([])
  }

  useEffect(() => {
    if (activityId) {
      fetchClientDetails(searchQuery)
    }
  }, [searchQuery, activityId])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setSearchQuery(value)
  }

  const updateClient = (clientId: number, clientName: string): void => {
    const body: IUpdateActivityClientBody = {
      activityId: activityId ? activityId : (0 as ActivityId),
      clientId: clientId,
    }
    updateActivityClient(body, dispatch).then((response) => {
      handleClientSelect(clientId, clientName, response)
      closeClientSelectModal()
      dispatch(addToast('Client Updated') as AnyAction)
    })
  }

  const onSelectClient = (clientId: number, clientName: string): void => {
    if (activityId && facilitatorId) {
      isFacilitatorInClientRole(facilitatorId, clientId, dispatch).then((response) => {
        if (response.success) {
          if (response.result) {
            updateClient(clientId, clientName)
          } else {
            setClientWarningModal(true)
            setSelectedClient({ clientId, clientName })
          }
        }
      })
    } else {
      handleClientSelect(clientId, clientName, false)
    }
  }

  const closeClientWarningModal = (): void => {
    setSelectedClient(initialSelectedClientState)
    setClientWarningModal(false)
  }

  const handleClientChange = (): void => {
    closeClientWarningModal()
    updateClient(selectedClient.clientId, selectedClient.clientName)
  }

  const openClientModal = (): void => {
    setAddAccountModal(true)
  }

  const closeClientModal = (): void => {
    setAddAccountModal(false)
  }

  const refreshClientList = (): void => {
    if (activityId) {
      fetchClientDetails(searchQuery)
    } else {
      refetchClientList && refetchClientList()
    }
  }

  let filteredData = clientsList
  if (!activityId) {
    filteredData =
      clientData?.filter((item) =>
        String(item.name).toLowerCase().includes(searchQuery.toLowerCase())
      ) ?? []
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Change Account')}
        cancelButtonText={getLanguageValue(languageText, 'Close')}
        handleCancelClick={closeClientSelectModal}
        width='lg'
      >
        <div className='d-flex flex-wrap justify-content-between'>
          <Input
            placeholder={getLanguageValue(languageText, 'Search')}
            value={searchQuery}
            handleInputChange={handleSearch}
          />
          <div>
            <button onClick={openClientModal} className='btn btn-success'>
              {getLanguageValue(languageText, 'Add New Account')}
            </button>
          </div>
        </div>

        {filteredData?.length > 0 ? (
          filteredData.map((item, index) => {
            return (
              <div key={index} className='d-flex align-items-center py-2'>
                {clientId === item.id ? (
                  <i className='bi bi-check-square text-success fs-4' />
                ) : (
                  <div role='button' onClick={() => onSelectClient(item.id, item.name)}>
                    <i className='bi bi-square text-secondary fs-4' />
                  </div>
                )}

                <div className={clsx('ps-3', clientId === item.id ? 'text-success' : '')}>
                  {item.name}
                </div>
              </div>
            )
          })
        ) : (
          <p className='text-center mt-10 fs-5'>
            {getLanguageValue(languageText, '0 records found')}
          </p>
        )}

        {addAccountModal && (
          <AddEditAccountModal
            rowData={rowData}
            isActivity={true}
            refreshClientList={refreshClientList}
            closeClientModal={closeClientModal}
          />
        )}

        {clientWarningModal && (
          <WarningModal
            headerText={getLanguageValue(languageText, 'Warning Message')}
            bodyText={`${getLanguageValue(
              languageText,
              'Selected client is not conected to facilitator. Do you really want to change the client'
            )} ?`}
            cancelButtonText={getLanguageValue(languageText, 'No')}
            submitButtonText={getLanguageValue(languageText, 'Yes')}
            isSubmitDangerButton={true}
            handleCancelClick={closeClientWarningModal}
            handleSubmitClick={handleClientChange}
          />
        )}
      </ModalComponent>
    </>
  )
}
