import React from 'react'
import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'

interface ICultureNormProps {
  languageText: ITranslationObject
  language: string
}

export const CultureNorm = (props: ICultureNormProps): JSX.Element => {
  const { languageText, language } = props

  return (
    <div className='border p-2'>
      <i className='bi bi-globe2 fs-5 me-2' />
      {getLanguageValue(languageText, 'Culture Norm')}

      <h5 className='mt-2 fw-bold'>{language}</h5>
    </div>
  )
}
