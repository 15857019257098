import React from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../../commonUtils/languageFunctionsHelper'
import { GetTypeOfRoleName } from '../../../../profileEnums'

interface IRespondentsProps {
  languageText: ITranslationObject
  roleId: number
  roleText: string
  noOfRespondents: number
  respondents: string[]
}

export const Respondents = (props: IRespondentsProps): JSX.Element => {
  const { languageText, roleId, roleText, noOfRespondents, respondents } = props

  return (
    <div className='border p-2'>
      <i className='bi bi-people-fill fs-5 me-2' />
      {getLanguageValue(languageText, 'Respondents')}

      <h5 className='fw-bold my-2'>
        {roleId ? getLanguageValue(languageText, GetTypeOfRoleName[roleId]) : (roleText ?? '')} (
        {noOfRespondents})
      </h5>

      <div className='d-flex flex-wrap gap-2'>
        {respondents?.length > 0 &&
          respondents.map((respondent, index) => (
            <div key={index} className='fs-5 bg-light p-2 rounded'>
              {respondent}
            </div>
          ))}
      </div>
    </div>
  )
}
