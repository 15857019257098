import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { routePath } from '../../routePaths'
import { ICategoryAndCourse, ICourse } from '../types'
import { getCategoryCourses, getCoursesList } from '../actions'
import { RootState } from '@app/store/configureStore'
import { getParticipantMedia, setSpinner } from '@app/containers/actions'
import { UserGeneratedMediaType } from '@app/containers/commonEnums'

export const useCategoryCourses = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const parentId = Number(useParams().parentId) || 0 // Parent category Id

  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)
  const userId = useSelector((state: RootState) => state.loginReducer.userId)
  const selectedCourseLanguageId: number = useSelector(
    (state: RootState) => state.academyReducer.selectedCourseLanguageId
  )
  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initialCoursesState: ICategoryAndCourse = {
    category: {
      id: 0,
      name: '',
      description: '',
      parentId: 0,
      topic: '',
      coursesCount: 0,
      lessonsCount: 0,
    },
    categoryCourses: [],
  }
  const [subCategories, setCourses] = useState<ICategoryAndCourse>(initialCoursesState)
  const [categoryCourses, setCategoryCourses] = useState<ICourse[]>([])

  const fetchSubCategories = async (): Promise<void> => {
    if (parentId) {
      await getCategoryCourses(
        parentId,
        defaultClient.defaultClientId,
        selectedCourseLanguageId,
        dispatch
      ).then((response) => {
        if (response) {
          setCourses(response)
        }
      })
    }
  }

  const fetchSubCourses = async (): Promise<void> => {
    await getCoursesList(
      defaultClient.defaultClientId,
      selectedCourseLanguageId,
      dispatch,
      parentId
    ).then((fetchedCourses) => {
      if (fetchedCourses && fetchedCourses.length > 0) {
        const updatedCourseItems = fetchedCourses.map(async (course) => {
          const fetchedImage = await getParticipantMedia(
            course.id,
            UserGeneratedMediaType.AcademyCourse,
            dispatch
          )
          return { ...course, logo: fetchedImage }
        })

        Promise.all(updatedCourseItems).then((updatedCourses) => setCategoryCourses(updatedCourses))
      } else setCategoryCourses([])
    })
  }

  const fetchSubCategoriesAndCategoryCourses = async (): Promise<void> => {
    dispatch(setSpinner(true))
    await Promise.all([fetchSubCategories(), fetchSubCourses()])
    dispatch(setSpinner(false))
  }

  useEffect(() => {
    if (userId && defaultClient.defaultClientId) {
      fetchSubCategoriesAndCategoryCourses()
    }
  }, [selectedCourseLanguageId, userId, defaultClient])

  const handleStartCourse = (categoryId: number): void => {
    navigate(routePath.participantCourseLessons.replace(':categoryId', categoryId.toString()))
  }

  return {
    languageText,
    subCategories,
    categoryCourses,
    handleStartCourse,
  }
}
