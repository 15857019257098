import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

interface IFileUpload {
  errorMessage?: string
  name: string
  disabled?: boolean
  label?: string
  file?: string
  isDocType?: boolean
  fileName?: string
  containerClassname?: string
  imagePath?: string
  removeIconBtnText?: string
  selectIconBtnText?: string
  isEditMode?: boolean
  handleFileUpload: (file: File, docName?: string) => void
  removeIcon?: () => void
  onOpenExistingIconModal?: () => void

  /**
   * Array of file suffixes that are allowed. For example '.jpg' and '.gif'.
   * Defaults to '.jpg, .png, .svg, .jpeg'
   */
  acceptFileTypes?: Array<string>
}

export const FileUpload = (props: IFileUpload): JSX.Element => {
  const {
    disabled,
    errorMessage,
    name,
    file,
    isDocType,
    fileName,
    containerClassname,
    label,
    imagePath,
    removeIconBtnText,
    selectIconBtnText,
    removeIcon,
    handleFileUpload,
    onOpenExistingIconModal,
  } = props
  const [isFileTypeImage, setIsFileTypeImage] = useState<boolean>(true)

  useEffect(() => {
    if (!isDocType) return
    setIsFileTypeImage(false)
  }, [isDocType])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const uploadedFile = event.target.files && event.target.files[0]
    if (uploadedFile) {
      const fileType = uploadedFile.type

      if (fileType.startsWith('image/') || fileType === 'image/svg+xml') {
        setIsFileTypeImage(true)
        handleFileUpload(uploadedFile!)
      } else {
        setIsFileTypeImage(false)
        handleFileUpload(uploadedFile!, uploadedFile.name)
      }
    }
  }

  const accept = (props.acceptFileTypes ?? ['.jpg', '.png', '.svg', '.jpeg']).join(',')

  return (
    <>
      <div className={clsx('mb-3', containerClassname && containerClassname)}>
        {label && (
          <label htmlFor='file-upload' className='mb-2 required'>
            {label}
          </label>
        )}
        {!imagePath ? (
          <div className='d-flex gap-4'>
            <input
              disabled={disabled ?? false}
              id='file-upload'
              type='file'
              name={name}
              accept={accept}
              className={clsx('form-control', errorMessage && 'border-danger bg-light-danger')}
              onChange={handleChange}
            />
            {selectIconBtnText ? (
              <div
                className='btn btn-sm btn-success text-nowrap text-center d-flex align-items-center'
                onClick={onOpenExistingIconModal}
              >
                {selectIconBtnText}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className='d-flex gap-2 mb-6 align-items-end'>
            <div>
              {isFileTypeImage ? (
                <img
                  src={imagePath}
                  width='150'
                  height='150'
                  className='img-thumbnail w-150px h-150px'
                  alt='Uploaded Image'
                />
              ) : (
                <span>{fileName}</span>
              )}
            </div>
            <>
              <div className='btn btn-sm btn-danger' onClick={removeIcon}>
                {removeIconBtnText}
              </div>
            </>
          </div>
        )}
        {errorMessage && !imagePath?.match(/\.(png|jpe?g|svg)$/i) && (
          <div className='text-danger'>{errorMessage}</div>
        )}
        {file && isFileTypeImage && (
          <div>
            <img src={file} className='rounded mx-auto mw-300px' alt='Uploaded Image' />
          </div>
        )}

        {file && !isFileTypeImage && (
          <div>
            <a className='rounded mx-auto mw-300px' href={imagePath} download={fileName}>
              {fileName}
            </a>
          </div>
        )}
      </div>
    </>
  )
}
