import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface ITokenExpiredProps {
  handleClick: () => void
}

export const TokenExpired: React.FC<ITokenExpiredProps> = (props) => {
  const { handleClick } = props

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Session Expired')}
        cancelButtonText={getLanguageValue(languageText, 'Ok')}
        handleCancelClick={handleClick}
      >
        {getLanguageValue(languageText, 'Session Expired. Please login again')}
      </ModalComponent>
    </>
  )
}
