import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { AnyAction } from 'redux'

import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IFocusError } from '../../../components/formComponents/input'
import { addToast, clearNotifications, setSpinner } from '../../actions'
import { RootState } from '@app/store/configureStore'
import { ValidationHelper } from '../../validationHelper'
import {
  deleteEmployeeById,
  getEmployeeDetailsById,
  getParticipantDashboardInfoForFacilitator,
  isEmployeeExists,
} from '../actions'
import { updateEmployee } from './actions'
import { E164Number } from 'libphonenumber-js/types'
import { routePath } from '../../routePaths'
import {
  IDashboardDetails,
  IProfileStatus,
} from '../../participantPages/participantDashboard/interface'
import { ProfileStatus } from '../../profileList/profileEnums'
import { ActivityId, ProfileId, UserId } from '@app/containers/reducer'

export interface IFocusInput {
  name: IFocusError
  surname: IFocusError
  emailAddress: IFocusError
}

export interface IEmployeeInfo {
  name: string
  surname: string
  emailAddress: string
  phoneNumber: string
}

export enum EmployeeInputs {
  name = 'name',
  surname = 'surname',
  emailAddress = 'emailAddress',
  phoneNumber = 'phoneNumber',
}

export const useUpdateEmployee = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const id = (Number(useParams().id) || 0) as UserId

  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)

  const initialProfileParticipantsStatusState: IProfileStatus = {
    activityId: 0 as ActivityId,
    profileId: 0 as ProfileId,
    activityName: '',
    profileDate: '',
    status: 0,
    isDemographicsFilled: false,
    isRoleFilled: false,
    isSelfFormFilled: false,
    isRespondentsInvited: false,
    isPresentationCreated: false,
    presentationId: 0,
    presentationName: '',
    facilitatorName: '',
    uniqueCode: '',
    presentationDate: '',
    presentationStatus: 0,
    presentationTemplateId: 0,
  }
  const initialDashboardDetailsState: IDashboardDetails = {
    profileStatus: initialProfileParticipantsStatusState,
    profiles: [],
    profileTimelines: [],
    profileCourseStudies: [],
  }
  const [dashboardDetails, setDashboardDetails] = useState<IDashboardDetails>(
    initialDashboardDetailsState
  )
  const [employeeInfo, setEmployeeInfo] = useState<IEmployeeInfo>({
    name: '',
    surname: '',
    emailAddress: '',
    phoneNumber: '',
  })
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    name: {
      touched: false,
      errorMessage: '',
    },
    surname: {
      touched: false,
      errorMessage: '',
    },
    emailAddress: {
      touched: false,
      errorMessage: '',
    },
  })
  const [currentEmail, setCurrentEmail] = useState<string>('')
  const [submitBtnEnabled, setSubmitBtnEnabled] = useState<boolean>(false)
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  useEffect(() => {
    if (id && defaultClient.defaultClientId) {
      dispatch(setSpinner(true))
      getEmployeeDetailsById(id, defaultClient.defaultClientId, dispatch).then((response) => {
        if (response) {
          setEmployeeInfo({
            name: response.name,
            surname: response.surname,
            emailAddress: response.emailAddress,
            phoneNumber: response.phoneNumber ?? '',
          })
          setCurrentEmail(response.emailAddress)
        }
      })
      getParticipantDashboardInfoForFacilitator(id, defaultClient.defaultClientId, dispatch)
        .then((response: IDashboardDetails) => {
          if (response) {
            const updatedDashboardDetails: IDashboardDetails = {
              profileStatus: response.profileStatus ?? [],
              profiles: response.profiles ?? [],
              profileTimelines: response.profileTimelines ?? [],
              profileCourseStudies: response.profileCourseStudies ?? [],
            }

            // what is this? why are we manipulating backend data? huh?
            //   -johan, 2024-11-12
            if (
              updatedDashboardDetails.profileStatus.status === ProfileStatus.Completed ||
              updatedDashboardDetails.profileStatus.status === ProfileStatus.Delivery
            ) {
              updatedDashboardDetails.profileStatus.isRoleFilled = true
              updatedDashboardDetails.profileStatus.isSelfFormFilled = true
              updatedDashboardDetails.profileStatus.isRespondentsInvited = true
            }
            if (updatedDashboardDetails.profileStatus.status === ProfileStatus.Delivery) {
              updatedDashboardDetails.profileStatus.isPresentationCreated = true
            }
            setDashboardDetails(updatedDashboardDetails)
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }, [id, defaultClient])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value
    if (name === EmployeeInputs.phoneNumber && value) {
      const isValid = ValidationHelper.isPhoneNumberValid(value)
      if (!isValid) return
    }

    setEmployeeInfo({
      ...employeeInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
    setSubmitBtnEnabled(true)
  }

  const handlePhoneInputChange = (value: string): void => {
    setEmployeeInfo({
      ...employeeInfo,
      phoneNumber: value,
    })
    setSubmitBtnEnabled(true)
  }

  const validatePhonenumber = (value: E164Number): void => {
    const phoneNumber = parseInt(value)

    if (phoneNumber === 0) {
      setPhoneNumberError(false)
    } else if (value.length >= 1 && value.length <= 4) {
      setPhoneNumberError(true)
    } else {
      setPhoneNumberError(false)
    }
  }

  const handleFormErrors = async (name: string, value: string) => {
    let errorMessage: string = ''
    const email = value

    switch (name) {
      case EmployeeInputs.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Firstname is required')
        }
        break
      case EmployeeInputs.surname:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Lastname is required')
        }
        break
      case EmployeeInputs.emailAddress:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Email is required')
        } else {
          const isValid = ValidationHelper.isEmailValid(value)
          if (!isValid) errorMessage = getLanguageValue(languageText, 'Invalid email')
          if (isValid) {
            if (currentEmail !== email) {
              await isEmployeeExists(email, defaultClient.defaultClientId, dispatch).then(
                (response) => {
                  if (response.result === true) {
                    errorMessage = getLanguageValue(languageText, 'Email already exists')
                  }
                }
              )
            }
          }
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !employeeInfo.name ||
      focusInput.name.errorMessage ||
      !employeeInfo.surname ||
      focusInput.surname.errorMessage ||
      !employeeInfo.emailAddress ||
      focusInput.emailAddress.errorMessage
    ) {
      for (const item in EmployeeInputs) {
        handleFormErrors(item, employeeInfo[item])
      }
      return false
    } else if (phoneNumberError) {
      return false
    }
    return true
  }

  const handleSubmit = (): void => {
    setSubmitBtnEnabled(false)
    dispatch(setSpinner(true))
    dispatch(clearNotifications(''))

    const empBody = {
      id: id,
      name: employeeInfo.name,
      surname: employeeInfo.surname,
      emailAddress: employeeInfo.emailAddress,
      phoneNumber: employeeInfo.phoneNumber,
      clientId: defaultClient.defaultClientId,
    }

    updateEmployee(empBody, dispatch)
      .then((response) => {
        if (response) {
          dispatch(addToast('Changes saved successfully') as AnyAction)
          navigate(routePath.employeeList)
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
        setSubmitBtnEnabled(true)
      })
  }

  const onSubmitClick = (): void => {
    if (!handleValidationOnSubmit()) return
    else {
      handleSubmit()
    }
  }

  const handleDeleteBtnClick = (): void => {
    setShowDeleteModal(true)
  }

  const closeDeleteModal = (): void => {
    setShowDeleteModal(false)
  }

  const handleDeleteEmployee = (): void => {
    deleteEmployeeById(id, defaultClient.defaultClientId, dispatch).then((response) => {
      if (response) {
        closeDeleteModal()
        navigate(routePath.employeeList)
      }
    })
  }

  return {
    languageText,
    dashboardDetails,
    employeeInfo,
    focusInput,
    phoneNumberError,
    submitBtnEnabled,
    showDeleteModal,
    onSubmitClick,
    handleBlurEvent,
    validatePhonenumber,
    handleInputChange,
    handlePhoneInputChange,
    handleDeleteBtnClick,
    handleDeleteEmployee,
    closeDeleteModal,
  }
}
