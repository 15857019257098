import React from 'react'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { PresentationInputKeys } from './hooks'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface ISendPresentationInvitationModalProps {
  languageText: ITranslationObject
  handleCloseInvitationModal: () => void
  handleSaveClick: (buttonClicked: PresentationInputKeys) => void
}

export const SendPresentationInvitationModal = (
  props: ISendPresentationInvitationModalProps
): JSX.Element => {
  const { languageText, handleCloseInvitationModal, handleSaveClick } = props

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Send invitation')}
        submitButtonText={getLanguageValue(languageText, 'Send')}
        secondarySubmitButtonText={getLanguageValue(languageText, `${`Don't send`}`)}
        cancelButtonText={getLanguageValue(languageText, 'Back')}
        secondarySubmitButtonColor='btn-secondary text-white'
        handleSubmitClick={() => handleSaveClick(PresentationInputKeys.send)}
        handleSecondarySubmitClick={() => handleSaveClick(PresentationInputKeys.dontSend)}
        handleCancelClick={handleCloseInvitationModal}
      >
        {getLanguageValue(
          languageText,
          'Would you like to send invitations to the participants at this time or at a later stage'
        )}
        ?
      </ModalComponent>
    </>
  )
}
