import React, { useState } from 'react'
import { E164Number } from 'libphonenumber-js/types'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { Input } from '../../../../components/formComponents/input'
import { TextArea } from '../../../../components/formComponents/textArea'
import { PhoneNumberInput } from '../../../employeeList/addEmployeeModal/phoneNumberInput'
import { ToggleCardCheckbox } from '../../../../components/formComponents/toggleCardCheckbox'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IAccountInfo, IFocusInput } from '../hooks'
import { AccountInputs } from '../clientEnums'
import { Checkbox } from '@app/components/formComponents/checkbox'
import { DeleteModal } from '@app/components/modals/deleteModal'
import { addToast, setSpinner } from '@app/containers/actions'
import { RootState } from '@app/store/configureStore'
import {
  deleteFacilitatorClient,
  updateFacilitatorDefaultClient,
} from '@app/containers/usersProfile/account/actions'
import { deleteClient } from '../../actions'
import { IUpdateFacilitatorDefaultClientBody } from '@app/containers/usersProfile/account/hooks'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'

interface IClientDetails {
  languageText: ITranslationObject
  clientId: number
  accountInfo: IAccountInfo
  focusInput: IFocusInput
  countries: IDropdownList[]
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void
  handleCountrySelect: (selectedItem: IDropdownSelectedItem) => void
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleTextAreaBlurEvent: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  handlePhoneInputChange: (value: E164Number) => void
  handleDifferentInvoiceAddress: () => void
  refreshClientList: () => void
  closeClientModal: () => void
}

export const ClientDetails = (props: IClientDetails) => {
  const {
    languageText,
    clientId,
    accountInfo,
    focusInput,
    countries,
    handleInputChange,
    handleCheckboxChange,
    handleBlurEvent,
    handleCountrySelect,
    handleTextAreaChange,
    handleTextAreaBlurEvent,
    handlePhoneInputChange,
    handleDifferentInvoiceAddress,
    closeClientModal,
    refreshClientList,
  } = props

  const dispatch = useDispatch()

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)
  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const handleDefaultClientChange = (): void => {
    dispatch(setSpinner(true))
    const body: IUpdateFacilitatorDefaultClientBody = {
      userId: userId,
      clientId,
      isDefaultClient: true,
    }
    updateFacilitatorDefaultClient(body, dispatch)
      .then((response) => {
        if (response) {
          dispatch(addToast('Changes saved successfully') as AnyAction)
          refreshClientList()
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const handleDeleteBtnClick = (): void => {
    setShowDeleteModal(true)
  }

  const closeDeleteModal = (): void => {
    setShowDeleteModal(false)
  }

  const handleDeleteAccount = (): void => {
    dispatch(setSpinner(true))
    if (isLoggedInRoleAdmin(loggedInUserRole)) {
      deleteClient(clientId, dispatch)
        .then((response) => {
          if (response) {
            closeClientModal()
            refreshClientList()
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    } else {
      deleteFacilitatorClient(userId, clientId, dispatch)
        .then((response) => {
          if (response) {
            closeClientModal()
            refreshClientList()
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  const showDeleteBtn =
    clientId && !accountInfo.isDefaultClient && defaultClient.defaultClientId !== clientId

  return (
    <>
      {clientId > 0 && (
        <div className='d-flex align-items-center justify-content-end gap-3 mb-3'>
          {!isLoggedInRoleAdmin(loggedInUserRole) && (
            <Checkbox
              label={getLanguageValue(languageText, 'Set as default client')}
              value={accountInfo.isDefaultClient}
              disable={accountInfo.isDefaultClient}
              handleCheckboxChange={handleDefaultClientChange}
            />
          )}
          {showDeleteBtn && (
            <div className='d-flex justify-content-end text-danger'>
              <span className='d-inline' role='button' onClick={handleDeleteBtnClick}>
                <i className='bi bi-trash3 me-1' />
                {getLanguageValue(languageText, 'Delete')}
              </span>
            </div>
          )}
        </div>
      )}

      <form>
        <div>
          <div className='row'>
            <div className='col-12 col-md-3'>
              <Input
                name={AccountInputs.name}
                label={getLanguageValue(languageText, 'Company Name')}
                placeholder={getLanguageValue(languageText, 'Company Name')}
                value={accountInfo.name}
                focusInput={focusInput.name}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
                required
              />
            </div>
            <div className='col-12 col-md-3'>
              <Input
                name={AccountInputs.businessIdentification}
                label={`${getLanguageValue(languageText, 'Business identification')} #`}
                placeholder={getLanguageValue(languageText, 'Business identification')}
                value={accountInfo.businessIdentification}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className='col-12 col-sm-6'>
              <Input
                name={AccountInputs.invoiceReference}
                label={getLanguageValue(languageText, 'Invoice Reference')}
                placeholder={getLanguageValue(languageText, 'Invoice Reference')}
                value={accountInfo.invoiceReference}
                handleInputChange={handleInputChange}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-12 col-md-9'>
              <Input
                name={AccountInputs.email}
                label={getLanguageValue(languageText, 'Email')}
                placeholder={getLanguageValue(languageText, 'Email')}
                value={accountInfo.email}
                focusInput={focusInput.email}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className='col-12 col-md-3'>
              <PhoneNumberInput
                languageText={languageText}
                name={AccountInputs.phone}
                label={getLanguageValue(languageText, 'Phone')}
                placeholder='+123 45 67 89'
                value={(accountInfo.phone as E164Number) || ''}
                phoneNumberErrorMsg={focusInput.phone.errorMessage}
                onChange={handlePhoneInputChange}
                onBlur={handleBlurEvent}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <TextArea
                name={AccountInputs.address}
                label={getLanguageValue(languageText, 'Address')}
                placeholder={getLanguageValue(languageText, 'Address')}
                value={accountInfo.address}
                focusInput={focusInput.address}
                onChange={handleTextAreaChange}
                handleBlurEvent={handleTextAreaBlurEvent}
                checkMarkNotRequired={true}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-3'>
              <Input
                name={AccountInputs.city}
                label={getLanguageValue(languageText, 'City')}
                placeholder={getLanguageValue(languageText, 'City')}
                value={accountInfo.city}
                focusInput={focusInput.city}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className='col-12 col-md-3'>
              <Input
                name={AccountInputs.zip}
                label={getLanguageValue(languageText, 'Zip Code')}
                placeholder={getLanguageValue(languageText, 'Zip Code')}
                type='number'
                value={accountInfo.zip}
                focusInput={focusInput.zip}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className='col-12 col-md-3'>
              <DropdownSelect
                name={AccountInputs.countryId}
                label={getLanguageValue(languageText, 'Country')}
                defaultLabel={getLanguageValue(languageText, 'Select Country')}
                list={countries}
                value={accountInfo.countryId}
                focusInput={focusInput.countryId}
                handleDropdownSelect={handleCountrySelect}
                searchOption
                required
              />
            </div>
            <div className='col-12 col-md-3'>
              <Input
                name={AccountInputs.euVatId}
                label={getLanguageValue(languageText, 'EU VAT ID (optional)')}
                placeholder={getLanguageValue(languageText, 'EU VAT ID')}
                value={accountInfo.euVatId}
                handleInputChange={handleInputChange}
              />
            </div>
          </div>

          <ToggleCardCheckbox
            header={getLanguageValue(languageText, 'Different invoice address')}
            description={getLanguageValue(languageText, 'Add a separate invoice address')}
            containerClass='col-12 col-md-5 mb-3'
            checked={accountInfo.differentInvoiceAddress}
            handleClick={handleDifferentInvoiceAddress}
          />

          {accountInfo.differentInvoiceAddress && (
            <>
              <div className='row'>
                <div className='col-12 col-md-12'>
                  <Input
                    name={AccountInputs.invoiceCompanyName}
                    label={getLanguageValue(languageText, 'Company Name')}
                    placeholder={getLanguageValue(languageText, 'Company Name')}
                    value={accountInfo.invoiceCompanyName}
                    focusInput={focusInput.invoiceCompanyName}
                    handleBlurEvent={handleBlurEvent}
                    handleInputChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-12'>
                  <TextArea
                    name={AccountInputs.invoiceAddress}
                    label={getLanguageValue(languageText, 'Address')}
                    placeholder={getLanguageValue(languageText, 'Address')}
                    value={accountInfo.invoiceAddress}
                    focusInput={focusInput.invoiceAddress}
                    handleBlurEvent={handleTextAreaBlurEvent}
                    onChange={handleTextAreaChange}
                    checkMarkNotRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-3'>
                  <Input
                    name={AccountInputs.invoiceCity}
                    label={getLanguageValue(languageText, 'City')}
                    placeholder={getLanguageValue(languageText, 'City')}
                    value={accountInfo.invoiceCity}
                    focusInput={focusInput.invoiceCity}
                    handleBlurEvent={handleBlurEvent}
                    handleInputChange={handleInputChange}
                  />
                </div>
                <div className='col-12 col-md-3'>
                  <Input
                    name={AccountInputs.invoiceZip}
                    label={getLanguageValue(languageText, 'Zip Code')}
                    placeholder={getLanguageValue(languageText, 'Zip Code')}
                    type='number'
                    value={accountInfo.invoiceZip}
                    focusInput={focusInput.invoiceZip}
                    handleBlurEvent={handleBlurEvent}
                    handleInputChange={handleInputChange}
                  />
                </div>
                <div className='col-12 col-md-3'>
                  <DropdownSelect
                    name={AccountInputs.invoiceCountryId}
                    label={getLanguageValue(languageText, 'Country')}
                    defaultLabel={getLanguageValue(languageText, 'Select Country')}
                    list={countries}
                    value={accountInfo.invoiceCountryId}
                    focusInput={focusInput.invoiceCountryId}
                    handleDropdownSelect={handleCountrySelect}
                    searchOption
                    required
                  />
                </div>
                <div className='col-12 col-md-3'>
                  <Input
                    name={AccountInputs.invoiceEUVATID}
                    label={getLanguageValue(languageText, 'EU VAT ID (optional)')}
                    placeholder={getLanguageValue(languageText, 'EU VAT ID')}
                    value={accountInfo.invoiceEUVATID}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </div>
            </>
          )}

          <div className='mb-3'>
            <Checkbox
              name={AccountInputs.enableParticipantCreateProfile}
              label={getLanguageValue(languageText, 'Can participants create profiles')}
              value={accountInfo.enableParticipantCreateProfile}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>

          <Input
            name={AccountInputs.sourceType}
            label={getLanguageValue(languageText, 'Source type')}
            placeholder={getLanguageValue(languageText, 'Source type')}
            value={accountInfo.sourceType}
            handleInputChange={handleInputChange}
          />

          <Input
            name={AccountInputs.sourceAddress}
            label={getLanguageValue(languageText, 'Source address')}
            placeholder={getLanguageValue(languageText, 'Source address')}
            value={accountInfo.sourceAddress}
            handleInputChange={handleInputChange}
          />
        </div>
      </form>

      {showDeleteModal && (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, 'Account')}
          bodyText={accountInfo.name}
          handleDeleteClick={handleDeleteAccount}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </>
  )
}
