import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { routePath } from '../../../routePaths'
import { courseStarted, getUserCourseParts, setCourseDetails } from '../../actions'
import { IMarkAsFinish } from '../../courseSummary/hooks'
import { setNotification } from '../../../actions'
import { ICourse } from '../../types'
import { CourseProgressBar } from './courseProgressBar'
import { ClassNames } from '@app/containers/commonEnums'
import { RootState } from '@app/store/configureStore'

interface ISingeCourseFooterProps {
  index: number
  courseId: number
  progress: number
  languageText: ITranslationObject
  canStartCourse: boolean
}

export const CourseCardFooter = (props: ISingeCourseFooterProps): JSX.Element => {
  const { index, courseId, progress, languageText } = props

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  const onStartCourse = async (): Promise<void> => {
    if (!props.canStartCourse) {
      return
    }

    const body: IMarkAsFinish = {
      userId: userId,
      courseId: Number(courseId),
    }

    const res: ICourse = await getUserCourseParts(courseId, dispatch)

    if (res && res.courseParts && res.courseParts.length > 0) {
      dispatch(setCourseDetails(res))
      const firstPartId = res.courseParts[0].id

      const courseUrl = routePath.participantCourse
        .replace(':courseId', courseId.toString())
        .replace(':partId', firstPartId.toString())

      const response = await courseStarted(body, dispatch)

      if (response) {
        navigate(courseUrl)
      }
    } else if (res && !res.courseParts?.length) {
      dispatch(setNotification('No course parts available'))
    }
  }

  return (
    <div className='card-footer border-0 p-4 pt-0 bg-transparent'>
      <button
        className='btn btn-success btn-lg'
        disabled={!props.canStartCourse}
        onClick={onStartCourse}
        title={
          !props.canStartCourse
            ? getLanguageValue(languageText, 'Course requires a delivered profile')
            : undefined
        }
      >
        {props.canStartCourse ? (
          <i className='bi bi-play-fill me-2' />
        ) : (
          <i className='bi bi-lock-fill me-2' />
        )}
        {getLanguageValue(languageText, 'Start Course')}
      </button>

      <p className='mt-3 mb-1'> {getLanguageValue(languageText, 'Status')}:</p>

      <CourseProgressBar
        id={index === 0 ? 'academyInstruction8' : ''}
        bgColor={ClassNames.Grey_bg}
        className='mt-0 pt-0'
        progress={progress}
      />
    </div>
  )
}
