import React from 'react'

import { RoleEnum, Roles } from '../commonEnums'
import { IUserRole, useUserList } from './hooks'
import { CustomModal as ResetPasswordModal } from '../../components/modals/customModal'
import { CustomModal as LoginUserModal } from '../../components/modals/customModal'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { AddEditUserModal } from './addEditUserModal/addEditUserModal'
import { ReactTable } from '../../components/reactTable/reactTable'
import { DeleteModal } from '../../components/modals/deleteModal'

export const UsersList = (props: IUserRole) => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    sorting,
    pageLimit,
    pageSelected,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    resetPaswordModal,
    loginUserModal,
    roleId,
    openUserModal,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    handleDeleteUser,
    handleResetPassword,
    handleLoginUser,
    closeCustomModal,
    addUserClick,
    closeUserModal,
    closeDeleteModal,
  } = useUserList(props)

  let role: string = Roles.Admin
  if (roleId === RoleEnum.Facilitator) role = Roles.Facilitator
  if (roleId === RoleEnum.Participant) role = Roles.Participant

  return (
    <>
      <ReactTable
        wrapperClass='p-2 pt-4 p-md-4'
        headerText={getLanguageValue(languageText, `${role}s`)}
        searchPlaceholder={getLanguageValue(languageText, `Search ${role}`)}
        buttonText={getLanguageValue(languageText, `Add ${role}`)}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        sorting={sorting}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        storePageConfiguredInfo={true}
        handleButtonClick={addUserClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {openUserModal || editedRowId ? (
        <AddEditUserModal
          languageText={languageText}
          editUserId={editedRowId}
          closeUserModal={closeUserModal}
          roleId={roleId}
          clientIdFromAccount={undefined}
        />
      ) : (
        ''
      )}

      {deleteRowId ? (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, role)}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeleteUser}
          closeDeleteModal={closeDeleteModal}
        />
      ) : (
        ''
      )}

      {resetPaswordModal && (
        <ResetPasswordModal
          headerText={getLanguageValue(languageText, 'Reset Password')}
          bodyText={`${getLanguageValue(
            languageText,
            `Do you want to reset password for`
          )} "${selectedActionRowName}"`}
          cancelButtonText={getLanguageValue(languageText, 'Cancel')}
          submitButtonText={getLanguageValue(languageText, 'Submit')}
          handleSubmitClick={handleResetPassword}
          handleCancelClick={closeCustomModal}
        />
      )}

      {loginUserModal && (
        <LoginUserModal
          headerText={getLanguageValue(languageText, 'Login')}
          bodyText={`${getLanguageValue(
            languageText,
            `Do you want to login as`
          )} "${selectedActionRowName}"`}
          cancelButtonText={getLanguageValue(languageText, 'Cancel')}
          submitButtonText={getLanguageValue(languageText, 'Login')}
          handleSubmitClick={handleLoginUser}
          handleCancelClick={closeCustomModal}
        />
      )}
    </>
  )
}
