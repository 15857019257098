import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { useEffect, useState } from 'react'
import { getClientList } from '../clientList/actions'
import { IClients, IActivity, IUsers, ModelSourceTypes } from '../commonInterfaces'
import { getActivityList } from '../profileList/actions'
import { IFetchActivitiesParams } from '../profileList/hooks'
import { IFetchUsersBody } from '../userList/hooks'
import { RoleEnum } from '../commonEnums'
import { getUserList } from '../userList/actions'
import { IUpcomingPresentationsParams, UpcomingPresentation } from '../presentationsList/upcoming'
import { getUpcomingPresentations } from '../presentationsList/actions'

export const useAdminDashboard = () => {
  const dispatch = useDispatch()

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [accounts, setAccounts] = useState<IClients[]>([])
  const [profiles, setProfiles] = useState<IActivity[]>([])
  const [users, setUsers] = useState<IUsers[]>([]) // Participants
  const [presentations, setPresentations] = useState<UpcomingPresentation[]>([])

  useEffect(() => {
    // Get the latest 5 accounts
    getClientList('', '', 500, 0, dispatch).then((response) => {
      if (response?.items.length > 0) {
        const latestFiveAccounts = response.items
          .filter((account) => account.sourceType !== ModelSourceTypes.Migrated) // Filter out migrated clients
          .sort((a, b) => b.id - a.id) // Sort by id to get the latest clients
          .slice(0, 5) // Get the latest 5 clients
        setAccounts(latestFiveAccounts)
      }
    })

    // Get the latest 5 profiles
    const profileParams: IFetchActivitiesParams = {
      filter: '',
      sorting: '',
      maxResultCount: 500,
      skipCount: 0,
      isFacilitator: false,
    }
    getActivityList(profileParams, dispatch).then((response) => {
      if (response?.items.length > 0) {
        const latestFiveProfiles = response.items
          .filter((account) => account.sourceType !== ModelSourceTypes.Migrated) // Filter out migrated accounts
          .sort((a, b) => b.id - a.id) // Sort by id to get the latest accounts
          .slice(0, 5) // Get the latest 5 accounts
        setProfiles(latestFiveProfiles)
      }
    })

    // Get the latest 5 users(participants)
    const usersBody: IFetchUsersBody = {
      filter: '',
      sorting: '',
      maxResultCount: 500,
      skipCount: 0,
      role: RoleEnum.Participant,
    }
    getUserList(usersBody, dispatch).then((response) => {
      if (response?.items.length > 0) {
        const latestFiveUsers = response.items
          .filter((account) => account.sourceType !== ModelSourceTypes.Migrated) // Filter out migrated users
          .sort((a, b) => b.id - a.id) // Sort by id to get the latest users
          .slice(0, 5) // Get the latest 5 users
        setUsers(latestFiveUsers)
      }
    })

    // Get the upcoming presentations
    const presentationParams: IUpcomingPresentationsParams = {
      filter: '',
      sorting: '',
      skipCount: 0,
      maxResultCount: 500,
      presentationDateStart: '',
      presentationDateEnd: '',
    }
    getUpcomingPresentations(presentationParams, dispatch).then((response) => {
      if (response?.items.length > 0) {
        const latestFivePresentations = [...response.items]
          .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
          .slice(0, 5)
        setPresentations(latestFivePresentations)
      }
    })
  }, [])

  return {
    languageText,
    accounts,
    profiles,
    users,
    presentations,
  }
}
