import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { IUpdateActivityRespondentsBody } from '../../../addProfile/hooks'
import { updateActivityRespondents } from '../../../addProfile/actions'
import {
  ActivityRespondentsStep,
  IInviteRespondents,
} from '../../../addProfile/activityRespondentsStep'
import { addToast, setSpinner } from '../../../../actions'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { AnyAction } from 'redux'
import { ActivityId } from '@app/containers/reducer'

interface IEditProfileRespondentModalProps {
  id: ActivityId
  languageText: ITranslationObject
  isGroup: boolean
  participantInviteExternal: boolean
  participantInviteColleagues: boolean
  participantInviteOtherParticipant: boolean
  closeModal: () => void
  handleSaveClick: (inviteRespondents: IInviteRespondents) => void
}

export const EditProfileRespondentModal: React.FC<IEditProfileRespondentModalProps> = (props) => {
  const {
    id,
    languageText,
    isGroup,
    participantInviteExternal,
    participantInviteColleagues,
    participantInviteOtherParticipant,
    closeModal,
    handleSaveClick,
  } = props

  const dispatch = useDispatch()

  const initialRespondentState: IInviteRespondents = {
    isInviteExternal: false,
    isInviteColleagues: false,
    isInviteOtherParticipant: false,
  }
  const [inviteRespondents, setInviteRespondents] =
    useState<IInviteRespondents>(initialRespondentState)

  useEffect(() => {
    setInviteRespondents({
      isInviteExternal: participantInviteExternal,
      isInviteColleagues: participantInviteColleagues,
      isInviteOtherParticipant: participantInviteOtherParticipant,
    })
  }, [])

  const handleRespondentsCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.target

    setInviteRespondents((prev) => ({
      ...prev,
      [name]: checked,
    }))
  }

  const onSaveClick = (): void => {
    dispatch(setSpinner(true))

    const body: IUpdateActivityRespondentsBody = {
      id,
      participantInviteExternal: inviteRespondents.isInviteExternal,
      participantInviteColleagues: inviteRespondents.isInviteColleagues,
      participantInviteOtherParticipant: inviteRespondents.isInviteOtherParticipant,
    }

    updateActivityRespondents(body, dispatch)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(addToast('Updated respondents') as AnyAction)
          handleSaveClick(inviteRespondents)
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
        closeModal()
      })
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Edit Respondents')}
        submitButtonText={getLanguageValue(languageText, 'Save')}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        handleSubmitClick={onSaveClick}
        handleCancelClick={closeModal}
      >
        <ActivityRespondentsStep
          languageText={languageText}
          isGroup={isGroup}
          respondentFromEditProfile={true}
          inviteRespondents={inviteRespondents}
          handleRespondentsCheckboxChange={handleRespondentsCheckboxChange}
          formName='editProfileRespondent'
        />
      </ModalComponent>
    </>
  )
}
