import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { E164Number } from 'libphonenumber-js/types'

import {
  getClientSubscriptionInvoiceDetails,
  createOrUpdateSubscriptionInvoice,
  sendSubscriptionInvoiceToFortnox,
} from './actions'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { getAllCountries } from '../../action'
import { getLanguageValue } from '../../../../../commonUtils/languageFunctionsHelper'
import { ValidationHelper } from '../../../../validationHelper'
import { IFocusError } from '../../../../../components/formComponents/input'
import { IPaymentTypes, getPaymentTypes } from '../../../../../commonUtils/planSuscriptionHelper'
import { PaymentType } from '../../clientEnums'
import { addToast, setSpinner } from '../../../../actions'
import { ISubscriptionAddInvoiceProps } from './subscriptionAddInvoice'
import {
  IInvoiceFocusInput,
  IInvoiceFormData,
  IProductData,
  ISubscriptionInvoiceDetails,
  InvoiceInputs,
  ProductInput,
} from '../../../../../components/invoice/invoiceInterface'
import {
  getFortnoxCustomers,
  getFortnoxInvoicesByCustomerNumber,
  getInvoiceArticlesByProfileOrClientSubscriptionId,
  updateFortnoxInvoiceStatus,
} from '../../../../profileList/editProfile/profileInvoiceBillingModal/actions'
import { ICountry } from '@app/containers/commonInterfaces'
import { ActivityId } from '@app/containers/reducer'

export const useSubscriptioInvoice = (props: ISubscriptionAddInvoiceProps) => {
  const {
    languageText,
    clientSubscriptionId,
    invoiceId,
    handleClientSubscription,
    handleCloseClick,
  } = props

  const dispatch = useDispatch()

  const paymentTypes: IPaymentTypes[] = useMemo(() => getPaymentTypes(languageText), [])

  const initialProfileInvoiceDetailsState: ISubscriptionInvoiceDetails = {
    idiInvoice: {
      idiClientId: 0,
      activityId: 0 as ActivityId,
      clientSubscriptionId: 0,
      idiLanguageId: 0,
      date: '',
      company: '',
      address: '',
      city: '',
      zip: '',
      country: 0,
      fortnoxInvoiceNumber: 0,
      fortnoxCustomerNumber: 0,
      fortnoxCustomerWithName: '',
      reference: '',
      businessIdentification: '',
      email: '',
      telephone: '',
      euvatid: '',
      subTotal: 0,
      taxAmount: 0,
      total: 0,
      currency: '',
      linkedToExistingFortnoxInvoice: true,
      fortnoxInvoiceStatus: '',
      id: 0,
      invoice: false,
      creditCard: false,
    },
    clientSubscriptionInvoiceRows: [],
    id: 0,
  }
  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: '',
  }
  const initialProfileAddressData: IInvoiceFormData = {
    paymentId: 0,
    company: '',
    businessIdentification: '',
    invoiceReference: '',
    email: '',
    telephone: '',
    address: '',
    city: '',
    zip: '',
    countryId: '',
    euvatid: '',
    invoiceId: 0,
    fortnoxCustomerNumber: '',
    fortnoxInvoiceNumber: '',
    date: '',
    currency: '',
    profileInvoiceRows: [],
  }
  const initialProductData: IProductData = {
    idiInvoiceId: 0,
    id: 0,
    articleId: 0,
    articleNumber: '',
    description: '',
    quantity: 0,
    price: 0,
    discount: 0,
    sum: 0,
    taxPercentage: 0,
    temporaryId: 0,
  }
  const initialInvoiceList: IDropdownList = {
    id: 0,
    displayName: 'New',
    value: '0',
  }
  const initialDropdownSelectedItemState: IDropdownSelectedItem = {
    id: 0,
    name: '',
    value: '',
  }

  const [countries, setCountries] = useState<IDropdownList[]>([])
  const [existingInvoiceDetails, setExistingInvoiceDetails] = useState<ISubscriptionInvoiceDetails>(
    initialProfileInvoiceDetailsState
  )
  const [invoiceFormData, setInvoiceFormData] =
    useState<IInvoiceFormData>(initialProfileAddressData)
  const [focusInput, setFocusInput] = useState<IInvoiceFocusInput>({
    company: initialFocusInputState,
    invoiceReference: initialFocusInputState,
    address: initialFocusInputState,
    city: initialFocusInputState,
    zip: initialFocusInputState,
    countryId: initialFocusInputState,
    businessIdentification: initialFocusInputState,
    email: initialFocusInputState,
    telephone: initialFocusInputState,
    articleId: initialFocusInputState,
    price: initialFocusInputState,
    quantity: initialFocusInputState,
    description: initialFocusInputState,
    sum: initialFocusInputState,
    fortnoxCustomerNumber: initialFocusInputState,
    fortnoxInvoiceNumber: initialFocusInputState,
  })
  const [fortnoxClientList, setFortnoxClientList] = useState<IDropdownList[]>([])
  const [fortnoxInvoiceList, setFortnoxInvoiceList] = useState<IDropdownList[]>([
    initialInvoiceList,
  ])
  const [selectedFortnoxCustomer, setSelectedFortnoxCustomer] = useState<IDropdownSelectedItem>(
    initialDropdownSelectedItemState
  ) // To Show Fortnox Customer Default Message
  const [showFortnoxInvoiceInputs, setShowFortnoxInvoiceInputs] = useState(false)
  const [productFormData, setProductFormData] = useState<IProductData>(initialProductData)
  const [productsList, setProductsList] = useState<IDropdownList[]>([])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const getFortnoxInvoices = async (fortnoxCustomerNumber: number): Promise<void> => {
    getFortnoxInvoicesByCustomerNumber(fortnoxCustomerNumber, dispatch).then((response) => {
      if (response) {
        const invoiceClient: IDropdownList[] = response.map((item) => ({
          id: item.documentNumber,
          displayName: item.documentNumber,
          value: String(item.documentNumber),
        }))

        const combinedArray = [initialInvoiceList, ...invoiceClient]

        setFortnoxInvoiceList(combinedArray)
      } else {
        setFortnoxInvoiceList([initialInvoiceList])
      }
    })
  }

  const getSubscriptionInvoiceData = (): void => {
    dispatch(setSpinner(true))
    getClientSubscriptionInvoiceDetails(clientSubscriptionId, invoiceId, dispatch)
      .then((response: ISubscriptionInvoiceDetails) => {
        if (response) {
          setExistingInvoiceDetails(response)
          const { idiInvoice, clientSubscriptionInvoiceRows } = response

          if (idiInvoice.fortnoxCustomerNumber) {
            getFortnoxInvoices(idiInvoice.fortnoxCustomerNumber)
          }

          if (idiInvoice.fortnoxInvoiceNumber) {
            setShowFortnoxInvoiceInputs(true)
          }

          // Added temporaryId's for product edit/delete purpose
          let updatedInvoiceRows: IProductData[] = []
          if (clientSubscriptionInvoiceRows?.length > 0) {
            updatedInvoiceRows = clientSubscriptionInvoiceRows.map((invoice, index) => ({
              ...invoice,
              temporaryId: index + 1,
            }))
          }

          setInvoiceFormData({
            company: idiInvoice.company ?? '',
            invoiceReference: idiInvoice.reference ?? '',
            address: idiInvoice.address ?? '',
            city: idiInvoice.city ?? '',
            zip: idiInvoice.zip ?? '',
            countryId: idiInvoice.country ? idiInvoice.country.toString() : '',
            businessIdentification: idiInvoice.businessIdentification ?? '',
            email: idiInvoice.email ?? '',
            telephone: idiInvoice.telephone ?? '',
            euvatid: idiInvoice.euvatid ?? '',
            paymentId: idiInvoice.creditCard ? PaymentType.CreditCard : PaymentType.Invoice,
            invoiceId: idiInvoice.id,
            fortnoxCustomerNumber: idiInvoice.fortnoxCustomerNumber
              ? idiInvoice.fortnoxCustomerNumber.toString()
              : '',
            fortnoxInvoiceNumber: idiInvoice.fortnoxInvoiceNumber
              ? idiInvoice.fortnoxInvoiceNumber.toString()
              : '0',
            date: idiInvoice.date,
            currency: idiInvoice.currency,
            profileInvoiceRows: updatedInvoiceRows,
          })
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    getSubscriptionInvoiceData()
  }, [invoiceId])

  const getAllInformationsRequired = (): void => {
    // Get Countries
    getAllCountries(dispatch).then((response: ICountry[]) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: String(item.id),
        }))
        setCountries(countries)
      }
    })

    //get fortnox customers
    let customers: IDropdownList[] = []
    getFortnoxCustomers(dispatch).then((response) => {
      if (response) {
        const customerArray = Object.entries(response).map(([id, value]) => ({
          id,
          value,
        }))

        customers = customerArray.map((item) => ({
          id: Number(item.id),
          displayName: String(item.value),
          value: String(item.id),
        }))
        setFortnoxClientList(customers)
      }
    })
  }

  useEffect(() => {
    getAllInformationsRequired()
  }, [])

  useEffect(() => {
    // Get Invoice products
    if (invoiceFormData.invoiceId) {
      getInvoiceArticlesByProfileOrClientSubscriptionId(clientSubscriptionId, false, dispatch).then(
        (response) => {
          if (response) {
            const productsArray = Object.entries(response).map(([id, value]) => ({
              id,
              value,
            }))
            const products: IDropdownList[] = productsArray.map((item) => ({
              id: Number(item.id),
              displayName: String(item.value),
              value: String(item.id),
            }))
            setProductsList(products)
          }
        }
      )
    }
  }, [invoiceFormData.invoiceId])

  useEffect(() => {
    // Get Invoice products
    if (existingInvoiceDetails.idiInvoice.fortnoxInvoiceNumber) {
      updateFortnoxInvoiceStatus(invoiceFormData.invoiceId, dispatch)
    }
  }, [existingInvoiceDetails.idiInvoice.fortnoxInvoiceNumber])

  // Payment Selection
  const handleSelectPaymentMethod = (paymentId: number) => {
    setInvoiceFormData({
      ...invoiceFormData,
      paymentId: PaymentType.Invoice === paymentId ? PaymentType.Invoice : PaymentType.CreditCard,
    })
  }

  // Company Address and Details
  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''
    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [InvoiceInputs.company]: 'Company name is required',
        [InvoiceInputs.invoiceReference]: 'Invoice is required',
        [InvoiceInputs.businessIdentification]: 'Business identification is required',
        [InvoiceInputs.email]: 'Email is required',
        [InvoiceInputs.address]: 'Address is required',
        [InvoiceInputs.city]: 'City is required',
        [InvoiceInputs.zip]: 'Zip code is required',
        [InvoiceInputs.countryId]: 'Country is required',
        [InvoiceInputs.fortnoxCustomerNumber]: 'Fortnox client is required',
        [InvoiceInputs.fortnoxInvoiceNumber]: 'Fortnox invoice is Required',
      }
      errorMessage = errorMessages[name] && getLanguageValue(languageText, errorMessages[name])
    }
    if (name === InvoiceInputs.email && value) {
      const isValid = ValidationHelper.isEmailValid(value)
      if (!isValid) errorMessage = getLanguageValue(languageText, 'Invalid email')
    }
    if (name === InvoiceInputs.telephone && value) {
      if (value.length >= 1 && value.length <= 4) {
        errorMessage = getLanguageValue(languageText, 'Invalid phonenumber')
      }
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target

    setInvoiceFormData({
      ...invoiceFormData,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleCountrySelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    setInvoiceFormData({
      ...invoiceFormData,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    handleFormErrors(name, value)
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setInvoiceFormData({
      ...invoiceFormData,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleTextAreaBlurEvent = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    const { name, value } = e.target
    handleFormErrors(name, value)
  }

  const handlePhoneInputChange = (value: E164Number): void => {
    setInvoiceFormData({
      ...invoiceFormData,
      telephone: value,
    })

    if (focusInput.telephone?.touched) {
      handleFormErrors(InvoiceInputs.telephone, value)
    }
  }

  // Fortnox Customer & Invoice selection
  const handleCloseCustomerDefaultPopup = (): void => {
    setSelectedFortnoxCustomer(initialDropdownSelectedItemState)
  }

  const handleFortnoxCustomerSelect = async (
    selectedItem: IDropdownSelectedItem
  ): Promise<void> => {
    const { name, value } = selectedItem

    setInvoiceFormData({
      ...invoiceFormData,
      fortnoxCustomerNumber: value,
      fortnoxInvoiceNumber: '0',
    })

    dispatch(setSpinner(true))
    await getFortnoxInvoices(Number(value))
    dispatch(setSpinner(false))

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleSaveCustomerAsDefault = (): void => {
    handleFortnoxCustomerSelect(selectedFortnoxCustomer)
    setSelectedFortnoxCustomer(initialDropdownSelectedItemState)
  }

  const handleFortnoxClientSelect = (selectedItem: IDropdownSelectedItem): void => {
    if (
      existingInvoiceDetails.idiInvoice.fortnoxCustomerNumber &&
      selectedItem.id &&
      existingInvoiceDetails.idiInvoice.fortnoxCustomerNumber !== selectedItem.id
    ) {
      setSelectedFortnoxCustomer(selectedItem)
    } else handleFortnoxCustomerSelect(selectedItem)
  }

  const handleInvoiceSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    setInvoiceFormData({
      ...invoiceFormData,
      fortnoxInvoiceNumber: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  // Edit Product
  const handleProductFormErrors = (name: string, value: string | number): void => {
    let errorMessage: string = ''
    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [ProductInput.articleId]: 'Product is required',
        [ProductInput.description]: 'Description is required',
        [ProductInput.price]: 'Price is required',
        [ProductInput.quantity]: 'Quantity is required',
      }
      errorMessage = errorMessages[name] && getLanguageValue(languageText, errorMessages[name])
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleProductSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    const updatedArticleNumber =
      productsList.find((product) => product.value === value)?.displayName.toString() ?? ''

    setProductFormData({
      ...productFormData,
      [name]: value,
      articleNumber: updatedArticleNumber,
    })

    if (focusInput[name]?.touched) {
      handleProductFormErrors(name, value)
    }
  }

  const handleProductInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target

    setProductFormData((prevFields) => {
      const next: IProductData = {
        ...prevFields,
        [name]: value,
      }

      next.sum = Number(next.quantity) * Number(next.price) - Number(next.discount)

      return next
    })

    if (focusInput[name]?.touched) {
      handleProductFormErrors(name, value)
    }
  }

  const handleCancelProduct = (): void => {
    setProductFormData(initialProductData)
    if (isEditMode) setIsEditMode(false)
  }

  const handleValidationOnAddEditProduct = (): boolean => {
    if (
      !productFormData.articleId ||
      focusInput.articleId.errorMessage ||
      !productFormData.description ||
      focusInput.description.errorMessage ||
      !productFormData.price ||
      focusInput.price.errorMessage ||
      !productFormData.quantity ||
      focusInput.quantity.errorMessage
    ) {
      for (const item in ProductInput) {
        if (item === ProductInput.discount) break
        handleProductFormErrors(item, productFormData[item])
      }
      return false
    }
    return true
  }

  const handleAddProduct = () => {
    if (!handleValidationOnAddEditProduct()) return

    // Adding temporary Id
    let updatedProductFormData: IProductData = initialProductData
    updatedProductFormData = {
      ...productFormData,
      temporaryId: invoiceFormData.profileInvoiceRows.length + 1,
    }

    setInvoiceFormData({
      ...invoiceFormData,
      profileInvoiceRows: [...invoiceFormData.profileInvoiceRows, updatedProductFormData],
    })
    handleCancelProduct()
  }

  const handleSaveProduct = () => {
    if (!handleValidationOnAddEditProduct()) return

    const existingProductIndex = invoiceFormData.profileInvoiceRows.findIndex(
      (product) => product.temporaryId === productFormData.temporaryId
    )

    const updatedProducts = [...invoiceFormData.profileInvoiceRows]
    updatedProducts[existingProductIndex] = {
      ...updatedProducts[existingProductIndex],
      ...productFormData,
    }

    setInvoiceFormData({
      ...invoiceFormData,
      profileInvoiceRows: updatedProducts,
    })
    handleCancelProduct()
  }

  const handleEditProduct = (id: number) => {
    const editProduct =
      invoiceFormData.profileInvoiceRows.find((product) => product.temporaryId === id) ??
      initialProductData

    setIsEditMode(true)
    setProductFormData(editProduct)
    setFocusInput({
      ...focusInput,
      articleId: initialFocusInputState,
      price: initialFocusInputState,
      quantity: initialFocusInputState,
      description: initialFocusInputState,
      sum: initialFocusInputState,
    })
  }

  const handleDeleteProduct = (id: number) => {
    const updatedProducts = [...invoiceFormData.profileInvoiceRows]
      .filter((product) => product.temporaryId !== id)
      .map((product, index) => ({
        ...product,
        temporaryId: index + 1,
      }))

    setInvoiceFormData({
      ...invoiceFormData,
      profileInvoiceRows: updatedProducts,
    })
  }

  //  Create / Update Invoice draft
  const handleInvoiceFormValidation = (invoiceFormData: IInvoiceFormData): boolean => {
    if (
      !invoiceFormData.company ||
      focusInput.company.errorMessage ||
      !invoiceFormData.businessIdentification ||
      focusInput.businessIdentification.errorMessage ||
      !invoiceFormData.invoiceReference ||
      focusInput.invoiceReference.errorMessage ||
      !invoiceFormData.email ||
      focusInput.email.errorMessage ||
      focusInput.telephone.errorMessage ||
      !invoiceFormData.address ||
      focusInput.address.errorMessage ||
      !invoiceFormData.city ||
      focusInput.city.errorMessage ||
      !invoiceFormData.zip ||
      focusInput.zip.errorMessage ||
      !invoiceFormData.countryId ||
      focusInput.countryId.errorMessage ||
      !invoiceFormData.fortnoxCustomerNumber ||
      focusInput.fortnoxCustomerNumber.errorMessage ||
      !invoiceFormData.fortnoxInvoiceNumber ||
      focusInput.fortnoxInvoiceNumber.errorMessage
    ) {
      for (const item in InvoiceInputs) {
        if (item === InvoiceInputs.euvatid) break
        handleFormErrors(item, invoiceFormData[item])
      }
      return false
    }
    return true
  }

  const createOrUpdateInvoiceDraft = (): void => {
    if (!handleInvoiceFormValidation(invoiceFormData)) return

    dispatch(setSpinner(true))

    const { idiInvoice } = existingInvoiceDetails
    const customerName =
      fortnoxClientList
        .find((client) => client.value === invoiceFormData.fortnoxCustomerNumber)
        ?.displayName.toString() ?? ''
    const subTotal = invoiceFormData.profileInvoiceRows.reduce((acc, item) => acc + item.sum, 0)
    const taxAmount = parseFloat((subTotal * 0.25).toFixed(2))
    const netTotal = Number((subTotal + taxAmount).toFixed(2))

    const updatedProducts: IProductData[] = [...invoiceFormData.profileInvoiceRows].map(
      (product) => {
        const { temporaryId, ...rest } = product
        return rest
      }
    )

    const body: ISubscriptionInvoiceDetails = {
      id: existingInvoiceDetails.id,
      idiInvoice: {
        idiClientId: idiInvoice.idiClientId,
        activityId: idiInvoice.activityId,
        clientSubscriptionId: clientSubscriptionId,
        idiLanguageId: idiInvoice.idiLanguageId,
        date: idiInvoice.date,
        company: invoiceFormData.company,
        address: invoiceFormData.address,
        city: invoiceFormData.city,
        zip: invoiceFormData.zip,
        country: Number(invoiceFormData.countryId),
        fortnoxInvoiceNumber: Number(invoiceFormData.fortnoxInvoiceNumber),
        fortnoxCustomerNumber: Number(invoiceFormData.fortnoxCustomerNumber),
        fortnoxCustomerWithName: customerName,
        reference: invoiceFormData.invoiceReference,
        businessIdentification: invoiceFormData.businessIdentification,
        email: invoiceFormData.email,
        telephone: invoiceFormData.telephone,
        euvatid: invoiceFormData.euvatid,
        subTotal: subTotal,
        taxAmount: taxAmount,
        total: netTotal,
        currency: idiInvoice.currency,
        linkedToExistingFortnoxInvoice: true,
        fortnoxInvoiceStatus: idiInvoice.fortnoxInvoiceStatus,
        id: idiInvoice.id,
        invoice: invoiceFormData.paymentId === PaymentType.Invoice,
        creditCard: invoiceFormData.paymentId === PaymentType.CreditCard,
      },
      clientSubscriptionInvoiceRows: updatedProducts,
    }

    createOrUpdateSubscriptionInvoice(body, dispatch)
      .then((response: number) => {
        if (response) {
          getSubscriptionInvoiceData()
          handleClientSubscription(clientSubscriptionId, response)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  // Send to Fortnox
  const handleSendToFortnox = (): void => {
    dispatch(setSpinner(true))

    sendSubscriptionInvoiceToFortnox(
      clientSubscriptionId,
      existingInvoiceDetails.idiInvoice.id,
      dispatch
    )
      .then((response) => {
        if (response) {
          handleClientSubscription(clientSubscriptionId)
          dispatch(addToast('Successfully send to fortnox') as AnyAction)
          handleCloseClick()
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  return {
    countries,
    paymentTypes,
    existingInvoiceDetails,
    invoiceFormData,
    focusInput,
    fortnoxClientList,
    fortnoxInvoiceList,
    selectedFortnoxCustomer,
    showFortnoxInvoiceInputs,
    productFormData,
    isEditMode,
    productsList,
    handleSelectPaymentMethod,
    handleInputChange,
    handlePhoneInputChange,
    handleTextAreaChange,
    handleCountrySelect,
    handleBlurEvent,
    handleTextAreaBlurEvent,
    handleFortnoxClientSelect,
    handleInvoiceSelect,
    handleCloseCustomerDefaultPopup,
    handleSaveCustomerAsDefault,
    handleProductSelect,
    handleProductInputChange,
    handleAddProduct,
    handleSaveProduct,
    handleCancelProduct,
    handleEditProduct,
    handleDeleteProduct,
    createOrUpdateInvoiceDraft,
    handleSendToFortnox,
  }
}
