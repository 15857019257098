import React from 'react'

import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from './modalComponent'

type IDeleteModalProps = React.PropsWithChildren<{
  languageText: ITranslationObject
  headerText: string
  bodyText: string
  handleDeleteClick: React.MouseEventHandler<HTMLButtonElement>
  closeDeleteModal: React.MouseEventHandler<HTMLButtonElement>
}>

export const DeleteModal: React.FC<IDeleteModalProps> = (props) => {
  const { languageText, headerText, bodyText, handleDeleteClick, closeDeleteModal } = props
  return (
    <>
      <ModalComponent
        headerText={`${getLanguageValue(languageText, 'Delete')} ${headerText}`}
        submitButtonText={getLanguageValue(languageText, 'Delete')}
        cancelButtonText={getLanguageValue(languageText, 'Discard')}
        handleSubmitClick={handleDeleteClick}
        handleCancelClick={closeDeleteModal}
        isSubmitDangerButton={true}
      >
        {getLanguageValue(languageText, 'Do you really want to delete')} "
        {bodyText && `${bodyText}`}"?
        {props.children}
      </ModalComponent>
    </>
  )
}
