import React from 'react'

import { ParticipantProfileGraph } from './participantProfileGraph'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IMyProfiles } from '../../myIDIProfilesPage/hooks'

interface IParticipantProfileProps {
  languageText: ITranslationObject
  profiles: ReadonlyArray<IMyProfiles>
}

export const ParticipantProfile = (props: IParticipantProfileProps): JSX.Element => {
  return (
    <div className='card h-100 shadow-sm bg-white rounded border-0'>
      <div className='card-header border-0 p-3 fs-5 text-body-tertiary'>
        {getLanguageValue(props.languageText, 'Profiles')}
      </div>
      <div className='card-body p-4'>
        <div className='mx-4'>
          <ParticipantProfileGraph languageText={props.languageText} profiles={props.profiles} />
        </div>
      </div>
    </div>
  )
}
