import React from 'react'

export const Spinner = (): JSX.Element => {
  return (
    <div className='container'>
      <div className='position-fixed top-0 start-0 end-0 bottom-0 zindex-toast d-flex justify-content-center align-items-center bg-transparent'>
        <span className='spinner-border text-primary' role='status' />
      </div>
    </div>
  )
}
