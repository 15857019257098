import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../api'
import { setNotification } from '../actions'
import { IAccessCodeBody, IVerifyUserEmailBody } from '../auth/signUp/hooks'
import { AuthenticateResult, loggedInUserId, setLoggedInUserRole } from '../auth/signUp/actions'
import { ApiResponse } from '@app/types'

// is this 2FA? huh?
export const logInWithAccessCode = async (
  body: IAccessCodeBody,
  dispatch: Dispatch
): Promise<AuthenticateResult> => {
  try {
    const response = await api.post<ApiResponse<AuthenticateResult>>(
      apiEndPoints.logIn,
      JSON.stringify(body)
    )
    if (!response.data.success) {
      return Promise.reject(response.data)
    }

    const accessTokens = [response.data.result.accessToken]
    const { userId } = response.data.result
    dispatch(loggedInUserId(userId))

    if (response.data.result.accessToken) {
      // Setting logged in role
      const roleName = response.data.result.accessToken.roleName
      dispatch(setLoggedInUserRole(roleName))

      // FIXME: hack for localhost frontend on non-localhost backend
      window.localStorage.setItem('logged_in', '1')
    }

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const verifyUserEmailConfirmCode = async (
  body: IVerifyUserEmailBody,
  dispatch: Dispatch
): Promise<AuthenticateResult> => {
  try {
    const response = await api.post<ApiResponse<AuthenticateResult>>(
      apiEndPoints.verifyUserEmailConfirmCode,
      JSON.stringify(body),
      {}
    )
    if (!response.data.success) {
      return Promise.reject(response.data)
    }
    const { userId } = response.data.result
    dispatch(loggedInUserId(userId))

    if (response.data.result.accessToken) {
      // Setting logged in role
      const roleName = response.data.result.accessToken.roleName
      dispatch(setLoggedInUserRole(roleName))

      // FIXME: hack for localhost frontend on non-localhost backend
      window.localStorage.setItem('logged_in', '1')
    }

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

type ISwitchUserRoleBody = {
  userRoleId: number
}

export function switchUserRole(
  userRoleId: number,
  dispatch: Dispatch
): Promise<AuthenticateResult> {
  const body: ISwitchUserRoleBody = {
    userRoleId: userRoleId,
  }
  return api.post<ApiResponse<AuthenticateResult>>(apiEndPoints.switchUserRole, body).then(
    (res) => {
      if (!res.data.success) {
        return Promise.reject(res.data)
      }
      // Setting logged in role
      const roleName = res.data.result.accessToken.roleName
      dispatch(setLoggedInUserRole(roleName))
      return res.data.result
    },
    (err) => {
      dispatch(setNotification(err))
      return Promise.reject(err)
    }
  )
}
