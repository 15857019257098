import React from 'react'
import clsx from 'clsx'

import { KTSVG } from '@app/commonUtils/metronic'

interface IToggleCardCheckboxProps {
  keys?: number
  header: string
  description: string
  checked: boolean
  icon?: string
  containerClass?: string
  additionalInfoHeader?: string
  strikeThroughHeader?: string
  additionalInfoDescription?: string
  handleClick: () => void
}

export const ToggleCardCheckbox = (props: IToggleCardCheckboxProps) => {
  const {
    keys,
    header,
    description,
    icon,
    checked,
    containerClass,
    additionalInfoHeader,
    strikeThroughHeader,
    additionalInfoDescription,
    handleClick,
  } = props

  return (
    <div key={keys} className={containerClass}>
      <div
        onClick={handleClick}
        className={clsx('p-3 border rounded', checked ? 'border-success' : '')}
        role='button'
      >
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            {icon && <KTSVG path={icon} className='svg-icon-1 me-4 svg-icon-dark' />}
            <div className='d-flex flex-column'>
              <h5>{header}</h5>
              <span className='text-secondary'>{description}</span>
            </div>
          </div>
          <i
            className={clsx(
              'bi fs-3',
              checked ? 'text-success  bi-check-circle-fill' : 'bi-circle'
            )}
          />
        </div>
        {additionalInfoHeader && (
          <div className='mt-2'>
            <span className='fw-bold me-2'>{additionalInfoHeader}</span>
            {strikeThroughHeader && <s className='me-2'>{strikeThroughHeader}</s>}
            <span className='text-secondary fw-bold'>{additionalInfoDescription}</span>
          </div>
        )}
      </div>
    </div>
  )
}
