import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'

enum Behave {
  Affiliation = 1253,
  Directiveness = 1255,
  Adaptability = 1256,
}

const getBehaveOptions = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: Behave.Affiliation,
    value: Behave.Affiliation.toString(),
    displayName: getLanguageValue(languageText, 'Affiliation'),
  },
  {
    id: Behave.Directiveness,
    value: Behave.Directiveness.toString(),
    displayName: getLanguageValue(languageText, 'Directiveness'),
  },
  {
    id: Behave.Adaptability,
    value: Behave.Adaptability.toString(),
    displayName: getLanguageValue(languageText, 'Adaptability'),
  },
]

export { Behave, getBehaveOptions }
