import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Select from 'react-select'
import { IFocusError } from './input'
import { classNames } from '@app/containers/utils'

export interface IMultiDropdownList {
  id: number
  label: string | JSX.Element
  value: string
}

interface IDropdownSelectProps {
  label?: string
  name: string
  required?: boolean
  list: IMultiDropdownList[]
  placeholder: string
  value?: Array<string>
  focusInput?: IFocusError
  disabled?: boolean
  handleMultiDropdownSelect: (name: string, value: Array<string>) => void
  narrow?: boolean
}

export const DropdownMultiSelect = (props: IDropdownSelectProps): JSX.Element => {
  const {
    label,
    name,
    required,
    list,
    placeholder,
    value,
    focusInput,
    disabled = false,
    handleMultiDropdownSelect,
  } = props

  const [selectedOptions, setSelectedOptions] = useState<IMultiDropdownList[] | null>(null)

  useEffect(() => {
    let defaultSelectedOptions: IMultiDropdownList[] = []
    if (value!.length > 0 && list.length > 0) {
      defaultSelectedOptions = list.filter((item) => value?.includes(item.value))
    }
    setSelectedOptions(defaultSelectedOptions)
  }, [list, value])

  const handleChange = (selectedItems: Array<IMultiDropdownList>) => {
    let selectedValues: string[] = selectedItems.map((item) => item.value)
    if (!selectedValues) selectedValues = []
    handleMultiDropdownSelect(name, selectedValues)
  }

  const clazz = classNames({
    'mb-3': !props.narrow,
  })

  return (
    <div className={clazz}>
      {label && <label className={clsx('mb-1', { required: required })}>{label}</label>}

      <Select
        closeMenuOnSelect={false}
        isMulti
        options={list}
        value={selectedOptions}
        hideSelectedOptions={false}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={handleChange}
      />

      {focusInput?.errorMessage && (
        <div className='invalid-feedback d-inline-block'>{focusInput.errorMessage}</div>
      )}
    </div>
  )
}
