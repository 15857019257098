import * as React from 'react'
import ReactPaginate from 'react-paginate'
import { getWindowDimensions, IWindowDimensions } from '../../commonUtils/screenWidthHelper'
import { IPageSelected } from './interface'
import { ILanguageText } from '@app/containers/reducer'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'

type Props = {
  handlePageChange: (next: IPageSelected) => void
  handlePageLimit: React.ChangeEventHandler<HTMLSelectElement>
  languageText: ILanguageText
  pageCount: number | undefined
  pageLimit: number | undefined
  pageSelected: number | undefined
  totalCount: number | undefined
}

export const Pagination: React.FC<Props> = (props) => {
  const [windowDimensions, setWindowDimensions] =
    React.useState<IWindowDimensions>(getWindowDimensions())

  React.useEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className='d-flex justify-content-between align-items-center flex-nowrap mt-4'>
      {Number(props.totalCount) > 10 && props.pageCount !== undefined && (
        <>
          <div className='d-flex me-2'>
            <div className='border d-flex bg-secondary bg-opacity-10 border-end-0 rounded-start-2 p-2 fw-bold small'>
              {' '}
              <i className='bi bi-hash'></i> {props.totalCount}
            </div>
            <select
              className='form-select rounded-start-0 border bg-white'
              value={props.pageLimit}
              onChange={props.handlePageLimit}
            >
              <option value='10'>{getLanguageValue(props.languageText, 'Show')} 10 </option>
              {Number(props.totalCount) > 50 && (
                <>
                  <option value='50'>{getLanguageValue(props.languageText, 'Show')} 50 </option>
                </>
              )}
              {Number(props.totalCount) > 100 && (
                <>
                  <option value='100'>{getLanguageValue(props.languageText, 'Show')} 100</option>
                </>
              )}
              <option value={props.totalCount}>
                {getLanguageValue(props.languageText, 'Show All')} {props.totalCount}
              </option>
            </select>
          </div>

          <ReactPaginate
            previousLabel={<i className='bi bi-chevron-left'></i>}
            nextLabel={<i className='bi bi-chevron-right'></i>}
            breakLabel={windowDimensions.width > 700 && '...'}
            breakClassName='page-item'
            breakLinkClassName='page-link'
            pageCount={props.pageCount}
            pageRangeDisplayed={windowDimensions.width > 700 ? 3 : 1}
            marginPagesDisplayed={windowDimensions.width > 700 ? 1 : 1}
            onPageChange={props.handlePageChange}
            containerClassName='pagination mb-0'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            activeClassName='active'
            forcePage={props.pageSelected}
          />
        </>
      )}
    </div>
  )
}
