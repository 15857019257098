enum AccountInputs {
  name = 'name',
  countryId = 'countryId',
  invoiceCompanyName = 'invoiceCompanyName',
  invoiceCountryId = 'invoiceCountryId',
  businessIdentification = 'businessIdentification',
  invoiceReference = 'invoiceReference',
  email = 'email',
  phone = 'phone',
  address = 'address',
  city = 'city',
  zip = 'zip',
  differentInvoiceAddress = 'differentInvoiceAddress',
  invoiceAddress = 'invoiceAddress',
  invoiceCity = 'invoiceCity',
  invoiceZip = 'invoiceZip',
  euVatId = 'euVatId',
  invoiceEUVATID = 'invoiceEUVATID',
  teamSize = 'teamSize',
  billingPeriod = 'billingPeriod',
  enableParticipantCreateProfile = 'enableParticipantCreateProfile',
  sourceType = 'sourceType',
  sourceAddress = 'sourceAddress',
}

enum BillingInputs {
  selectedPaymentMethodId = 'selectedPaymentMethodId',
  paymentTerms = 'paymentTerms',
  price = 'price',
  teamSize = 'teamSize',
  startDate = 'startDate',
  selectedPlan = 'selectedPlan',
  endDate = 'endDate',
  status = 'status',
  payment = 'payment',
  paymentAfterEnd = 'paymentAfterEnd',
  currencyId = 'currencyId',
}

enum NavTabs {
  clientDetails = 'clientDetails',
  billing = 'billing',
  users = 'users',
  profiles = 'profiles',
}

enum BillingPeriodType {
  Annual = 1,
  Monthly = 2,
}

enum SubscriptionType {
  Plan = 1, // per profile
  Subscription = 2, // monthly/yearly
}

enum PaymentTerms {
  '30 days' = 30,
  '60 days' = 60,
  '90 days' = 90,
}

enum PaymentTermsType {
  D30 = 30,
  D60 = 60,
  D90 = 90,
}

enum PaymentType {
  Invoice = 1,
  CreditCard = 2,
}

enum PaymentStatus {
  Trail = 1,
  Active = 2,
  Paused = 3,
  Cancelled = 4,
}

enum Payment {
  NotInvoiced = 1,
  Invoiced = 2,
  Paid = 3,
  Overdue = 4,
  Probono = 5,
}

enum PaymentAfterEnd {
  AskToRenew = 1,
  AutoRenew = 2,
  AskToStart = 3,
  Cancel = 4,
}

export {
  AccountInputs,
  BillingInputs,
  NavTabs,
  BillingPeriodType,
  SubscriptionType,
  PaymentTerms,
  PaymentTermsType,
  PaymentType,
  PaymentStatus,
  Payment,
  PaymentAfterEnd,
}
