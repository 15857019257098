import React, { useEffect, useState } from 'react'

import { InvoicedFilter, ActivityStatus } from '@app/containers/profileList/profileEnums'
import { SubscriptionType } from '@app/containers/clientList/addEditModal/clientEnums'

export interface IFilterObject {
  status: ActivityStatus | null
  clientName: string
  facilitatorName: string
  dateFrom: Date | null
  dateTo: Date | null
  addedProfilesMin: number | null
  addedProfilesMax: number | null
  completedProfilesMin: number | null
  completedProfilesMax: number | null
  source: string
  invoiced: InvoicedFilter | null
  subscriptionType: SubscriptionType | null
}

export const useActivityFilters = (
  initialFilterObject: IFilterObject,
  clearFilterOptions: boolean
) => {
  const [filterOptions, setFilterOptions] = useState<IFilterObject>(initialFilterObject)

  useEffect(() => {
    setFilterOptions(initialFilterObject)
  }, [clearFilterOptions])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value
    setFilterOptions((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleDateSelect = (name: string, date: Date) => {
    setFilterOptions((prevState) => ({
      ...prevState,
      [name]: date,
    }))
  }

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const name = e.target.name
    const value = e.target.value
    setFilterOptions((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return {
    filterOptions,
    handleInputChange,
    handleDateSelect,
    handleDropdownChange,
  }
}
