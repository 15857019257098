import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { IDocumentList, DocumentInputs, IFocusInput, IDocumentCategoryResponse } from '../types'
import { createDocs, updateDocs } from './actions'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { getAllDocumentCategory } from '../actions'
import { addToast, setSpinner } from '../../../actions'
import { getLanguages } from '../../../languageList/actions'
import { RenderFlagComponent } from '../../../languageList/renderFlagComponent'
import { IFocusError } from '../../../../components/formComponents/input'
import { ILanguage } from '@app/containers/commonInterfaces'
import { AnyAction } from 'redux'

export interface IAddEditDocsModalProps {
  rowData: any
  closeDocsModal: (refreshNews?: boolean) => void
}

export const useAddEditDocs = (props: IAddEditDocsModalProps) => {
  const { rowData, closeDocsModal } = props
  const dispatch = useDispatch()

  const [languages, setLanguages] = useState<IDropdownList[]>([])
  const [documentCategory, setDocumentCategory] = useState<IDropdownList[]>([])
  const [isEditClicked, setIsEditClicked] = useState<boolean>(false)
  const [fileName, setFileName] = useState<string>('')
  const [originalFile, setOriginalFile] = useState<string>('')
  const documentCategoryParams = {
    filter: '',
    sorting: '',
    maxResultCount: 10,
    skipCount: 0,
    dispatch: dispatch,
  }

  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: '',
  }

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    title: initialFocusInputState,
    file: initialFocusInputState,
    excerpt: initialFocusInputState,
    publishedDate: initialFocusInputState,
    documentCategoryId: initialFocusInputState,
    iDILanguageId: initialFocusInputState,
  })

  const [docData, setDocumentData] = useState<IDocumentList>({
    id: 0,
    title: '',
    publishedDate: new Date(),
    excerpt: '',
    file: '',
    documentFile: null,
    originalFileName: '',
    published: false,
    type: '',
    category: '',
    documentCategoryId: '',
    language: '',
    iDILanguageId: '',
  })

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const updateDocsData = (): void => {
    setFileName(rowData.file)
    setOriginalFile(rowData.originalFileName)
    setDocumentData({
      ...docData,
      id: rowData.id,
      title: rowData.title,
      excerpt: rowData.excerpt,
      file: rowData.file,
      originalFileName: rowData.originalFileName,
      published: rowData.published,
      publishedDate: new Date(rowData.publishedDate),
      type: rowData.type,
      category: rowData.category,
      documentCategoryId: rowData.documentCategoryId,
      documentFile: rowData.documentFile,
      iDILanguageId: rowData.idiLanguageId,
      language: rowData.language,
    })
  }

  //useeffects for addedit news modal
  useEffect(() => {
    if (Object.keys(rowData).length > 0) {
      updateDocsData()
      setIsEditClicked(true)
    }
  }, [])

  useEffect(() => {
    dispatch(setSpinner(true))
    getLanguages(dispatch).then((response) => {
      if (response) {
        let existingLanguages: IDropdownList[] = []
        const existingLanguagesData: ILanguage[] = [...response.items]
        existingLanguages = existingLanguagesData.map((item) => ({
          id: item.id,
          displayName: <RenderFlagComponent flagName={item.icon} value={item.displayName} />,
          value: item.id.toString(),
        }))
        setLanguages(existingLanguages)
      }
    })

    getAllDocumentCategory(
      documentCategoryParams.filter,
      documentCategoryParams.sorting,
      documentCategoryParams.maxResultCount,
      documentCategoryParams.skipCount,
      documentCategoryParams.dispatch
    )
      .then((response) => {
        if (response) {
          let existingCategories: IDropdownList[] = []
          const existingCategoriesData: IDocumentCategoryResponse[] = [...response.items]
          existingCategories = existingCategoriesData.map((item: IDocumentCategoryResponse) => ({
            id: item.id,
            displayName: item.name,
            value: item.id.toString(),
          }))
          setDocumentCategory(existingCategories)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }, [])

  //fns for addedit news modals
  const closeAddEditDocModal = (value: boolean) => {
    closeDocsModal(value)
  }

  const closeModal = (): void => closeAddEditDocModal(false)

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''
    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [DocumentInputs.title]: 'Name is required',
        [DocumentInputs.excerpt]: 'Description is required',
        [DocumentInputs.publishedDate]: 'Published Date is required',
        [DocumentInputs.documentCategoryId]: 'Type is required',
        [DocumentInputs.iDILanguageId]: 'Language is required',
      }
      errorMessage = errorMessages[name] && getLanguageValue(languageText, errorMessages[name])
    }
    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.checked

    setDocumentData({
      ...docData,
      [name]: value,
    })
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    setDocumentData({
      ...docData,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name
    const value = e.target.value

    setDocumentData({
      ...docData,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  //handle file upload
  const handleFileUpload = (file: File, docName?: string) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setDocumentData({
          ...docData,
          documentFile: file,
          file: reader.result as string,
          originalFileName: file.name,
        })
      }
      reader.readAsDataURL(file)
    } else {
      setDocumentData({
        ...docData,
        documentFile: null,
        file: '',
        originalFileName: '',
      })
    }
    handleFormErrors(DocumentInputs.file, docData.file)
  }

  const removeIcon = (): void => {
    setDocumentData({
      ...docData,
      file: '',
      documentFile: null,
      originalFileName: '',
    })
  }

  const handleDateSelect = (name: string, date: Date) => {
    setDocumentData({
      ...docData,
      publishedDate: date,
    })
    if (focusInput[name]?.touched) {
      handleFormErrors(name, date.toString())
    }
  }

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    setDocumentData({
      ...docData,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !docData.title ||
      focusInput.title.errorMessage ||
      !docData.publishedDate ||
      focusInput.publishedDate.errorMessage ||
      !docData.excerpt ||
      focusInput.excerpt.errorMessage ||
      !docData.documentCategoryId ||
      focusInput.documentCategoryId.errorMessage ||
      (!isEditClicked && (!docData.file || focusInput.file.errorMessage))
    ) {
      for (const item in DocumentInputs) {
        handleFormErrors(item, docData[item])
      }

      //check if file is present or not
      if ((!docData.id && !docData.file) || (docData.id && !docData.file)) {
        setFocusInput((prev) => ({
          ...prev,
          [DocumentInputs.file]: {
            touched: true,
            errorMessage: getLanguageValue(languageText, 'Document is required'),
          },
        }))
      }

      //check if file present valid or not
      const fileName = docData.documentFile?.name
      const isFileValid = fileName?.match(/\.(docx?|pptx?|pdf)$/i) !== null
      let errorMessage = ''
      if (docData.id && docData.file) {
        if (!docData.documentFile || !isFileValid) {
          errorMessage = getLanguageValue(
            languageText,
            'Invalid file. Please choose file of .doc .docx .pdf .ppt and .pptx extions'
          )
        }
      } else if (!docData.id && !isFileValid) {
        errorMessage = getLanguageValue(
          languageText,
          'Invalid file. Please choose file of .doc .docx .pdf .ppt and .pptx extions'
        )
      }
      if (errorMessage) {
        setFocusInput((prev) => ({
          ...prev,
          [DocumentInputs.file]: {
            touched: true,
            errorMessage: errorMessage,
          },
        }))
      }

      return false
    }
    return true
  }

  const handleDocSubmit = async (): Promise<void> => {
    if (!handleValidationOnSubmit()) return
    dispatch(setSpinner(true))

    //the below code is to check if in case of file change (or re-uploaded), if not re-uploaded it will send the original File Name
    const checkIfFileChanged = docData.file.startsWith('data:')
    let updatedDocumentFile: File | null = null
    let updatedFile = docData.file

    if (checkIfFileChanged) {
      const blobResponse = await fetch(docData.file)
      const blobData = await blobResponse.blob()
      updatedDocumentFile = new File([blobData], docData.originalFileName, { type: blobData.type })
    } else {
      updatedFile = fileName
    }

    const body: IDocumentList = {
      id: docData.id ?? 0,
      title: docData.title,
      excerpt: docData.excerpt,
      file: updatedFile,
      originalFileName: isEditClicked ? originalFile : '',
      published: docData.published,
      publishedDate: new Date(docData.publishedDate ?? new Date()),
      type: docData.type,
      category: docData.category,
      documentCategoryId: docData.documentCategoryId,
      documentFile: updatedDocumentFile,
      iDILanguageId: docData.iDILanguageId,
      language: docData.language,
    }

    //adding the documents
    if (!isEditClicked) {
      createDocs(body, dispatch)
        .then((response) => {
          if (response) {
            dispatch(addToast('Document created successfully') as AnyAction)
            closeAddEditDocModal(true)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
        })
    } else {
      //update news
      updateDocs(body, dispatch)
        .then((response) => {
          if (response) {
            dispatch(addToast('Document updated successfully') as AnyAction)
            closeAddEditDocModal(true)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
        })
    }
  }

  return {
    languageText,
    focusInput,
    isEditClicked,
    languages,
    docData,
    documentCategory,
    handleDocSubmit,
    handleDateSelect,
    handleDropdownSelect,
    handleBlurEvent,
    handleInputChange,
    handleTextAreaChange,
    handleCheckboxChange,
    handleFileUpload,
    closeModal,
    removeIcon,
  }
}
