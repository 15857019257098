import { IFocusError } from '../../../components/formComponents/input'

export interface IDocument {
  totalCount: number
  pageLimit: number
  items: IDocumentList[]
}

export interface IDocumentList {
  id: number
  title: string
  excerpt: string
  file: string
  originalFileName: string
  publishedDate: Date
  documentFile?: File | null
  published: boolean
  type: string
  iDILanguageId: string
  language: string
  documentCategoryId: string
  category: string
}

export interface IDocumentCategoryResponse {
  id: number
  name: string
}

export enum DocumentInputs {
  id = 'id',
  title = 'title',
  publishedDate = 'publishedDate',
  excerpt = 'excerpt',
  published = 'published',
  type = 'type',
  documentFile = 'documentFile',
  file = 'file',
  iDILanguageId = 'iDILanguageId',
  language = 'language',
  category = 'category',
  documentCategoryId = 'documentCategoryId',
}

export interface IFocusInput {
  title: IFocusError
  file: IFocusError
  excerpt: IFocusError
  publishedDate: IFocusError
  iDILanguageId: IFocusError
  documentCategoryId: IFocusError
}
