import React, { useEffect, useState } from 'react'
import { ValidationHelper } from '../../containers/validationHelper'
import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'

interface IValidBoxProps {
  languageText: ITranslationObject
  value: string
}

export enum validateCharsEnum {
  eightChar = 1,
  specialChar = 2,
  alphanumeric = 3,
  uppercase = 4,
}

export const ValidationBox = (props: IValidBoxProps): JSX.Element => {
  const { languageText, value } = props
  const [validatedChars, setValidatedChars] = useState<number[]>([])
  const [showValidationBar, setShowValidationBar] = useState<boolean>(false)
  const [firstbar, setFirstbar] = useState('')
  const [secondbar, setSecondbar] = useState('')
  const [thirdbar, setThirdbar] = useState('')
  const [fourthbar, setFourthbar] = useState('')

  const handleInputChangeProps = React.useCallback(() => {
    if (props.value.length >= 1) {
      setShowValidationBar(true)
    } else {
      setShowValidationBar(false)
    }
    let validatedChars: number[] = []

    const isEightChar = ValidationHelper.isPasswordHasSpecialChar(
      validateCharsEnum.eightChar,
      value
    )
    const isSpecialChar = ValidationHelper.isPasswordHasSpecialChar(
      validateCharsEnum.specialChar,
      value
    )
    const isAlphanumeric = ValidationHelper.isPasswordHasSpecialChar(
      validateCharsEnum.alphanumeric,
      value
    )
    const isUppercase = ValidationHelper.isPasswordHasSpecialChar(
      validateCharsEnum.uppercase,
      value
    )

    if (value.length === 0) {
      validatedChars = []
    }
    if (isEightChar) validatedChars.push(validateCharsEnum.eightChar)
    if (isSpecialChar) validatedChars.push(validateCharsEnum.specialChar)
    if (isAlphanumeric) validatedChars.push(validateCharsEnum.alphanumeric)
    if (isUppercase) validatedChars.push(validateCharsEnum.uppercase)

    setValidatedChars(validatedChars)

    if (validatedChars.length === 0) {
      setFirstbar('')
      setSecondbar('')
      setThirdbar('')
      setFourthbar('')
    }
    if (validatedChars.length === 1) {
      setFirstbar('bg-danger-subtle')
      setSecondbar('')
      setThirdbar('')
      setFourthbar('')
    }
    if (validatedChars.length === 2) {
      setFirstbar('bg-danger')
      setSecondbar('bg-danger')
      setThirdbar('')
      setFourthbar('')
    }
    if (validatedChars.length === 3) {
      setFirstbar('bg-warning')
      setSecondbar('bg-warning')
      setThirdbar('bg-warning')
      setFourthbar('')
    }
    if (validatedChars.length === 4) {
      setFirstbar('bg-success')
      setSecondbar('bg-success')
      setThirdbar('bg-success')
      setFourthbar('bg-success')
    }
  }, [props.value])

  useEffect(() => {
    handleInputChangeProps()
  }, [handleInputChangeProps])

  const isEightChar = validatedChars.includes(validateCharsEnum.eightChar)
  const isSpecialChar = validatedChars.includes(validateCharsEnum.specialChar)
  const isAlphanumeric = validatedChars.includes(validateCharsEnum.alphanumeric)
  const isUppercase = validatedChars.includes(validateCharsEnum.uppercase)

  return (
    <>
      {showValidationBar && (
        <div className='d-flex flex-column'>
          <p className='fs-5'>
            <span>
              {validatedChars.length <= 1 && getLanguageValue(languageText, 'Too weak')}
              {validatedChars.length === 2 && getLanguageValue(languageText, 'Weak')}
              {validatedChars.length === 3 && getLanguageValue(languageText, 'Medium')}
              {validatedChars.length >= 4 && getLanguageValue(languageText, 'Strong')}
            </span>{' '}
            {getLanguageValue(languageText, 'Password')}!
          </p>
          <div className='row px-2'>
            <div
              className={`p-1 me-1 col rounded ${firstbar ? firstbar : 'bg-secondary-subtle'}`}
            />
            <div
              className={`p-1 me-1 col rounded ${secondbar ? secondbar : 'bg-secondary-subtle'}`}
            />
            <div
              className={`p-1 me-1 col rounded ${thirdbar ? thirdbar : 'bg-secondary-subtle'}`}
            />
            <div className={`p-1 col rounded ${fourthbar ? fourthbar : 'bg-secondary-subtle'}`} />
          </div>
          <div className='mt-2 mb-3'>
            <p>{getLanguageValue(languageText, 'Your password must contain')}</p>
            <div className='row'>
              <div className='col-md-6 mb-2'>
                <div className='rounded-circle me-1 d-inline'>
                  {isEightChar ? (
                    <i className='bi bi-check-circle-fill text-success'></i>
                  ) : (
                    <i className='bi bi-x-circle-fill text-danger'></i>
                  )}
                </div>
                {getLanguageValue(languageText, 'Min. 8 characters')}
              </div>
              <div className='col-md-6 mb-2'>
                <div className='rounded-circle me-1 d-inline'>
                  {isAlphanumeric ? (
                    <i className='bi bi-check-circle-fill text-success'></i>
                  ) : (
                    <i className='bi bi-x-circle-fill text-danger'></i>
                  )}
                </div>
                {getLanguageValue(languageText, 'Special character')}
              </div>
              <div className='col-md-6 mb-2'>
                <div className='rounded-circle me-1 d-inline'>
                  {isUppercase ? (
                    <i className='bi bi-check-circle-fill text-success'></i>
                  ) : (
                    <i className='bi bi-x-circle-fill text-danger'></i>
                  )}
                </div>
                {getLanguageValue(languageText, 'Upper lowercase')}
              </div>
              <div className='col-md-6 mb-2'>
                <div className='rounded-circle me-1 d-inline'>
                  {isSpecialChar ? (
                    <i className='bi bi-check-circle-fill text-success'></i>
                  ) : (
                    <i className='bi bi-x-circle-fill text-danger'></i>
                  )}
                </div>
                {getLanguageValue(languageText, 'Number')}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
