import React from 'react'

import { useNewsList } from './hooks'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ReactTable } from '../../../components/reactTable/reactTable'
import { AddEditNewsModal } from './addEditNews'
import { DeleteModal } from '../../../components/modals/deleteModal'
import { NewsType } from './types'
import { TabNavItem } from '../../../components/multiTabComponent/tabNavItem'

export const AdminNewsList = (): JSX.Element => {
  const {
    languageText,
    editedRowId,
    deleteRowId,
    filter,
    newsType,
    navTabs,
    activeTab,
    news,
    openNewsModal,
    pageCount,
    pageLimit,
    pageSelected,
    selectedActionRowName,
    tableHeader,
    totalCount,
    setActiveTab,
    setFilter,
    setPageSelected,
    setSorting,
    setPageLimit,
    handleDeleteNews,
    addNewsClick,
    closeNewsModal,
    closeDeleteModal,
  } = useNewsList()

  let rowData = {}
  if (editedRowId) {
    rowData = news.find((item) => item.id === editedRowId)
  }

  return (
    <>
      <div className='p-4 p-md-12'>
        <TabNavItem
          className='fs-4'
          navTabs={navTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <ReactTable
          wrapperClass='py-7'
          headerText={getLanguageValue(
            languageText,
            newsType == NewsType.News ? 'News List' : 'Blogs List'
          )}
          searchPlaceholder={getLanguageValue(
            languageText,
            newsType == NewsType.News ? 'Search News' : 'Search Blogs'
          )}
          buttonText={getLanguageValue(
            languageText,
            newsType == NewsType.News ? 'Add News' : 'Add Blogs'
          )}
          tableHeader={tableHeader}
          data={news}
          totalCount={totalCount}
          pageCount={pageCount}
          filter={filter}
          pageLimit={pageLimit}
          pageSelected={pageSelected}
          handleButtonClick={addNewsClick}
          setFilter={setFilter}
          setSorting={setSorting}
          setPageLimit={setPageLimit}
          setPageSelected={setPageSelected}
        />
      </div>

      {openNewsModal || editedRowId ? (
        <AddEditNewsModal rowData={rowData} closeNewsModal={closeNewsModal} />
      ) : (
        ''
      )}

      {deleteRowId ? (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, newsType == NewsType.News ? 'News' : 'Blogs')}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeleteNews}
          closeDeleteModal={closeDeleteModal}
        />
      ) : (
        ''
      )}
    </>
  )
}
