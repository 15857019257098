import { Dispatch } from 'redux'
import { api, apiEndPoints } from '../../api'
import { setNotification } from '../actions'
import { ICreateOrUpdateProfileDescription } from './addEditModal/hooks'
import { IProfileDescriptionData } from './interface'

interface IProfileDescriptionListResponse {
  totalCount: number
  items: IProfileDescriptionData[]
}
export const getProfileDescriptionList = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch
): Promise<IProfileDescriptionListResponse> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
  }

  try {
    const response = await api.get(apiEndPoints.getProfileDescription, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createProfileDescription = async (
  body: ICreateOrUpdateProfileDescription,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.post(apiEndPoints.createProfileDescription, JSON.stringify(body), {})
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateProfileDescription = async (
  body: ICreateOrUpdateProfileDescription,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(apiEndPoints.updateProfileDescription, JSON.stringify(body), {})
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteProfileDescription = async (
  deleteId: number,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.delete(apiEndPoints.deleteProfileDescription, {
      params: { id: deleteId },
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getProfileDescriptionById = async (
  id: number,
  dispatch: Dispatch
): Promise<IProfileDescriptionData> => {
  try {
    const response = await api.get(apiEndPoints.getProfileDescriptionById, {
      params: { id },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
