import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../../commonUtils/languageFunctionsHelper'
import { getWindowDimensions } from '../../../../../commonUtils/screenWidthHelper'
import {
  ProfileRoleStatus,
  GetTypeOfRoleName,
  getRoleSettingsName,
  getRoleStatusName,
} from '../../../profileEnums'
import { EditActivityRoleModal } from './editActivityRoleModal'
import { IRoleStepData } from '../../../addProfile/activityRoleStep'
import { IEditActivity } from '../../interface'

interface IEditProfileRoleStepProps {
  id: number
  languageText: ITranslationObject
  profileData: IEditActivity
  isActivityCancelled: boolean
  isActivityInvoiced: boolean
  handleRoleChanges: (roleSettings: IRoleStepData) => void
}

export const EditActivityRoleStep = (props: IEditProfileRoleStepProps) => {
  const {
    id,
    languageText,
    profileData,
    isActivityCancelled,
    isActivityInvoiced,
    handleRoleChanges,
  } = props

  const isMobileView = getWindowDimensions().width < 768

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [roleSetBy, setRoleSetBy] = useState<number>(0)
  const [roleSameForAll, setRoleSameForAll] = useState<boolean>(true)
  const [roleId, setRoleId] = useState<number>(0)
  const [roleText, setRoleText] = useState<string>('')
  const [noOfRespondents, setNoOfRespondents] = useState<number>(0)
  const [roleCanBeChangedByParticipant, setRoleCanBeChangedByParticipant] = useState<boolean>(true)
  const [editRoleModal, setEditRoleModal] = useState<boolean>(false)

  useEffect(() => {
    setRoleSetBy(profileData.roleSetBy)
    setRoleSameForAll(profileData.roleSameForAll)
    if (profileData.roleid) {
      setRoleId(profileData.roleid)
    } else {
      setRoleText(profileData.roleText)
    }
    setNoOfRespondents(profileData.roleNoOfRespondents)
    setRoleCanBeChangedByParticipant(profileData.roleCanBeChangedByParticipant)
  }, [profileData])

  const openEditModal = (): void => {
    if (!isActivityCancelled) {
      setEditRoleModal(true)
    }
  }

  const closeRoleModal = (): void => {
    setEditRoleModal(false)
  }

  const handleToggle = () => setIsExpanded(!isExpanded)

  const handleSaveClick = (roleSettings: IRoleStepData): void => {
    setRoleSetBy(roleSettings.roleSetBy)
    setRoleSameForAll(roleSettings.roleSameForAll)
    setRoleId(roleSettings.roleId)
    setRoleText(roleSettings.roleText)
    setNoOfRespondents(roleSettings.noOfRespondents)
    setRoleCanBeChangedByParticipant(roleSettings.roleCanBeChangedByParticipant)
    handleRoleChanges(roleSettings)
  }

  const isGroup = profileData.activityType === true
  const roleSetByFacilitator = roleSetBy === Number(ProfileRoleStatus.RoleSetByFacilitator)

  let cannotEditRoleCanBeChangedByParticipant = false
  if (editRoleModal && profileData.newProfileCount > 0) {
    cannotEditRoleCanBeChangedByParticipant = profileData.newProfiles.some(
      (participant) =>
        (!participant.roleId && !participant.roleText) || !participant.noOfRespondents
    )
  }

  return (
    <>
      <div id='roleSettings' className='p-3 mt-3 rounded bg-white'>
        <div>
          <div className='col-12 small fw-bold mb-2 d-none text-secondary opacity-75'>
            {getLanguageValue(languageText, 'Role')}
          </div>
          {roleSetBy ? (
            <>
              <div className='row'>
                {/* SET ROLE */}
                <div
                  className={clsx(
                    'col-lg-2 col-md-4 col-6 mb-3 mb-md-0',
                    roleSetByFacilitator && 'border-end mb-lg-0 mb-md-5 mb-8'
                  )}
                >
                  <div className='text-secondary'>
                    {getLanguageValue(languageText, 'Role set by')}
                  </div>
                  <div
                    className={clsx(
                      'fs-5 text-dark opacity-75 fw-bold',
                      !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                    )}
                    onClick={() => !isActivityInvoiced && openEditModal()}
                  >
                    {getLanguageValue(languageText, getRoleStatusName(String(roleSetBy)))}
                  </div>
                </div>
                {/* ROLE SETTING */}
                {roleSetByFacilitator && (
                  <div className='col-lg-2 col-md-4 col-6 border-end mb-lg-0 mb-md-5 mb-3'>
                    <div className='text-secondary'>
                      {getLanguageValue(languageText, 'Role setting')}
                    </div>
                    <div
                      className={clsx(
                        'fs-5 text-dark opacity-75 fw-bold',
                        !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                      )}
                      onClick={() => !isActivityInvoiced && openEditModal()}
                    >
                      {getLanguageValue(languageText, getRoleSettingsName(roleSameForAll))}
                    </div>
                  </div>
                )}
                {isMobileView && !isExpanded && roleSetBy > 0 && (
                  <div className='d-flex justify-content-center'>
                    <span className='text-primary me-3' onClick={handleToggle}>
                      <i className='bi bi-chevron-down' />
                    </span>
                  </div>
                )}

                {((isMobileView && isExpanded) || !isMobileView) && (
                  <>
                    {/* ROLE TYPE, NO OF RESPONDENTS */}
                    {roleSetByFacilitator && roleSameForAll && (
                      <>
                        <div className='col-lg-2 col-md-4 col-6 border-end mb-lg-0 mb-md-5 mb-3'>
                          <div className='text-secondary'>
                            {getLanguageValue(languageText, 'Role')}
                          </div>
                          <div
                            className={clsx(
                              'fs-5 text-dark opacity-75 fw-bold',
                              !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                            )}
                            onClick={() => !isActivityInvoiced && openEditModal()}
                          >
                            {roleId
                              ? getLanguageValue(languageText, GetTypeOfRoleName[roleId])
                              : roleText}
                          </div>
                        </div>
                        <div
                          className={clsx(
                            'col-lg-2 col-md-4 col-6',
                            roleSetByFacilitator && 'border-end mb-lg-0 mb-md-5 mb-3'
                          )}
                        >
                          <div className='text-secondary'>
                            {getLanguageValue(languageText, 'No. of respondents')}
                          </div>
                          <div
                            className={clsx(
                              'fs-5 text-dark opacity-75 fw-bold',
                              !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
                            )}
                            onClick={() => !isActivityInvoiced && openEditModal()}
                          >
                            {noOfRespondents}
                          </div>
                        </div>
                      </>
                    )}

                    {/* PARTICIPANT CHANGE ROLE */}
                    {roleSetByFacilitator && (
                      <div className='col-lg-4 col-md-6'>
                        <div className='text-secondary'>
                          {getLanguageValue(languageText, 'Modifiable by participant')}
                        </div>
                        <div
                          className={clsx(
                            'fs-5 text-success fw-bold',
                            !isActivityCancelled && isActivityInvoiced && 'cursor-pointer',
                            !roleCanBeChangedByParticipant && 'text-danger'
                          )}
                          onClick={() => !isActivityInvoiced && openEditModal()}
                        >
                          {getLanguageValue(
                            languageText,
                            roleCanBeChangedByParticipant ? 'Yes' : 'No'
                          )}
                        </div>
                      </div>
                    )}

                    {isMobileView && isExpanded && (
                      <div className='d-flex justify-content-center'>
                        <span className='text-primary me-3' onClick={handleToggle}>
                          <i className='bi bi-chevron-up' />
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div
              className={clsx(
                'fs-6 color-999',
                !isActivityCancelled && !isActivityInvoiced && 'cursor-pointer'
              )}
              onClick={() => !isActivityInvoiced && openEditModal()}
            >
              {getLanguageValue(languageText, 'Not set')}
            </div>
          )}
        </div>
      </div>

      {editRoleModal && (
        <EditActivityRoleModal
          id={id}
          languageText={languageText}
          isGroup={isGroup}
          roleSetBy={roleSetBy}
          roleSameForAll={roleSameForAll}
          roleId={roleId}
          roleText={roleText}
          noOfRespondents={noOfRespondents}
          roleCanBeChangedByParticipant={roleCanBeChangedByParticipant}
          cannotEditRoleCanBeChangedByParticipant={cannotEditRoleCanBeChangedByParticipant}
          closeModal={closeRoleModal}
          handleSaveClick={handleSaveClick}
        />
      )}
    </>
  )
}
