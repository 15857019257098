export interface IWindowDimensions {
  width: number
}

export const getWindowDimensions = (): IWindowDimensions => {
  const { innerWidth: width } = window
  return {
    width,
  }
}
