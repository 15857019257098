import * as React from 'react'
import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { Dropdown } from '@app/components/formComponents/dropdown'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { FileUpload } from '@app/components/formComponents/fileUpload'
import { blobToString, downloadFileFromBlob } from '@app/commonUtils/downloadHepler'
import { addToast } from '@app/containers/actions'
import { AnyAction } from 'redux'

type Props<T> = {
  lang: ITranslationObject

  /** Specializes the name of the exported file. It has no other purpose. */
  name: string
  handleClose: React.MouseEventHandler<HTMLElement>
  handleImport: (data: T) => Promise<unknown>
  handleExport: () => Promise<T>
}

type Action<T> = { kind: 'export' } | { kind: 'import'; data: T | undefined }

const actions: Array<IDropdownList> = [
  {
    id: 1,
    displayName: 'Export',
    value: 'export',
  },
  {
    id: 2,
    displayName: 'Import',
    value: 'import',
  },
]

export function ImportExportModal<T>(props: Props<T>): JSX.Element {
  const dispatch = useDispatch()
  const [action, setAction] = React.useState<Action<T>>({
    kind: 'export',
  })

  async function handleSubmit(event: React.MouseEvent<HTMLElement>): Promise<void> {
    switch (action.kind) {
      case 'import': {
        if (!action.data) {
          return
        }
        await props.handleImport(action.data)
        dispatch(addToast('Imported successfully') as AnyAction)
        props.handleClose(event)
        break
      }
      case 'export': {
        const dt = moment().format('YYYYMMDD_HHmmss')
        const name = `idi-${props.name}-${dt}.json`
        const data = await props.handleExport()

        // pretty-print the data, because it might be useful to manually edit the export sometimes.
        const str = JSON.stringify(data, null, '  ')
        const blobby = new Blob([str], {
          type: 'application/json',
        })
        downloadFileFromBlob(blobby, name)
        break
      }
    }
  }

  function handleFileUpload(file: File): Promise<unknown> {
    return blobToString(file).then((res) => {
      const parsed = JSON.parse(res)
      setAction({
        kind: 'import',
        data: parsed,
      })
    })
  }

  return (
    <ModalComponent
      width='lg'
      headerText={getLanguageValue(props.lang, 'Import & export')}
      handleSubmitClick={handleSubmit}
      handleCancelClick={props.handleClose}
      submitButtonText={getLanguageValue(props.lang, 'Execute')}
      submitButtonDisabled={action.kind === 'import' && !action.data}
    >
      <Dropdown
        label={getLanguageValue(props.lang, 'Action')}
        handleDropdownSelect={(event) => {
          switch (event.target.value) {
            case 'export':
              setAction({ kind: 'export' })
              break
            case 'import':
              setAction({ kind: 'import', data: undefined })
              break
          }
        }}
        list={actions}
        value={action.kind}
      />

      {action.kind === 'import' && (
        <div>
          <FileUpload acceptFileTypes={['.json']} name='' handleFileUpload={handleFileUpload} />
          {action.data && (
            <div className='alert alert-danger'>
              <strong>Proceed with caution.</strong>
              <br />
              This action will overwrite all data. Use the 'export' action to make a backup
              beforehand.
            </div>
          )}
        </div>
      )}
    </ModalComponent>
  )
}
