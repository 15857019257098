import React from 'react'
import { ITranslationObject, getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'

interface ICreatedDateProps {
  languageText: ITranslationObject
  createdDate: string
}

export const CreatedDate = (props: ICreatedDateProps): JSX.Element => {
  const { languageText, createdDate } = props

  return (
    <div className='border p-2'>
      <i className='bi bi-calendar me-2' />
      {getLanguageValue(languageText, 'Created')}

      <h5 className='mt-2'>{createdDate.substring(0, 10)}</h5>
    </div>
  )
}
