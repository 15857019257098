import React from 'react'

interface ITabContentProps {
  id: string
  activeTab: string
  children: JSX.Element
}

export const TabContent = (props: ITabContentProps) => {
  const { id, activeTab, children } = props

  return activeTab === id ? <div className='TabContent'>{children}</div> : null
}
