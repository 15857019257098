import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnDef } from '@tanstack/react-table'

import { BooleanCell } from '../../components/reactTable/booleanCell'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { getClientList } from './actions'
import { RootState } from '@app/store/configureStore'
import { setSpinner } from '../actions'
import { IDIInvoice } from '../../components/invoice/invoiceInterface'
import { getInitialPageConfiguredInfoState } from '@app/components/reactTable/reactTable'
import { IPageConfiguredInfo } from '../reducer'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { IClients } from '../commonInterfaces'
import { ISubscription } from '../subscriptionsList/addEditSubscription/hooks'

export interface IClientSubscription {
  clientId: number
  subscriptionId: number
  articleId: number
  paymentTerms: number
  price: number
  billingPeriod: number
  teamSize: number
  startDate: string
  endDate: string
  invoice: true
  creditCard: true
  status: number
  payment: number
  paymentAfterEnd: number
  currencyId: number
  id: number
  idiInvoices: IDIInvoice[]
  total: number
  subscription: ISubscription
}

export const useClientList = () => {
  const dispatch = useDispatch()
  const isMounted = useRef(false)
  const pathname = window.location.pathname

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const pageConfiguredInfo: IPageConfiguredInfo =
    useSelector((state: RootState) => state.mainReducer.pageConfiguredInfo).find(
      (info) => info.pathname === pathname
    ) ?? getInitialPageConfiguredInfoState()
  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const [data, setdata] = useState<IClients[]>([])
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [editedRowId, setEditedRowId] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0) // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10) // items_per_page
  const [pageCount, setPageCount] = useState<number>(0) // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0) // page_number_selected
  const [openClientModal, setOpenClientModal] = useState<boolean>(false) // Add/Edit Client popup
  const [clientUpdated, setClientUpdated] = useState<boolean>(false) // This flag is used to re-reder the clients data on requirement

  const fetchClients = (
    skipCount: number,
    existingFilter?: string,
    existingSorting?: string,
    existingPageLimit?: number
  ): void => {
    dispatch(setSpinner(true))
    const updatedFilter = existingFilter ? existingFilter : filter
    const updatedSorting = existingSorting ? existingSorting : sorting
    const updatedPageLimit = existingPageLimit ? existingPageLimit : pageLimit

    getClientList(updatedFilter, updatedSorting, updatedPageLimit, skipCount, dispatch)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit)
          setdata([...response.items])
          setTotalCount(response.totalCount)
          setPageCount(pageSize)
          if (clientUpdated) setClientUpdated(false)
          if (!isMounted.current) isMounted.current = true
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected
      fetchClients(skipCount)
    }
  }, [pageSelected, sorting])

  useEffect(() => {
    if (isMounted.current) {
      fetchClients(0)
    }
  }, [filter, pageLimit])

  useEffect(() => {
    if (clientUpdated) {
      const skipCount = pageLimit * pageSelected
      fetchClients(skipCount)
    }
  }, [clientUpdated])

  useEffect(() => {
    const { searchText, sorting, pageLimit, pageSelected } = pageConfiguredInfo
    setFilter(searchText)
    setSorting(sorting)
    setPageLimit(pageLimit)
    setPageSelected(pageSelected)
    fetchClients(pageLimit * pageSelected, searchText, sorting, pageLimit)
  }, [])

  const addClientClick = (): void => {
    setOpenClientModal(true)
  }

  const refreshClientList = (): void => setClientUpdated(true)

  const closeClientModal = (): void => {
    if (editedRowId) {
      setEditedRowId(0)
    }
    setOpenClientModal(false)
  }

  const handleEditClick = (id: number): void => {
    setEditedRowId(id)
  }

  const tableHeader: Array<ColumnDef<IClients, keyof IClients>> = [
    {
      header: '',
      accessorKey: 'actions',
      enableSorting: false,
      cell: ({ ...props }) => (
        <div
          className='btn btn-lg border-end rounded-0 my-n3 ms-n3'
          onClick={() => handleEditClick(props.row.original.id)}
        >
          <i className='bi bi-pencil-fill text-secondary' />
        </div>
      ),
    },
    {
      header: getLanguageValue(languageText, 'ID'),
      accessorKey: 'id',
    },
    {
      header: getLanguageValue(languageText, 'Name'),
      accessorKey: 'name',
      width: 'min-w-150px',
    } as ColumnDef<IClients> & { width: string }, // we're hacking react table with custom fields.
    {
      header: getLanguageValue(languageText, 'Org No'),
      accessorKey: 'orgNo',
    },
    {
      header: getLanguageValue(languageText, 'VAT'),
      accessorKey: 'euvatid',
    },
    {
      header: getLanguageValue(languageText, 'Facilitator'),
      accessorKey: 'facilitator',
    },
    {
      header: getLanguageValue(languageText, 'Country'),
      accessorKey: 'countryName',
    },
    {
      header: getLanguageValue(languageText, 'Facilitators'),
      accessorKey: 'numberOfFacilitators',
    },
    {
      header: getLanguageValue(languageText, 'Profiles'),
      accessorKey: 'numberOfProfiles',
    },
    {
      header: getLanguageValue(languageText, 'Users'),
      accessorKey: 'numberOfUsers',
    },
    {
      header: getLanguageValue(languageText, 'Participants'),
      accessorKey: 'numberOfProfileParticipants',
    },
    {
      header: getLanguageValue(languageText, 'Active'),
      accessorKey: 'active',
      cell: ({ ...props }) => (
        <BooleanCell languageText={languageText} boolValue={props.row.original.active} />
      ),
    },
  ]

  if (isLoggedInRoleAdmin(loggedInUserRole)) {
    tableHeader.push({
      header: getLanguageValue(languageText, 'Source'),
      accessorKey: 'sourceType',
    })
  }

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    sorting,
    pageLimit,
    pageSelected,
    openClientModal,
    editedRowId,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addClientClick,
    refreshClientList,
    closeClientModal,
  }
}
