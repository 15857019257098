import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { IAddEditPresentationModalProps } from './addEditPresentationModal'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IFocusError } from '../../../components/formComponents/input'
import { getLanguages } from '../../languageList/actions'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { RenderFlagComponent } from '../../languageList/renderFlagComponent'
import { setSpinner } from '../../actions'
import {
  createPresentationTemplate,
  getPresentationTemplateById,
  updatePresentationTemplate,
} from '../actions'
import { ILanguage } from '@app/containers/commonInterfaces'

enum PresentationInputs {
  description = 'description',
  language = 'language',
  isActive = 'isActive',
  enableAccessControl = 'enableAccessControl',
  isSelfDeliveryDefault = 'isSelfDeliveryDefault',
  isSelfDeliveryTemplate = 'isSelfDeliveryTemplate',
}

export interface ICreateOrUpdatePresentation {
  id: number
  description: string
  language: number
  isActive: boolean
  enableAccessControl: boolean
  isSelfDeliveryDefault: boolean
  isSelfDeliveryTemplate: boolean
}

export interface IFocusInput {
  description: IFocusError
  language: IFocusError
}

const initialPresentationInfoState: ICreateOrUpdatePresentation = {
  id: 0,
  description: '',
  language: 0,
  isActive: false,
  enableAccessControl: false,
  isSelfDeliveryDefault: false,
  isSelfDeliveryTemplate: false,
}

export const useAddEditPresentation = (props: IAddEditPresentationModalProps) => {
  const { editId, refreshPresentationList, closePresentationModal } = props

  const dispatch = useDispatch()

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const [presentationInfo, setPresentationInfo] = useState<ICreateOrUpdatePresentation>(
    initialPresentationInfoState
  )
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    description: {
      touched: false,
      errorMessage: '',
    },
    language: {
      touched: false,
      errorMessage: '',
    },
  })
  const [languages, setLanguages] = useState<IDropdownList[]>([])

  // Check for edit click
  let isEditClicked: boolean = false
  if (editId > 0) isEditClicked = true

  useEffect(() => {
    getLanguages(dispatch).then((response) => {
      if (response) {
        let existingLanguages: IDropdownList[] = []
        const existingLanguagesData: ILanguage[] = [...response.items]
        existingLanguages = existingLanguagesData.map((item) => ({
          id: item.id,
          displayName: <RenderFlagComponent flagName={item.icon} value={item.displayName} />,
          value: item.id.toString(),
        }))
        setLanguages(existingLanguages)
      }
    })
    if (isEditClicked) {
      getPresentationTemplateById(editId, dispatch).then(
        (response: ICreateOrUpdatePresentation) => {
          if (response) {
            setPresentationInfo({
              id: response.id,
              description: response.description,
              language: response.language,
              isActive: response.isActive,
              enableAccessControl: response.enableAccessControl,
              isSelfDeliveryDefault: response.isSelfDeliveryDefault,
              isSelfDeliveryTemplate: response.isSelfDeliveryTemplate,
            })
          }
        }
      )
    }
  }, [])

  useEffect(() => {
    // enforce that 'isSelfDeliveryDefault' cannot be enabled while 'isSelfDeliveryTemplate' is disabled.
    if (!presentationInfo.isSelfDeliveryTemplate && presentationInfo.isSelfDeliveryDefault) {
      setPresentationInfo((prev) => {
        return {
          ...prev,
          isSelfDeliveryDefault: false,
        }
      })
    }
  }, [presentationInfo.isSelfDeliveryTemplate])

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    switch (name) {
      case PresentationInputs.description:
        if (!value) {
          errorMessage = 'Description is required'
        }
        break
      case PresentationInputs.language:
        if (!value) {
          errorMessage = 'Language is required'
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage ? getLanguageValue(languageText, errorMessage) : '',
      },
    }))
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    setPresentationInfo({
      ...presentationInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    setPresentationInfo({
      ...presentationInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPresentationInfo({
      ...presentationInfo,
      [e.target.name]: e.target.checked,
    })
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !presentationInfo.description ||
      focusInput.description.errorMessage ||
      !presentationInfo.language ||
      focusInput.language.errorMessage
    ) {
      for (const item in PresentationInputs) {
        if (item === PresentationInputs.isActive) break
        handleFormErrors(item, presentationInfo[item])
      }
      return false
    }
    return true
  }

  const handleSaveClick = (): void => {
    if (!handleValidationOnSubmit()) return

    dispatch(setSpinner(true))
    const body: ICreateOrUpdatePresentation = {
      id: editId ?? 0,
      description: presentationInfo.description,
      language: Number(presentationInfo.language),
      isActive: presentationInfo.isActive,
      enableAccessControl: presentationInfo.enableAccessControl,
      isSelfDeliveryDefault: presentationInfo.isSelfDeliveryDefault,
      isSelfDeliveryTemplate: presentationInfo.isSelfDeliveryTemplate,
    }

    if (editId > 0) {
      updatePresentationTemplate(body, dispatch)
        .then((response) => {
          if (response) {
            refreshPresentationList()
            closePresentationModal()
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    } else {
      createPresentationTemplate(body, dispatch)
        .then((response) => {
          if (response) {
            refreshPresentationList()
            closePresentationModal()
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    }
  }

  return {
    languageText,
    isEditClicked,
    PresentationInputs,
    presentationInfo,
    focusInput,
    languages,
    handleInputChange,
    handleBlurEvent,
    handleDropdownSelect,
    handleCheckboxChange,
    handleSaveClick,
  }
}
