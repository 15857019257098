import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ColumnDef } from '@tanstack/react-table'

import { ReactTable } from '../../../../components/reactTable/reactTable'
import { MultiLabelToggleCheckbox } from '../../../../components/formComponents/multiLabelToggleCheckbox'
import { ToggleCardCheckbox } from '../../../../components/formComponents/toggleCardCheckbox'
import { ReactDatePicker } from '../../../../components/datePicker/ReactDatePicker'
import { Input } from '../../../../components/formComponents/input'
import { SubscriptionAddInvoice } from './subscriptionAddInvoice/subscriptionAddInvoice'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { BillingInputs, SubscriptionType } from '../clientEnums'
import { IPaymentTypes } from '../../../../commonUtils/planSuscriptionHelper'
import {
  IAccountInfo,
  ISelectedPlan,
  IBillingFocusInput,
  IBillingInfo,
  ISubscriptions,
} from '../hooks'
import { FortnoxInvoiceStatus, IDIInvoice } from '../../../../components/invoice/invoiceInterface'
import { CustomActionsCell } from '../../../../components/reactTable/customActionsCell'
import { isLoggedInRoleAdmin } from '@app/commonUtils/roleHelper'
import { RootState } from '@app/store/configureStore'

interface IClientBillingProps {
  languageText: ITranslationObject
  accountInfo: IAccountInfo
  isAnnual: boolean
  subscriptions: ISubscriptions[]
  selectedPlan: ISelectedPlan
  paymentTermsList: IDropdownList[]
  billingInfo: IBillingInfo
  billingFocusInput: IBillingFocusInput
  paymentTypes: IPaymentTypes[]
  paymentStatusList: IDropdownList[]
  paymentList: IDropdownList[]
  paymentAfterEndList: IDropdownList[]
  currencyList: IDropdownList[]
  invoiceList: IDIInvoice[]
  clientSubscriptionId: number
  handlePlanChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectPlan: (
    selectedPlanId: number,
    price: number,
    type: number,
    individual: boolean
  ) => void
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void
  handleDropdownSelect: (selectedItem: IDropdownSelectedItem) => void
  handleDateSelect: (name: string, date: Date) => void
  handleSelectPaymentMethod: (id: number) => void
  handleSubscription: (isActive: boolean) => void
  handleBackToSubscriptionList: () => void
  getClientSubscription: (clientSubscriptionId: number) => void
}

export const ClientBilling = (props: IClientBillingProps): JSX.Element => {
  const {
    languageText,
    accountInfo,
    isAnnual,
    subscriptions,
    selectedPlan,
    paymentTermsList,
    billingInfo,
    billingFocusInput,
    paymentTypes,
    paymentStatusList,
    paymentList,
    paymentAfterEndList,
    currencyList,
    invoiceList,
    clientSubscriptionId,
    handlePlanChange,
    handleSelectPlan,
    handleInputChange,
    handleBlurEvent,
    handleDropdownSelect,
    handleDateSelect,
    handleSelectPaymentMethod,
    handleSubscription,
    handleBackToSubscriptionList,
    getClientSubscription,
  } = props

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)

  const [editedInvoiceId, setEditedInvoiceId] = useState<number>(0)

  const handleEditInvoice = (id: number): void => {
    setOpenInvoiceForm(true)
    setEditedInvoiceId(id)
  }

  const invoiceTableHeader = useMemo<ColumnDef<IDIInvoice, unknown>[]>(
    () => [
      {
        header: '',
        accessorKey: 'actions',
        disableSortBy: true,
        cell: ({ ...props }) => (
          <CustomActionsCell
            editOption
            languageText={languageText}
            id={props.row.original.id}
            handleEditClick={handleEditInvoice}
          />
        ),
      },
      {
        header: `${getLanguageValue(languageText, 'Invoice')} #`,
        accessorKey: 'fortnoxInvoiceNumber',
        enableSorting: false,
        cell: ({ ...props }) =>
          props.row.original.fortnoxInvoiceNumber ? props.row.original.fortnoxInvoiceNumber : '',
      },
      {
        header: getLanguageValue(languageText, 'Issue Date'),
        accessorKey: 'date',
        enableSorting: false,
        cell: ({ ...props }) => <>{props.row.original.date?.substring(0, 10)}</>,
      },
      {
        header: getLanguageValue(languageText, 'Due Date'),
        accessorKey: 'dueDate',
        enableSorting: false,
      },
      {
        header: getLanguageValue(languageText, 'Amount'),
        accessorKey: 'total',
        enableSorting: false,
        cell: ({ ...props }) => (
          <>
            {props.row.original.total} {props.row.original.currency}
          </>
        ),
      },
      {
        header: getLanguageValue(languageText, 'Amount Due'),
        accessorKey: '',
        enableSorting: false,
      },
      {
        header: getLanguageValue(languageText, 'Status'),
        accessorKey: 'fortnoxInvoiceStatus',
        enableSorting: false,
        cell: ({ ...props }) => (
          <>
            {props.row.original.fortnoxInvoiceStatus &&
              getLanguageValue(
                languageText,
                FortnoxInvoiceStatus[props.row.original.fortnoxInvoiceStatus]
              )}
          </>
        ),
      },
      {
        header: getLanguageValue(languageText, 'Payment Method'),
        accessorKey: 'paymentMethod',
        enableSorting: false,
      },
    ],
    [languageText]
  )

  const [openInvoiceForm, setOpenInvoiceForm] = useState<boolean>(false)

  const addInvoiceClick = (): void => setOpenInvoiceForm(true)

  const handleClientSubscription = (clientSubscriptionId: number, invoiceId: number): void => {
    if (invoiceId) setEditedInvoiceId(invoiceId)
    getClientSubscription(clientSubscriptionId)
  }

  const closeInvoiceForm = (): void => {
    setOpenInvoiceForm(false)
    if (editedInvoiceId) setEditedInvoiceId(0)
  }

  const isEdit = false
  const isAdmin = isLoggedInRoleAdmin(loggedInUserRole)

  return (
    <>
      <form>
        <div className='d-flex flex-wrap justify-content-between align-items-center'>
          <h5>{getLanguageValue(languageText, 'Subscription and Plans')}</h5>
          <MultiLabelToggleCheckbox
            flabel={getLanguageValue(languageText, 'Annual')}
            rlabel={getLanguageValue(languageText, 'Monthly')}
            value={!isAnnual}
            handleCheckboxChange={handlePlanChange}
          />
        </div>

        {/* Subscription Plans */}
        <div className='mb-3'>
          <div className='row g-3'>
            {subscriptions.map((subscription, index) => {
              let currencyUnit: string = 'SEK/User/Year'
              let price: number = subscription.priceAnnualSEK
              if (subscription.type === SubscriptionType.Plan) {
                currencyUnit = 'SEK/Profile'
                price = subscription.priceSEK
              } else if (!isAnnual) {
                currencyUnit = 'SEK/User/Month'
                price = subscription.priceMonthlySEK
              }

              const isPriceUpdated =
                subscription.id === selectedPlan.selectedPlanId &&
                billingInfo.price &&
                Number(billingInfo.price) !== price
              const currentPrice = isPriceUpdated ? billingInfo.price : price.toString()

              return (
                <ToggleCardCheckbox
                  key={index}
                  keys={subscription.id}
                  header={subscription.name}
                  containerClass='col-md-3 col-12'
                  checked={subscription.id === selectedPlan.selectedPlanId}
                  description={subscription.description}
                  additionalInfoHeader={currentPrice}
                  strikeThroughHeader={isPriceUpdated ? price.toString() : ''}
                  handleClick={() =>
                    handleSelectPlan(
                      subscription.id,
                      price,
                      subscription.type,
                      subscription.individual
                    )
                  }
                  additionalInfoDescription={currencyUnit}
                />
              )
            })}
          </div>
          {billingFocusInput.selectedPlan.errorMessage && (
            <div className='invalid-feedback d-inline-block'>
              {billingFocusInput.selectedPlan.errorMessage}
            </div>
          )}
        </div>

        {/* Admin Billing Details */}
        {isAdmin && (
          <>
            <div className='row'>
              <div className='col-md-3 col-12'>
                <DropdownSelect
                  name={BillingInputs.paymentTerms}
                  label={getLanguageValue(languageText, 'Payment terms')}
                  defaultLabel={getLanguageValue(languageText, 'Select payment terms')}
                  list={paymentTermsList}
                  value={billingInfo.paymentTerms}
                  focusInput={billingFocusInput.paymentTerms}
                  handleDropdownSelect={handleDropdownSelect}
                  required
                />
              </div>
              <div className='col-md-3 col-12'>
                <Input
                  name={BillingInputs.price}
                  label={getLanguageValue(languageText, 'Price')}
                  placeholder={getLanguageValue(languageText, 'Price')}
                  type='number'
                  value={billingInfo.price}
                  focusInput={billingFocusInput.price}
                  handleBlurEvent={handleBlurEvent}
                  handleInputChange={handleInputChange}
                  required
                />
              </div>

              {selectedPlan.type > 0 &&
                selectedPlan.type !== SubscriptionType.Plan &&
                !selectedPlan.individual && (
                  <div className='col-md-3 col-12'>
                    <Input
                      name={BillingInputs.teamSize}
                      label={getLanguageValue(languageText, 'Team Size')}
                      placeholder={getLanguageValue(languageText, 'Team Size')}
                      type='number'
                      value={billingInfo.teamSize}
                      focusInput={billingFocusInput.teamSize}
                      handleBlurEvent={handleBlurEvent}
                      handleInputChange={handleInputChange}
                      required
                    />
                  </div>
                )}
            </div>
            <div className='row mb-6'>
              <ReactDatePicker
                className='col-md-3 col-12'
                name={BillingInputs.startDate}
                label={getLanguageValue(languageText, 'Start Date')}
                placeholder={getLanguageValue(languageText, 'Start Date')}
                date={billingInfo.startDate}
                minDate={new Date()}
                focusInput={billingFocusInput.startDate}
                handleDateSelect={handleDateSelect}
                required
              />
              <ReactDatePicker
                className='col-md-3 col-12'
                name={BillingInputs.endDate}
                label={getLanguageValue(languageText, 'End Date')}
                placeholder={getLanguageValue(languageText, 'End Date')}
                date={billingInfo.endDate}
                minDate={new Date()}
                handleDateSelect={handleDateSelect}
              />
            </div>
          </>
        )}

        {/* Facilitator Billing Details */}
        {!isAdmin && (
          <div className='row mb-10'>
            <ReactDatePicker
              className='col-md-3 col-12'
              name={BillingInputs.startDate}
              label={getLanguageValue(languageText, 'Start Date')}
              placeholder={getLanguageValue(languageText, 'Start Date')}
              date={billingInfo.startDate}
              minDate={new Date()}
              handleDateSelect={handleDateSelect}
              required
            />
            <ReactDatePicker
              className='col-md-3 col-12'
              name={BillingInputs.endDate}
              label={getLanguageValue(languageText, 'End Date')}
              placeholder={getLanguageValue(languageText, 'End Date')}
              date={billingInfo.endDate}
              minDate={new Date()}
              handleDateSelect={handleDateSelect}
            />
            {selectedPlan.type > 0 &&
              selectedPlan.type !== SubscriptionType.Plan &&
              !selectedPlan.individual && (
                <div className='col-md-3 col-12'>
                  <Input
                    name={BillingInputs.teamSize}
                    label={getLanguageValue(languageText, 'Team Size')}
                    placeholder={getLanguageValue(languageText, 'Team Size')}
                    type='number'
                    value={billingInfo.teamSize}
                    focusInput={billingFocusInput.teamSize}
                    handleBlurEvent={handleBlurEvent}
                    handleInputChange={handleInputChange}
                    required
                  />
                </div>
              )}
          </div>
        )}

        {/* Payment Types */}
        <div>
          <h5 className='required'>{getLanguageValue(languageText, 'Payment method')}</h5>
          <div className='row g-3 mb-3'>
            {paymentTypes.map((paymentType, index) => (
              <ToggleCardCheckbox
                key={index}
                keys={paymentType.id}
                header={paymentType.name}
                description={paymentType.description}
                containerClass='col-md-4 col-12'
                icon={paymentType.icon}
                checked={paymentType.id === billingInfo.selectedPaymentMethodId}
                handleClick={() => handleSelectPaymentMethod(paymentType.id)}
              />
            ))}
          </div>
          {billingFocusInput.selectedPaymentMethodId.errorMessage && (
            <div className='invalid-feedback d-block mt-n2 mb-3'>
              {billingFocusInput.selectedPaymentMethodId.errorMessage}
            </div>
          )}
        </div>

        {/* admin status, payment, After end, currency field*/}
        {isAdmin && (
          <div className='row mt-8'>
            <div className='col-md-3 col-12'>
              <DropdownSelect
                name={BillingInputs.status}
                label={getLanguageValue(languageText, 'Status')}
                defaultLabel={getLanguageValue(languageText, 'Select Status')}
                list={paymentStatusList}
                value={billingInfo.status}
                handleDropdownSelect={handleDropdownSelect}
              />
            </div>
            <div className='col-md-3 col-12'>
              <DropdownSelect
                name={BillingInputs.payment}
                label={getLanguageValue(languageText, 'Payment')}
                defaultLabel={getLanguageValue(languageText, 'Select Payment')}
                list={paymentList}
                value={billingInfo.payment}
                handleDropdownSelect={handleDropdownSelect}
              />
            </div>
            <div className='col-md-3 col-12'>
              <DropdownSelect
                name={BillingInputs.paymentAfterEnd}
                label={getLanguageValue(languageText, 'After End')}
                defaultLabel={getLanguageValue(languageText, 'Select After End')}
                list={paymentAfterEndList}
                value={billingInfo.paymentAfterEnd}
                handleDropdownSelect={handleDropdownSelect}
              />
            </div>
            <div className='col-md-3 col-12'>
              <DropdownSelect
                name={BillingInputs.currencyId}
                label={getLanguageValue(languageText, 'Currency')}
                defaultLabel={getLanguageValue(languageText, 'Select Currency')}
                list={currencyList}
                value={billingInfo.currencyId}
                handleDropdownSelect={handleDropdownSelect}
              />
            </div>
          </div>
        )}

        {/* Projected Bill */}
        {Number(billingInfo.teamSize) > 0 && selectedPlan.selectedPlanId > 0 && (
          <div className='border border-secondary rounded p-3'>
            <h5>{getLanguageValue(languageText, 'Projected Bill')}</h5>
            <br />
            <h3>
              {Number(billingInfo.teamSize) *
                Number(billingInfo.price ? billingInfo.price : selectedPlan.price)}{' '}
              SEK
              {selectedPlan.price !== Number(billingInfo.price) && (
                <>
                  {' '}
                  (<s>{selectedPlan.price * Number(billingInfo.teamSize)}</s> SEK)
                </>
              )}
            </h3>
            <p className='mb-10'>
              {getLanguageValue(languageText, 'Estimated invoice amount including Tax')}.
            </p>
            <p>{getLanguageValue(languageText, 'Services included')}:</p>
            <p>
              - {getLanguageValue(languageText, 'Unlimited Profiles/User')}
              <br />- {getLanguageValue(languageText, 'Unlimited Access to IDI Academy Courses')}
              <br />- {getLanguageValue(languageText, 'Facilitator consultation')}
            </p>
          </div>
        )}

        {isEdit && accountInfo.isSubscriptionActive && (
          <div className='mt-3'>
            <h5>{getLanguageValue(languageText, 'End subscription')}</h5>
            <button
              type='button'
              className='btn btn-danger mt-2'
              onClick={() => handleSubscription(false)}
            >
              {getLanguageValue(languageText, 'Cancel your subscription')}
            </button>
          </div>
        )}

        <button className='btn btn-success mt-3' onClick={handleBackToSubscriptionList}>
          {getLanguageValue(languageText, 'Go back')}
        </button>
      </form>

      {isAdmin && !openInvoiceForm && clientSubscriptionId > 0 && (
        <div className='mt-10'>
          <ReactTable
            headerText={getLanguageValue(languageText, 'Invoices')}
            buttonText={getLanguageValue(languageText, 'Add Invoice')}
            minHeight='min-h-150px'
            tableHeader={invoiceTableHeader}
            data={invoiceList}
            paginationRequired={false}
            handleButtonClick={addInvoiceClick}
          />
        </div>
      )}

      {openInvoiceForm && (
        <SubscriptionAddInvoice
          languageText={languageText}
          clientSubscriptionId={clientSubscriptionId}
          invoiceId={editedInvoiceId}
          handleClientSubscription={handleClientSubscription}
          handleCloseClick={closeInvoiceForm}
        />
      )}
    </>
  )
}
