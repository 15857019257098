import React from 'react'
import famfamfam_flags from '../../../public/DeliveryImages/famfamfam_flags.png'

interface IRenderFlagComponentProps {
  flagName: string
  value: string
}

export const RenderFlagComponent = (props: IRenderFlagComponentProps): JSX.Element => {
  const { flagName, value } = props

  return (
    <span className='d-flex align-items-center'>
      <i className={`me-4 ${flagName}`} style={{ backgroundImage: `url(${famfamfam_flags})` }} />
      {value}
    </span>
  )
}
