import { IButtonGroupList } from '../components/formComponents/toggleButtonGroup'
import { TypeOfRole, NoOfRespondents } from '../containers/profileList/profileEnums'
import { ITranslationObject, getLanguageValue } from './languageFunctionsHelper'

export const getTypeOfRoleButtonList = (languageText: ITranslationObject): IButtonGroupList[] => [
  {
    id: 1,
    name: TypeOfRole.Colleagues.toString(),
    title: getLanguageValue(languageText, 'Colleagues'),
  },
  {
    id: 2,
    name: TypeOfRole.TeamMembers.toString(),
    title: getLanguageValue(languageText, 'Team members'),
  },
  {
    id: 3,
    name: TypeOfRole.Clients.toString(),
    title: getLanguageValue(languageText, 'Clients'),
  },
  {
    id: 4,
    name: TypeOfRole.Suppliers.toString(),
    title: getLanguageValue(languageText, 'Suppliers'),
  },
  {
    id: 5,
    name: TypeOfRole.Subordinates.toString(),
    title: getLanguageValue(languageText, 'Subordinates'),
  },
]

export const getNoOfRespondentsButtonList = (): IButtonGroupList[] => [
  {
    id: 1,
    name: NoOfRespondents.N3,
    title: NoOfRespondents.N3,
  },
  {
    id: 2,
    name: NoOfRespondents.N4,
    title: NoOfRespondents.N4,
  },
  {
    id: 3,
    name: NoOfRespondents.N5,
    title: NoOfRespondents.N5,
  },
  {
    id: 4,
    name: NoOfRespondents.N6,
    title: NoOfRespondents.N6,
  },
]
