import React, { useEffect, useMemo, useState } from 'react'

import { RoleSettings } from './roleSettings/roleSettings'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import {
  ToggleButtonGroup,
  IButtonGroupList,
} from '../../../components/formComponents/toggleButtonGroup'
import {
  IndividualGroupBtnsEnum,
  ParticipantChangeRole,
  ProfileRoleStatus,
  RoleBtnsEnum,
} from '../profileEnums'

export interface IRoleStepData {
  roleSetBy: number
  roleSameForAll: boolean
  roleId: number
  roleText: string
  noOfRespondents: number
  roleCanBeChangedByParticipant: boolean
}

interface IActivityRoleStepProps {
  languageText: ITranslationObject
  roleFromEditProfile?: boolean
  selectedIndividualGroupBtn: string
  typeOfRoleButtonList: IButtonGroupList[]
  selectedSetRoleBtn: string
  selectedSameRoleBtn: string
  selectedTypeOfRoleBtn: string
  typeOfRoleInput: string
  customTypeOfRole: string
  roleInputSelected: boolean
  selectedNoOfRespondentsBtn: string
  noOfRespondentsInput: string
  customNoOfRespondents: string
  respondentsInputSelected: boolean
  respondentsConfirmModal: boolean
  selectedParticipantChangeBtn: string
  roleStepError: string
  showRoleSaveBtn?: boolean
  showEditModeSelectedParticipantBtnClick?: boolean
  showEditModeSelectedSameRoleBtn?: boolean
  showEditModeNoOfRespondent?: boolean
  showEditModeRoleButtonList?: boolean
  showEditModeRoleType?: boolean
  commonText?: boolean
  setShowEditModeNoOfRespondent?: React.Dispatch<React.SetStateAction<boolean>>
  setShowEditModeRoleType?: React.Dispatch<React.SetStateAction<boolean>>
  setShowEditModeRoleButtonList?: React.Dispatch<React.SetStateAction<boolean>>
  setShowEditModeSelectedSameRoleBtn?: React.Dispatch<React.SetStateAction<boolean>>
  setshowEditModeSelectedParticipantBtnClick?: React.Dispatch<React.SetStateAction<boolean>>
  onSetRoleBtnClick: (name: string) => void
  onSameRoleBtnClick: (name: string) => void
  onTypeOfRoleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onTypeOfRoleBtnClick: (name: string) => void
  handleTypeOfRoleFocus: (e: React.ChangeEvent<HTMLInputElement>) => void
  onNoOfRespondentsChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onNoOfRespondentsClick: (name: string) => void
  handleNoOfRespondentsFocus: (e: React.ChangeEvent<HTMLInputElement>) => void
  cancelRespondentsConfirmModal: () => void
  SubmitRespondentsConfirmModal: () => void
  handleParticipantChangeRoleClick: (name: string) => void
  onSaveClick?: (data: any) => void
}

export const ActivityRoleStep = (props: IActivityRoleStepProps): JSX.Element => {
  const {
    languageText,
    roleFromEditProfile,
    selectedIndividualGroupBtn,
    typeOfRoleButtonList,
    selectedSetRoleBtn,
    selectedSameRoleBtn,
    selectedTypeOfRoleBtn,
    typeOfRoleInput,
    customTypeOfRole,
    roleInputSelected,
    selectedNoOfRespondentsBtn,
    noOfRespondentsInput,
    respondentsInputSelected,
    respondentsConfirmModal,
    selectedParticipantChangeBtn,
    roleStepError,
    showRoleSaveBtn,
    commonText,
    showEditModeNoOfRespondent,
    showEditModeRoleType,
    showEditModeRoleButtonList,
    showEditModeSelectedSameRoleBtn,
    showEditModeSelectedParticipantBtnClick,
    onSetRoleBtnClick,
    onSameRoleBtnClick,
    onTypeOfRoleChange,
    onTypeOfRoleBtnClick,
    handleTypeOfRoleFocus,
    onNoOfRespondentsChange,
    onNoOfRespondentsClick,
    handleNoOfRespondentsFocus,
    cancelRespondentsConfirmModal,
    SubmitRespondentsConfirmModal,
    handleParticipantChangeRoleClick,
    onSaveClick,
    setShowEditModeNoOfRespondent,
    setShowEditModeRoleType,
    setShowEditModeRoleButtonList,
    setShowEditModeSelectedSameRoleBtn,
    setshowEditModeSelectedParticipantBtnClick,
  } = props

  const setRoleButtonList = useMemo(
    () =>
      [
        {
          id: 1,
          name: ProfileRoleStatus.RoleSetByFacilitator,
          title: getLanguageValue(languageText, 'Role is set by facilitator'),
        },
        {
          id: 2,
          name: ProfileRoleStatus.RoleSetByParticipant,
          title: getLanguageValue(languageText, 'Role is set by participant'),
        },
        // {
        //   id: 3,
        //   name: ProfileRoleStatus.DisableRole,
        //   title: getLanguageValue(languageText, 'Disable role'),
        // },
      ] as IButtonGroupList[],
    [languageText]
  )
  const sameRoleButtonList = useMemo(
    () =>
      [
        {
          id: 1,
          name: RoleBtnsEnum.sameRole,
          title: getLanguageValue(languageText, 'Same role setting for everyone'),
        },
        {
          id: 2,
          name: RoleBtnsEnum.individualRole,
          title: getLanguageValue(languageText, 'Individual role setting'),
        },
      ] as IButtonGroupList[],
    [languageText]
  )
  const participantRoleButtonList = useMemo(
    () =>
      [
        {
          id: 1,
          name: ParticipantChangeRole.canChange,
          title: getLanguageValue(languageText, 'Yes'),
        },
        {
          id: 2,
          name: ParticipantChangeRole.cannotChange,
          title: getLanguageValue(languageText, 'No'),
        },
      ] as IButtonGroupList[],
    [languageText]
  )
  const [rolesTitle, setRolesTitle] = useState<string>('')
  const [changeRoleTitle, setChangeRoleTitle] = useState<string>('')
  const [sameRoleTitle, setSameRoleTitle] = useState<string>('')

  useEffect(() => {
    if (Number(selectedSetRoleBtn) > 0) {
      const filteredArray = setRoleButtonList.filter((value) =>
        selectedSetRoleBtn.includes(value.name)
      )
      setRolesTitle(filteredArray[0].title)
    } else {
      setRolesTitle('')
    }
    if (selectedSameRoleBtn !== '') {
      const filteredArray = sameRoleButtonList.filter((value) =>
        selectedSameRoleBtn.includes(value.name)
      )
      setSameRoleTitle(filteredArray[0].title)
    } else {
      setSameRoleTitle('')
    }
    if (selectedParticipantChangeBtn !== '') {
      const filteredArray = participantRoleButtonList.filter((value) =>
        selectedParticipantChangeBtn.includes(value.name)
      )
      setChangeRoleTitle(filteredArray[0].title)
    } else {
      setChangeRoleTitle('')
    }
  }, [
    rolesTitle,
    selectedSetRoleBtn,
    selectedSameRoleBtn,
    sameRoleTitle,
    changeRoleTitle,
    selectedParticipantChangeBtn,
    languageText,
  ])

  const onSelectRoleEditBtnClick = () => {
    if (setShowEditModeRoleButtonList) setShowEditModeRoleButtonList(true)
    if (setShowEditModeSelectedSameRoleBtn) setShowEditModeSelectedSameRoleBtn(true)
    if (setShowEditModeNoOfRespondent) setShowEditModeNoOfRespondent(true)
    if (setShowEditModeRoleType) setShowEditModeRoleType(true)
    if (setshowEditModeSelectedParticipantBtnClick) setshowEditModeSelectedParticipantBtnClick(true)
  }

  const onSelectRoleSameEditBtnClick = () => {
    if (setShowEditModeSelectedSameRoleBtn) setShowEditModeSelectedSameRoleBtn(true)
    if (setShowEditModeNoOfRespondent) setShowEditModeNoOfRespondent(true)
    if (setShowEditModeRoleType) setShowEditModeRoleType(true)
    if (setshowEditModeSelectedParticipantBtnClick) setshowEditModeSelectedParticipantBtnClick(true)
  }

  const onSelectedParticipantBtnClick = () => {
    if (setshowEditModeSelectedParticipantBtnClick) setshowEditModeSelectedParticipantBtnClick(true)
  }

  return (
    <>
      <div id='activityRoleStep'>
        {!roleFromEditProfile && (
          <div className='fs-5 fw-bold mb-3'>{getLanguageValue(languageText, 'Role')}</div>
        )}

        {/* SET ROLE BUTTONS */}
        <div className='mb-4'>
          <div className='form-label'>{getLanguageValue(languageText, 'Role is set by')}</div>
          {showEditModeRoleButtonList ? (
            <ToggleButtonGroup
              buttonList={setRoleButtonList}
              selectedBtn={selectedSetRoleBtn}
              handleButtonClick={onSetRoleBtnClick}
            />
          ) : (
            <div className='d-flex align-items-center'>
              <div className='btn me-3 border'>{rolesTitle}</div>
              <button className='btn btn-light' onClick={onSelectRoleEditBtnClick}>
                <i className='bi bi-pencil' />
              </button>
            </div>
          )}
        </div>

        {/* ROLE SETTING BUTTONS */}
        {selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group &&
          selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator && (
            <div className='mb-4'>
              <div className='form-label'>{getLanguageValue(languageText, 'Role setting')}</div>
              {showEditModeSelectedSameRoleBtn ? (
                <ToggleButtonGroup
                  buttonList={sameRoleButtonList}
                  selectedBtn={selectedSameRoleBtn}
                  handleButtonClick={onSameRoleBtnClick}
                />
              ) : (
                <div className='d-flex align-items-center'>
                  <div className='btn border me-3'>{sameRoleTitle}</div>
                  <button className='btn btn-light' onClick={onSelectRoleSameEditBtnClick}>
                    <i className='bi bi-pencil' />
                  </button>
                </div>
              )}
            </div>
          )}

        {/* ROLE SETTINGS */}
        {selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator &&
          (selectedSameRoleBtn === RoleBtnsEnum.sameRole ||
            selectedIndividualGroupBtn === IndividualGroupBtnsEnum.individual) && (
            <>
              <RoleSettings
                languageText={languageText}
                selectedTypeOfRoleBtn={selectedTypeOfRoleBtn}
                typeOfRoleButtonList={typeOfRoleButtonList}
                roleInputSelected={roleInputSelected}
                typeOfRoleInput={typeOfRoleInput}
                selectedNoOfRespondentsBtn={selectedNoOfRespondentsBtn}
                respondentsInputSelected={respondentsInputSelected}
                noOfRespondentsInput={noOfRespondentsInput}
                respondentsConfirmModal={respondentsConfirmModal}
                onTypeOfRoleBtnClick={onTypeOfRoleBtnClick}
                onTypeOfRoleChange={onTypeOfRoleChange}
                handleTypeOfRoleFocus={handleTypeOfRoleFocus}
                onNoOfRespondentsClick={onNoOfRespondentsClick}
                onNoOfRespondentsChange={onNoOfRespondentsChange}
                handleNoOfRespondentsFocus={handleNoOfRespondentsFocus}
                SubmitRespondentsConfirmModal={SubmitRespondentsConfirmModal}
                cancelRespondentsConfirmModal={cancelRespondentsConfirmModal}
                setShowEditModeNoOfRespondent={setShowEditModeNoOfRespondent}
                showEditModeNoOfRespondent={showEditModeNoOfRespondent}
                showEditModeRoleType={showEditModeRoleType}
                setShowEditModeRoleType={setShowEditModeRoleType}
              />
            </>
          )}

        {/* PARTICIPANT CHANGE ROLE BUTTON */}
        {((selectedIndividualGroupBtn === IndividualGroupBtnsEnum.individual &&
          selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator &&
          (selectedTypeOfRoleBtn || customTypeOfRole)) ||
          (selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group &&
            selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator &&
            (selectedSameRoleBtn === RoleBtnsEnum.individualRole ||
              (selectedSameRoleBtn === RoleBtnsEnum.sameRole &&
                (selectedTypeOfRoleBtn || customTypeOfRole))))) && (
          <>
            <div className='form-label'>
              {getLanguageValue(
                languageText,
                'Should the participant be able to change role setting'
              )}
              ?
            </div>
            {showEditModeSelectedParticipantBtnClick ? (
              <ToggleButtonGroup
                buttonList={participantRoleButtonList}
                selectedBtn={selectedParticipantChangeBtn}
                handleButtonClick={handleParticipantChangeRoleClick}
              />
            ) : (
              <div className='d-flex align-items-center'>
                <div className='btn border me-3'>{changeRoleTitle}</div>
                <button className='btn btn-light' onClick={onSelectedParticipantBtnClick}>
                  <i className='bi bi-pencil' />
                </button>
              </div>
            )}
          </>
        )}

        {commonText && (
          <>
            <p className='mt-4 text-danger'>
              {getLanguageValue(languageText, 'The changes will affect the participants')}
            </p>
          </>
        )}

        {/* ERROR ELEMENT */}
        {roleStepError && <div className='invalid-feedback d-inline-block'>{roleStepError}</div>}

        {/* SAVE BUTTON */}
        {showRoleSaveBtn && !roleFromEditProfile && (
          <div className='mt-4'>
            <span
              className='fs-5 fw-bold text-decoration-underline'
              onClick={onSaveClick}
              role='button'
            >
              {getLanguageValue(languageText, 'Save and continue')}
            </span>
          </div>
        )}
      </div>
    </>
  )
}
