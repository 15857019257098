import React from 'react'

import { IDisconnectParticipantData } from '../editProfileCompletedOrPlannedParticipant/editProfileCompletedParticipant'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface IDisconnectParticipantModalProps {
  languageText: ITranslationObject
  presentationData: IDisconnectParticipantData
  handleDisconnectClick: () => void
  closeDisconnectModal: () => void
}

export const DisconnectParticipantModal: React.FC<IDisconnectParticipantModalProps> = (props) => {
  const { languageText, presentationData, handleDisconnectClick, closeDisconnectModal } = props

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, 'Disconnect Participant')}
        submitButtonText={getLanguageValue(languageText, 'Disconnect')}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        handleSubmitClick={handleDisconnectClick}
        handleCancelClick={closeDisconnectModal}
        isSubmitDangerButton={true}
      >
        {getLanguageValue(
          languageText,
          'Are you sure? You want to disconnect the participant profile presentation for this date?'
        )}
        <br />
        {presentationData.presentationDate} - {getLanguageValue(languageText, 'ID')}:{' '}
        {presentationData.presentationId} - {getLanguageValue(languageText, 'Name')}
      </ModalComponent>
    </>
  )
}
