import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUserDetails, loginUserAction, sendEmailForResetPassword } from './actions'
import { RootState } from '@app/store/configureStore'
import {
  clearNotifications,
  setAlerts,
  setSpinner,
  triggerAlert,
  triggerLanguageChange,
} from '../../actions'
import { Path, useNavigate } from 'react-router-dom'
import { IFocusError } from '../../../components/formComponents/input'
import {
  logInWithAccessCode,
  verifyUserEmailConfirmCode,
} from '../../twoFactorAuthenticationPage/actions'
import { getLanguageValue, ILanguageObject } from '../../../commonUtils/languageFunctionsHelper'
import { routePath } from '../../routePaths'
import { RoleName, Roles } from '../../commonEnums'
import { langKey } from '@app/consts'
import { getAlerts } from '@app/containers/alertList/actions'
import { MultiRoleBehavior, useAuthFlow } from '@app/auth'
import { UserId } from '@app/containers/reducer'

interface IFocusInput {
  email: IFocusError
  password: IFocusError
}

export enum UserInputs {
  email = 'email',
  password = 'password',
}

export interface IAccessCodeBody {
  userNameOrEmailAddress: string
  password: string
  twoFactorVerificationCode: string
}

export interface IVerifyUserEmailBody {
  userId: UserId
  password: string
  emailConfirmCode: string
}

type LoginFormState =
  | { kind: 'password'; username: string; password: string }
  | { kind: 'verify_two_factor'; code: string; username: string; password: string }
  | { kind: 'verify_email'; code: string; username: string; password: string }
  | { kind: 'forgot_password'; username: string }

export const useLogIn = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)
  const languageCode = queryParams.get(langKey) || ''

  const spinner: boolean = useSelector((state: RootState) => state.mainReducer.spinner)
  const userID = useSelector((state: RootState) => state.loginReducer.userId)
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)

  const [successMessage, setSuccessMessage] = useState('')
  const [language, setLanguage] = useState<ILanguageObject[]>([])
  const [formState, setFormState] = useState<LoginFormState>({
    kind: 'password',
    username: '',
    password: '',
  })

  const [formError, setFormError] = useState<IFocusInput>({
    email: {
      touched: false,
      errorMessage: '',
    },
    password: {
      touched: false,
      errorMessage: '',
    },
  })
  const [tokenExpired, setTokenExpired] = useState<boolean>(false)
  const authFlow = useAuthFlow(dispatch, navigate)

  useEffect(() => {
    const tokenExpired = sessionStorage.getItem('tokenExpired') === 'true'
    if (tokenExpired) setTokenExpired(true)
  }, [])

  useEffect(() => {
    triggerLanguageChange(languageCode, false, dispatch, 0)
  }, [])

  const handleCloseTokenExpiredModal = (): void => {
    setTokenExpired(false)
    sessionStorage.removeItem('tokenExpired')
  }

  const onGoBack = (): void => {
    dispatch(clearNotifications(''))
    setFormState({
      kind: 'password',
      username: formState.username,
      password: '',
    })
  }

  /** Returns 'true' when the form is valid. */
  const validatePasswordForm = (): boolean => {
    if (formState.kind !== 'password') {
      return true
    }

    const errors: IFocusInput = {
      email: {
        touched: false,
        errorMessage: formState.username ? '' : getLanguageValue(languageText, 'Email is required'),
      },
      password: {
        touched: false,
        errorMessage: formState.password
          ? ''
          : getLanguageValue(languageText, 'Password is required'),
      },
    }

    setFormError(errors)

    return !errors.email.errorMessage && !errors.password.errorMessage
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    validatePasswordForm()
  }

  function loginByUsernameAndPassword(): Promise<void> {
    if (formState.kind !== 'password') {
      return Promise.reject()
    }
    if (!validatePasswordForm()) {
      return Promise.reject()
    }

    dispatch(setSpinner(true))

    return loginUserAction(
      dispatch,
      { email: formState.username, password: formState.password },
      false
    )
      .then((res) => {
        const accessToken = res.accessToken?.token
        if (!accessToken) {
          if (res.shouldResetPassword) {
            navigate(routePath.resetPassword)
          } else if (res.requiresTwoFactorVerification) {
            dispatch(clearNotifications(''))
            setFormState({
              kind: 'verify_two_factor',
              code: '',

              // the backend is really stupid; it verifies the 2FA and then it just
              // attemps to login by password again. therefore it needs the credentials
              // twice.
              username: formState.username,
              password: formState.password,
            })
          } else if (!res.emailConfirmed) {
            setFormState({
              kind: 'verify_email',
              code: '',
              username: formState.username,
              password: formState.password,
            })
          }
        } else {
          getUserDetails(dispatch, userLanguage.userLanguageCode).then((details) => {
            /** This is a conversion done so that we don't have to change too much of the rest of the
             * code for now... 2024-09-17 Joakim
             */
            const roles: Array<RoleName> = details?.userRoles.map((a) => a.assignedRoleName) ?? []

            authFlow.redirectToStartPageByRole(
              res.accessToken.roleName,
              details,
              MultiRoleBehavior.AskUser
            )

            return getAlerts(dispatch, {
              filter: '',
              sorting: '',
              maxResultCount: 500,
              skipCount: 0,
            }).then((res) => {
              dispatch(setAlerts(res.items))

              if (roles.includes(Roles.Admin)) {
                dispatch(triggerAlert('on_admin_login'))
              }
              if (roles.includes(Roles.Facilitator)) {
                dispatch(triggerAlert('on_facilitator_login'))
              }
              if (roles.includes(Roles.Participant)) {
                dispatch(triggerAlert('on_participant_login'))
              }
              if (roles.includes(Roles.Respondent)) {
                dispatch(triggerAlert('on_respondent_login'))
              }

              dispatch(triggerAlert('on_login'))
            })
          })
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  type EventLike = Pick<Event, 'preventDefault'>

  function handleSubmit(event?: EventLike): Promise<void> {
    event?.preventDefault()

    switch (formState.kind) {
      case 'password':
        return loginByUsernameAndPassword()
      case 'verify_email': {
        const body: IVerifyUserEmailBody = {
          // this user ID shows up from the outer redux state, magically. obviously
          // should not depend on stupid side effects like this but whatever.
          //   -johan, 2024-09-12
          userId: userID,
          password: formState.password,
          emailConfirmCode: formState.code,
        }

        return verifyUserEmailConfirmCode(body, dispatch)
          .then((res) => {
            if (res) {
              getUserDetails(dispatch, userLanguage.userLanguageCode).then((response) => {
                authFlow.redirectToStartPageByRole(
                  res.accessToken.roleName,
                  response,
                  MultiRoleBehavior.AskUser
                )
              })
            }
          })
          .finally(() => dispatch(setSpinner(false)))
      }
      case 'verify_two_factor': {
        const body: IAccessCodeBody = {
          userNameOrEmailAddress: formState.username,
          password: formState.password,
          twoFactorVerificationCode: formState.code,
        }
        return logInWithAccessCode(body, dispatch)
          .then(
            (res) => {
              getUserDetails(dispatch, userLanguage.userLanguageCode).then((response) => {
                authFlow.redirectToStartPageByRole(
                  res.accessToken.roleName,
                  response,
                  MultiRoleBehavior.AskUser
                )
              })
            },
            (err) => {
              setFormState({
                kind: 'verify_two_factor',
                username: formState.username,
                password: formState.password,
                code: '',
              })
            }
          )
          .finally(() => dispatch(setSpinner(false)))
      }
      case 'forgot_password': {
        return sendEmailForResetPassword(
          formState.username,
          userLanguage.userLanguageCode,
          dispatch
        ).then((response) => {
          if (response?.status === 200) {
            setSuccessMessage(
              getLanguageValue(languageText, 'Reset password link sent to your email')
            )
          }
        })
      }
    }
  }

  return {
    languageText,
    language,
    formError,
    userID,
    spinner,
    successMessage,
    tokenExpired,
    setLanguage,
    onGoBack,
    handleSubmit,
    handleBlurEvent,
    navigate,
    dispatch,
    setFormError,
    handleCloseTokenExpiredModal,
    formState,
    setFormState,
  }
}
