import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../api'
import { setNotification } from '../actions'
import { ISubscriptionBody } from './addEditSubscription/hooks'

export const getAllSubscriptions = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  dispatch: Dispatch
): Promise<any> => {
  const body = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
  }

  try {
    const response = await api.get(apiEndPoints.getAllSubscriptions, {
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getSubscriptionById = async (
  subscriptionId: number,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getSubscriptionById, {
      params: { id: subscriptionId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createSubscription = async (
  body: ISubscriptionBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.post(apiEndPoints.createSubscription, JSON.stringify(body), {})
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateSubscription = async (
  body: ISubscriptionBody,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const response = await api.put(apiEndPoints.updateSubscription, JSON.stringify(body), {})
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteSubscription = async (id: number, dispatch: Dispatch): Promise<any> => {
  const paramQuery = {
    id: id,
  }
  try {
    await api.delete(apiEndPoints.deleteSubscription, {
      params: paramQuery,
    })
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getAllCurrencies = async (dispatch: Dispatch): Promise<any> => {
  try {
    const response = await api.get(apiEndPoints.getAllCurrencies, {
      params: { maxResultCount: 100 },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
