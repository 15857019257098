import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { IFocusError } from '../../../../components/formComponents/input'
import { addToast, setSpinner } from '../../../actions'
import { getLoggedInUserData } from '../../../auth/signUp/actions'
import { RootState } from '@app/store/configureStore'
import { updateUser } from '../../../usersProfile/edit-user/actions'
import { E164Number } from 'libphonenumber-js/types'
import { AnyAction } from 'redux'
import { IUserProfile } from '@app/containers/commonInterfaces'

export interface IUserData {
  name: string
  surname: string
  phoneNumber: string
}

interface IFocusInput {
  name: IFocusError
  surname: IFocusError
}

export enum UserData {
  name = 'name',
  surname = 'surname',
  phoneNumber = 'phoneNumber',
}

export interface IEditUserDataProps {
  handleCancelClick: () => void
}

export const useEditParticipantModal = (props: IEditUserDataProps) => {
  const { handleCancelClick } = props
  const dispatch = useDispatch()
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const userProfileData = useSelector((state: RootState) => state.loginReducer.userData)

  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false)
  const [userData, setUserData] = useState<IUserData>({
    name: '',
    surname: '',
    phoneNumber: '',
  })

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    name: {
      touched: false,
      errorMessage: '',
    },
    surname: {
      touched: false,
      errorMessage: '',
    },
  })

  useEffect(() => {
    setUserData({
      name: userProfileData.name,
      surname: userProfileData.surname,
      phoneNumber: userProfileData.phoneNumber,
    })
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setUserData({
      ...userData,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    switch (name) {
      case UserData.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Name is required')
        }
        break
      case UserData.surname:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Surname is required')
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handlePhoneInputChange = (value: string): void => {
    setUserData({
      ...userData,
      phoneNumber: value,
    })
  }

  const validatePhoneNumber = (value: E164Number): void => {
    const phoneNumber = parseInt(value)

    if (phoneNumber === 0) {
      setPhoneNumberError(false)
    } else if (value.length >= 1 && value.length <= 4) {
      setPhoneNumberError(true)
    } else {
      setPhoneNumberError(false)
    }
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !userData.name ||
      focusInput.name.errorMessage ||
      !userData.surname ||
      focusInput.surname.errorMessage
    ) {
      for (const item in UserData) {
        if (item === UserData.phoneNumber) break
        handleFormErrors(item, userData[item])
      }
      return false
    }
    if (phoneNumberError) {
      return false
    }
    return true
  }

  const onSubmitClick = (e: { preventDefault: () => void }): void => {
    e.preventDefault()
    if (!handleValidationOnSubmit()) return
    const body: IUserProfile = {
      ...userProfileData,
      name: userData.name,
      surname: userData.surname,
      phoneNumber: userData.phoneNumber,
      emailAddress: userProfileData.emailAddress,
      isTwoFactorEnabled: userProfileData.isTwoFactorEnabled,
    }
    dispatch(setSpinner(true))
    updateUser(body, true, dispatch)
      .then((response) => {
        if (response) {
          dispatch(addToast('Changes saved successfully') as AnyAction)
          getLoggedInUserData(true, dispatch)
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
        handleCancelClick()
      })
  }

  return {
    languageText,
    focusInput,
    userData,
    phoneNumberError,
    handlePhoneInputChange,
    validatePhoneNumber,
    handleBlurEvent,
    handleCancelClick,
    onSubmitClick,
    handleInputChange,
  }
}
