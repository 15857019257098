import React from 'react'

import { Input } from '../../formComponents/input'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from '@app/components/formComponents/dropdownSelect'
import { IInvoiceFocusInput, IProductData, ProductInput } from '../invoiceInterface'

interface IAddEditProduct {
  languageText: ITranslationObject
  isEditMode: boolean
  productFormData: IProductData
  productsList: IDropdownList[]
  focusInput: IInvoiceFocusInput
  handleProductInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleProductSelect: (selectedItem: IDropdownSelectedItem) => void
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void
  handleAddProduct: () => void
  handleSaveProduct: () => void
  handleCancelProduct: () => void
}

export const AddEditProduct = (props: IAddEditProduct) => {
  const {
    languageText,
    isEditMode,
    productFormData,
    productsList,
    focusInput,
    handleProductInputChange,
    handleProductSelect,
    handleBlurEvent,
    handleAddProduct,
    handleSaveProduct,
    handleCancelProduct,
  } = props

  return (
    <div className='row border rounded align-items-center m-0 mb-3 pt-2'>
      <div className='col-lg-3 col-12'>
        <DropdownSelect
          name={ProductInput.articleId}
          label={getLanguageValue(languageText, 'Product')}
          list={productsList}
          defaultLabel={getLanguageValue(languageText, 'Select Product')}
          value={productFormData.articleId ? String(productFormData.articleId) : ''}
          focusInput={focusInput.articleId}
          handleDropdownSelect={handleProductSelect}
          searchOption
          required
        />
      </div>
      <div className='col-lg-3 col-12'>
        <Input
          name={ProductInput.description}
          label={getLanguageValue(languageText, 'Details')}
          placeholder={getLanguageValue(languageText, 'Details')}
          checkMarkNotRequired={true}
          value={productFormData.description ?? ''}
          focusInput={focusInput.description}
          handleInputChange={handleProductInputChange}
          handleBlurEvent={handleBlurEvent}
          required
        />
      </div>

      <div className='col-lg-1 col-4'>
        <Input
          name={ProductInput.quantity}
          type='number'
          label={getLanguageValue(languageText, 'Quantity')}
          placeholder={getLanguageValue(languageText, 'Quantity')}
          value={productFormData.quantity ?? 0}
          focusInput={focusInput.quantity}
          handleInputChange={handleProductInputChange}
          handleBlurEvent={handleBlurEvent}
          requireLeadingZero
          checkMarkNotRequired
          invalidMarkNotRequired
          required
        />
      </div>
      <div className='col-lg-2 col-8'>
        <Input
          name={ProductInput.price}
          type='number'
          label={getLanguageValue(languageText, 'Price')}
          placeholder={getLanguageValue(languageText, 'Price')}
          value={productFormData.price ?? 0}
          focusInput={focusInput.price}
          handleInputChange={handleProductInputChange}
          handleBlurEvent={handleBlurEvent}
          requireLeadingZero
          checkMarkNotRequired
          invalidMarkNotRequired
          required
        />
      </div>
      <div className='col-lg-1 col-4'>
        <Input
          name={ProductInput.discount}
          type='number'
          label={getLanguageValue(languageText, 'Discount')}
          placeholder={getLanguageValue(languageText, 'Discount')}
          value={productFormData.discount ?? 0}
          handleInputChange={handleProductInputChange}
          requireLeadingZero
          checkMarkNotRequired
          invalidMarkNotRequired
        />
      </div>
      <div className='col-lg-2 col-8'>
        {!isEditMode ? (
          <button onClick={handleAddProduct} className='btn btn-success mt-8 w-75'>
            {getLanguageValue(languageText, 'Add')}
          </button>
        ) : (
          <div className='d-flex gap-2 mt-2'>
            <button onClick={handleSaveProduct} className='btn btn-success btn-sm'>
              {getLanguageValue(languageText, 'Save')}
            </button>
            <button onClick={handleCancelProduct} className='btn btn-secondary btn-sm'>
              {getLanguageValue(languageText, 'Cancel')}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
