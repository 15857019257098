import React from 'react'

import { Input } from '../../../../components/formComponents/input'
import { PhoneNumberInput } from '../../../employeeList/addEmployeeModal/phoneNumberInput'
import { IEditUserDataProps, useEditParticipantModal, UserData } from './hooks'
import { E164Number } from 'libphonenumber-js/types'
import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '@app/components/modals/modalComponent'

export const EditParticipantModal: React.FC<IEditUserDataProps> = (props): JSX.Element => {
  const {
    languageText,
    userData,
    focusInput,
    phoneNumberError,
    validatePhoneNumber,
    handleBlurEvent,
    handleInputChange,
    handleCancelClick,
    onSubmitClick,
    handlePhoneInputChange,
  } = useEditParticipantModal(props)

  return (
    <>
      <ModalComponent
        width='lg'
        headerText={getLanguageValue(languageText, 'Edit your profile')}
        submitButtonText={getLanguageValue(languageText, 'Submit')}
        cancelButtonText={getLanguageValue(languageText, 'Cancel')}
        handleSubmitClick={onSubmitClick}
        handleCancelClick={handleCancelClick}
      >
        <Input
          name={UserData.name}
          type='text'
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          placeholder={getLanguageValue(languageText, 'Name')}
          focusInput={focusInput.name}
          label={getLanguageValue(languageText, 'Name')}
          value={userData.name}
        />
        <Input
          name={UserData.surname}
          type='text'
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          placeholder={getLanguageValue(languageText, 'Surname')}
          focusInput={focusInput.surname}
          label={getLanguageValue(languageText, 'Surname')}
          value={userData.surname}
        />
        <PhoneNumberInput
          value={(userData.phoneNumber as E164Number) || ''}
          label={getLanguageValue(languageText, 'Phonenumber')}
          onChange={(value) => handlePhoneInputChange(value)}
          onBlur={(e) => validatePhoneNumber(e.target.value as E164Number)}
          languageText={languageText}
          phoneNumberError={phoneNumberError}
          errorFieldClassName='fs-6 mt-2'
        />
        <p className='text-danger mt-1'>{phoneNumberError && phoneNumberError}</p>
      </ModalComponent>
    </>
  )
}
