import React from 'react'
import { Dropdown } from 'react-bootstrap'

import famfamfam_flags from '../../../public/DeliveryImages/famfamfam_flags.png'
import logo from '../../../public/DeliveryImages/IDI_Logotyp.webp'
import { useLanguageNavbar } from './hooks'

interface ILanguageNavBarProps {
  hideLanguageSelection?: boolean
  // LanguageCode will be stored in URL in pages before user logs-in
  // Once user logs-in, we will be getting languageCode from api
  languageCodeInURL?: boolean
}

export const LanguageNavbar = (props: ILanguageNavBarProps): JSX.Element => {
  const { selectedLanguage, languages, handleChangeLanguage } = useLanguageNavbar(
    props.languageCodeInURL!
  )

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between mb-5'>
        <img src={logo} className='idi-logo' />
        {!props.hideLanguageSelection && (
          <Dropdown>
            <Dropdown.Toggle
              variant='default'
              className='d-flex align-items-center justify-content-start'
            >
              <i className='bi bi-globe'></i>
              <div className='d-inline-block text-truncate ms-2'>{selectedLanguage}</div>
            </Dropdown.Toggle>
            <Dropdown.Menu className='overflow-auto'>
              {languages?.map((language) => (
                <Dropdown.Item key={language.id} onClick={() => handleChangeLanguage(language)}>
                  <div className='d-flex align-items-center gap-3 my-1'>
                    <div
                      className={`${language.icon}`}
                      style={{ backgroundImage: `url(${famfamfam_flags})` }}
                    />
                    <span>{language.displayName}</span>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </React.Fragment>
  )
}
