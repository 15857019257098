import { ITranslationObject, getLanguageValue } from '../commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'

export enum Directiveness {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export const getDirectivenessOptions = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: 1,
    value: Directiveness.A,
    displayName: getLanguageValue(languageText, Directiveness.A),
  },
  {
    id: 2,
    value: Directiveness.B,
    displayName: getLanguageValue(languageText, Directiveness.B),
  },
  {
    id: 3,
    value: Directiveness.C,
    displayName: getLanguageValue(languageText, Directiveness.C),
  },
  {
    id: 4,
    value: Directiveness.D,
    displayName: getLanguageValue(languageText, Directiveness.D),
  },
]

export enum Affiliation {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export const getAffiliationOptions = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: 1,
    value: Affiliation.One.toString(),
    displayName: getLanguageValue(languageText, Affiliation.One.toString()),
  },
  {
    id: 2,
    value: Affiliation.Two.toString(),
    displayName: getLanguageValue(languageText, Affiliation.Two.toString()),
  },
  {
    id: 3,
    value: Affiliation.Three.toString(),
    displayName: getLanguageValue(languageText, Affiliation.Three.toString()),
  },
  {
    id: 4,
    value: Affiliation.Four.toString(),
    displayName: getLanguageValue(languageText, Affiliation.Four.toString()),
  },
]
