import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { IFocusError } from '../../../components/formComponents/input'
import { SubscriptionType } from '../../clientList/addEditModal/clientEnums'
import { IAddEditSubscriptionProps } from './../addEditSubscription/addEditSubscription'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import {
  createSubscription,
  getAllCurrencies,
  getSubscriptionById,
  updateSubscription,
} from './../actions'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { addToast, setSpinner } from '../../actions'
import { AnyAction } from 'redux'

export enum SubscriptionInputs {
  name = 'name',
  priceSEK = 'priceSEK',
  priceAnnualSEK = 'priceAnnualSEK',
  priceMonthlySEK = 'priceMonthlySEK',
  profileIncludedAnnual = 'profileIncludedAnnual',
  academyCoursesIncludedAnnual = 'academyCoursesIncludedAnnual',
  facilitatorConsultationHoursIncludedAnnual = 'facilitatorConsultationHoursIncludedAnnual',
  trialDays = 'trialDays',
  type = 'type',
  description = 'description',
  enabled = 'enabled',
  hiddenForFacilitators = 'hiddenForFacilitators',
  hiddenForParticipants = 'hiddenForParticipants',
  enableAnnual = 'enableAnnual',
  enableMonthly = 'enableMonthly',
  individual = 'individual',
  profile = 'profile',
  profileIncluded = 'profileIncluded',
  profileIncludedMonthly = 'profileIncludedMonthly',
  profileIncludedInTrial = 'profileIncludedInTrial',
  academyCourses = 'academyCourses',
  academyCoursesIncluded = 'academyCoursesIncluded',
  academyCoursesIncludedMonthly = 'academyCoursesIncludedMonthly',
  academyCoursesIncludedInTrial = 'academyCoursesIncludedInTrial',
  facilitatorConsultationHours = 'facilitatorConsultationHours',
  facilitatorConsultationHoursIncluded = 'facilitatorConsultationHoursIncluded',
  facilitatorConsultationHoursIncludedMonthly = 'facilitatorConsultationHoursIncludedMonthly',
  facilitatorConsultationHoursIncludedInTrial = 'facilitatorConsultationHoursIncludedInTrial',
  discount = 'discount',
  discountAnnual = 'discountAnnual',
  discountMonthly = 'discountMonthly',
  invoice = 'invoice',
  creditCard = 'creditCard',
  subscriptionCurrency = 'subscriptionCurrency',
}

export interface ISubscriptionCurrency {
  currencyId: string
  price: number
  priceAnnual: number
  priceMonthly: number
  isError: boolean
}

export type ISubscriptionBody = Omit<
  ISubscription,
  | 'type'
  | 'profileIncludedAnnual'
  | 'academyCoursesIncludedAnnual'
  | 'facilitatorConsultationHoursIncludedAnnual'
> & {
  type: number
  profileIncludedAnnual: number
  academyCoursesIncludedAnnual: number
  facilitatorConsultationHoursIncludedAnnual: number
}

export interface ISubscription {
  id: number
  name: string
  trialDays: number
  type: string
  description: string
  enabled: boolean
  hiddenForFacilitators: boolean
  hiddenForParticipants: boolean
  enableAnnual: boolean
  enableMonthly: boolean
  individual: boolean
  profile: boolean
  profileIncluded: number
  profileIncludedAnnual: string
  profileIncludedMonthly: number
  profileIncludedInTrial: number
  academyCourses: boolean
  academyCoursesIncluded: number
  academyCoursesIncludedAnnual: string
  academyCoursesIncludedMonthly: number
  academyCoursesIncludedInTrial: number
  facilitatorConsultationHours: boolean
  facilitatorConsultationHoursIncluded: number
  facilitatorConsultationHoursIncludedAnnual: string
  facilitatorConsultationHoursIncludedMonthly: number
  facilitatorConsultationHoursIncludedInTrial: number
  priceSEK: number
  priceAnnualSEK: number
  priceMonthlySEK: number
  discount: number
  discountAnnual: number
  discountMonthly: number
  invoice: boolean
  creditCard: boolean
  subscriptionCurrencies: ISubscriptionCurrency[]
}

export interface ICurrency {
  id: number
  name: string
  code: string
}

interface ICurrencyResponse {
  items: ICurrency[]
}

interface IFocusInput {
  name: IFocusError
  priceSEK: IFocusError
  priceAnnualSEK: IFocusError
  priceMonthlySEK: IFocusError
  profileIncludedAnnual: IFocusError
  academyCoursesIncludedAnnual: IFocusError
  facilitatorConsultationHoursIncludedAnnual: IFocusError
  invoice: IFocusError
}

export const useAddEditSubscription = (props: IAddEditSubscriptionProps) => {
  const { subscriptionId, handleRefetchSubscriptions, closeModal } = props

  const dispatch = useDispatch()

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initialFocusState: IFocusError = {
    touched: false,
    errorMessage: '',
  }
  const initialFocusInputState: IFocusInput = {
    name: initialFocusState,
    priceSEK: initialFocusState,
    priceAnnualSEK: initialFocusState,
    priceMonthlySEK: initialFocusState,
    profileIncludedAnnual: initialFocusState,
    academyCoursesIncludedAnnual: initialFocusState,
    facilitatorConsultationHoursIncludedAnnual: initialFocusState,
    invoice: initialFocusState,
  }
  const [subscriptionInfo, setSubscriptionInfo] = useState<ISubscription>({
    id: 0,
    name: '',
    trialDays: 0,
    type: '',
    description: '',
    enabled: false,
    hiddenForFacilitators: false,
    hiddenForParticipants: false,
    enableAnnual: false,
    enableMonthly: false,
    individual: false,
    profile: false,
    profileIncluded: 0,
    profileIncludedAnnual: '0',
    profileIncludedMonthly: 0,
    profileIncludedInTrial: 0,
    academyCourses: false,
    academyCoursesIncluded: 0,
    academyCoursesIncludedAnnual: '0',
    academyCoursesIncludedMonthly: 0,
    academyCoursesIncludedInTrial: 0,
    facilitatorConsultationHours: false,
    facilitatorConsultationHoursIncluded: 0,
    facilitatorConsultationHoursIncludedAnnual: '0',
    facilitatorConsultationHoursIncludedMonthly: 0,
    facilitatorConsultationHoursIncludedInTrial: 0,
    priceSEK: 0,
    priceAnnualSEK: 0,
    priceMonthlySEK: 0,
    discount: 0,
    discountAnnual: 0,
    discountMonthly: 0,
    invoice: false,
    creditCard: false,
    subscriptionCurrencies: [],
  })
  const [focusInput, setFocusInput] = useState<IFocusInput>(initialFocusInputState)
  // Currency states
  const initialCurrencyInfoState: ISubscriptionCurrency = {
    currencyId: '',
    price: 0,
    priceAnnual: 0,
    priceMonthly: 0,
    isError: false,
  }
  const [currencyInfo, setCurrencyInfo] = useState<ISubscriptionCurrency[]>([
    initialCurrencyInfoState,
  ])
  const [currencies, setCurrencies] = useState<IDropdownList[]>([])

  const subscriptionTypes = useMemo<IDropdownList[]>(
    () => [
      {
        id: 1,
        displayName: getLanguageValue(languageText, 'Plan'),
        value: SubscriptionType.Plan.toString(),
      },
      {
        id: 2,
        displayName: getLanguageValue(languageText, 'Subscription'),
        value: SubscriptionType.Subscription.toString(),
      },
    ],
    [languageText]
  )

  const fetchSubscription = () => {
    dispatch(setSpinner(true))
    getSubscriptionById(subscriptionId, dispatch)
      .then((response: ISubscriptionBody) => {
        if (response) {
          const updatedInfo: ISubscription = {
            ...response,
            type: response.type.toString(),
            profileIncludedAnnual:
              response.profileIncludedAnnual === 99999
                ? 'Unlimited'
                : response.profileIncludedAnnual.toString(),
            academyCoursesIncludedAnnual:
              response.academyCoursesIncludedAnnual === 99999
                ? 'Unlimited'
                : response.academyCoursesIncludedAnnual.toString(),
            facilitatorConsultationHoursIncludedAnnual:
              response.facilitatorConsultationHoursIncludedAnnual === 99999
                ? 'Unlimited'
                : response.facilitatorConsultationHoursIncludedAnnual.toString(),
          }
          setSubscriptionInfo(updatedInfo)

          const updatedCurrencyInfo: ISubscriptionCurrency[] = response.subscriptionCurrencies.map(
            (currency) => ({
              ...currency,
              currencyId: currency.currencyId.toString(),
            })
          )
          setCurrencyInfo(updatedCurrencyInfo)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const fetchCurrencies = () => {
    dispatch(setSpinner(true))
    getAllCurrencies(dispatch)
      .then((response: ICurrencyResponse) => {
        if (response && response.items) {
          const currencyDList = response.items.map(
            (item): IDropdownList => ({
              id: item.id,
              displayName: item.code,
              value: item.id.toString(),
            })
          )
          setCurrencies(currencyDList)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  useEffect(() => {
    fetchCurrencies()
  }, [])

  useEffect(() => {
    subscriptionId
      ? fetchSubscription()
      : setSubscriptionInfo({ ...subscriptionInfo, type: SubscriptionType.Plan.toString() })
  }, [subscriptionId])

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    switch (name) {
      case SubscriptionInputs.name:
        if (!value) {
          errorMessage = 'Name is required'
        }
        break
      case SubscriptionInputs.priceSEK:
        if (!value) errorMessage = 'Price is required'
        break
      case SubscriptionInputs.priceAnnualSEK:
        if (!value) errorMessage = 'Price annual is required'
        break
      case SubscriptionInputs.priceMonthlySEK:
        if (!value) errorMessage = 'Price monthly is required'
        break
      case SubscriptionInputs.profileIncludedAnnual:
      case SubscriptionInputs.academyCoursesIncludedAnnual:
      case SubscriptionInputs.facilitatorConsultationHoursIncludedAnnual:
        if (
          !Number(value) &&
          Number(value) !== 0 &&
          value.toString().toLowerCase() !== 'Unlimited'.toLowerCase()
        ) {
          errorMessage = `Price should be 'Unlimited' or number`
        }
        break
      default:
      case SubscriptionInputs.invoice:
      case SubscriptionInputs.creditCard:
        if (!value) errorMessage = 'Minimum 1 type of payment is required'
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage ? getLanguageValue(languageText, errorMessage) : '',
      },
    }))
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const type = e.target.type
    let value = e.target.value
    if (type === 'number') value = value.replace(/^0+/, '')

    setSubscriptionInfo({
      ...subscriptionInfo,
      [name]: value,
    })

    if (focusInput[name as keyof typeof focusInput]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setSubscriptionInfo({
      ...subscriptionInfo,
      [name]: value,
    })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.checked

    setSubscriptionInfo({
      ...subscriptionInfo,
      [name]: value,
    })

    if (
      (name === SubscriptionInputs.invoice || name === SubscriptionInputs.creditCard) &&
      focusInput.invoice.errorMessage
    ) {
      handleFormErrors(SubscriptionInputs.invoice, 'true')
    }
  }

  const handleSubscriptionTypeChange = (selectedItem: IDropdownSelectedItem): void => {
    const { value } = selectedItem

    if (Number(value) === SubscriptionType.Subscription) {
      setSubscriptionInfo({
        ...subscriptionInfo,
        priceAnnualSEK: 0,
        priceMonthlySEK: 0,
        discountAnnual: 0,
        discountMonthly: 0,
        profileIncludedAnnual: '0',
        profileIncludedMonthly: 0,
        profileIncludedInTrial: 0,
        academyCoursesIncludedAnnual: '0',
        academyCoursesIncludedMonthly: 0,
        academyCoursesIncludedInTrial: 0,
        facilitatorConsultationHoursIncludedAnnual: '0',
        facilitatorConsultationHoursIncludedMonthly: 0,
        facilitatorConsultationHoursIncludedInTrial: 0,
        type: value,
      })

      setCurrencyInfo((prev) =>
        prev.map((currency) => ({
          ...currency,
          priceAnnual: 0,
          priceMonthly: 0,
          isError: false,
        }))
      )
    } else {
      setSubscriptionInfo({
        ...subscriptionInfo,
        priceSEK: 0,
        discount: 0,
        profileIncluded: 0,
        academyCoursesIncluded: 0,
        facilitatorConsultationHoursIncluded: 0,
        type: value,
      })

      setCurrencyInfo((prev) =>
        prev.map((currency) => ({
          ...currency,
          price: 0,
          isError: false,
        }))
      )
    }

    setFocusInput(initialFocusInputState)
  }

  // Currency Fn's
  const handleCurrencyInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const name = e.target.name
    const type = e.target.type
    let value = e.target.value
    if (type === 'number') value = value.replace(/^0+/, '')

    setCurrencyInfo((prev) =>
      prev.map((currency, i) =>
        i === index ? { ...currency, [name]: value, isError: false } : currency
      )
    )
  }

  const handleCurrencyDropdownChange = (
    selectedItem: IDropdownSelectedItem,
    index: number
  ): void => {
    const { name, value } = selectedItem

    setCurrencyInfo((prev) =>
      prev.map((currency, i) =>
        i === index ? { ...currency, [name]: value, isError: false } : currency
      )
    )
  }

  const handleAddCurrency = (): void => {
    // Check if any currency object has an error
    let hasError = false

    const updatedInfo = currencyInfo.map((currency) => {
      let isError = false
      if (Number(subscriptionInfo.type) === SubscriptionType.Plan) {
        isError = currency.currencyId.length > 0 && currency.price > 0 ? false : true
      } else {
        isError =
          currency.currencyId.length > 0 && currency.priceAnnual > 0 && currency.priceMonthly > 0
            ? false
            : true
      }
      if (isError) hasError = true

      return {
        ...currency,
        isError: isError,
      }
    })

    // If there's an error, return early
    if (hasError) {
      setCurrencyInfo(updatedInfo) // Update the state to show errors
      return
    }

    // Otherwise, update the isError values
    setCurrencyInfo((prev) => [...prev, initialCurrencyInfoState])
  }

  const handleDeleteCurrency = (index: number): void => {
    const updatedInfo = currencyInfo.filter((_, i) => i !== index)
    setCurrencyInfo(updatedInfo)
  }

  const handleValidation = (): boolean => {
    let hasErrors = false
    if (Number(subscriptionInfo.type) === SubscriptionType.Plan) {
      if (
        !subscriptionInfo.name ||
        focusInput.name.errorMessage ||
        !subscriptionInfo.priceSEK ||
        focusInput.priceSEK.errorMessage
      ) {
        for (const item of Object.values(SubscriptionInputs).slice(0, 2)) {
          handleFormErrors(item, subscriptionInfo[item])
        }
        hasErrors = true
      }
    } else {
      if (
        !subscriptionInfo.name ||
        focusInput.name.errorMessage ||
        !subscriptionInfo.priceAnnualSEK ||
        focusInput.priceAnnualSEK.errorMessage ||
        !subscriptionInfo.priceMonthlySEK ||
        focusInput.priceMonthlySEK.errorMessage ||
        (!Number(subscriptionInfo.profileIncludedAnnual) &&
          Number(subscriptionInfo.profileIncludedAnnual) !== 0 &&
          subscriptionInfo.profileIncludedAnnual.toString().toLowerCase() !==
            'Unlimited'.toLowerCase()) ||
        (!Number(subscriptionInfo.academyCoursesIncludedAnnual) &&
          Number(subscriptionInfo.academyCoursesIncludedAnnual) !== 0 &&
          subscriptionInfo.academyCoursesIncludedAnnual.toString().toLowerCase() !==
            'Unlimited'.toLowerCase()) ||
        (!Number(subscriptionInfo.facilitatorConsultationHoursIncludedAnnual) &&
          Number(subscriptionInfo.facilitatorConsultationHoursIncludedAnnual) !== 0 &&
          subscriptionInfo.facilitatorConsultationHoursIncludedAnnual.toString().toLowerCase() !==
            'Unlimited'.toLowerCase())
      ) {
        for (const item of Object.values(SubscriptionInputs).slice(0, 7)) {
          handleFormErrors(item, subscriptionInfo[item])
        }
        hasErrors = true
      }
    }
    if (!subscriptionInfo.invoice && !subscriptionInfo.creditCard) {
      handleFormErrors(SubscriptionInputs.invoice, '')
      hasErrors = true
    }

    return hasErrors
  }

  const handleSubmit = async (): Promise<void> => {
    if (handleValidation()) return
    dispatch(setSpinner(true))

    const subscriptionCurrencies: ISubscriptionCurrency[] = currencyInfo
      .map((currency) => {
        if (Number(subscriptionInfo.type) === SubscriptionType.Plan) {
          if (currency.currencyId && currency.price) {
            return {
              currencyId: currency.currencyId,
              price: Number(currency.price),
              priceAnnual: 0,
              priceMonthly: 0,
            }
          } else return
        } else {
          if (currency.currencyId && currency.priceAnnual && currency.priceMonthly) {
            return {
              currencyId: currency.currencyId,
              price: 0,
              priceAnnual: Number(currency.priceAnnual),
              priceMonthly: Number(currency.priceMonthly),
            }
          } else return
        }
      })
      .filter((currency): currency is ISubscriptionCurrency => currency !== undefined)

    let profileIncludedAnnual = Number(subscriptionInfo.profileIncludedAnnual)
    let academyCoursesIncludedAnnual = Number(subscriptionInfo.academyCoursesIncludedAnnual)
    let facilitatorConsultationHoursIncludedAnnual = Number(
      subscriptionInfo.facilitatorConsultationHoursIncludedAnnual
    )
    if (subscriptionInfo.profileIncludedAnnual.toLowerCase() === 'Unlimited'.toLowerCase()) {
      profileIncludedAnnual = 99999
    }
    if (subscriptionInfo.academyCoursesIncludedAnnual.toLowerCase() === 'Unlimited'.toLowerCase()) {
      academyCoursesIncludedAnnual = 99999
    }
    if (
      subscriptionInfo.facilitatorConsultationHoursIncludedAnnual.toLowerCase() ===
      'Unlimited'.toLowerCase()
    ) {
      facilitatorConsultationHoursIncludedAnnual = 99999
    }

    const body: ISubscriptionBody = {
      ...subscriptionInfo,
      type: Number(subscriptionInfo.type),
      subscriptionCurrencies,
      profileIncludedAnnual,
      academyCoursesIncludedAnnual,
      facilitatorConsultationHoursIncludedAnnual,
      id: subscriptionId ?? 0,
    }

    if (subscriptionId) {
      await updateSubscription(body, dispatch)
        .then((response) => {
          if (response?.success) {
            handleRefetchSubscriptions()
            closeModal()
            dispatch(addToast('Subscription updated successfully') as AnyAction)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
        })
    } else {
      await createSubscription(body, dispatch)
        .then((response) => {
          if (response?.success) {
            handleRefetchSubscriptions()
            closeModal()
            dispatch(addToast('Subscription created successfully') as AnyAction)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
        })
    }
  }

  return {
    languageText,
    subscriptionInfo,
    focusInput,
    subscriptionTypes,
    currencies,
    currencyInfo,
    handleInputChange,
    handleSubscriptionTypeChange,
    handleTextAreaChange,
    handleBlurEvent,
    handleCheckboxChange,
    handleCurrencyInputChange,
    handleCurrencyDropdownChange,
    handleAddCurrency,
    handleDeleteCurrency,
    handleSubmit,
  }
}
